import React, { useState, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormControl,
  ProgressBar,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import Endpoints from "../../../components/services/Endpoints";
import fileDownload from "js-file-download";
import * as Loader from "react-loader-spinner";
import "../Usersignin.css";
import LogoNegativo from "../../../CDLlogoNegativo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const MySwal = withReactContent(Swal);
var CodiceFiscale = require("codice-fiscale-js");
const ENDPOINT = Endpoints.getUrls();

const CreateAccount = (props) => {
  const navigate = useNavigate();

  const auth = useAuth();
  const token = auth.user?.expires_at;

  const detail = auth.user?.profile;

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadDocPercentage, setUploadDocPercentage] = useState(0);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [gender, setGender] = useState("");
  const [birthplaceProvincia, setBirthplaceProvincia] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [codiceFiscaleCv, setCodiceFiscaleCv] = useState("");

  const [verificato, setVerificato] = useState(false);
  const [validated, setValidated] = useState(false);

  //questi per l'upload
  const [fileCollaboratore, setFileCollaboratore] = useState([]);
  const [fileDocumento, setFileDocumento] = useState([]);
  //questi per il db
  const [mandatoCollaboratore, setMandatoCollaboratore] = useState([]);
  const [documentoUser, setDocumentoUser] = useState([]);

  const params = useLocation();
  console.log(params);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let newUserObj = {
        nome: event.target.nome.value,
        cognome: event.target.cognome.value,
        codice_fiscale: event.target.codice_fiscale.value,
        partita_iva: event.target.partita_iva.value,
        ragione_sociale: event.target.ragione_sociale.value,
        cellulare: event.target.cellulare.value,
        telefono: event.target.telefono.value,
        consenso: "si",
        email: event.target.email.value,
        creato: new Date().toLocaleString("it-IT"),
        pec: event.target.pec.value,
        fax: event.target.fax.value,
        roles: ["consulente", "teleconsul"],
        stato: "Off",
        password:
          "$2a$10$1ptBKCI2SSkvkd4UGO6aou3smO59DuCAp8hfYqpi21XSwpafkqbRy",
        ritratto: "",
        ultimo_accesso: "",
        indirizzo: {
          via: event.target.via.value,
          cap: event.target.cap.value,
          comune: event.target.comune.value,
          provincia: event.target.provincia.value,
          regione: event.target.regione.value,
          citta: event.target.citta.value,
        },
        documento: documentoUser,
        collaboratore: {
          nome: name,
          cognome: surname,
          codice_fiscale: codiceFiscaleCv,
        },
        mandato: mandatoCollaboratore,
        localita_esercizio: event.target.localita_esercizio.value,
        subid: params.state.subid,
      };

      console.log(newUserObj);

      if (newUserObj.nome) {
        axios
          .post(`${ENDPOINT}/users/teleconsul/create/`, newUserObj)
          .then(function (response) {
            if (response.data.upsertedId) {
              async function fetchData() {
                // You can await here
                let response = await fetch(
                  process.env.REACT_APP_BACKEND_URL +
                  "/users/teleconsul/fetch/",
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json, text/plain, */*",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ subid: params.state.subid }),
                  }
                );
                response = await response.json();
                if (response) {
                  console.log(response);
                  //  setData(response.authuser);
                  let info = response.authuser;

                  let tokenObj = {
                    _id: params.state._id,
                    nome: info.nome,
                    cognome: info.cognome,
                    email: info.email,
                    cellulare: info.cellulare,
                    telefono: info.telefono,
                    role: info.roles,
                    token: info.accessToken,
                    subscription: info.subscription,
                    created_at: info.creato,
                  };
                  localStorage.setItem("currentUser", JSON.stringify(tokenObj));

                  Swal.fire({
                    title: "Account",
                    text: `Account creato con successo e in attesa di convalida: puoi entrare nella piattaforma, ma non potrai operare finchè non verrà convalidato`,
                    icon: "info",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Ok!",
                    footer: `<b>Teleconsul<b/>`,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // event.target.form.reset();
                      navigate("/consulente/tipo_pratica");
                    }
                  });
                }

                // ...
              }
              fetchData();

              /*      
                  Swal.fire(
                    `Account creato con successo!`,
                    'Il tuo profilo sarà attivato a breve'
                  )
                  console.log(response.data);
                  event.target.reset();
                  navigate('/');
*/
            } else {
              Swal.fire("Impossibile procede");

              console.log(response.data);
              event.target.reset();
              navigate("/");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      event.preventDefault();

      setValidated(true);
    }
  };

  let urlfile = `${ENDPOINT}/static/documenti/`;
  let nomefile = "incarico_collaboratore_volontario.pdf";

  const handleDownload = (urlfile, nomefile) => {
    axios
      .get(urlfile, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, nomefile);
      });
  };

  const onFileCollaboratoreChange = (event) => {
    setFileCollaboratore(event.target.files[0]);
  };

  const handleFileCollaboratoreUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("mandato", fileCollaboratore);
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        console.log(progressEvent);
        let percent = Math.floor((loaded * 100) / total);
        setUploadPercentage((loaded * 100) / total);
        console.log(percent);
        if (percent < 100) {
          //  setUploadPercentage(percent);
        }
      },
    };

    // Details of the uploaded file
    console.log(fileCollaboratore);

    const headers = {
      "Content-Type": "multipart/form-data",
      mode: "no-cors",
    };
    // Request made to the backend api
    // Send formData object

    axios
      .post(`${ENDPOINT}/mandato`, formData, options, headers)
      .then((response) => {
        console.log(response);
        let fileCollObject = [
          {
            filename: response.data[0].filename,
            creato: new Date().toLocaleString(),
            path: `${ENDPOINT}/static/mandato/${response.data[0].filename}`,
          },
        ];

        setMandatoCollaboratore(fileCollObject);
      })
      .catch((error) => {
        console.log(error);
        setUploadPercentage(0);
      });
  };

  const onFileDocumentoChange = (event) => {
    setFileDocumento(event.target.files[0]);
  };

  const handleFileDocumentoUpload = () => {
    const formData = new FormData();
    // Update the formData object
    formData.append("documentouser", fileDocumento);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        console.log(progressEvent);

        let percent = Math.floor((loaded * 100) / total);
        setUploadDocPercentage((loaded * 100) / total);

        console.log(percent);
        if (percent < 100) {
          //  setUploadPercentage(percent);
        }
      },
    };

    // Details of the uploaded file
    console.log(fileDocumento);

    const headers = {
      "Content-Type": "multipart/form-data",
      mode: "no-cors",
    };
    // Request made to the backend api
    // Send formData object

    axios
      .post(`${ENDPOINT}/documentouser`, formData, options, headers)
      .then((response) => {
        console.log(response);

        //   [{"fieldname":"documentouser","originalname":"spedizioneOcchialiVintageSephora.pdf","encoding":"7bit","mimetype":"application/pdf","destination":"./public/data/uploads/identitausers/","filename":"1654840577918_spedizioneOcchialiVintageSephora.pdf","path":"public/data/uploads/identitausers/1654840577918_spedizioneOcchialiVintageSephora.pdf","size":30500}]

        let fileDocObject = [
          {
            filename: response.data[0].filename,
            creato: new Date().toLocaleString(),
            path: `${ENDPOINT}/static/identitausers/${response.data[0].filename}`,
          },
        ];

        setDocumentoUser(fileDocObject);
      })
      .catch((error) => {
        console.log(error);
        setUploadPercentage(0);
      });
  };

  // File content to be displayed after
  // file upload is complete
  const fileCollaboratoreData = () => {
    if (fileCollaboratore) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {fileCollaboratore.name}</p>
          <p>File Type: {fileCollaboratore.type}</p>
          <p>
            Last Modified: {fileCollaboratore.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  const fileDocumentoData = () => {
    if (fileCollaboratore) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {fileDocumento.name}</p>
          <p>File Type: {fileDocumento.type}</p>
          <p>Last Modified: {fileDocumento.lastModifiedDate.toDateString()}</p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  const handleChange = (event) => {
    console.log("hello");
  };

  const onChangeSurName = async (event) => {
    await setSurname(event.target.value);
  };

  const onChangeName = async (event) => {
    await setName(event.target.value);
  };

  const onChangeGender = async (event) => {
    await setGender(event.target.value);
  };

  const onChangeBirthplace = async (event) => {
    await setBirthplace(event.target.value);
  };
  const onChangeBirthplaceProvincia = async (event) => {
    await setBirthplaceProvincia(event.target.value);
  };

  const onChangeDay = async (event) => {
    await setDay(event.target.value);
  };
  const onChangeMonth = async (event) => {
    await setMonth(event.target.value);
  };
  const onChangeYear = async (event) => {
    await setYear(event.target.value);
  };

  const calcolaCF = () => {
    calcolaCFx();
  };

  const verificaCF = (event) => {
    verificaCFx(event.target.value);
  };

  function calcolaCFx() {
    if (gender) {
      console.log(gender);
    } else {
      alert("Sesso deve essere M o F");
    }

    var cf = new CodiceFiscale({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });

    setCodiceFiscaleCv(cf);

    console.log({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });
    var cf = new CodiceFiscale({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });

    console.log(cf.code);

    setCodiceFiscaleCv(cf.code);
    verificaCFx(cf.code);
  }

  function verificaCFx(cf) {
    if (CodiceFiscale.check(cf)) {
      console.log("corretto");
      setVerificato(true);
    } else {
      console.log("errato");
      setVerificato(false);
    }
  }

  return (
    <div className="login-page bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h3 className="mb-3 text-white"> </h3>
            <div
              className="bg-white shadow rounded"
              style={{
                position: "relative",
                top: "50%",
                WebkitTransform: "translateY(-50%)",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)",
              }}
            >
              <div className="row">
                <div className="col-md-9 pe-0">
                  <div className="form-left h-100 py-5 px-5">
                    <Form
                      className="row g-3 needs-validation"
                      novalidate
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="Nome">Nome</Form.Label>
                          <Form.Control
                            required
                            placeholder="Nome"
                            name="nome"
                            type="text"
                            defaultValue={detail.given_name}
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="Cognome">Cognome</Form.Label>
                          <Form.Control
                            required
                            placeholder="Cognome"
                            name="cognome"
                            type="text"
                            defaultValue={detail.family_name}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="Ragione Sociale">
                            Ragione Sociale
                          </Form.Label>
                          <Form.Control
                            placeholder="Ragione Sociale"
                            name="ragione_sociale"
                            required
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="email">Email</Form.Label>
                          <Form.Control
                            required
                            placeholder="email"
                            name="email"
                            defaultValue={detail.email}
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="cellulare">Cellulare</Form.Label>
                          <Form.Control
                            required
                            placeholder="cellulare"
                            name="cellulare"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="telefono">Telefono</Form.Label>
                          <Form.Control
                            required
                            placeholder="telefono"
                            name="telefono"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="fax">Fax</Form.Label>
                          <Form.Control
                            placeholder="fax"
                            defaultValue={"0000000000"}
                            name="fax"
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Pec
                          </Form.Label>
                          <Form.Control
                            placeholder="PEC"
                            name="pec"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            C.F.
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="Codice Fiscale"
                            name="codice_fiscale"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            P. I.
                          </Form.Label>
                          <Form.Control
                            placeholder="Partita IVA"
                            name="partita_iva"
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col className="mb-2 mt-2">
                          <Form.Label htmlFor="disabledTextInput">
                            Indirizzo
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="via"
                            name="via"
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            CAP
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="cap"
                            name="cap"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Comune
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="comune"
                            name="comune"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Provincia
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="esempio: MI"
                            name="provincia"
                            type="text"
                            maxLength="2"
                            style={{ textTransform: "uppercase" }}
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Regione
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="regione"
                            name="regione"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Città
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="citta"
                            name="citta"
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Località esercizio
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="localita_esercizio"
                            name="localita_esercizio"
                            type="text"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-4">
                        <Col>
                          <h4>
                            Collaboratore volontario (Obbligatorio){" "}
                            <i className="bi bi-person-plus-fill"></i>
                          </h4>
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Cognome
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="cv_cognome"
                            name="surname"
                            type="text"
                            onChange={onChangeSurName}
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Nome
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="cv_nome"
                            name="name"
                            type="text"
                            onChange={onChangeName}
                          />
                        </Col>
                        <Col className="col-2">
                          <Form.Label htmlFor="disabledTextInput">
                            Sesso
                          </Form.Label>
                          <Form.Select
                            required
                            aria-label="Sesso"
                            name="gender"
                            onChange={onChangeGender}
                          >
                            <option>Apri per selezionare</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row className="mb-3 mt-3">
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Luogo di nascita
                          </Form.Label>
                          <Form.Control
                            placeholder="luogo"
                            name="birthplace"
                            onChange={onChangeBirthplace}
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor="disabledTextInput">
                            Provincia (Sigla)
                          </Form.Label>
                          <Form.Control
                            placeholder="provincia"
                            name="birthplaceProvincia"
                            maxLength={2}
                            onChange={onChangeBirthplaceProvincia}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2 mt-2">
                        <Col>
                          <Form.Label htmlFor="Giorno">Giorno</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="day"
                            onChange={onChangeDay}
                          >
                            <option>Apri per selezionare</option>
                            <option value="02">02</option>
                            <option value="01">01</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </Form.Select>
                        </Col>

                        <Col>
                          <Form.Label htmlFor="Mese">Mese</Form.Label>
                          <Form.Select
                            aria-label="Mese"
                            name="month"
                            onChange={onChangeMonth}
                          >
                            <option>Apri per selezionare</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </Form.Select>
                        </Col>

                        <Col>
                          <Form.Label htmlFor="Anno">Anno</Form.Label>
                          <Form.Select
                            aria-label="Anno"
                            name="year"
                            onChange={onChangeYear}
                          >
                            <option>Apri per selezionare</option>
                            <option value="1920">1920</option>
                            <option value="1921">1921</option>
                            <option value="1922">1922</option>
                            <option value="1923">1923</option>
                            <option value="1924">1924</option>
                            <option value="1925">1925</option>
                            <option value="1926">1926</option>
                            <option value="1927">1927</option>
                            <option value="1928">1928</option>
                            <option value="1929">1929</option>
                            <option value="1930">1930</option>
                            <option value="1931">1931</option>
                            <option value="1932">1932</option>
                            <option value="1933">1933</option>
                            <option value="1934">1934</option>
                            <option value="1935">1935</option>
                            <option value="1936">1936</option>
                            <option value="1937">1937</option>
                            <option value="1938">1938</option>
                            <option value="1939">1939</option>
                            <option value="1940">1940</option>
                            <option value="1941">1941</option>
                            <option value="1942">1942</option>
                            <option value="1943">1943</option>
                            <option value="1944">1944</option>
                            <option value="1945">1945</option>
                            <option value="1946">1946</option>
                            <option value="1947">1947</option>
                            <option value="1948">1948</option>
                            <option value="1949">1949</option>
                            <option value="1950">1950</option>
                            <option value="1951">1951</option>
                            <option value="1952">1952</option>
                            <option value="1953">1953</option>
                            <option value="1954">1954</option>
                            <option value="1955">1955</option>
                            <option value="1956">1956</option>
                            <option value="1957">1957</option>
                            <option value="1958">1958</option>
                            <option value="1959">1959</option>
                            <option value="1960">1960</option>
                            <option value="1961">1961</option>
                            <option value="1962">1962</option>
                            <option value="1963">1963</option>
                            <option value="1964">1964</option>
                            <option value="1965">1965</option>
                            <option value="1966">1966</option>
                            <option value="1967">1967</option>
                            <option value="1968">1968</option>
                            <option value="1969">1969</option>
                            <option value="1970">1970</option>
                            <option value="1971">1971</option>
                            <option value="1972">1972</option>
                            <option value="1973">1973</option>
                            <option value="1974">1974</option>
                            <option value="1975">1975</option>
                            <option value="1976">1976</option>
                            <option value="1977">1977</option>
                            <option value="1978">1978</option>
                            <option value="1979">1979</option>
                            <option value="1980" defaultValue={"1980"}>
                              19800
                            </option>
                            <option value="1981">1981</option>
                            <option value="1982">1982</option>
                            <option value="1983">1983</option>
                            <option value="1984">1984</option>
                            <option value="1985">1985</option>
                            <option value="1986">1986</option>
                            <option value="1987">1987</option>
                            <option value="1988">1988</option>
                            <option value="1989">1989</option>
                            <option value="1990">1990</option>
                            <option value="1991">1991</option>
                            <option value="1992">1992</option>
                            <option value="1993">1993</option>
                            <option value="1994">1994</option>
                            <option value="1995">1995</option>
                            <option value="1996">1996</option>
                            <option value="1997">1997</option>
                            <option value="1998">1998</option>
                            <option value="1999">1999</option>
                            <option value="2000">2000</option>
                            <option value="2001">2001</option>
                            <option value="2002">2002</option>
                            <option value="2003">2003</option>
                            <option value="2004">2004</option>
                            <option value="2005">2005</option>
                            <option value="2006">2006</option>
                            <option value="2007">2007</option>
                            <option value="2008">2008</option>
                            <option value="2009">2009</option>
                            <option value="2010">2010</option>
                            <option value="2011">2011</option>
                            <option value="2012">2012</option>
                            <option value="2013">2013</option>
                            <option value="2014">2014</option>
                            <option value="2015">2015</option>
                            <option value="2016">2016</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                          </Form.Select>
                        </Col>
                      </Row>

                      <Row className="mb-3 mt-3">
                        <Col>
                          <Button
                            variant="success"
                            style={{ float: "right" }}
                            onClick={calcolaCF}
                          >
                            Calcola/Verifica
                          </Button>{" "}
                        </Col>
                      </Row>

                      <Row
                        className="mb-2 px-4 py-4 row"
                        style={{ background: "#009345", borderRadius: "6px" }}
                      >
                        <Col>
                          <Form.Label
                            htmlFor="disabledTextInput"
                            style={{ color: "white" }}
                          >
                            Codice Fiscale
                          </Form.Label>
                          <Form.Control
                            required
                            placeholder="codice fiscale del collaboratore"
                            name="cv-codice-fiscale"
                            type="text"
                            defaultValue={codiceFiscaleCv}
                            onChange={verificaCF}
                          />
                        </Col>
                        <Col>
                          {verificato ? (
                            <div style={{ color: "white" }}>
                              {" "}
                              Verificato!{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="currentColor"
                                className="bi bi-patch-check-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                              </svg>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>

                      <Row className="mb-3 mt-3">
                        <Col>
                          <label className="form-info-label">
                            Per usufruire dei Servizi di Patronato per il CDL
                            devi:
                          </label>
                          <p></p>
                          <ul
                            style={{
                              listStyleType: "upper-roman",
                              padding: "4px",
                            }}
                          >
                            <li style={{ padding: "4px" }}>
                              Scaricare la richiesta di nomina di collaboratore
                              volontario A.NAS:PRO (clicca su Scarica Mandato)
                            </li>
                            <li style={{ padding: "4px" }}>
                              Compilarla la richiesta in tutte le sue parti
                            </li>
                            <li style={{ padding: "4px" }}>
                              Scansionarla e salvarla in formato pdf
                            </li>
                            <li style={{ padding: "4px", color: "red" }}>
                              Attenzione: rinominare come da esempio:
                              nomina_collaboratore_nome_cognome_2022.pdf
                            </li>
                            <li style={{ padding: "4px" }}>
                              Caricare la richiesta salvata e rinominata in pdf
                              (Cliccando su Carica il mandato)
                            </li>
                            <li style={{ padding: "4px" }}>
                              Caricare copia del documento d'identità del
                              Collaboratore volontario in pdf (Clicca su Carica
                              il documeno)
                            </li>
                            <li style={{ padding: "4px", color: "red" }}>
                              Attenzione: rinominare come da esempio:
                              documento_personale_nome_cognome_2022.pdf
                            </li>
                            <li style={{ padding: "4px" }}>
                              Infine clicca su (Crea nuovo utente per
                              finalizzare l'iscrizione)
                            </li>
                            <li style={{ padding: "4px" }}>
                              {" "}
                              Consulta lo statuto Anaspro{" "}
                              <a
                                href={`${ENDPOINT}/static/documenti/STATUTO_ANASPRO.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                CLICCA QUI
                              </a>
                            </li>
                          </ul>
                        </Col>
                        <Row className="mb-3 mt-3">
                          <Col>
                            <a
                              className="btn btn-primary"
                              style={{ float: "right" }}
                              href={`${ENDPOINT}/static/documenti/incarico_collaboratore_volontario.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Scarica il mandato
                            </a>{" "}
                          </Col>
                        </Row>
                      </Row>

                      <Row className="mb-3 mt-3">
                        <Col>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>
                              Carica mandato del Collaboratore Volontario
                            </Form.Label>
                            <Form.Control
                              name="mandato"
                              type="file"
                              accept=".pdf"
                              onChange={onFileCollaboratoreChange}
                            />
                          </Form.Group>
                          {uploadPercentage > 0 && (
                            <div className="row mt-3">
                              <div className="col pt-1">
                                <ProgressBar
                                  now={uploadPercentage}
                                  striped={true}
                                  label={`${uploadPercentage}%`}
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary px-4 float-end mt-4"
                            onClick={handleFileCollaboratoreUpload}
                          >
                            Carica il mandato
                          </button>
                        </div>
                      </Row>

                      {/* fileCollaboratore */}

                      <Row className="mb-3 mt-3">
                        <Col>
                          <Form.Group controlId="formFile2" className="mb-3">
                            <Form.Label>Documento d'identità</Form.Label>
                            <Form.Control
                              name="documento"
                              type="file"
                              accept=".pdf"
                              onChange={onFileDocumentoChange}
                            />
                          </Form.Group>
                          {uploadDocPercentage > 0 && (
                            <div className="row mt-3">
                              <div className="col pt-1">
                                <ProgressBar
                                  now={uploadDocPercentage}
                                  striped={true}
                                  label={`${uploadDocPercentage}%`}
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary px-4 float-end mt-4"
                            onClick={handleFileDocumentoUpload}
                          >
                            Carica il documeto
                          </button>
                        </div>
                      </Row>

                      <Row className="mb-3 mt-3">
                        <Col>
                          {mandatoCollaboratore.length > 0 &&
                            documentoUser.length > 0 ? (
                            <div style={{ padding: "15px" }}>
                              <Button
                                type="submit"
                                variant="primary"
                                style={{ float: "left" }}
                              >
                                Crea nuovo utente
                              </Button>{" "}
                            </div>
                          ) : (
                            <div style={{ padding: "15px" }}>
                              <Button
                                type="submit"
                                variant="info"
                                style={{ float: "left" }}
                                disabled
                              >
                                Crea nuovo utente
                              </Button>{" "}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div className="col-md-3 ps-0 d-none d-md-block">
                  <div className="form-right h-100 bg-primary text-white text-center pt-5">
                    <img src={LogoNegativo} style={{ maxWidth: "200px" }}></img>
                    <p></p>
                    <h3 className="fs-1">Iscrizione:</h3>

                    <h3 className="mb-3 text-white">Compila tutti i campi</h3>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-end text-secondary mt-3">
              Bootstrap 5 Login Page Design
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
