
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalContact(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Contatti
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        backgroundColor: '#0d6efd',
        color: 'white'
      }}>

        <div className="contact-wrapper" style={{
          backgroundColor: '#0d6efd',
          color: 'white'
        }}>
          {/*   <PageTitle content="Contatti" />  */}

          <h4>Gli operatori osservano il seguente orario di lavoro:</h4>
          <p>
            Lunedì - Venerdì dalle ore 9:00 alle ore 13:00 e dalle ore
            14:00 alle ore 18:00.
          </p>
          <p>
            <i><u> Per informazioni sulle pratiche di patronato contattare il numero</u> <b>06 94446812</b></i>
          </p>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalContact;

