import React from 'react';
import { Container } from 'react-bootstrap';
import './Policy.css'

function Cookies(props) {
    return (
        <div>
<Container>
    <div style={{padding: '50px', color:'white'}} >
    <div class="clearfix text-formatted quickedit-field"><p>La
    presente "Cookie Policy" (la nostra politica relativa all’uso di 
   cookie) viene applicata su tutti i siti Internet, comprese le pagine su 
   piattaforme di terze parti (ad esempio, Facebook o YouTube), e alle 
   applicazioni a cui si fa accesso o che vengono utilizzate attraverso 
   tali siti internet o piattaforme di terze parti che siano condotte da o 
   per conto dell'azienda patronatocdl.it. Facendo uso dei nostri Siti, si 
   acconsente al nostro utilizzo di cookie in conformità con la presente 
   Cookie Policy. Se non si acconsente al nostro utilizzo di cookie, 
   occorre impostare il proprio browser in modo appropriato oppure non 
   usare i Siti dell'azienda patronatocdl.it. Se si decide di disabilitare i
    cookie che impieghiamo, ciò potrebbe influenzare l’esperienza 
   dell’utente mentre si trova su uno dei nostri Siti. La tabella riportata
    di seguito riepiloga i diversi tipi di cookie che potrebbero essere 
   utilizzati sui Siti dell'azienda patronatocdl.it, insieme alla loro 
   rispettiva funzione e durata (ovvero per quanto tempo ciascun cookie 
   rimarrà sul vostro dispositivo)</p>
   
   <h3>Cosa sono i cookies?</h3>
   
   <p>I cookie sono file informatici o dati parziali che possono venire 
   salvati sul vostro computer (o altri dispositivi abilitati alla 
   navigazione su internet, per esempio smartphone o tablet) quando 
   visitate un Sito. Di solito un cookie contiene il nome del sito internet
    dal quale il cookie stesso proviene, la "durata vitale" del cookie 
   (ovvero per quanto tempo rimarrà sul vostro dispositivo), ed un valore, 
   che di regola è un numero unico generato in modo casuale.</p>
   
   <h3>Per cosa usiamo i cookies?</h3>
   
   <p>Noi utilizziamo i cookie per rendere l’uso dei nostri Siti più 
   semplice e per meglio adattarli ai vostri interessi e bisogni. I cookie 
   possono anche venire usati per aiutarci a velocizzare le vostre future 
   esperienze ed attività sui nostri Siti. Inoltre usiamo i cookie per 
   compilare statistiche anonime aggregate che ci consentono di capire come
    le persone usano i nostri Siti e per aiutarci a migliorare la struttura
    ed i contenuti di questi Siti. Non siamo in grado di identificarvi 
   personalmente attraverso queste informazioni.</p>
   
   <h3>Che tipi di cookie usiamo?</h3>
   
   <p>Due tipi di cookie possono venire usati sui nostri Siti: "session 
   cookies" e "persistent cookies". I "session cookies" sono cookie 
   temporanei che rimangono sul vostro dispositivo fino al momento in cui 
   lasciate il Sito. Un "persistent cookie" rimane sul vostro dispositivo 
   per molto più tempo o fino a quando lo cancellate manualmente (quanto 
   tempo il cookie rimane sul vostro dispositivo dipenderà dalla "durata 
   vitale" del cookie in oggetto e dalle impostazioni del vostro browser). 
   Alcune delle pagine che visitate possono anche raccogliere informazioni 
   mediante "pixel tags" (conosciuti anche con il nome "clear gifs") che 
   potrebbero venire condivisi con terze parti che assistono in modo 
   diretto le nostre attività promozionali e lo sviluppo dei siti internet.
    Per esempio, informazioni sull’uso dei siti internet relative alle 
   persone che visitano i nostri Siti possono venire condivise con agenzie 
   pubblicitarie per mirare i banner pubblicitari sui nostri siti internet 
   in modo ottimale. In ogni caso, le informazioni non sono personalmente 
   identificabili, anche se potrebbero venire agganciate ai vostri dati 
   personali.</p>
   
   <h3>Cookies utilizzati sui Siti dell'azienda patronatocdl.it</h3>
   
   <p><em>Cookies necessari</em>&nbsp;Questi cookie sono essenziali per il 
   corretto funzionamento dei nostri siti, consentono alle persone di 
   navigare sui nostri siti internet e di sfruttarne le caratteristiche 
   come la memorizzazione di azioni precedenti (testi inseriti) quando si 
   ritorna ad una pagina nel corso della medesima sessione. Questi cookie 
   non ti identificano come individuo. Se non si accettano questi cookie, 
   la resa del sito internet, o sezioni dello stesso, potrebbe risultare 
   impattate.</p>
   
   <p><em>Cookies performance</em>&nbsp;Questi cookie ci aiutano a capire come 
   gli utenti interagiscono con i nostri siti internet fornendoci 
   informazioni relative alle sezioni visitate, il tempo trascorso sul 
   sito, e qualsiasi questione che sia emersa, per esempio un messaggio di 
   errore. Questo ci aiuta a migliorare la resa dei nostri siti internet. 
   Questi cookie non ti identificano come individuo. Tutti i dati vengono 
   raccolti e aggregati in forma anonima.</p>
   
   <p><em>Cookies funzionalità</em>&nbsp;Questi cookie permettono ai nostri siti
    internet di ricordare le scelte che fate (per esempio, il vostro nome 
   utente, la lingua prescelta o la regione in cui vi trovate, gli 
   eventuali prodotti inseriti nel carrello della spesa) per fornirvi 
   un’esperienza online più personalizzata. Possono anche consentire agli 
   utenti di visualizzare i video, entrare nei giochi e interagire con gli 
   strumenti sociali tipo blog, chatroom e forum. Le informazioni che 
   vengono raccolte da questi cookie possono includere informazioni 
   personali identificabili che avete fornito, per esempio il vostro nome 
   utente o immagine del profilo. Saremo sempre trasparenti nei vostri 
   confronti in merito a quali informazioni raccogliamo, l‘uso che ne 
   facciamo e con chi le condividiamo. Se non si accettano questi cookie, 
   la resa e funzionalità del sito internet potrebbe risultare impattate e 
   l’accesso ai contenuti del sito potrebbe venire limitata.</p>
   
   <p><em>Cookies targeting/pubblicità</em>&nbsp;Questi cookie vengono usati per
    presentare contenuti più adatti a voi e ai vostri interessi. Possono 
   venire usati per visualizzare pubblicità mirate o per limitare il numero
    di volte che visualizzate una pubblicità. Inoltre ci aiutano a misurare
    l’efficacia delle campagne pubblicitarie sui nostri siti internet. 
   Potremmo usare questi cookie per ricordare i siti internet che avete 
   visitato e potremmo condividere queste informazioni con terze parti, 
   incluse le nostre agenzie e gli inserzionisti. La maggioranza di cookie 
   di questo tipo tracciano i consumatori attraverso il loro indirizzo IP e
    quindi potrebbero raccoglier alcune informazioni personali 
   identificabili. Per ulteriori informazioni su questi cookie, incluse le 
   informazioni che raccolgono e come vengono usate, consultate la relativa
    tabella.</p>
   
   <h3>Facciamo uso di cookie di terze parti?</h3>
   
   <p>Facciamo uso di svariati fornitori che possono altresì impostare 
   cookie sul vostro dispositivo per nostro conto quando visitate i nostri 
   siti per consentirgli di erogare i servizi che stanno fornendo. Se 
   desiderate avere ulteriori informazioni relative a questi cookie, 
   insieme a informazioni su come evitare la ricezione di questi cookie, vi
    preghiamo di consultare la tabella apposita. Quando visitate i nostri 
   siti potreste ricevere cookie da siti internet o domini di terze parti. 
   Noi ci sforziamo per identificare questi cookie prima che vengano 
   utilizzati in modo da permettervi di decidere se desiderate accettarli o
    meno. Ulteriori informazioni relative a questi cookie potrebbero essere
    disponibili sul sito internet della terza parte in questione.</p>
   
   <h3>Come posso controllare o cancellare i cookies?</h3>
   
   <p>La maggioranza dei browser internet sono inizialmente impostati per 
   accettare i cookie in modo automatico. Potete modificare queste 
   impostazioni per bloccare i cookie o per avvertirvi che dei cookie 
   vengono mandati al vostro dispositivo. Esistono svariati modi per 
   gestire i cookie. Vi preghiamo di fare riferimento al manuale 
   d’istruzioni o alla schermata di aiuto del vostro browser per scoprire 
   come regolare o modificare le impostazioni del vostro browser. Se 
   disabilitate i cookie di cui facciamo uso, ciò potrebbe influenzare la 
   vostra esperienza mentre vi trovate sui nostri siti, per esempio 
   potreste non essere in grado di visitare certe sezioni o non ricevere 
   informazioni personalizzate quando visitate un nostro Sito. Se usate 
   dispositivi diversi tra loro per visualizzare e accedere ai Siti 
   Internet (per esempio, computer, smartphone, tablet, ecc.), dovrete 
   assicurarvi che ciascun browser su ciascun dispositivo sia regolato per 
   riflettere le vostre preferenze relative ai cookie.</p>
   
   <h3>Ulteriori informazioni specifiche</h3>
   
   <p>I cookie di sessione/temporanei vengono cancellati dal vostro 
   dispositivo quando uscite dal sito internet. I cookie 
   permanenti/persistenti non vengono cancellati dal vostro dispositivo 
   quando uscite dal sito internet. Rimangono sul vostro dispositivo per un
    lasso di tempo maggiore.</p>
   
   <h3>PERFORMANCE: Cookie Analitico/Tracking</h3>
   
   <p>Fornisce informazioni anonime e aggregate su dove andate e cosa fate 
   sui nostri siti e su altri siti internet. È persistente, di sessione e 
   di terze parti. Per ulteriori dettagli clicca su:&nbsp;<a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">Google Analytics</a>&nbsp;<a href="http://www.google.co.uk/intl/en/policies/privacy/">YouTube Tracking</a>&nbsp;Per
    migliorare ed ottimizzare costantemente la nostra offerta, impieghiamo 
   cosiddette tecnologie tracking. A tal fine utilizziamo i servizi di 
   Google Analytics. Google Analytics è un servizio della Google Inc., 1600
    Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Google 
   Analytics utilizza i "cookies“, dei file di testo salvati sul tuo 
   computer, che permettono di analizzare come utilizzi la nostra offerta 
   internet attraverso Google. Le informazioni ricavate dal cookie sul tuo 
   utilizzo nei nostri web (incluso l’indirizzo IP) sono normalmente 
   trasmesse e salvate presso il server della Google negli USA. Ti 
   informiamo che sui nostri siti web Google Analytics è stato ampliato con
    il codice “gat.anonymizelp();” al fine di garantire il rilevamento 
   anonimo degli indirizzi IP (cosiddetto IP-Masking). Pertanto, su nostra 
   richiesta, Google rileva il tuo indirizzo IP soltanto in forma 
   abbreviata, il che garantisce l’anonimato e non permette la tua 
   identificazione. Nel caso in cui su questo sito web si dovesse attivare 
   l’anonimizzazione dell’IP, il tuo indirizzo IP verrà abbreviato 
   preventivamente all’interno degli stati membri dell’Unione Europea o di 
   altri stati che hanno aderito all’accordo sullo spazio economico 
   europeo. Soltanto in casi eccezionali l’intero indirizzo IP sarà inviato
    ai server di Google negli USA e poi abbreviato sul posto. Google 
   rispetta inoltre le disposizioni sulla protezione dei dati della 
   convenzione “US-Safe-Harbor” ed è registrato presso il programma “Safe 
   Harbor” del ministero del commercio americano. Google utilizzerà queste 
   informazioni per valutare il tuo utilizzo dei nostri siti web, per 
   redigere dei protocolli sulle attività dei siti web e per fornirci altri
    servizi connessi all’utilizzo del sito web e di internet. L’indirizzo 
   IP che Google Analytics trasferisce dal tuo browser non sarà associato 
   con altri dati di Google. Google trasmetterà queste informazioni a terzi
    soltanto nel caso in cui vi siano delle prescrizioni di legge o al fine
    dell’elaborazione dati. In nessun caso Google assocerà i tuoi dati con 
   altri dati rilevati da Google. Utilizzando questi siti web, accetti 
   l’elaborazione dei tuoi dati rilevati nella maniera e per gli scopi 
   sopra descritti. Puoi evitare la memorizzazione dei cookies attraverso 
   le apposite impostazioni del software del tuo browser; ti avvisiamo però
    che in tal caso potrebbe essere compromessa qualche funzionalità dei 
   tuoi siti web. Inoltre puoi impedire il rilevamento da parte di Google 
   dei dati creati dai cookies inerenti all’utilizzo che fai dei siti web 
   (incluso l’indirizzo IP); la stessa cosa vale per l'elaborazione di 
   questi dati da parte di Google. A tal fine potete scaricare ed 
   installare il browser-plugin disponibile al seguente indirizzo: 
   https://tools.google.com/dlpage/gaoptout?hl=it Qui troverete anche 
   ulteriori informazioni su Google Analytics e la protezione dei dati.</p>
   
   <h3>FUNZIONALITÀ: Cookie Social Media/condivisione (sharing)</h3>
   
   <p>Vi permettono di condividere commenti, valutazioni, pagine, bookmark e
    vi aiutano a semplificare l’accesso ai social network e agli strumenti 
   sociali su internet. Sono di terze parti. Per ulteriori dettagli clicca 
   su:<br />
   <a href="http://www.facebook.com/about/privacy/your-info-on-other#anothersite">Facebook Connect</a><br />
   <a href="http://www.facebook.com/about/privacy/your-info-on-other#socialplugin">Facebook plug-in</a><br />
   <a href="http://www.google.co.uk/intl/en/policies/privacy/">Youtube</a><br />
   <a href="http://twitter.com/privacy">Twitter</a><br />
   <a href="https://www.google.com/intl/it/policies/privacy/">Google +</a><br />
   <a href="https://about.pinterest.com/it/privacy-policy">Pinterest</a></p>
   
   <h3>TARGETING E PUBBLICITÀ: Tracking incrociato dei siti</h3>
   
   <p>Fornisce informazioni anonime sui visitatori, per esempio i siti che 
   visitano prima e/o dopo aver visitato un nostro Sito. È persistente, di 
   sessione e terze parti. Questo sito non utilizza questo tipo di cookies.</p>
   
   <h3>Rich Media</h3>
   
   <p>Sostengono vari e diversi elementi di funzionalità sui nostri Siti e 
   sui siti associati, come la visualizzazione di video, l’uso di codici di
    coupon internet / applicazioni fedeltà e il download di musica. È 
   persistente, di sessione e terze parti. Per ulteriori dettagli clicca 
   su:<br />
   <a href="http://www.google.co.uk/intl/en/policies/privacy/">Youtube</a></p>
   
   <h3>Beacon Domini</h3>
   
   <p>I "Web beacon" (fari internet, anche conosciuti con il nome web bugs,
    pixel tags, clear GIFs) controllano il comportamento dei visitatori sui
    siti internet e possono fornire informazioni su per esempio cosa gli 
   utenti fanno sui siti internet e gli indirizzi IP degli utenti a società
    terze. È persistente, di sessione e terze parti. Questo sito non 
   utilizza questo tipo di cookies.</p>
   
   <h3>Altro, geolocalizzazione</h3>
   
   <p>Sostengono vari e diversi elementi di funzionalità sui nostri Siti. È persistente, di sessione e terze parti.<br />
   <a href="http://support.google.com/maps/bin/answer.py?hl=en&amp;answer=153807">Google Maps</a></p>
   
   <h3>Come disabilitare i cookie mediante la configurazione del browser. Clicca sul browser che ti interessa:</h3>
   
   <p><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Firefox</a><br />
   <a href="http://www.google.com/policies/technologies/managing/">Chrome</a><br />
   <a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">Internet Explorer</a><br />
   <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT&amp;locale=it_IT">Safari</a><br />
   <a href="http://help.opera.com/Mac/12.10/it/cookies.html">Opera</a></p>
   
   <h3>Come disabilitare i cookie di terze parti:</h3>
   
   <p><a href="http://www.youronlinechoices.com/it">Tramite il servizio di YourOnlineChoice</a><br />
   <a href="http://www.aboutads.info/choices/">Tramite il servizio di AboutAds</a><br />
   <a href="http://www.google.com/ads/preferences/?hl=it">Servizi di Google</a><br />
   <a href="https://www.facebook.com/help/cookies?ref_type=sitefooter">Facebook</a><br />
   <a href="https://support.twitter.com/articles/20170519-uso-dei-cookie-e-di-altre-tecnologie-simili-da-parte-di-twitter">Twitter</a></p>
   </div>
    </div>
    </Container>
    </div>
    );
}

export default Cookies;