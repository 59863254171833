import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import {
  Navbar,
  NavDropdown,
  Nav,
  Container,
  Button,
  Badge,
  NavItem,
  NavbarBrand,
} from "react-bootstrap";
import Endpoints from "../components/services/Endpoints";
import logoNegativo from "../CDLlogoNegativo.svg";
import { StyledEngineProvider } from "@mui/material/styles";
import Demo from "./notifyCenter/demo";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../utils/client";
import Swal from "sweetalert2";
//import 'bootstrap/dist/css/bootstrap.min.css';

const ENDPOINT = Endpoints.getUrls();
export default function Invoice() {
  const [id, setId] = useState([]);
  const [cognome, setCognome] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [online, setOnline] = useState(0);
  const [bannerMessage, setBannerMessage] = useState("");

  let navigate = useNavigate();
  const getUserLogged = async () => {
    const operatoreClient = await JSON.parse(
      localStorage.getItem("currentUser")
    );

    const { _id, cognome, nome, email, role } = operatoreClient;

    setCognome(cognome);
    setNome(nome);
    setEmail(email);
    setRole(role);
  };

  useEffect(() => {
    const socket3 = socketIOClient(process.env.REACT_APP_BASE_URL);
    //  console.log('socket3');
    //  console.log(socket3);
    socket3.on("visitor enters", (data) => {
      setOnline(data);
      //  console.log('online', data);
      //  console.log(typeof(online), online)
      //  UserConnected();
    });

    socket3.on("visitor exits", (data) => {
      setOnline(data);
      //   console.log('online', data);
      //    UserConnected();
    });

    // CLEAN UP THE EFFECT
    return () => socket3.disconnect();
  }, []);

  useEffect(() => {
    getUserLogged();
  }, []);

  const logout = () => {
    let items = ["currentUser"];

    items.forEach((items) => {
      localStorage.removeItem(items);
    });

    navigate("/");
  };

  return (
    <main>
      <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Container fluid>
          <NavbarBrand
            href="#home"
            style={{ fontSize: "0.85rem", fontWeight: "lighter" }}
          >
            CAF Nazionale Consulenti del Lavoro
          </NavbarBrand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* 
              <NavLink to="#home">Home</NavLink>
              <NavLink to="#link">Link</NavLink>
              */}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-center">
            <Navbar>
              <span style={{ color: "rgb(208 199 4)", marginRight: 50 }}>
                utenti online: {online}
              </span>{" "}
            </Navbar>

            <Navbar>
              <span style={{ color: "rgb(208 199 4)", marginRight: 50 }}>
                <StyledEngineProvider injectFirst>
                  <Demo />
                  <ToastContainer position="bottom-right" newestOnTop />
                </StyledEngineProvider>
              </span>{" "}
            </Navbar>

            <Navbar>
              <span style={{ color: "white" }}>Ciao: </span>
              <NavDropdown title={nome}>
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}> Logout</NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
            </Navbar>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        className="logo"
        style={{ backgroundColor: "#0066cc", height: "180px" }}
      >
        <div style={{ padding: "8px" }}>
          <img src={logoNegativo} style={{ height: "160px" }} />
        </div>
      </div>

      {/* 
      <Navbar className='navbar navbar-expand-lg' style={{background: '#0d5bb0', fontSize: 'small'}} >
      <Container fluid >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{marginLeft: '18px'}}>
       
            <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>News</Nav.Link>
            <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Domande frequenti</Nav.Link>
            <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Anagrafiche</Nav.Link>
            <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Gestione Pratiche</Nav.Link>
            <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Messaggi</Nav.Link>
            <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Contatti</Nav.Link>
            <NavItem className="me-auto" >
            
            
            </NavItem>


          </Nav>
        </Navbar.Collapse>

        <Button variant="danger" style={{marginRight: '19%'}}>
        utenti online <Badge bg="secondary">{ online }</Badge>
          <span className="visually-hidden">unread messages</span>
        </Button>
      </Container>
    </Navbar>
*/}
    </main>
  );
}
