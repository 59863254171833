import React from "react";
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar,  Nav, Container, NavItem,  } from  'react-bootstrap';
const Footer = () => (
  <>
  <Navbar className='navbar navbar-expand-lg' style={{background: '#0d5bb0', fontSize: 'small', height:100}} >
  <Container fluid >
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto" style={{marginLeft: '18px'}}>
   
        <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>News</Nav.Link>
        <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Domande frequenti</Nav.Link>
        <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Anagrafiche</Nav.Link>
        <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Messaggi</Nav.Link>
        <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Contatti</Nav.Link>
        <NavItem className="me-auto" >
        
        
        </NavItem>


      </Nav>
    </Navbar.Collapse>

  </Container>
</Navbar>
</> 
);

export default Footer;