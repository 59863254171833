import React, { useState, useEffect, useMemo } from "react";
import TableContainer from "./TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Table,
  Button,
  Accordion,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import Endpoints from "../components/services/Endpoints";
import Footer from "../components/Footer/Footer";
import "./loaderino.css";

import client from "../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();

const PraticheChiuse = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const [findDescrizione, setFindDescrizione] = useState("");
  const [findRegione, setFindRegione] = useState("");
  const [findId, setFindId] = useState("");
  const [findConsulenteCognome, setFindConsulenteCognome] = useState("");
  const [findAssistitoCognome, setFindAssistitoCognome] = useState("");
  const [findAssistitoCF, setFindAssistitoCF] = useState("");
  const [findOperatoreCognome, setFindOperatoreCognome] = useState("");
  const [findFrom, setFindFrom] = useState("");
  const [findTo, setFindTo] = useState("");

  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchPraticheAll();
    fetchPraticheCount();
  }, []);

  const fetchPraticheAll = () => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/pratiche/chiuse`,
        config: { method: "GET" },
      });
      const pratiche = response.data;
      setData(pratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  };

  const fetchPraticheCount = async () => {
    const op = JSON.parse(localStorage.getItem("currentUser"));
    const id = op._id;
    const result = await client({
      url: `/pratiche/count/inviate/id?_id=${id}`,
      config: { method: "GET" },
    });
    setCount(result.data.chiuse);
  };

  const handleSelectedId = (event) => {
    setSelectedId(event.values._id);
  };

  const BasicExample = () => {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <i className="bi bi-binoculars"></i> Cerca sul server{" "}
          </Accordion.Header>
          <Accordion.Body style={{ background: "aliceblue" }}>
            <p style={{ fontWeigth: "medium", color: "darkred" }}>
              Il campo ID va utilizzato solo e non in combo con altri campi.
            </p>
            {BasicForm()}
            {data.length && (
              <p style={{ fontWeigth: "medium", marginTop: "20px" }}>
                Cerca tra {count} risultati
              </p>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const FindOnServer = (event) => {
    event.preventDefault();

    if (event.target.name === "find_id") {
      setFindId(event.currentTarget.value);
    }

    if (event.target.name === "find_descrizione") {
      setFindDescrizione(event.currentTarget.value);
    }

    if (event.target.name === "find_regione") {
      setFindRegione(event.currentTarget.value);
    }

    if (event.target.name === "find_consulente_cognome") {
      setFindConsulenteCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cognome") {
      setFindAssistitoCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cf") {
      setFindAssistitoCF(event.currentTarget.value);
    }

    if (event.target.name === "find_operatore_cognome") {
      setFindOperatoreCognome(event.currentTarget.value);
    }
    if (event.target.name === "find_from") {
      setFindFrom(event.currentTarget.value);
    }
    if (event.target.name === "find_to") {
      setFindTo(event.currentTarget.value);
    }
  };

  const allFieldAreEmpty = () => {
    return (
      !findId &&
      !findDescrizione &&
      !findRegione &&
      !findConsulenteCognome &&
      !findAssistitoCognome &&
      !findAssistitoCF &&
      !findOperatoreCognome &&
      !findFrom &&
      !findTo
    );
  };

  const ClearAll = () => {
    setFindId("");
    setFindDescrizione("");
    setFindRegione("");
    setFindConsulenteCognome("");
    setFindAssistitoCognome("");
    setFindAssistitoCF("");
    setFindOperatoreCognome("");
    setFindFrom("");
    setFindTo("");
    fetchPraticheAll();
    fetchPraticheCount();
  };

  const findValueOnServer = () => {
    if (allFieldAreEmpty()) {
      fetchPraticheAll();
      fetchPraticheCount();
      return;
    }

    setSpinnerLoading(true);

    const asyncPostCall = async () => {
      try {
        const response = await client({
          url: `/pratiche/find/chiuse/`,
          config: {
            method: "POST",
            data: {
              // your expected POST request payload goes here
              numberId: findId ? Number(findId) : "",
              descrizione: findDescrizione,
              regione: findRegione,
              consulenteCognome: findConsulenteCognome,
              assistitoCognome: findAssistitoCognome,
              operatoreCognome: findOperatoreCognome,
              assistitoCF: findAssistitoCF,
              findFrom: findFrom,
              findTo: new Date(findTo).setUTCHours(23, 59, 59, 999),
            },
          },
        });
        const data = response.data;
        setData(data);
        setCount(data.length);
        setSpinnerLoading(false);
      } catch (error) {
        // enter your logic for when there is an error (ex. error toast)
        setSpinnerLoading(false);
        console.log(error);
      }
    };

    asyncPostCall();
  };

  const BasicForm = () => {
    return (
      <>
        <Form>
          <Row>
            <Col>
              <Form.Control
                placeholder="_id"
                name="find_id"
                type="number"
                value={findId}
                onChange={FindOnServer}
                style={{ border: "solid 1px red" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Descrizione"
                name="find_descrizione"
                value={findDescrizione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Regione"
                name="find_regione"
                value={findRegione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Assistito cognome"
                name="find_assistito_cognome"
                value={findAssistitoCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Consulente cognome"
                name="find_consulente_cognome"
                value={findConsulenteCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Assistito C.F."
                name="find_assistito_cf"
                value={findAssistitoCF}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Operatore cognome"
                name="find_operatore_cognome"
                value={findOperatoreCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Da"
                name="find_from"
                type="date"
                value={findFrom}
                onChange={FindOnServer}
                style={{ border: "solid 1px teal" }}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Da"
                name="find_to"
                type="date"
                value={findTo}
                onChange={FindOnServer}
                style={{ border: "solid 1px teal" }}
              />
            </Col>

            <Col>
              <Button variant="primary" onClick={findValueOnServer}>
                Cerca
              </Button>
            </Col>
            <Col>
              <Button variant="primary" onClick={ClearAll}>
                Pulisci tutto
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;
    let ricevuteArr = ricevute;

    const RenderAllegati = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    let statiPossibili = [
      "Lavorazione",
      "Chiusa",
      "Lavorazione_generale",
      "Sospesa",
      "Annullata",
    ];

    return (
      <>
        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Consulente</div>
            <div className="col-4">
              <small style={{ color: "#6c757d" }}>Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope" style={{ color: "#6c757d" }}></i>
              <small style={{ color: "#6c757d" }}> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone" style={{ color: "#6c757d" }}></i>
              <small style={{ color: "#6c757d" }}> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone" style={{ color: "#6c757d" }}></i>
              <small style={{ color: "#6c757d" }}> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Tipo Pratica</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Assistito</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#6c757d" }}>Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#6c757d" }}>Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#6c757d" }}>Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Collaboratore volontario</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="stato-pratica">
          <div className="row">
            <div>
              <span style={{ fontWeight: "bold" }}> Stato Pratica</span>
            </div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>

            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Note</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div>
              {" "}
              Documenti Allegati{" "}
              <i
                className="bi bi-arrow-up-square"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Ricevute{" "}
              <i
                className="bi bi-file-earmark-arrow-down"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Operatore{" "}
              <i
                className="bi bi-person-video3"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico pratica{" "}
              <i
                className="bi bi-folder2-open"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Note{" "}
              <i className="bi bi-card-text" style={{ color: "#6c757d" }}></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <span>
                <i className="bi bi-caret-down-fill"></i>
              </span>
            ) : (
              <span onClick={() => handleSelectedId(row)}>
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        /* accessor: c => `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}` */
        //   accessor: c => `${c.operatore.nome} ${c.operatore.cognome}`
        accessor: (c) =>
          c.operatore[0].nome
            ? `${c.operatore[0].nome} ${c.operatore[0].cognome}`
            : undefined,
      },
      {
        Header: "CF. Assistito",
        accessor: (d) =>
          d.assistito.codice_fiscale_assistito
            ? d.assistito.codice_fiscale_assistito
            : undefined,
      },
      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "#046f3e", fontWeight: "700" }}>
            {props.value} <i className="bi bi-check2-circle"></i>
          </span>
        ),
      },
      {
        Header: "Chiusa il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(
            d.storico_pratica[d.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
      },
      /*  {
            Header: "Action",
            id: "id",
           Cell: props => <Button className="btn btn-primary btn-sm" onClick={() => handleShow(props)}> <i className="bi bi-archive"></i> Prendi tu!</Button>
          }, */
    ],
    []
  );

  return (
    <>
      <div
        className="test"
        style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? <div className="classic-3"></div> : null}
        </div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          {BasicExample()}
          <div className="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              type="chiuse"
            />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PraticheChiuse;
