import React, {useState} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
//import {Container, Col, Row, Button, Form, FormControl} from 'react-bootstrap';
import Endpoints from "../../components/services/Endpoints";
import 'bootstrap/dist/css/bootstrap.min.css';
import LogoNegativo from '../../CDLlogoNegativo.svg'
import './Usersignin.css'
var bcrypt = require('bcryptjs');
const ENDPOINT = Endpoints.getUrls();


const UserforgotStepOne =() => {


    let navigate = useNavigate();
 //   const location = useLocation();


    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({email:'', password:''});
    const [richiediOtp, setRichiediOtp] = useState(true);
    const[confirmPassword, setConfirmPassword] = useState('');
    const[password, setPassword] = useState('');

    const [input, setImput] = useState({});
    const[matches, setMatches] = useState(false);

    const { state } = useLocation();
    console.log(state);


    const handleChange =(event) => {

        let inputx = input;
    
        inputx[event.target.name] = event.target.value;
         console.log(inputx)
        setImput(inputx);
    
      }

const handleCheck = (event) => {


        let userPassword = input.nuovapassword;
        let confirmPassword = input.repeatPassword; 
        
          if (userPassword === confirmPassword) {
            let matchText =  document.getElementById('message');
            matchText.style.color = "green"
            console.log("MATCH!");
            setMatches(true);
        
            document.getElementById('message').innerHTML = `<span><i className="bi bi-emoji-wink" style={{color: 'primary'}}>Coincidono</i>`;
            document.getElementById('submit').disabled = false;
          }else{
            let noMatchText =  document.getElementById('message');
            noMatchText.style.color = "rebeccapurple"
            document.getElementById('message').innerHTML = `<span><i className="bi bi-emoji-frown"></i> non coincidono</span>`;
            document.getElementById('submit').disabled = true;
            console.log("NOT MATCH!");
            setMatches(false);
          }
          
        
    console.log(event.target);
}

const handleSubmit = (event) => {
    console.log(event.target);

    if(matches){
      let resetObj = input;
      console.log(resetObj);


   let hash =  bcrypt.genSalt(10, function(err, salt) {
        bcrypt.hash(resetObj.repeatPassword, salt, function(err, hash) {
            // Store hash in your password DB.
            let payload = {
              email: resetObj.email,
              nuovapassword: hash,
              codiceotp: resetObj.codiceotp
          }
          console.log(payload);
          updatePass(payload);
        });
    });

    const updatePass = (payload) => {

      axios.post(`${ENDPOINT}/forgot/nextstep/ `, payload)
        .then(function (response) {
          console.log(response);
          if(response ){
            navigate('/userforgotfinal');
          }
       //   navigate('/forgotstep3',{state:{id:1,email:email}});
           })
        .catch(function (error) {
           console.log(error);
      });
      
    }

  
    } else return;

    event.preventDefault();

   

      }



    return (
        <div className="login-page bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                <h3 className="mb-3 text-white"> </h3>
                    <div className="bg-white shadow rounded" style={{position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)',  msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'}}>
                        <div className="row">
                            <div className="col-md-7 pe-0">
                                <div className="form-left h-100 py-5 px-5">
                                    <form onSubmit={handleSubmit} autoComplete="off" className="row g-4" >
                                            <div className="col-12">
                                                <label>Email<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <div className="input-group-text"><i className="bi bi-envelope"></i></div>
                                                    <input type="text" name="email" className="form-control" placeholder="Inserisci email" defaultValue={''} onChange={handleChange} autoComplete="off"/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                            <label>Password<span className="text-danger">*</span></label>
                                            <div className="input-group">
                                                <div className="input-group-text"><i className="bi bi-incognito"></i></div>
                                                <input type="password" id="nuovapassword" name="nuovapassword" className="form-control" placeholder="Nuova password" defaultValue={''} onChange={handleChange}   autoFocus autoComplete="new-password"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                        <label>Ripeti password<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-text"><i className="bi bi-incognito"></i></div>
                                            <input type="password" className="form-control" name="repeatPassword" placeholder="Ripeti password" id="repeatPassword" defaultValue={''} onChange={handleChange} onKeyUp={handleCheck} />
                                            <span id='message'></span>

                                            </div>
                                    </div>
                                    <div className="col-12">
                                    <label>OTP<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <div className="input-group-text"><i className="bi bi-code"></i></div>
                                        <input type="text" id="codiceotp" name="codiceotp" className="form-control" placeholder="Inserisci il codice OTP ricevuto via mail" onChange={handleChange} required autoFocus />
                                    </div>
                                </div>


                                            <div className="col-12">
                                            <button type="submit" id="submit" className="btn btn-primary px-4 float-end mt-4">Invia</button>
                                            
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5 ps-0 d-none d-md-block">
                                <div className="form-right h-100 bg-primary text-white text-center pt-5">
                                   <img src={LogoNegativo} width={300}></img>
                                   <p></p>
                                    <h3 className="fs-1">Secondo passo:</h3>
  
                                    <h4 className="mb-3 text-white">inserisci il codice OTP inviato alla tua Email e clicca su invia</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-end text-secondary mt-3"></p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default UserforgotStepOne;