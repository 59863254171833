import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import Endpoints from "../components/services/Endpoints";
import { Container } from "react-bootstrap";
import client from "../utils/client";

const ENDPOINT = Endpoints.getUrls();

const AdminTipoPraticaAdd = () => {
  const [moduliMenu, setModuliMenu] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const [tipoPraticata, setTipoPratica] = useState("");
  const [scadenza, setScadenza] = useState(Date);
  const [giorniValidiCaricamento, setGiorniValidiCaricamento] = useState(0);
  const [descrizione, setDescrizione] = useState("");
  const [tipoModulo, setTipoModulo] = useState([]);

  const [htmlContent, setHtmlContent] = useState("");
  const [convertedContent, setConvertedContent] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [textEmpty, seTtextEmpty] = useState(true);

  const liStyleAlert = { border: "solid 1px red" };
  const liStyleGo = { border: "solid 1px green" };

  useEffect(() => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/admin/moduli/`,
        config: { method: "GET" },
      });
      const modulifetched = response.data;
      setModuliMenu(modulifetched);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());

    setConvertedContent(currentContentAsHTML);
    if (currentContentAsHTML) {
      seTtextEmpty(false);
    }
  };

  const handleDescrizione = (event) => {
    setDescrizione(event.target.value);
  };

  const handleGiorniValidiCaricamento = (event) => {
    setGiorniValidiCaricamento(event.target.value);
  };

  const handleScadenza = (event) => {
    setScadenza(event.target.value);
  };

  const handleTipoPratica = (event) => {
    setTipoPratica(event.target.value);
  };

  const handleTipoModulo = (event) => {
    setTipoModulo(event);
  };

  const RenderClosedCase = () => {
    return (
      <div className="row mb-2 mt-3">
        <div className="col">
          <button
            className="btn btn-primary"
            type="submit"
            id="inputGroupFileAddon088"
            style={{ float: "right" }}
          >
            Crea tipo pratica!
          </button>
        </div>
      </div>
    );
  };

  const renderTextEditor = () => {
    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!convertedContent) {
      alert("Prego inserire il testo per i documenti necessari");
    }

    setTipoPratica("");
    setDescrizione("");
    setScadenza(Date);
    setGiorniValidiCaricamento("");

    setConvertedContent("");
    setTipoModulo([]);
    setEditorState(() => EditorState.createEmpty());

    console.log(tipoPraticata);
    console.log(descrizione);
    console.log(scadenza);
    console.log(giorniValidiCaricamento);
    console.log(convertedContent);
    console.log(tipoModulo);

    let moduloArray = [];

    if (tipoModulo.length <= 0) {
      moduloArray = tipoModulo;
    } else {
      moduloArray.push(tipoModulo);
    }

    client({
      url: `/admin/tipopratica/create/`,
      config: {
        method: "POST",
        data: {
          descrizione: descrizione,
          tipo_pratica: tipoPraticata,
          scadenza: scadenza,
          ordine: "0",
          active: "1",
          created_at: new Date().toLocaleString(),
          updated_at: new Date().toLocaleString(),
          giorni_validi_per_caricamento: giorniValidiCaricamento,
          documenti_necessari: convertedContent,
          modulo: moduloArray,
        },
      },
    })
      .then(function (response) {
        alert(JSON.stringify(response.data));
        event.target.reset();
      })
      .catch(function (error) {
        alert(error);
      });
  };

  return (
    <>
      <div style={{ background: "rgb(21 74 134);" }}>
        <Container fluid>
          <Container className="mb-4 mt-3">
            <div
              style={{
                textAlign: "center",
                textAlign: "center",
                paddingTop: "1rem",
                paddingBottom: "2rem",
                fontWeight: "600",
                color: "white",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-house-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                />
                <path
                  fill-rule="evenodd"
                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                />
              </svg>{" "}
              / Admin / Tipopratica / Aggiungi
            </div>

            <div className="card" style={{ width: "82rem", padding: "2rem" }}>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Descrizione
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="tipo"
                          name="tipo"
                          defaultValue={descrizione}
                          onChange={handleDescrizione}
                          required
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Tipo pratica
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="tipo_pratica"
                          name="tipo_pratica"
                          defaultValue={tipoPraticata}
                          onChange={handleTipoPratica}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Scadenza
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="scadenza"
                        name="scadenza"
                        defaultValue={scadenza}
                        onChange={handleScadenza}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Giorni validi per il caricamento (numero)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Giorni validi per il caricamento"
                          name="giorni_validi_caricamento"
                          defaultValue={giorniValidiCaricamento}
                          onChange={handleGiorniValidiCaricamento}
                          required
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Associa un primo modulo alla nuova pratica
                        </label>
                        <select
                          name="tipo_modulo"
                          className="form-select form-control"
                          aria-label="Default select example"
                          onChange={(e) => handleTipoModulo(e.target.value)}
                          required
                        >
                          <option value="0">Apri questo menu</option>
                          {moduliMenu?.map((val) => (
                            <option
                              key={val._id}
                              value={val._id}
                              style={{ padding: "0.4rem" }}
                            >
                              {val.tipo}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label>Documenti Necessari</label>

                      {textEmpty ? (
                        <div
                          className="form-control bulletin"
                          style={liStyleAlert}
                        >
                          {renderTextEditor()}
                        </div>
                      ) : (
                        <div
                          className="form-control bulletin"
                          style={liStyleGo}
                        >
                          {renderTextEditor()}
                        </div>
                      )}
                    </div>
                  </div>

                  {RenderClosedCase()}
                </form>
              </div>
            </div>
          </Container>
          <div className="row">
            <div
              style={{
                textAlign: "center",
                textAlign: "center",
                paddingTop: "1rem",
                paddingBottom: "3rem",
                fontWeight: "600",
              }}
            ></div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdminTipoPraticaAdd;
