import React, { useState, useEffect, useMemo } from "react";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { stateToHTML } from "draft-js-export-html";
import { Container, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import client from "../utils/client";

import Endpoints from "../components/services/Endpoints";
import TableContainer from "./TableContainer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ENDPOINT = Endpoints.getUrls();

const AdminGetionePratiche = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const [tipoPraticaEdit, setTipoPraticaEdit] = useState("");

  const [descrizioneEdit, setDescrizioneEdit] = useState("");
  const [giorniUtiliEdit, setGiorniUtiliEdit] = useState("");
  const [activeStatusEdit, setActiveStatusEdit] = useState("");
  const [dataScadenzaObbligatoria, setDataScadenzaObbligatoria] = useState("");

  const [convertedContent, setConvertedContent] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const doFetch = async () => {
      setSpinnerLoading(true);

      const response = await client({
        url: `/admin/tipopratica/moduli/join/`,
        config: { method: "GET" },
      });

      const tipopratica = response.data;
      setData(tipopratica);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  const handleShow = (event) => {
    console.log(event.cell.row.values._id);
    var idPratica = event.cell.row.values._id;
    const descrConfirm = event.cell.row.values.descrizione;

    const adminClient = JSON.parse(localStorage.getItem("currentUser"));

    if (adminClient.role[0] === "ROLE_ADMIN") {
      Swal.fire({
        title: `Vuoi davvero eliminare ${descrConfirm}?`,
        text: `${descrConfirm}`,
        icon: "warning",
        html: `<b style{{color:'red'}}>Questa azione avrà ripercussioni sulle pratiche create in precedenza <b>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SI, elimina!",
        footer: `<b>Ruolo: <b/>${adminClient.role[0]}`,
      }).then((result) => {
        if (result.isConfirmed) {
          client({
            url: `/admin/tipopratica/${idPratica}`,
            config: { method: "DELETE" },
          })
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                let updTbl = {
                  praticaId: idPratica,
                };

                renewTable(updTbl);

                Swal.fire("Eliminata!", data.success, "Grazie");
              }
              if (data.error) {
                Swal.fire("Error!", data.error, "Grazie");
              }
            })
            .catch((error) => {
              // enter your logic for when there is an error (ex. error toast)
              console.log(error);
            });

          //fine conferma seleziona
        }
      });
    } else {
      Swal.fire("Non hai i privilegi per eseguire questa azione", "Grazie");
    }
  };

  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
  };

  const handlePluginSelected = (e) => {
    console.log(e);
  };

  const renderRowSubComponent = (row) => {
    let {
      descrizione,
      scadenza,
      giorni_validi_per_caricamento,
      tipo_pratica,
      ordine,
      scadenza_obbligatoria,
      ...rest
    } = row.original;
    let selectedId = rest._id;
    let documenti_necessari = rest.documenti_necessari;
    let moduliAssociati = rest.moduls;
    let attiva = rest.active;

    const RenderPlugins = () => {
      return (
        <table className="table table table-bordered">
          <thead>
            <tr>
              <th scope="col">_id</th>
              <th scope="col">Tipo</th>
              <th scope="col">contenuto</th>
              <th scope="col">documenti</th>
              <th scope="col">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "small" }}>
            {moduliAssociati?.map(function (d, idx) {
              return (
                <tr key={idx}>
                  <td>{d._id}</td>
                  <td>{d.tipo}</td>
                  <td>{d.contenuto}</td>
                  <td>{d.documento}</td>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={d._id}
                      id={`flexCheck${idx}`}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };

    const RenderActive = () => {
      return (
        <div
          className="form-control"
          style={{ backgroundColor: "#f8f9fa", border: "none" }}
        >
          <select
            name="active"
            className="form-select"
            defaultValue={attiva}
            aria-label="Default select example"
            onChange={(e) => setActiveStatusEdit(e.target.value)}
          >
            <option key={1} value={"1"} style={{ padding: "0.4rem" }} id={"key1"}>
              Attiva
            </option>
            <option key={0} value={"0"} style={{ padding: "0.4rem" }} id={"key2"}>
              Sospesa
            </option>
          </select>
        </div>
      );
    };

    const handleEditorChange = (state) => {
      setEditorState(state);
      convertContentToHTML();
    };
    const convertContentToHTML = () => {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
    };

    const handleOnTipoPratica = (event) => {
      setTipoPraticaEdit(event.target.value);
    };
    const handleOnDescrizione = (event) => {
      setDescrizioneEdit(event.target.value);
    };

    const handleOnGiorniUtili = (event) => {
      setGiorniUtiliEdit(event.target.value);
    };

    const handleDataObbligatoria = (event) => {
      setDataScadenzaObbligatoria(event.target.value)
    }

    const convertCommentFromJSONToHTML = (text) => {
      return stateToHTML(convertFromRaw(text));
    };

    const RenderTextEditor = () => {
      return (
        <Editor
          defaultEditorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
      );
    };

    const RenderClosedCase = () => {
      return (
        <div className="edit">
          <div className="col">
            <button
              className="btn btn-outline-primary"
              type="submit"
              id="inputGroupFileAddon088"
            >
              Modifica il tipo pratica!
            </button>
          </div>
        </div>
      );
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      let validModuliArray = [];
      Array.prototype.forEach.call(event.target.elements, (element) => {
        if (element.type === "checkbox") {
          if (!element.checked) {
            console.log(element.value, element.id);
            validModuliArray.push(element.value);
          }
        }
      });

      let activeStatusNew = "";
      let tipoPraticaNew = "";
      let descrizioneNew = "";
      let documentiNecessariNew = "";
      let giorniUtiliNew = "";
      let dataScadenzaObbligatoriaNew = "";

      if (activeStatusEdit) {
        console.log(`activeStatusEdit: ${activeStatusEdit}`);
        activeStatusNew = activeStatusEdit;
      } else {
        activeStatusNew = attiva;
        console.log(`activeStatusNew: ${activeStatusNew}`);
      }

      if (tipoPraticaEdit) {
        console.log(`tipoPraticEdit: ${tipoPraticaEdit}`);
        tipoPraticaNew = tipoPraticaEdit;
      } else {
        tipoPraticaNew = tipo_pratica;
        console.log(`tipoPraticaNew: ${tipoPraticaNew}`);
      }

      if (descrizioneEdit) {
        console.log(`descrizioneEdit: ${descrizioneEdit}`);
        descrizioneNew = descrizioneEdit;
      } else {
        descrizioneNew = descrizione;
        console.log(`descrizioneNew: ${descrizioneNew}`);
      }

      if (convertedContent) {
        console.log(`convertedContent: ${convertedContent}`);
        documentiNecessariNew = convertedContent;
      } else {
        documentiNecessariNew = documenti_necessari;
        console.log(`documentiNecessariNew: ${documentiNecessariNew}`);
      }

      if (giorniUtiliEdit) {
        console.log(`giorniUtiliEdit: ${giorniUtiliEdit}`);
        giorniUtiliNew = giorniUtiliEdit;
      } else {
        giorniUtiliNew = giorni_validi_per_caricamento;
        console.log(`giorniUtiliNew: ${giorniUtiliNew}`);
      }

      if (dataScadenzaObbligatoria) {
        console.log(`dataScadenzaObbligatoria: ${dataScadenzaObbligatoria}`);
        dataScadenzaObbligatoriaNew = dataScadenzaObbligatoria;
      } else {
        dataScadenzaObbligatoriaNew = scadenza_obbligatoria;
        console.log(`dataScadenzaObbligatoriaNew: ${dataScadenzaObbligatoriaNew}`);
      }


      client(
        {
          url: `/admin/tipopratica/edit/${selectedId}`,
          config: {
            method: "POST", data: {
              descrizione: descrizioneNew,
              tipo_pratica: tipoPraticaNew,
              scadenza_obbligatoria: dataScadenzaObbligatoriaNew,
              giorni_validi_per_caricamento: giorniUtiliNew,
              active: activeStatusNew,
              modulo: validModuliArray,
              documenti_necessari: documentiNecessariNew,

            }
          },
        },

      ).then(function (response) {
        alert("Il Tipo Pratica è stato moficato con successo");
        const doFetch = async () => {
          setSpinnerLoading(true);
          const response = await client({
            url: `/admin/tipopratica/moduli/join/`,
            config: { method: "GET" },
          });
          const tipopratica = response.data;
          setData(tipopratica);
          setSpinnerLoading(false);
        };
        doFetch();
        //  window.location.reload();
      })
        .catch(function (error) {
          alert("Ooooops qualcosa è andata storta: contatta il developer");
          console.log(error);
        });
    };

    return (
      <>
        <Container>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Descrizione
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="tipo"
                    name="descrizione"
                    defaultValue={descrizione}
                    onChange={handleOnDescrizione}
                    readOnly
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Tipo Pratica
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tipo Pratica"
                    name="tipo_pratica"
                    defaultValue={tipo_pratica}
                    onChange={handleOnTipoPratica}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col">
                <div className="mb-3 fade alert alert-info show">
                  <div
                    name="contenuto"
                    dangerouslySetInnerHTML={{ __html: documenti_necessari }}
                  />

                  {/* 
      <label htmlFor="exampleFormControlInput1" className="form-label">Documenti necessari</label>
        <textarea
      cols="40" rows="5"
      className="form-control" 
      placeholder="contenuto"
      name='contenuto'
      defaultValue={documenti_necessari}
      disabled />
*/}
                </div>
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col">
                <label className="form-label">Moduli associati</label>

                <div>
                  {RenderPlugins()}
                  {/* 
      {testone.map((rowData, index) => (
        Object.entries(rowData).map(([key, val]) => 
        <li key={key}>{key}: {val}</li>
    )
      ))}
      */}
                </div>
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col">
                <label className="form-label">
                  Modifica il banner dei documenti necessari
                </label>
                <div className="form-control bulletin">
                  {RenderTextEditor()}
                </div>
              </div>


            </div>

            <div className="row mt-2 mb-2">
              <div className="col-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    giorni validi per il caricamento
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="giorni_validi_per_caricamento"
                    defaultValue={giorni_validi_per_caricamento}
                    onChange={handleOnGiorniUtili}
                    maxLength="2"
                  />
                </div>
              </div>

              <div className="col">
                <label className="form-label form-label mb-1">Data di scadenza obbligatoria?</label>

                <div className="form-control" style={{ backgroundColor: "#f8f9fa", border: "none" }}
                >
                  <select
                    name="dataScadenzaObbligatoria"
                    className="form-select"
                    aria-label="Default select example"
                    defaultValue={scadenza_obbligatoria}
                    onChange={handleDataObbligatoria}
                  >
                    <option key={0} value={0} style={{ padding: "0.4rem" }} selected>
                      Non obbligatoria
                    </option>
                    <option key={1} value={1} style={{ padding: "0.4rem" }}>
                      Obbligatoria
                    </option>
                  </select>
                </div>
              </div>

              <div className="col">
                <label className="form-label form-label mb-1">Stato?</label>

                {RenderActive()}
              </div>

              <div className="col-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    verifica tutto e poi clicca sul bottone
                  </label>
                  {RenderClosedCase()}
                </div>
              </div>
            </div>
          </form>
        </Container>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <span className="btn1">
                {" "}
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "_id",
        accessor: "_id",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Tipo",
        accessor: "tipo_pratica",
      },
      {
        Header: "Attiva?",
        accessor: "active",
      },
      {
        Header: "Action",
        id: "id",
        Cell: (props) => (
          <Button
            className="btn btn-sm btn-danger"
            onClick={() => handleShow(props)}
          >
            {" "}
            <small>
              <i className="bi bi-archive"></i> Elimina!
            </small>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <div className="row">
      <div
        style={{
          textAlign: "center",
          textAlign: "center",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          fontWeight: "600",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-house-fill"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
          />
          <path
            fillRule="evenodd"
            d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
          />
        </svg>{" "}
        / Admin / Gestione pratiche / Vedi
      </div>

      <div
        className="test"
        style={{
          paddingBottom: "100px",
          backgroundColor: "rgb(233, 236, 239)",
        }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}></div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          <div class="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AdminGetionePratiche;
