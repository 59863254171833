import React, { useState, useEffect, useMemo } from "react";
import socketIOClient from "socket.io-client";
import * as Loader from "react-loader-spinner";
import { Container, Table, Button } from "react-bootstrap";
import client from "../utils/client";

import TableContainer from "./TableContainer";
import Endpoints from "../components/services/Endpoints";
import Footer from "../components/Footer/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import "./pratica.css";

import moment from "moment";

const ENDPOINT = Endpoints.getUrls();

const PraticheLavorazione = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  useEffect(() => {
    doFetch();
  }, []);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);

    socket.on("assegnazione", function (payload) {
      console.log("praticaPresa da: ", payload);
      doFetch();
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  const doFetch = async () => {
    setSpinnerLoading(true);

    const response = await client({
      url: `/pratiche/box/generale/`,
      config: { method: "get" },
    });

    const pratiche = response.data;
    setData(pratiche);
    setSpinnerLoading(false);
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    const RenderAllegati = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path} `}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    return (
      <>
        <div className="container  pratica">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container  pratica">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container  pratica">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Assistito</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {`${assistitoObj.data_stato_civile} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container  pratica">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="stato-pratica">
          <div className="row">
            <div>
              <span style={{ fontWeight: "bold" }}> Stato Pratica</span>
            </div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>

            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        {/*
              <div className="container  pratica">
                <div className="row">
                <div> Stato Pratica</div>
                  <div className="col">
                  <small className="label-pratica">descrizione: </small>
                  {`${statoPratica} `}
                  </div>
                  <div className="col">
                  <small className="label-pratica">cambia lo stato pratica qui: </small>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <select defaultValue={statoPratica} className="form-select" aria-label="Default select example" onChange={handleSelect} >
                  {statiPossibili.map((item, i) =>{
                    return <option key={i} value={item}>{item}</option>
                  })}
                  </select>


                  <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                aria-valuenow={ progress }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div></div>
                  { isVisible ?
                      <div className='edit' >
                      <div className='col'>

                      {fileInfos &&
                        fileInfos.map((fileInfo, index) => (
                          <div className="mb-2" key={index}>
                            <span>{fileInfo.path}</span>
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-info"
                                role="progressbar"
                                aria-valuenow={ progress }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div>
                            </div>
                          </div>
                        ))}

                      <small className="label-pratica">carica la ricevuta </small>
                      <div className="input-group mb-3">
                      <input type="file" name="files" multiple onChange={selectionFiles} className="form-control" id="inputGroupFile02" />
                      <button className="btn btn-outline-secondary"
                      disabled={!selectedFiles}
                      onClick={onClickHandler}
                      type="button" id="inputGroupFileAddon04">Carica i files!</button>
                    </div>
                      </div>

                <div className='col'>
                   <ul>

                  {fileInfos.map((el, i)=>{
                    return(
                      <li key={i}>
                      <a  href={`http://localhost:5001/static/allegati/${el.path} `}><i className="bi bi-file-pdf" style={{color: 'red'}}></i>{el.filename}</a>
                      </li>
                      )

                  } )}
                   </ul>
                 </div>

                      <div className='col'>
                      <small className="label-pratica">aggiungi note </small>
                      <div className="input-group">
                      <span className="input-group-text">With textarea</span>
                      <textarea
                      className="form-control"
                      aria-label="With textarea"
                      name='note'
                      onChange={e => setNote(e.target.value)}

                      defaultValue={note}></textarea>
                    </div>
                      </div>
                      <div className='col'>
                      <button className="btn btn-outline-secondary"
                      disabled={!selectedFiles}
                      onClick={handleSubmit}
                      type="button" id="inputGroupFileAddon088">Cambia lo stato pratica!</button>


                      </div>


                      </div>
                      : null }
                  </form>

                  </div>
                </div>
              </div>
 */}
        <div className="container  pratica">
          <div className="row">
            <div> Note</div>
            <div className="col">
              <small className="label-pratica">Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div className="container  pratica">
          <div className="row">
            <div>
              {" "}
              Documenti allegati <i className="bi bi-paperclip"></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div className="container  pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Ricevute <i className="bi bi-file-earmark-arrow-down"></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div className="container  pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div className="container  pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico pratica <i className="bi bi-folder2-open"></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div className="container  pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-text"></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            marginBottomTop: "3rem",
            background: "#31353a",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div>
              {" "}
              <h2 style={{ color: "rgb(16 219 56)" }}>
                {" "}
                Stato Pratica: {`${statoPratica} `}{" "}
                <i className="bi bi-person-workspace"></i>
              </h2>{" "}
            </div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        /*
                Cell: ({ row }) => (
                  <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
                  </span>
                ),
                */

        Cell: ({ row, rows, toggleRowExpanded }) => (
          <span
            {...row.getToggleRowExpandedProps({
              ...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
                onClick: () => {
                  const expandedRow = rows.find((row) => row.isExpanded);

                  if (expandedRow) {
                    const isSubItemOfRow = Boolean(
                      expandedRow && row.id.split(".")[0] === expandedRow.id
                    );

                    if (isSubItemOfRow) {
                      const expandedSubItem = expandedRow.subRows.find(
                        (subRow) => subRow.isExpanded
                      );

                      if (expandedSubItem) {
                        const isClickedOnExpandedSubItem =
                          expandedSubItem.id === row.id;
                        if (!isClickedOnExpandedSubItem) {
                          toggleRowExpanded(expandedSubItem.id, false);
                        }
                      }
                    } else {
                      toggleRowExpanded(expandedRow.id, false);
                    }
                  }
                  row.toggleRowExpanded();
                },
              }),
            })}
          >
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill" style={{ color: "red" }}></i>
            ) : (
              <i className="bi bi-caret-right-fill"></i>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        accessor: (c) =>
          `${c.operatore[c.operatore.length - 1].nome} ${
            c.operatore[c.operatore.length - 1].cognome
          }`,
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
      },
      {
        Header: "Data",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(d.data_scadenza, "DD/MM/YYYY, HH:mm:ss").format(
            "DD/MM/YYYY, HH:mm"
          )}`,
      },
      /*   {
            Header: "Action",
            id: "id",
           Cell: props => <Button className="btn1 btn-sm" onClick={() => handleShow(props)}> <i className="bi bi-archive"></i> Prendi tu!</Button>
          },*/
    ],
    []
  );

  return (
    <>
      <div
        className="test"
        style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? (
            <Loader.ThreeDots color="white" height={100} width={100} />
          ) : null}
        </div>

        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          <div className="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PraticheLavorazione;
