import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
//import LogoutButton from './LogoutButton';

//const LoadingComponent = () => <div>My loader</div>

const Teleconsul = () => {
  const navigate = useNavigate();

  const auth = useAuth();
  // const token = auth.user?.expires_at;

  const detail = auth.user?.profile;
  console.log(detail);

  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState({});
  const [subid, setSubid] = useState("");
  /*

*/

  useEffect(() => {
    const token = auth.user?.access_token;
    if (token) {
      const subs = auth.user?.profile.sub;
      const formData = new FormData();
      formData.append("access_token", token);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/middleware/teleconsul_check.php`,
          formData
        )

        .then(function (response) {
          setSubid(subs);
          console.log(response.data.sottoscrizioni);
          let MYARR = [];
          MYARR = response.data.sottoscrizioni;
          setPosts(MYARR);

          setUser(response.data.utente);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [auth]);

  const checkCodice = () => {
    //  const myArray = [{x:100}, {x:200}, {x:300}];
    /*
const myArray = 
[
    {
        "codice": "TWB1",
        "titolo": "Servizi Patronato",
        "data_inizio": "2021-04-01T00:00:00",
        "data_fine": "2022-12-31T00:00:00"
    }
];
*/

    posts.forEach((element, index, array) => {
      if (element.codice === "TWB1") {
        console.log(element.codice, element.titolo);
        Ckeckdata();
      } else {
        alert(
          "Non è stato possibile effettuare l'accesso.<br>Verifica il tuo abbonamento con il fornitore del servizio."
        );
        navigate("/");
      }
    });
  };

  const Ckeckdata = () => {
    if (!subid) {
      alert(
        "Non è stato possibile effettuare l'accesso.<br>Verifica con il fornitore del servizio."
      );
      return;
    }

    const data = posts[0].data_fine;
    const dataFine = new Date(data);

    let myDate = new Date();
    let yourDate = new Date(dataFine);

    if (myDate < yourDate) {
      console.log("myDate is less than yourDate");

      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/users/teleconsul/check/", {
          subid: subid,
          // subid: 12341
        })
        .then(function (response) {
          console.log(response);

          if (response.data.msg === "success") {
            let pros = response.data.authuser;
            let _id = pros.id;
            //&& pros.codice_fiscale ??? serve???

            if (
              (!pros.nome ||
                !pros.cognome ||
                !pros.codice_fiscale ||
                !pros.collaboratore.nome ||
                !pros.collaboratore.cognome ||
                !pros.collaboratore.codice_fiscale) &&
              pros.mandato.length > 0 &&
              pros.documento.length > 0
            ) {
              console.log("middle");
              navigate("/teleconsul/updatemiddleaccount", {
                state: {
                  _id,
                  subid,
                },
              });
            } else if (pros.mandato.length <= 0 || pros.documento.length <= 0) {
              console.log("full");
              navigate("/teleconsul/updateaccount", {
                state: {
                  _id,
                  subid,
                },
              });
            }
            // expected output: false
            else if (
              pros.nome &&
              pros.cognome &&
              pros.codice_fiscale &&
              pros.collaboratore.nome &&
              pros.collaboratore.cognome &&
              pros.collaboratore.codice_fiscale &&
              pros.mandato.length > 0 &&
              pros.documento.length > 0
            ) {
              console.log("go");
              let tokenObj = {
                _id: _id,
                cognome: pros.cognome,
                nome: pros.nome,
                email: pros.email,
                cellulare: pros.cellulare,
                telefono: pros.telefono,
                //   password: risposta.password,
                role: pros.roles,
                token: pros.accessToken,
                subscription: pros.subscription,
                created_at: pros.creato,
              };
              localStorage.setItem("currentUser", JSON.stringify(tokenObj));

              navigate("/consulente/tipo_pratica");
            }

            /*
      if(pros.collaboratore.codice_fiscale && pros.collaboratore.cognome && pros.collaboratore.nome && pros.documento.length >0 && pros.mandato.length > 0 && pros.nome ==='' || pros.cognome === '' || pros.codice_fiscale === '' || pros.partita_iva === ''){
        navigate('/teleconsul/updatemiddleaccount', {
          state:{
            _id,
          subid
            },
        })
      }
      
      if (!pros.collaboratore.codice_fiscale && !pros.collaboratore.cognome && !pros.collaboratore.nome && pros.documento.length <=0 && pros.mandato.length <=0) {
        navigate('/teleconsul/updateaccount', {
          state:{
            _id,
          subid
            },
        })
        
      } 
      
      if(pros.collaboratore.codice_fiscale && pros.collaboratore.cognome && pros.collaboratore.nome && pros.documento.length >0 && pros.mandato.length > 0 && pros.nome && pros.cognome && pros.codice_fiscale) {
        let tokenObj= {
          _id: _id,
          cognome: pros.cognome,
          nome: pros.nome,
          email: pros.email,
          cellulare: pros.cellulare,
          telefono: pros.telefono,
       //   password: risposta.password,
          role: pros.roles,
          token: pros.accessToken
        }
        localStorage.setItem('currentUser', JSON.stringify(tokenObj));
  
         navigate('/consulente/tipo_pratica');
      }
*/
          }
          if (response.data.msg === "error") {
            //   alert(response.data.motivo + " Clicca ok per creare un account")
            navigate("/teleconsul/createaccount", {
              state: {
                subid,
              },
            });
          }
          if (response.data.msg === "Profilo utente non validato") {
            alert(response.data.msg);

            navigate("/");
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      return (
        <div>{`myDate is less than yourDate so: Good Account, your sub:${subid}`}</div>
      ); // will be printed
    }
    if (myDate > yourDate) {
      console.log("myDate is greater than yourDate");
      return (
        <div>
          {
            "Non è stato possibile effettuare l'accesso.<br>Verifica il tuo abbonamento con il fornitore del servizio."
          }
        </div>
      );
    }
  };

  /*
  const userCheck = JSON.parse(token);
  let userObj = {

    email: userCheck.mail,
    family_name: userCheck.family_name,
    given_name: userCheck.given_name,
//  http://schemas.teleconsul.it/teleconsul/tenant/id: "5355"
    id_cliente: userCheck.id_cliente,
    idp: userCheck.idp,
    name: userCheck.name,
    preferred_username: userCheck.preferred_username,
    sid: userCheck.sid,
    sub: userCheck.sub
  }
  alert(JSON.stringify(userCheck))
*/

  /*
client_id: '7E7B3401-4475-4DD5-AB26-BC2E0684BC32'
redirect_uri: 'http://localhost:3000/authentication/callback'
silent_redirect_uri: 'http://localhost:3000/authentication/callback'
scope: 'openid profile external.api'
authority: 'https://tcis-dev.teleconsul.it'
*/

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col" style={{ background: "rgb(12, 74, 134)" }}>
                <div
                  className="card mt-20"
                  style={{
                    backgroundColor: "transparent",
                    marginTop: "20px",
                    textAlign: "center",
                    border: "none",
                  }}
                >
                  <div className="card-body ">
                    <h1
                      className="no_toc text-white"
                      style={{ marginBottom: "4rem" }}
                    >
                      TELECONSUL Crico informazioni uteni...Se la procedura non
                      va avanti, contattare l'amministratore
                    </h1>
                    <br />

                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {posts.length > 0 ? checkCodice() : null}

      {/* posts.length >0 ?
  Ckeckdata() : null */}
    </div>
  );
};

export default Teleconsul;

/*
{"utente":
          {"nome":"Sandro","cognome":"Peggion","cf":"","piva":"","email":"sundsx@gmail.com"},
"sottoscrizioni":[{"codice":"TWB1","titolo":"Servizi Patronato","data_inizio":"2021-04-01T00:00:00","data_fine":"2022-12-31T00:00:00"}]}

*/
