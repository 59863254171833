import axios from "axios";

import Endpoints from "./Endpoints";

const ENDPOINT = Endpoints.getUrls();


export default axios.create({
  baseURL: ENDPOINT,
  headers: {
    "Content-type": "application/json"
  }
});

//est ENDPOINT = "http://54.204.111.155:5001/";
