
class Endpoints {

  getUrls() {

   
  //  return "http://localhost:5001/"
  return process.env.REACT_APP_BACKEND_URL

  }

  getAllegatiUrls() {

   return process.env.REACT_APP_BACKEND_URL + "/static/allegati/";
  //  return "http://localhost:5001/static/allegati/";
  }

  getDocumentiUrls() {

    return process.env.REACT_APP_BACKEND_URL +"/static/documenti/";
   // return "http://localhost:5001/static/documenti/";
  }

//da testare

getRicevuteUrls() {

  return process.env.REACT_APP_BACKEND_URL + "/static/pratiche/ricevute/";
 // return "http://localhost:5001/static/pratiche/ricevute/";
}


}

export default new Endpoints();
