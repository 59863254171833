import React, {useEffect, useState, useRef} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Endpoints from "../components/services/Endpoints";
import axios from "axios";
import {Container, Table, Button} from 'react-bootstrap';
import './messaggi.css'

const ENDPOINT = Endpoints.getUrls();

const MessaggiOp = () => {

    const [messages, setMessages] = useState([]);
    const scrollRef = useRef();
    const [operatoreId, setOperatoreId] = useState('');
    const ref = useRef(null);

    useEffect(async () => {

        const op = JSON.parse(localStorage.getItem('currentUser'));
         const id = op._id;
         setOperatoreId(id);
        const response = await axios.post(`${ENDPOINT}/direct/getmessages`, {
          from: id
        });
        console.log(response.data)
        setMessages(response.data);
      }, []);


    return (
        <div>

 
         
        <Container fluid>
        <div className="chat-header">
          <div className="user-details">
   
            <div className="username">
              <h3>Messaggi</h3>
            </div>
          </div>
        
        </div>
        <div className="chat-messages">
{/* 
{Object.keys(messages).map(cat => (
    <div>
      <h3>{cat}</h3>
      {messages.map(ord => (
        <div>
          <div>{ord.message}</div>
        </div>
      ))}
    </div>
  ))}

      */}



          {messages?.map((message) => {
            return (
              <div ref={scrollRef} key={uuidv4()}>

                <div 
                  className={`message ${
                    message.sender === operatoreId ? "sended" : "recieved"
                  }`}
                >
                  <div className="content ">
                  <p>Pratica <strong>{message.idPratica}</strong> - <strong>{message.descrizione}</strong></p>
                  <p>Partecipanti: tu e {message.receiverName}</p>
                    <p>Messaggio: {message.message}</p>
                    <p>Inviato il: {new Date(message.createdAt).toLocaleString()}</p>
                  {message.sender === operatoreId ? <p style={{color: 'yellow'}}><i className="bi bi-send-check-fill"></i> inviato da te </p> : <p style={{color: 'yellow'}}>ricevuto <i className="bi bi-bell-fill"></i></p>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

      </Container> 
        </div>
    );
}



export default MessaggiOp;