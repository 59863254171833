import React from 'react';
import { useAuth } from "react-oidc-context";
import { useNavigate, useSearchParams } from 'react-router-dom';


function Telelogout(props) {

  const [search, setSearch] = useSearchParams();

  /*
http://localhost:3000/teleconsul/logout?sid=295c1583a730569af91c5e30a5c918af&iss=https%3A%2F%2Ftcis-dev.teleconsul.it

  */

const navigate = useNavigate();
const auth = useAuth();

const remoteLogout = () => {
  if (auth.isAuthenticated) {
    auth.removeUser();
    logout();
}
}


const logout = () => {


  let items = ["currentUser", "tipoPaticaId"];
        
  items.forEach( items =>  {
      localStorage.removeItem(items);
  })
        
  navigate('/');
        
}
    
    
      


    return (
        <div>

{search.get('sid')}

            {search.get('id') ?  remoteLogout() :null }
        </div>
    );
}

export default Telelogout;