import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Endpoints from "../components/services/Endpoints";
import TableConsContainer from "./TableConsContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import FileUpload from "./File_Upload_Component/file-upload.componentsospese";
import { Container, Table, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-modal";
import client from "../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();
const ENDPOINTALLEGATI = Endpoints.getAllegatiUrls();

const PraticheSospese = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [statoPratica, setStatoPratica] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [progress, setProgress] = useState(0);
  const [note, setNote] = useState("");

  const [allegati, setAllegati] = useState([]);
  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  });

  const [abilitaInvia, setAbilitaInvia] = useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [userEdit, setUserEdit] = useState({});
  const [praticaID, setPraticaID] = useState("");
  const [isNubile, setIsNubile] = useState(null);

  const customStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      overflowY: "scroll",
      zIndex: 2,
    },
    content: {
      zIndex: 1,
      inset: "50% auto auto 50%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      overflowY: "scroll",
    },
  };

  useEffect(() => {
    doFetch();
  }, []);

  const doFetch = async () => {
    const consulente = JSON.parse(localStorage.getItem("currentUser"));
    const id = consulente._id;
    const response = await fetch(
      `${ENDPOINT}/pratiche/consulente/sospese/id?_id=${id}`
    );
    const pratiche = await response.json();
    setData(pratiche);
  };

  // aggiorno la ta tabella dopo l'assegnazione
  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
  };

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });

  const handleFiles = () => {
    let selectedFile = newUserInfo.profileImages;

    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("allCollection", selectedFile[x]);
    }
    const headers = { "Content-Type": "multipart/form-data" };

    client({
      url: `${ENDPOINT}/allegati`,
      config: {
        method: "POST",
        data,
        headers,
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      },
    }).then((response) => {
      // then print response status
      console.log(response);
      setFileInfos(response.data);

      let allegatiArray = [];

      for (let i = 0; i < response.data.length; i++) {
        allegatiArray.push({
          path: response.data[i].filename,
          creato: new Date().toISOString(),
        });
      }

      setAllegati(allegatiArray);
    });
    //********************* */
  };

  const handleDelete = (event) => {
    console.log(event.cell.row.values._id);
    var idPratica = event.cell.row.values._id;
    const descrConfirm = event.cell.row.values.descrizione;
    const aConfirmNome = event.cell.row.original.assistito.nome;
    const aConfirmCognome = event.cell.row.original.assistito.cognome;

    // const cConfirmNome = event.cell.row.original.consulente.nome;
    // const cConfirmCognome = event.cell.row.original.consulente.cognome;

    const adminClient = JSON.parse(localStorage.getItem("currentUser"));

    if (adminClient.role[0] === "ROLE_CONSULENTE") {
      Swal.fire({
        title: "Vuoi davvero eliminare?",
        text: `${descrConfirm}`,
        icon: "question",
        html: `<b>Descrizione: </b>${descrConfirm}<br> <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SI, elimina!",
        footer: `<b>Consulente<b/>`,
      }).then((result) => {
        if (result.isConfirmed) {
          //inizio conferma seleziona
          // qui elimino la pratica
          client(
            { url: `/pratiche/${idPratica}`, config: { method: "DELETE" } },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                let updTbl = {
                  praticaId: idPratica,
                };

                renewTable(updTbl);

                Swal.fire("Eliminata!", data.success, "Grazie");
              }
              if (data.error) {
                Swal.fire("Error!", data.error, "Grazie");
              }
            })
            .catch((error) => {
              // enter your logic for when there is an error (ex. error toast)
              console.log(error);
            });

          //fine conferma seleziona
        }
      });
    } else {
      Swal.fire("Non hai i privilegi per eseguire questa azione", "Grazie");
    }
  };

  const handleSelect = (event) => {
    setStatoPratica(event.target.value);
    setIsVisible(true);

    setAbilitaInvia(true);

    event.preventDefault();
  };
  /*
    const handleNote = (event) => {
      console.log(event.target.value)
      setNote(event.target.value);
     }
  */
  const handleSubmit = (event, praticaId) => {
    event.preventDefault();
    console.log(praticaId);

    // const consulenteClient = JSON.parse(localStorage.getItem('currentUser'));
    //   const {_id, cognome, nome, email, role} = consulenteClient;

    if (statoPratica) {
      let aggiornaObj = {
        id: praticaId,
        statoPratica: statoPratica,
        allegato: allegati,
        note: note,
        storico_pratica: {
          stato: statoPratica,
          data: new Date().toLocaleString("it-IT"),
        },
      };

      const headers = {
        "Content-Type": "application/json",
      };

      client({
        url: `${ENDPOINT}/pratiche/consulente/ricambiolostato/`,
        config: { method: "POST", data: aggiornaObj, headers },
      }).then((response) => {
        // then print response status
        console.log(response);

        setIsVisible(false);
        setFileInfos([]);
        setNote("");
        /*
      let updTbl = {
        praticaId: selectedId
      }
      renewTable(updTbl);
      */

        doFetch();

        Swal.fire({
          title: "La tua pratica è stata inviata correttamente.",
          html: `<p><strong>Attenzione!</strong> La ritrovi nelle pratiche INVIATE</p>`,
          showDenyButton: true,
          confirmButtonText: "Vuoi andare nelle inviate ora?",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate("/consulente/pratiche_inviate");
          }
        });
      });
    } else {
      Swal.fire({
        title: "Attenzione:",
        html: `<i>NON HAI CAMBIATO LO STATO PRATICA IN: <strong>INVIATA!<strong></i>`,
        icon: "ERROR",
      });

      return;
    }

    // fine
  };

  const selectionFiles = (event) => {
    setSelectedFile(event.target.files);
    setProgressInfos([]);
    console.log("New state in ASYNC callback:", selectedFile);
    console.log("New fileInfos in ASYNC callback:", fileInfos);
  };

  const onClickHandler = () => {
    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("rcvCollection", selectedFile[x]);
      //   data.append('name', 'ricevitabis');
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${ENDPOINT}/ricevute`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })

      .then((response) => {
        // then print response status
        setFileInfos(response.data);

        let allegatiArray = [];

        for (let i = 0; i < response.data.length; i++) {
          allegatiArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString(),
          });
        }

        setAllegati(allegatiArray);
      });
  };

  const handleEdit = (praticaID, assistitoObj) => {
    setIsOpen(true);
    assistitoObj.stato_civile === "nubile o celibe"
      ? setIsNubile(true)
      : setIsNubile(false);
    setUserEdit(assistitoObj);
    setPraticaID(praticaID);
  };

  const closeModal = () => {
    setUserEdit({});
    setPraticaID("");
    setIsOpen(false);
    setIsNubile(null);
  };

  const EditAssistito = async (event) => {
    event.preventDefault();

    const assistito = {
      nome: event.target.nome.value,
      cognome: event.target.cognome.value,
      codice_fiscale_assistito: event.target.codice_fiscale.value,
      cellulare: event.target.cellulare.value,
      telefono: event.target.telefono.value,
      email: event.target.email.value,
      indirizzo: event.target.indirizzo.value,
      cap: event.target.cap.value,
      comune: event.target.comune.value,
      citta: event.target.comune.value,
      provincia: event.target.provincia.value,
      regione: event.target.regione.value,
      categoria_assistito: event.target.categoria_assistito.value,
      stato_civile: event.target.stato_civile.value,
      data_stato_civile:
        event.target.stato_civile.value === "nubile o celibe"
          ? ""
          : event.target.data_stato_civile.value,
    };

    console.log(assistito);

    const response = await client({
      url: `/pratiche/${praticaID}/consulente/assistito`,
      config: { method: "PATCH", data: assistito },
    }).then(() => {
      closeModal();
      Swal.fire("Success", "Assistito modificato con successo!");
      doFetch();
    });
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;
    let praticaID = rest._id;

    const RenderNote = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {storico_note.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderOperatoreStorico = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    let statiPossibili = ["Sospesa", "inviata"];

    const handleStatoCivile = (e) => {
      console.log(e.target.value);
      e.target.value === "nubile o celibe"
        ? setIsNubile(true)
        : setIsNubile(false);
    };

    const RenderModal = () => {
      return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
          <div className="row mb-2">
            <div className="col">
              <button
                type="button"
                className="btn btn-secondary btn-sm "
                onClick={closeModal}
                style={{ float: "right" }}
              >
                Chiudi
              </button>
            </div>
          </div>
          <form className="form-inline" onSubmit={EditAssistito}>
            <div className="row mb-2">
              <div className="col-6 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="staticEmail2"
                  className="sr-only"
                >
                  Nome
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nome"
                  name="nome"
                  defaultValue={userEdit.nome}
                  required
                />
              </div>
              <div className="col-6 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="inputPassword2"
                  className="sr-only"
                >
                  Cognome
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cognome"
                  name="cognome"
                  defaultValue={userEdit.cognome}
                  required
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  defaultValue={userEdit.email}
                />
              </div>

              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Categoria Assistito
                </label>
                <select
                  className="form-control"
                  id="categoria_assistito"
                  name="categoria_assistito"
                  defaultValue={userEdit.categoria_assistito}
                >
                  <option value="artigiano">Artigiano</option>
                  <option value="commerciante">Commerciante</option>
                  <option value="autonomo">Autonomo</option>
                  <option value="disoccupato">Disoccupato</option>
                  <option value="gestione separata">Gestione separata</option>
                  <option value="lavoro dipendente privato">
                    Lavoro dipendente - privato
                  </option>
                  <option value="lavoro dipendente pubblico">
                    Lavoro dipendente - pubblico
                  </option>
                  <option value="pensionato">Pensionato</option>
                  <option value="studente minore">Studente/Minore</option>
                </select>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Cellulare
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cellulare"
                  name="cellulare"
                  defaultValue={userEdit.cellulare}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Telefono
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="telefono"
                  name="telefono"
                  defaultValue={userEdit.telefono}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  C.F
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="codice_fiscale"
                  name="codice_fiscale"
                  defaultValue={userEdit.codice_fiscale_assistito}
                />
              </div>

              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Stato Civile
                </label>
                <select
                  className="form-control"
                  id="stato_civile"
                  name="stato_civile"
                  defaultValue={userEdit.stato_civile}
                  onChange={(e) => handleStatoCivile(e)}
                >
                  <option value="nubile o celibe">Nubile/Celibe</option>
                  <option value="coniugato">Coniugato</option>
                  <option value="divorziato">Divorziato</option>
                  <option value="separato">Separato</option>
                  <option value="vedovo">Vedovo</option>
                </select>
              </div>
            </div>

            <div className="row mb-2 mt-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Via
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="indirizzo"
                  name="indirizzo"
                  defaultValue={userEdit.indirizzo}
                />
              </div>

              {!isNubile && (
                <div className="col input-group-sm">
                  <label
                    style={{ fontSize: "smaller" }}
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Data Stato Civile
                  </label>
                  <input
                    type="date"
                    required
                    className="form-control"
                    id="data_stato_civile"
                    name="data_stato_civile"
                    defaultValue={userEdit.data_stato_civile}
                  />
                </div>
              )}
            </div>
            <div className="row mb-2 mt-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  CAP
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cap"
                  name="cap"
                  defaultValue={userEdit.cap}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Comune
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="comune"
                  name="comune"
                  defaultValue={userEdit.comune}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Provincia
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="provincia"
                  name="provincia"
                  defaultValue={userEdit.provincia}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Regione
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="regione"
                  name="regione"
                  defaultValue={userEdit.regione}
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Salva
            </button>
          </form>
        </Modal>
      );
    };

    return (
      <>
        {RenderModal()}

        <div className="container blocco-row">
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: " space-between",
                alignItems: "center",
              }}
            >
              <span>Assistito</span>
              <Button onClick={() => handleEdit(praticaID, assistitoObj)}>
                Modifica
              </Button>
            </div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile}`}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container container blocco-row">
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">data di scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        {/*
              <div className="container blocco-row" >
              <div className="row">

              <h4 style={{color:'red'}}>Stato Pratica:  {`${statoPratica} `}</h4>
              </div> 
              </div>

              <div className="container blocco-row" >
              <div >

              <small className="label-pratica">Carica gli allegati: </small>
              <FileUpload
              accept=".pdf"
              label="Area docummenti allegati (max 7,5 mb per file - max 30 files)"
              multiple
              updateFilesCb={updateUploadedFiles}
            />
            <button className="btn btn-primary"
            disabled={!selectedFiles}
            onClick={onClickHandler}
            type="button" id="inputGroupFileAddon04">Carica i files!
            </button>
              </div> 
              </div>

              <div className="container blocco-row" >
                <div className="row">

                  <div className="col">


                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <small className="label-pratica">Cambia lo stato pratica qui: </small>
                  <select defaultValue={statoPratica} className="form-select" aria-label="Default select example" onChange={handleSelect} >
                  {statiPossibili.map((item, i) =>{
                    return <option key={i} value={item}>{item}</option>
                  })}
                  </select>


                  <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                aria-valuenow={ progress }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div></div>
                  { isVisible ?
                      <div className='edit' >
                      <div className='col'>

                      {fileInfos &&
                        fileInfos.map((fileInfo, index) => (
                          <div className="mb-2" key={index}>
                            <span>{fileInfo.path}</span>
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-info"
                                role="progressbar"
                                aria-valuenow={ progress }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                <div className='col'>
                   <ul>

                  {fileInfos.map((el, i)=>{
                    return(
                      <li key={i}>
                      <a  href={`http://localhost:5001/static/allegati/${el.path} `}><i className="bi bi-file-pdf" style={{color: 'red'}}></i>{el.filename}</a>
                      </li>
                      )

                  } )}
                   </ul>
                 </div>




                      <div className='col'>
                      <small className="label-pratica">Aggiungi note </small>
                      <div className="input-group">
                      <span className="input-group-text">Campo testo</span>
                      <textarea
                      className="form-control"
                      aria-label="Campo testo"
                      name='note'
                      onChange={e => setNote(e.target.value)}

                      defaultValue={''}></textarea>
                    </div>
                      </div>

                      <div className="container blocco-row" >
                      <div className="row">
                      <button className="btn btn-success"
                      disabled={!selectedFiles}
                      onClick={handleSubmit}
                      type="button" id="inputGroupFileAddon088">Cambia lo stato pratica!</button>
                      </div></div>



                      </div>
                      : null }
                  </form>

                  </div>

                </div>
              </div>
 */}

        <div className="container blocco-row">
          <div
            className="label-pratica"
            style={{ color: "red", fontWeigth: "700" }}
          >{` 1) CARICA L'ALLEGATO E CLICCA SU UPLOAD `}</div>
        </div>

        <div className="container">
          <div className="row blocco-row">
            <FileUpload
              accept=".pdf"
              label="Area documenti allegati"
              multiple
              updateFilesCb={updateUploadedFiles}
            />

            {isVisible ? (
              <div className="edit">
                <div className="col">
                  {fileInfos &&
                    fileInfos.map((fileInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>{fileInfo.path}</span>
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-info"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                          >
                            {progress}%
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="col">
                  <ul>
                    {fileInfos.map((el, i) => {
                      return (
                        <li key={i}>
                          <a
                            href={`${ENDPOINTALLEGATI}${el.filename}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            downlod="true"
                          >
                            {" "}
                            {el.name}{" "}
                            <i
                              className="bi bi-file-pdf"
                              style={{ color: "red" }}
                            ></i>
                            {el.filename}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>

          <div className="row blocco-row">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFiles}
              >
                Upload!
              </button>
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div
            className="label-pratica"
            style={{ color: "red", fontWeigth: "700" }}
          >{` 2) CAMBIA LO STATO DELLA PRATICA CLICCANDO SUL MENU QUI SOTTO: DA -> SOSPESA A -> INVIATA`}</div>
        </div>

        <div className="container">
          <div className="row blocco-row">
            <form className="needs-validation" encType="multipart/form-data">
              <div className="row mt-4">
                <div className="col-10">
                  <select
                    className="form-select"
                    defaultValue={statoPratica}
                    aria-label="Default select example"
                    onChange={handleSelect}
                    required
                  >
                    <option disabled value="">
                      Seleziona stato...
                    </option>
                    {statiPossibili.map((item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>

                  <div className="valid-feedback">OK!</div>
                </div>
              </div>

              <div className="container blocco-row">
                <div
                  className="label-pratica"
                  style={{ color: "red", fontWeigth: "700" }}
                >{`3) INSERISCI UNA NOTA (facoltativo)`}</div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <div className="input-group">
                    <span className="input-group-text">
                      inserisci le note qui
                    </span>
                    <textarea
                      className="form-control"
                      aria-label="Campo testo"
                      name="note"
                      onChange={(e) => setNote(e.target.value)}
                      defaultValue={""}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="row blocco-row">
                <div
                  className="label-pratica"
                  style={{ color: "red", fontWeigth: "700" }}
                >{` 4) CLICCA SU SALVA PER INVIARE LA PRATICA! - Info: se si salta anche un solo passaggio richiesto, non si potrà procedere`}</div>
              </div>

              <div className="row mt-4">
                <div className="">
                  <div className="label-pratica">. </div>
                  <button
                    className="btn btn-primary btn-lg"
                    style={{ float: "right" }}
                    disabled={!abilitaInvia}
                    onClick={(e) => handleSubmit(e, praticaID)}
                    type="button"
                    id="inputGroupFileAddon088"
                  >
                    Salva!
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> DOCUMENTI ALLEGATI</div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody>
                  {allegato?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {" "}
                        <i
                          className="bi bi-file-pdf"
                          style={{ color: "red", fontSize: "25px" }}
                        ></i>
                      </td>
                      <td>
                        {" "}
                        <a
                          href={`${ENDPOINT}/static/allegati/` + rowData.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {rowData.path}
                        </a>
                      </td>
                      <td>
                        {moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-workspace"> </i>
            </div>
            <div className="col">{RenderOperatoreStorico()}</div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div> Storico pratica</div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Stato</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {storico_pratica?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.stato}</td>
                      <td>
                        {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                          "DD/MM/YYYY, HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Ricevuta <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>Url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody>
                  {ricevute?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <i
                          className="bi bi-file-pdf"
                          style={{ color: "red" }}
                        ></i>
                      </td>
                      <td>
                        <a
                          href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {rowData.path}
                        </a>
                      </td>
                      <td>
                        {moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-text"></i>
            </div>
            <div className="col">{RenderNote()}</div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <span className="btn1">
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        //   accessor: c => `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}`
        accessor: (c) =>
          c.operatore
            ? `${c.operatore[c.operatore.length - 1].nome} ${
                c.operatore[c.operatore.length - 1].cognome
              }`
            : "NULL",
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "#ffc107", fontWeight: "bolder" }}>
            {props.value} <i className="bi bi-exclamation-circle"></i>
          </span>
        ),
      },
      {
        Header: "Sospesa il",
        sortType: () => 1,
        accessor: (c) =>
          `${moment(
            c.storico_pratica[c.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
      },
      {
        Header: "Azione",
        id: "id",
        Cell: (props) => (
          <Button className="btn1" onClick={() => handleDelete(props)}>
            {" "}
            <i className="bi bi-archive"></i> Elimina!
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <div
      className="test"
      style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
    >
      <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
        <div className="table-responsive">
          <TableConsContainer
            columns={columns}
            data={data}
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </Container>
    </div>
  );
};

export default PraticheSospese;
