import React, { useState, useEffect, useMemo } from 'react';
//import socketIOClient from "socket.io-client";
import TableConsContainer from "./TableConsContainer"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Table, Button } from 'react-bootstrap';

//import { Console } from 'jest-util';
import axios from "axios";
//import UploadRicevutaService from '../components/services/uploadRicevutaService';
import Endpoints from "../components/services/Endpoints";

import '../SlideDrawer/SlideDrawer.css';
import '../SlideDrawer/Backdrop.css';
import moment from 'moment';


const ENDPOINT = Endpoints.getUrls();

const PraticheLavorazione = () => {

  const [data, setData] = useState([])

  // const [statoPratica, setStatoPratica] = useState('');
  //  const [isVisible, setIsVisible] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  //  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  //  const [progress, setProgress] = useState(0);
  // PER CAMBIOSTATO DELLA PRATICA
  const [currentLoggedUser, setCurrentLoggedUser] = useState({});
  const [selectedOperatore, setSelectedOperatore] = useState({});
  const [selectedDescrizione, setSelectedDescrizione] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  //  const [note, setNote] = useState('');
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    const doFetch = async () => {

      console.log("TEST-RoleCons: ", localStorage.getItem('currentUser'));
      const cons = JSON.parse(localStorage.getItem('currentUser'));
      setCurrentLoggedUser(cons)
      const id = cons._id;
      const response = await fetch(`${ENDPOINT}/pratiche/consulente/lavorazione/id?_id=${id}`);
      const pratiche = await response.json();
      console.log(pratiche);
      setData(pratiche);
    };
    doFetch();
  }, []);

  const handleShow = (event) => {

    console.log(event.original._id)
    console.log(event.values._id)
    setSelectedId(event.values._id);
    setSelectedOperatore(event.original.operatore[0]);
    setSelectedDescrizione(event.original.descrizione);
  }



  useEffect(() => {
    console.log("->126 ", selectedFile);
    console.log("->127 ", fileInfos);
  })


  const handleMessaggi = (event) => {

    event.preventDefault();
    console.log(selectedOperatore)

    let currentUser = currentLoggedUser;

    let praticaId = "";
    let sender = "";
    let receiver = "";
    let to = selectedOperatore._id
    let from = currentUser._id;
    let messaggio = "";

    const formData = new FormData(event.currentTarget);

    for (let [key, value] of formData.entries()) {
      if (key === "idpratica") {
        praticaId = value;
      }
      if (key === "sender") {
        sender = value;
      }
      if (key === "receiver") {
        receiver = value;
      }
      if (key === "messaggio") {
        messaggio = value;
      }
      console.log(key, value);
    }

    let msgObj = {
      idPratica: praticaId,
      descrizione: selectedDescrizione,
      users: [from, to],
      sender: from,
      senderName: sender,
      receiverName: receiver,
      message: messaggio,
      createdAt: new Date()
    }

    const headers = {
      "Content-Type": "application/json",
    }
    axios.post(`${ENDPOINT}/direct/message`, msgObj, {
      headers: headers,
    }).then(response => { // then print response status
      console.log(response);
      setDrawerOpen(!drawerOpen);
    })

  }


  const renderRowSubComponent = (row) => {

    let { allegato, operatore, data_scadenza, descrizione, note, statoPratica, storico_pratica, ricevute, storico_operatore, storico_note, ...rest } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    console.log(rest.consulente);


    //     let statiPossibili = ['Bozza', 'inviata'];
    const SlideDrawer = () => {

      let drawerClasses = 'side-drawer'

      if (drawerOpen) {
        drawerClasses = 'side-drawer open'
      }
      return (

        <div className={drawerClasses}>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
          </svg>
          <button type="button" className="btn btn-light btn-sm" style={{ float: 'right' }} onClick={() => setDrawerOpen(!drawerOpen)}> X </button>

          <form onSubmit={handleMessaggi} encType="multipart/form-data" >

            <div className="mb-3">
              <label htmlFor="idpratica" className="form-label">id pratica</label>
              <input
                type="text"
                className="form-control"
                name='idpratica'
                id="idpratica"
                defaultValue={selectedId}
                readOnly="1" />
            </div>

            <div className="row">
              <div className="col mb-3">
                <label htmlFor="exampleInputSender" className="form-label">Mittente</label>
                <input
                  type="text"
                  className="form-control"
                  name='sender'
                  id="sender"
                  defaultValue={`${currentLoggedUser.cognome} ${currentLoggedUser.nome}`}
                  readOnly="1"
                />
              </div>
              <div className="col mb-3">
                <label v="exampleInputSender" className="form-label">Destinatario</label>
                <input
                  type="text"
                  className="form-control"
                  name='receiver'
                  id="receiver"
                  defaultValue={`${selectedOperatore.cognome} ${selectedOperatore.nome}`}
                  readOnly="1"
                />
              </div>
            </div>


            <div className="mb-3">
              <label htmlFor="messaggio" className="form-label">Messaggio</label>
              <input
                type="text"
                className="form-control"
                name='messaggio'
                id="messaggio"
                rows="2"
              />
            </div>
            <div className="mb-3 form-check">

            </div>
            <button type="submit" className="btn btn-primary">Invia</button>

          </form>
        </div>

      )

    }

    const Backdrop = () => {
      return (
        <div className="backdrop" />
      )
    }

    return (
      <>

        <div className="container container blocco-row">
          <div className="row">
            {SlideDrawer()}
            <div className="col-4">
              <span style={{ fontWeight: 'bold' }}>Consulente</span>
            </div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col-4">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>


          </div>
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>

          </div>
          <div className="row">
            <div className="col"><i className="bi bi-envelope" ></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col"><i className="bi bi-phone" ></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col"><i className="bi bi-telephone" ></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>


        <div className="container container blocco-row" >
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>

          </div>
        </div>

        <div className="container container blocco-row" >
          <div className="row">
            <div> Assistito</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {assistitoObj.data_stato_civile && `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format("DD/MM/YYYY")} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>




        <div className="container container blocco-row" >
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container container blocco-row" >
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
            {data_scadenza && <div className="col">
              <small className="label-pratica">data di scadenza: </small>
              {`${data_scadenza} `}
            </div>}
          </div>
        </div>

        <div className="container container blocco-row" >
          <div className="row">
            <div> Note</div>
            <div className="col">
              <small className="label-pratica">Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div className="container container blocco-row" >
          <div className="row">
            <div> DOCUMENTI ALLEGATI</div>
            <div className="col">
              <Table bordered style={{ borderColor: '#5e5c59' }}>
                <thead >
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody>
                  {allegato?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>  <i className="bi bi-file-pdf" style={{ color: 'red', fontSize: '25px' }}></i></td>
                      <td> <a href={`${ENDPOINT}/static/allegati/${rowData.path} `} download target="_blank" rel="noopener noreferrer">
                        {rowData.path}</a></td>
                      <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>


        <div className="container container blocco-row" id="storico-pratica">
          <div className="row">
            <div> Storico pratica</div>
            <div className="col">
              <Table bordered style={{ borderColor: '#5e5c59' }}>
                <thead >
                  <tr>
                    <th>#</th>
                    <th>Stato</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {storico_pratica?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.stato}</td>
                      <td>{moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format("DD/MM/YYYY, HH:mm")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>


        <div className="container container blocco-row" id="storico-ricevuta">
          <div className="row">
            <div> Storico Ricevuta <i className="bi bi-card-checklist" ></i></div>
            <div className="col">

              <Table bordered style={{ borderColor: '#5e5c59' }}>
                <thead >
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>Url</th>
                    <th>Creato</th>

                  </tr>
                </thead>
                <tbody>
                  {ricevute?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td><i className="bi bi-file-pdf" style={{ color: 'red' }}></i></td>
                      <td><a href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `} download target="_blank" rel="noopener noreferrer">{rowData.path}</a></td>
                      <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
                    </tr>

                  ))}

                </tbody>
              </Table>

            </div>
          </div>
        </div>



        <div className="container container blocco-row" id="storico-note">
          <div className="row">
            <div> Storico Note <i className="bi bi-card-checklist"></i></div>
            <div className="col">

              <Table bordered style={{ borderColor: '#5e5c59' }}>
                <thead >
                  <tr>
                    <th>#</th>
                    <th>Nota</th>
                    <th>Data</th>

                  </tr>
                </thead>
                <tbody>
                  {storico_note?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.nota}</td>
                      <td>{moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format("DD/MM/YYYY, HH:mm")}</td>
                    </tr>

                  ))}

                </tbody>
              </Table>

            </div>
          </div>
        </div>


        <div className="container container blocco-row" id="storico-operatore">
          <div className="row">
            <div> Storico Operatore <i className="bi bi-person-video3"></i></div>
            <div className="col">
              <Table bordered style={{ borderColor: '#5e5c59' }}>
                <thead >
                  <tr>
                    <th>#</th>
                    <th>_id</th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Email</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {storico_operatore?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.id}</td>
                      <td>{rowData.nome}</td>
                      <td>{rowData.cognome}</td>
                      <td>{rowData.email}</td>
                      <td>{moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format("DD/MM/YYYY, HH:mm")}</td>

                    </tr>

                  ))}

                </tbody>
              </Table>
            </div>
          </div>
        </div>



      </>


    );
  };


  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander', // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <i className="bi bi-caret-down-fill"></i> : <span className="btn1" onClick={() => handleShow(row)}> <i className="bi bi-caret-right-fill"></i> </span>}
          </span>
        ),

      },
      {
        Header: "ID",
        accessor: "numberId",

      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: a => `${a.assistito.nome} ${a.assistito.cognome}`

      },
      {
        Header: "Consulente",
        accessor: b => `${b.consulente.nome} ${b.consulente.cognome}`

      },
      {
        Header: "Operatore",
        //   accessor: c => `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}`
        accessor: c => (c.operatore ? `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}` : "NULL")
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: props => <span style={{ color: 'darkgreen', fontWeight: 'bolder' }}>{props.value}</span>
      },
      {
        Header: "In Lavorazione dal",
        accessor: (c) =>
          `${moment(c.storico_pratica[c.storico_pratica.length - 1].data, "DD/MM/YYYY, HH:mm:ss").format("DD/MM/YYYY, HH:mm")}`,
      },
    ],
    []
  )


  return (
    <div className='test' style={{ paddingBottom: '100px', backgroundColor: '#dee2e6' }}>
      <Container fluid style={{ marginTop: 25, marginBottom: '40px' }}>
        <div className="table-responsive">
          <TableConsContainer columns={columns} data={data} renderRowSubComponent={renderRowSubComponent} />
        </div>
      </Container>
    </div>
  );
};

export default PraticheLavorazione;
