import React, { useState, useEffect, useMemo } from "react";
import TableArchivioContainer from "./TableArchivioContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Table,
  Button,
  Accordion,
  InputGroup,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import "../loaderino.css";
import Endpoints from "../../components/services/Endpoints";
import client from "../../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();

const ArchivioPraticheAdmin = () => {
  const [data, setData] = useState([]);

  // const [statoPratica, setStatoPratica] = useState('');
  //  const [isVisible, setIsVisible] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  //  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [findDescrizione, setFindDescrizione] = useState("");
  const [findId, setFindId] = useState("");
  const [findConsulenteCognome, setFindConsulenteCognome] = useState("");
  const [findAssistitoCognome, setFindAssistitoCognome] = useState("");
  const [findAssistitoCF, setFindAssistitoCF] = useState("");
  const [findFrom, setFindFrom] = useState("");
  const [findTo, setFindTo] = useState("");

  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    fetchPraticheAll();
  }, []);

  const fetchPraticheAll = () => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/archivio/operatore`,
        config: { method: "GET" },
      });
      const pratiche = response.data;
      setData(pratiche);

      setSpinnerLoading(false);
    };
    doFetch();
  };

  const handleShow = (event) => {
    console.log(event.original._id);
    console.log(event.values._id);
    setSelectedId(event.values._id);
  };

  useEffect(() => {
    console.log("->126 ", selectedFile);
    console.log("->127 ", fileInfos);
  });

  const BasicExample = () => {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <i class="bi bi-binoculars"></i> Cerca in Archivio{" "}
          </Accordion.Header>
          <Accordion.Body style={{ background: "beige", marginBottom: "11px" }}>
            <p style={{ fontWeigth: "medium", color: "darkred" }}>
              Il campo _id va utilizzato "solo" e non in combo con altri campi
            </p>
            {BasicForm()}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const FindOnServer = (event) => {
    event.preventDefault();

    if (event.target.name === "find_id") {
      setFindId(event.currentTarget.value);
    }

    if (event.target.name === "find_descrizione") {
      setFindDescrizione(event.currentTarget.value);
    }

    if (event.target.name === "find_consulente_cognome") {
      setFindConsulenteCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cognome") {
      setFindAssistitoCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cf") {
      setFindAssistitoCF(event.currentTarget.value);
    }

    if (event.target.name === "find_from") {
      setFindFrom(event.currentTarget.value);
    }

    if (event.target.name === "find_to") {
      setFindTo(event.currentTarget.value);
    }
  };

  const ClearAll = () => {
    fetchPraticheAll();
    setFindId("");
    setFindDescrizione("");
    setFindConsulenteCognome("");
    setFindAssistitoCognome("");
    setFindAssistitoCF("");
    setFindFrom("");
    setFindTo("");
  };

  const findValueOnServer = () => {
    setSpinnerLoading(true);
    //  console.log(pratiche);

    const asyncPostCall = async () => {
      try {
        const response = await client({
          url: `/archivio/find/`,
          config: {
            method: "POST",
            data: {
              // your expected POST request payload goes here
              _id: findId,
              descrizione: findDescrizione,
              consulenteCognome: findConsulenteCognome,
              assistitoCognome: findAssistitoCognome,
              assistitoCF: findAssistitoCF,
              findFrom: findFrom,
              findTo: findTo
                ? new Date(findTo).setUTCHours(23, 59, 59, 999)
                : "",
            },
          },
        });
        const data = response.data;
        setData(data);
        setSpinnerLoading(false);
      } catch (error) {
        // enter your logic for when there is an error (ex. error toast)
        setSpinnerLoading(false);
        console.log(error);
      }
    };

    asyncPostCall();
  };

  const BasicForm = () => {
    return (
      <>
        <Form>
          <Row>
            <Col>
              <Form.Control
                placeholder="_id"
                name="find_id"
                value={findId}
                onChange={FindOnServer}
                style={{ border: "solid 1px red" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Descrizione"
                name="find_descrizione"
                value={findDescrizione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Consulente cognome"
                name="find_consulente_cognome"
                value={findConsulenteCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Assistito cognome"
                name="find_assistito_cognome"
                value={findAssistitoCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Assistito C.F."
                name="find_assistito_cf"
                value={findAssistitoCF}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Da"
                name="find_from"
                type="date"
                value={findFrom}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Da"
                name="find_to"
                type="date"
                value={findTo}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Button variant="primary" onClick={findValueOnServer}>
                Cerca
              </Button>
            </Col>
            <Col>
              <Button variant="primary" onClick={ClearAll}>
                Pulisci tutto
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      note,
      statoPratica,
      storico_pratica,
      ricevute,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    const formatDate = (utcSeconds) => {
      let date = new Date(0);
      date.setUTCSeconds(utcSeconds);
      return date.toLocaleString();
    };

    return (
      <>
        <div className="container blocco-row">
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Assistito</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {`${assistitoObj.data_stato_civile} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Note</div>
            <div className="col">
              <small className="label-pratica">Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> DOCUMENTI ALLEGATI</div>
            <div className="col">
              <Table>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody>
                  {allegato?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <i
                          className="bi bi-file-pdf"
                          style={{ color: "red", fontSize: "25px" }}
                        ></i>
                      </td>
                      <td>
                        <a
                          href={rowData.path}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {rowData.path}
                        </a>
                      </td>
                      <td>{rowData.creato && formatDate(rowData.creato)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div> Storico pratica</div>
            <div className="col">
              <Table>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>Stato</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody style={{ color: "grey" }}>
                  {storico_pratica?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.stato !== "nessuno" && rowData.stato}</td>
                      <td>{rowData.data && formatDate(rowData.data)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Ricevuta <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">
              <Table>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>Url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody style={{ color: "gray" }}>
                  {ricevute?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <i
                          className="bi bi-file-pdf"
                          style={{ color: "red" }}
                        ></i>
                      </td>
                      <td>
                        <a
                          href={rowData.path}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {rowData.path}
                        </a>
                      </td>
                      <td>{rowData.creato && formatDate(rowData.creato)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">
              <Table>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>Nota</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody className="label-pratica">
                  {storico_pratica.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.note && rowData.note}</td>
                      <td>{rowData.data && formatDate(rowData.data)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">
              <Table>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody className="label-pratica">
                  {[operatore].map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.nome}</td>
                      <td>{rowData.cognome}</td>
                      <td>{rowData.email}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i class="bi bi-caret-down-fill"></i>
            ) : (
              <span className="btn1" onClick={() => handleShow(row)}>
                {" "}
                <i class="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "_id",
        accessor: "_id",
      },
      {
        Header: "Tipo Pratica",
        accessor: "tipo_pratica",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "C.F. Assistito",
        accessor: (a) => `${a.assistito.codice_fiscale_assistito}`,
      },

      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        //   accessor: c => `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}`
        accessor: (c) =>
          c.operatore ? `${c.operatore.nome} ${c.operatore.cognome}` : "NULL",
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "green", fontWeight: "bolder" }}>
            {props.value}
          </span>
        ),
      },

      {
        Header: "Chiusa il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment
            .unix(Number(d.storico_pratica[d.storico_pratica.length - 1]?.data))
            .format("DD/MM/YYYY, HH:mm")}`,
      },
    ],
    []
  );

  return (
    <div
      className="test"
      style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
    >
      <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
        {BasicExample()}
        <TableArchivioContainer
          columns={columns}
          data={data}
          renderRowSubComponent={renderRowSubComponent}
        />
      </Container>
    </div>
  );
};

export default ArchivioPraticheAdmin;
