import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormControl,
  ProgressBar,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "bootstrap/dist/css/bootstrap.min.css";
var CodiceFiscale = require("codice-fiscale-js");

const ENDPOINT = process.env.REACT_APP_BACKEND_URL;
const MySwal = withReactContent(Swal);
const UpdateAccount = (props) => {
  const auth = useAuth();
  const token = auth.user?.expires_at;

  const detail = auth.user?.profile;

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadDocPercentage, setUploadDocPercentage] = useState(0);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [gender, setGender] = useState("");
  const [birthplaceProvincia, setBirthplaceProvincia] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [codiceFiscaleCv, setCodiceFiscaleCv] = useState("");

  const [verificato, setVerificato] = useState(false);
  const [validated, setValidated] = useState(false);

  //questi per l'upload
  const [fileCollaboratore, setFileCollaboratore] = useState([]);
  const [fileDocumento, setFileDocumento] = useState([]);
  //questi per il db
  const [mandatoCollaboratore, setMandatoCollaboratore] = useState([]);
  const [documentoUser, setDocumentoUser] = useState([]);

  console.warn("propppese", props);
  const params = useLocation();
  console.log(params);

  const [data, setData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/users/teleconsul/fetch/",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subid: params.state.subid }),
        }
      );
      response = await response.json();
      if (response) {
        console.log(response);
        setData(response.authuser);
      }

      // ...
    }
    fetchData();
  }, [params.state.subid]); // Or [] if effect doesn't need props or state

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let newUserObj = {
        _id: params.state._id,
        nome: event.target.nome.value,
        cognome: event.target.cognome.value,
        codice_fiscale: event.target.codice_fiscale.value,
        partita_iva: event.target.partita_iva.value,
        ragione_sociale: event.target.ragione_sociale.value,
        cellulare: event.target.cellulare.value,
        telefono: event.target.telefono.value,
        consenso: "si",
        email: event.target.email.value,
        creato: new Date().toLocaleString("it-IT"),
        pec: event.target.pec.value,
        fax: event.target.fax.value,
        roles: ["consulente", "teleconsul"],
        stato: "Off",
        password:
          "$2a$10$1ptBKCI2SSkvkd4UGO6aou3smO59DuCAp8hfYqpi21XSwpafkqbRy",
        ritratto: "",
        ultimo_accesso: new Date().toLocaleString,
        indirizzo: {
          via: event.target.via.value,
          cap: event.target.cap.value,
          localita_esercizio: event.target.localita_esercizio.value,
          comune: event.target.comune.value,
          provincia: event.target.provincia.value,
          regione: event.target.regione.value,
          citta: event.target.localita_esercizio.value,
        },
        documento: documentoUser,
        collaboratore: {
          nome: event.target.cv_nome.value,
          cognome: event.target.cv_cognome.value,
          codice_fiscale: event.target.cv_codice_fiscale.value,
        },
        mandato: mandatoCollaboratore,
      };

      console.log(newUserObj);

      if (newUserObj.nome && verificato) {
        axios
          .post(`${ENDPOINT}/users/teleconsul/update/`, newUserObj)
          .then(function (response) {
            let updt = response.data.mess;

            if (parseInt(updt) === 1) {
              async function fetchData() {
                // You can await here
                let response = await fetch(
                  process.env.REACT_APP_BACKEND_URL +
                  "/users/teleconsul/fetch/",
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json, text/plain, */*",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ subid: params.state.subid }),
                  }
                );
                response = await response.json();
                if (response) {
                  console.log(response);
                  //  setData(response.authuser);
                  let info = response.authuser;

                  let tokenObj = {
                    _id: params.state._id,
                    nome: info.nome,
                    cognome: info.cognome,
                    email: info.email,
                    cellulare: info.cellulare,
                    telefono: info.telefono,
                    //   password: risposta.password,
                    role: info.roles,
                    token: info.accessToken,
                    subscription: info.subscription,
                    created_at: info.creato,
                    freeAccount: info.freeAccount
                  };
                  localStorage.setItem("currentUser", JSON.stringify(tokenObj));

                  setValidated(true);

                  Swal.fire({
                    title: "Updates",
                    text: `Account modificato con successo! Il tuo account verrà attivato a breve`,
                    icon: "info",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok!",
                    footer: `<b>Teleconsul<b/>`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      //  event.target.form.reset();
                      navigate("/consulente/tipo_pratica");
                    }
                  });
                }

                // ...
              }
              fetchData();
            } else {
              Swal.fire("Impossibile procede");

              console.log(response.data);
              event.target.reset();
              navigate("/");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        alert("Verificare il codice fiscale: cancellarlo e riscriverlo");
        return;
      }
    }
  };
  const onFileCollaboratoreChange = (event) => {
    setFileCollaboratore(event.target.files[0]);
  };

  const handleFileCollaboratoreUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("mandato", fileCollaboratore);
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        console.log(progressEvent);
        let percent = Math.floor((loaded * 100) / total);
        setUploadPercentage((loaded * 100) / total);
        console.log(percent);
        if (percent < 100) {
          //  setUploadPercentage(percent);
        }
      },
    };

    // Details of the uploaded file
    console.log(fileCollaboratore);

    const headers = {
      "Content-Type": "multipart/form-data",
      mode: "no-cors",
    };
    // Request made to the backend api
    // Send formData object

    axios
      .post(`${ENDPOINT}/mandato`, formData, options, headers)
      .then((response) => {
        console.log(response);
        let fileCollObject = [
          {
            filename: response.data[0].filename,
            creato: new Date().toLocaleString(),
            path: `${ENDPOINT}/static/mandato/${response.data[0].filename}`,
          },
        ];

        setMandatoCollaboratore(fileCollObject);
      })
      .catch((error) => {
        console.log(error);
        setUploadPercentage(0);
      });
  };

  const onFileDocumentoChange = (event) => {
    setFileDocumento(event.target.files[0]);
  };

  const handleFileDocumentoUpload = () => {
    const formData = new FormData();
    // Update the formData object
    formData.append("documentouser", fileDocumento);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        console.log(progressEvent);

        let percent = Math.floor((loaded * 100) / total);
        setUploadDocPercentage((loaded * 100) / total);

        console.log(percent);
        if (percent < 100) {
          //  setUploadPercentage(percent);
        }
      },
    };

    // Details of the uploaded file
    console.log(fileDocumento);

    const headers = {
      "Content-Type": "multipart/form-data",
      mode: "no-cors",
    };
    // Request made to the backend api
    // Send formData object

    axios
      .post(`${ENDPOINT}/documentouser`, formData, options, headers)
      .then((response) => {
        console.log(response);

        //   [{"fieldname":"documentouser","originalname":"spedizioneOcchialiVintageSephora.pdf","encoding":"7bit","mimetype":"application/pdf","destination":"./public/data/uploads/identitausers/","filename":"1654840577918_spedizioneOcchialiVintageSephora.pdf","path":"public/data/uploads/identitausers/1654840577918_spedizioneOcchialiVintageSephora.pdf","size":30500}]

        let fileDocObject = [
          {
            filename: response.data[0].filename,
            creato: new Date().toLocaleString(),
            path: `${ENDPOINT}/static/identitausers/${response.data[0].filename}`,
          },
        ];

        setDocumentoUser(fileDocObject);
      })
      .catch((error) => {
        console.log(error);
        setUploadPercentage(0);
      });
  };

  const verificaCF = (event) => {
    verificaCFx(event.target.value);
  };

  function verificaCFx(cf) {
    if (CodiceFiscale.check(cf)) {
      console.log("corretto");
      setVerificato(true);
    } else {
      console.log("errato");
      setVerificato(false);
    }
  }

  return (
    <div className="login-page">
      <small></small>

      <div className="container">
        {data ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h3 className="mb-3 text-white"> </h3>
              <div
                className="bg-white shadow rounded"
                style={{
                  position: "relative",
                  top: "50%",
                  WebkitTransform: "translateY(-50%)",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <div className="row">
                  <div className="col-md-9 pe-0">
                    <div className="form-left h-100 py-5 px-5">
                      <Form
                        className="row g-3 needs-validation"
                        novalidate
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        style={{ color: "darkgrey", fontSize: "x-small" }}
                      >
                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="Nome">Nome</Form.Label>
                            <Form.Control
                              required
                              placeholder="Nome"
                              name="nome"
                              type="text"
                              defaultValue={
                                data.nome ? data.nome : detail.given_name
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="Cognome">Cognome</Form.Label>
                            <Form.Control
                              required
                              placeholder="Cognome"
                              name="cognome"
                              type="text"
                              defaultValue={
                                data.cognome ? data.cognome : detail.family_name
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="Ragione Sociale">
                              Ragione Sociale
                            </Form.Label>
                            <Form.Control
                              placeholder="Ragione Sociale"
                              name="ragione_sociale"
                              required
                              type="text"
                              defaultValue={data.ragione_sociale}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                              required
                              placeholder="email"
                              name="email"
                              style={{ textTransform: "lowercase" }}
                              defaultValue={
                                data.email ? data.email : detail.email
                              }
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="cellulare">
                              Cellulare
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cellulare"
                              name="cellulare"
                              defaultValue={data.cellulare}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="telefono">Telefono</Form.Label>
                            <Form.Control
                              required
                              placeholder="telefono"
                              name="telefono"
                              defaultValue={data.telefono}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="fax">Fax</Form.Label>
                            <Form.Control
                              placeholder="fax"
                              name="fax"
                              type="text"
                              defaultValue={data.fax}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Pec
                            </Form.Label>
                            <Form.Control
                              placeholder="PEC"
                              name="pec"
                              type="text"
                              style={{ textTransform: "lowercase" }}
                              defaultValue={data.pec}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              C.F.
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="Codice Fiscale"
                              name="codice_fiscale"
                              defaultValue={data.codice_fiscale}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              P. I.
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="Partita IVA"
                              name="partita_iva"
                              defaultValue={data.partita_iva}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mb-2 mt-2">
                            <Form.Label htmlFor="disabledTextInput">
                              Indirizzo
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="via"
                              name="via"
                              defaultValue={data.indirizzo?.via}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              CAP
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cap"
                              name="cap"
                              defaultValue={data.indirizzo?.cap}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Comune
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="comune"
                              name="comune"
                              defaultValue={data.indirizzo?.comune}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Provincia (Sigla)
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="esempio: MI"
                              name="provincia"
                              type="text"
                              maxLength="2"
                              style={{ textTransform: "uppercase" }}
                              defaultValue={data.indirizzo?.provincia}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Regione
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="regione"
                              name="regione"
                              defaultValue={data.indirizzo?.regione}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Città
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="citta"
                              name="citta"
                              defaultValue={data.indirizzo?.localita_esercizio}
                              type="text"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Località esercizio
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="localita_esercizio"
                              name="localita_esercizio"
                              defaultValue={data.localita_esercizio}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-4">
                          <Col>
                            <h4>
                              Collaboratore volontario (Obbligatorio){" "}
                              <i className="bi bi-person-plus-fill"></i>
                            </h4>
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Cognome
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cv_cognome"
                              name="cv_cognome"
                              defaultValue={data.collaboratore?.cognome}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Nome
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cv_nome"
                              name="cv_nome"
                              defaultValue={data.collaboratore?.nome}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row
                          className="mb-2 px-4 py-4 row"
                          style={{ background: "#009345", borderRadius: "6px" }}
                        >
                          <Col>
                            <Form.Label
                              htmlFor="disabledTextInput"
                              style={{ color: "white" }}
                            >
                              Codice Fiscale
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="codice fiscale del collaboratore"
                              name="cv_codice_fiscale"
                              type="text"
                              defaultValue={data.collaboratore?.codice_fiscale}
                              onChange={verificaCF}
                            />
                          </Col>
                          <Col>
                            {verificato ? (
                              <div style={{ color: "white" }}>
                                {" "}
                                Verificato!{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="64"
                                  height="64"
                                  fill="currentColor"
                                  className="bi bi-patch-check-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                </svg>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <Row className="mb-3 mt-3">
                          <Col>
                            <label className="form-info-label">
                              Per usufruire dei Servizi di Patronato per il CDL
                              devi:
                            </label>
                            <p></p>
                            <ul
                              style={{
                                listStyleType: "upper-roman",
                                padding: "4px",
                              }}
                            >
                              <li style={{ padding: "4px" }}>
                                Scaricare la richiesta di nomina di
                                collaboratore volontario A.NAS:PRO (clicca su
                                Scarica Mandato)
                              </li>
                              <li style={{ padding: "4px" }}>
                                Compilarla la richiesta in tutte le sue parti
                              </li>
                              <li style={{ padding: "4px" }}>
                                Scansionarla e salvarla in formato pdf
                              </li>
                              <li style={{ padding: "4px", color: "red" }}>
                                Attenzione: rinominare come da esempio:
                                nomina_collaboratore_nome_cognome_2022.pdf
                              </li>
                              <li style={{ padding: "4px" }}>
                                Caricare la richiesta salvata e rinominata in
                                pdf (Cliccando su Carica il mandato)
                              </li>
                              <li style={{ padding: "4px" }}>
                                Caricare copia del documento d'identità del
                                Collaboratore volontario in pdf (Clicca su
                                Carica il documeno)
                              </li>
                              <li style={{ padding: "4px", color: "red" }}>
                                Attenzione: rinominare come da esempio:
                                documento_personale_nome_cognome_2022.pdf
                              </li>
                              <li style={{ padding: "4px" }}>
                                Infine clicca su (Crea nuovo utente per
                                finalizzare l'iscrizione)
                              </li>
                              <li style={{ padding: "4px" }}>
                                {" "}
                                Consulta lo statuto Anaspro{" "}
                                <a
                                  href={`${ENDPOINT}/static/documenti/STATUTO_ANASPRO.pdf`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  CLICCA QUI
                                </a>
                              </li>
                            </ul>
                          </Col>
                          <Row className="mb-3 mt-3">
                            <Col>
                              <a
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                href={`${ENDPOINT}/static/documenti/incarico_collaboratore_volontario.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Scarica il mandato
                              </a>{" "}
                            </Col>
                          </Row>
                        </Row>

                        <Row className="mb-3 mt-3">
                          <Col>
                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label>
                                Carica mandato del Collaboratore Volontario
                              </Form.Label>
                              <Form.Control
                                name="mandato"
                                type="file"
                                accept=".pdf"
                                onChange={onFileCollaboratoreChange}
                              />
                            </Form.Group>
                            {uploadPercentage > 0 && (
                              <div className="row mt-3">
                                <div className="col pt-1">
                                  <ProgressBar
                                    now={uploadPercentage}
                                    striped={true}
                                    label={`${uploadPercentage}%`}
                                  />
                                </div>
                              </div>
                            )}
                          </Col>
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-primary px-4 float-end mt-4"
                              onClick={handleFileCollaboratoreUpload}
                            >
                              Carica il mandato
                            </button>
                          </div>
                        </Row>

                        {/* fileCollaboratore */}

                        <Row className="mb-3 mt-3">
                          <Col>
                            <Form.Group controlId="formFile2" className="mb-3">
                              <Form.Label>Documento d'identità</Form.Label>
                              <Form.Control
                                name="documento"
                                type="file"
                                accept=".pdf"
                                onChange={onFileDocumentoChange}
                              />
                            </Form.Group>
                            {uploadDocPercentage > 0 && (
                              <div className="row mt-3">
                                <div className="col pt-1">
                                  <ProgressBar
                                    now={uploadDocPercentage}
                                    striped={true}
                                    label={`${uploadDocPercentage}%`}
                                  />
                                </div>
                              </div>
                            )}
                          </Col>
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-primary px-4 float-end mt-4"
                              onClick={handleFileDocumentoUpload}
                            >
                              Carica il documeto
                            </button>
                          </div>
                        </Row>

                        {/* Mandato */}
                        <Row className="mb-3 mt-3">
                          <Col>
                            {mandatoCollaboratore.length > 0 &&
                              documentoUser.length > 0 ? (
                              <div style={{ padding: "15px" }}>
                                <Button
                                  type="submit"
                                  variant="primary"
                                  style={{ float: "left" }}
                                >
                                  Aggiorna dati utente
                                </Button>{" "}
                              </div>
                            ) : (
                              <div style={{ padding: "15px" }}>
                                <Button
                                  type="submit"
                                  variant="info"
                                  style={{ float: "left" }}
                                  disabled
                                >
                                  Aggiorna dati utente
                                </Button>{" "}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-3 ps-0 d-none d-md-block">
                    <div className="form-right h-100 bg-primary text-white text-center pt-5">
                      {/*   <img src={LogoNegativo} style={{maxWidth:'200px'}}></img> */}
                      <p></p>
                      <row>
                        <h3 className="mb-3 text-white">Aggiornamento dati:</h3>

                        <h3 className="mb-3 text-white">
                          {" "}
                          I TUOI DATI SONO INCOMPLETI: COMPILA TUTTI I CAMPI PER
                          REGOLARIZZARE
                        </h3>
                      </row>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-end text-secondary mt-3">
                Bootstrap 5 Login Page Design
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UpdateAccount;
