import React from "react";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
//import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
//import { createBrowserHistory } from "history";
import App from "./App";
//import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from "react-oidc-context";

//authority: https://accounts.teleconsul.it/
//redirectUri : https://cdl.bitsolution.digital/authentication/callback

//PRODUZIONE
const oidcConfig = {
  authority: "https://accounts.teleconsul.it/",
  client_id: "C2ED6707-A63E-438B-89B7-F0F419C4B391",
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/authentication/callback`,
  response_type: "code",
  client_secret: "D30D3B6D-5E38-42FF-A015-69AA824E5557",
  //  response_mode: "fragment",
  scope: "openid profile external.api",
  post_logout_redirect_uri: "https://www.tcdesk.it/Catalogo",
};

// STAGING AMBIENT
// const oidcConfig = {
//   authority: "https://tcis-dev.teleconsul.it",
//   client_id: "7E7B3401-4475-4DD5-AB26-BC2E0684BC32",
//   redirect_uri: "https://cdl.bitsolution.digital/authentication/callback",
//   response_type: "code",
//   client_secret: "1F463FD8-EB63-48DD-B1BF-2FCB465956B9",
// //  response_mode: "fragment",
//   scope: "openid profile external.api",
//   post_logout_redirect_uri: "https://cdl.bitsolution.digital//logout"
// };

/*
TEST AMBIENT 
const oidcConfig = {
  authority: "https://tcis-dev.teleconsul.it",
  client_id: "7E7B3401-4475-4DD5-AB26-BC2E0684BC32",
  redirect_uri: "http://localhost:3000/authentication/callback",
  response_type: "code",   
  client_secret: "1F463FD8-EB63-48DD-B1BF-2FCB465956B9",                
//  response_mode: "fragment",
  scope: "openid profile external.api",
  post_logout_redirect_uri: "http://localhost:3000/logout"
};
*/

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
