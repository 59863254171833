import React, {useState} from 'react';
import { useNavigate, Link, Location } from 'react-router-dom';
import axios from "axios";
import Endpoints from "../../components/services/Endpoints";
import LogoNegativo from '../../CDLlogoNegativo.svg'
import './Usersignin.css'

const ENDPOINT = Endpoints.getUrls();


function Userforgot(props) {

    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMail, setErrormail] = useState(false);
    const [disableSend, setDisableSend] = useState(false);

    const handleSubmit = async (e) => {

        axios.post(`${ENDPOINT}/checkmailforgot`, {
          email: email
        })
        .then(function (response) {
  
          console.log(response);
  
                if(response){
  
                  axios.post(`${ENDPOINT}/otpemail`, {
                    email: email
                  })
                  .then(function (response) {
                    console.log(response);
                    navigate('/userforgotstepone',{state:{id:1,email:email}});
                  })
                  .catch(function (err) {
                    console.error("Error response:");
                    console.error(err.response.data);    // ***
                    console.error(err.response.status);  // ***
                    console.error(err.response.headers); // ***
                    setErrormail(true)
                  });
  
                }
          
  
        })
        .catch(function (err) {
  
          setErrormail(true);
          setDisableSend(true);
          console.error(err.response.data);    // ***
          console.error(err.response.status);  // ***
          console.error(err.response.headers); // ***
          console.log(err);
        });
  
          e.preventDefault();
      
      }



    return (
        <div className="login-page bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                <h3 className="mb-3 text-white"> forgot </h3>
                    <div className="bg-white shadow rounded" style={{position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)',  msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'}}>
                        <div className="row">
                            <div className="col-md-7 pe-0">
                                <div className="form-left h-100 py-5 px-5">
                                    <form  className="row g-4" onSubmit={handleSubmit}>
                                            <div className="col-12">
                                                <label>Email<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <div className="input-group-text"><i className="bi bi-person-fill"></i></div>
                                                    <input type="email" name="email" className="form-control" placeholder="Inserisci email" onChange={e=> setEmail(e.target.value)}/> 
                                                </div>
                                                {errorMail ?
                                                    <label htmlFor="errorMail" style={{color:'red'}}>Account non trovato: prego contatattare CAFCDL</label>
                                                    : 
                                                  null
                                                  }
                                            </div>

                                            {disableSend ? 
                                                <div className="col-12 ">
                                                    <button type="submit" className="btn btn-primary" disabled> Invia </button>
                                                </div>
                                                  
                                                    :
                                               <div className="col-12 ">
                                                    <button type="submit" className="btn btn-primary" >Invia</button>
                                                </div>
                          
                                                  }


                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5 ps-0 d-none d-md-block">
                                <div className="form-right h-100 bg-primary text-white text-center pt-5">
                                   <img src={LogoNegativo} width={300}></img>
                                   <p></p>
                                    <h3 className="fs-1">Primo passo:</h3>
  
                                    <h3 className="mb-3 text-white">inserisci Email e clicca su invia</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-end text-secondary mt-3">Bootstrap 5 Login Page Design</p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Userforgot;