import React, { useState } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Endpoints from "../components/services/Endpoints";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import logoNegativo from "../CDLlogoNegativo.svg";

//import PageTitle from "../Archivio/components/PageTitle";

import "./ContactPage.css";

const RenderHeader = () => {
  return (
    <div>
      <nav className="navbar navbar-primary" style={{ background: "#0059b3" }}>
        <div className="container-fluid text-white">
          <span className="navbar-brand mb-0 h5 text-white">
            CAF Nazionale Consulenti del Lavoro
          </span>
          <form className="d-flex">

            <Link to="/signin">
              <button className="btn text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="white"
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>{" "}
                Accedi all'area personale
              </button>
            </Link>
          </form>
        </div>
      </nav>

      <div>
        <div
          className="wrap-nav"
          style={{ height: "174px", background: "#0066cc" }}
        >
          <div
            className="logo"
            style={{
              backgroundColor: "#0066cc",
              height: "120px",
              marginLeft: "50px",
            }}
          >
            <div style={{ padding: "8px" }}>
              <img src={logoNegativo} alt="Logo" style={{ height: "140px" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderFooter = () => {
  return (
    <Container fluid style={{ background: "#001a33" }}>
      <Row style={{ minHeight: "116px" }}>
        <div className="mb-64 mt-64 text-white">
          <ul
            style={{
              listStyle: "inline",
              listStyleType: "none",
              paddingTop: "20px",
            }}
          >
            <li style={{ display: "inline", marginRight: "20px" }}>
              <a href="/policy">Privacy Policy</a>{" "}
            </li>
            <li style={{ display: "inline", marginRight: "20px" }}>
              <a href="/cookies">Cookie Policy</a>{" "}
            </li>
            <li style={{ display: "inline", marginRight: "20px" }}>
            <a href="/">Home</a>{" "}
          </li>
            {/*    <li style={{display:'inline', marginRight:'20px'}}> Archivio Pratiche</li>  */}
          </ul>
        </div>
      </Row>
    </Container>
  );
};

const ContactPage = () => {
  return (
    <div>
      <div>
        {RenderHeader()}

        <div
          className="wrap-nav"
          style={{ height: "60vh", background: "white" }}
        >
          <div className="contact-wrapper">
          {/*   <PageTitle content="Contatti" />  */}

            <p>
              <b>Gli operatori osservano il seguente orario di lavoro:</b>
              <br /> Lunedì -­ Venerdì dalle ore 9:00 alle ore 13:00 e dalle ore
              15:00 alle ore 18:00.
              <br />
              Per informazioni sulle pratiche di patronato contattare il numero
              06 94446812 int. 2
            </p>
            <p>
              Per informazioni sulle modalità di adesione ai servizi di
              patronato contattare il numero 06 94446812 int. 1<br /> Lunedì -­
              Venerdì dalle ore 9:00 alle ore 13:00 e dalle ore 15:00 alle ore
              16:00.
            </p>
          </div>
        </div>
      </div>
      {RenderFooter()}
    </div>
  );
};

export default ContactPage;
