import React, { useState, useEffect, useMemo } from "react";

import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import TableContainer from "./TableContainer";
import Endpoints from "../components/services/Endpoints";
import { Container, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import axios from "axios";

import client from "../utils/client";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "bootstrap-icons/font//bootstrap-icons.css";

import UploadService from "../components/services/upload-files.service";

const ENDPOINT = Endpoints.getUrls();

const AdminModuloPratica = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);

  const [progress, setProgress] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [documentoFile, setDocumentoFile] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const [tipoEdit, setTipoEdit] = useState("");
  const [messaggioEdit, setMessaggioEdit] = useState("");
  const [documentoFileEdit, setDocumentoFileEdit] = useState([]);

  const [htmlContent, setHtmlContent] = useState("");
  const [convertedContent, setConvertedContent] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const doFetch = async () => {
    setSpinnerLoading(true);
    const response = await client({
      url: `/admin/moduli/`,
      config: { method: "GET" },
    });
    const tipopratica = await response.data;
    setData(tipopratica);
    setSpinnerLoading(false);
  };

  useEffect(() => {
    doFetch();
  }, []);

  //qui mi assegno la pratica
  const handleShow = (event) => {
    console.log(event.original._id);
    console.log(event.values._id);
    setSelectedId(event.values._id);
  };

  const uploadFiles = () => {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    setProgressInfos(_progressInfos);
    uploadDoc(selectedFiles);

    const uploadDoc = (selectedFiles) => {
      for (let i = 0; i < selectedFiles.length; i++) {
        upload(i, selectedFiles[i]);
      }
    };
  };

  const upload = (idx, file) => {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        console.log(response);

        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Uploaded the file successfully: " + response.data.path,
          ];

          return {
            message: nextMessage,
          };
        });
        //   return `http://localhost:5001/static/allegati/${response.data.path}`;
        return UploadService.getFiles(response.data);
      })
      .then((files) => {
        this.setState((prevState) => ({
          fileInfos: [...prevState.fileInfos, files],
        }));
        const event = new Date();
        this.setState(
          (prevState) => ({
            allegati: [
              ...prevState.allegati,
              { creato: event.toISOString(), path: files.path },
            ],
          }),
          () => {
            console.log("New allegati in ASYNC callback:", this.state.allegati);
          }
        );
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Could not upload the file: " + file.name,
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  };

  // OK
  const selectionFiles = (event) => {
    setSelectedFile(event.target.files);
    setProgressInfos([]);
    console.log("New state in ASYNC callback:", selectedFile);
    console.log("New fileInfos in ASYNC callback:", fileInfos);
  };
  // OK
  const onClickHandler = () => {
    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("docCollection", selectedFile[x]);
      //   data.append('name', 'ricevitabis');
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${ENDPOINT}/documenti`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })

      .then((response) => {
        // then print response status
        console.log(response);
        setFileInfos(response.data);

        let documentoArray = [];

        for (let i = 0; i < response.data.length; i++) {
          documentoArray.push(response.data[i].filename);
        }

        setDocumentoFile(documentoArray);
      });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const handleOnTipo = (event) => {
    setTipoEdit(event.target.value);
  };
  const handleOnMessaggio = (event) => {
    setMessaggioEdit(event.target.value);
  };

  const renderRowSubComponent = (row) => {
    let { tipo, messaggio, documento, contenuto, ...rest } = row.original;
    let selectedId = rest._id;

    const RenderClosedCase = () => {
      return (
        <div className="edit">
          <div className="col">
            {fileInfos &&
              fileInfos.map((fileInfo, index) => (
                <div className="mb-2" key={index}>
                  <span>{fileInfo.path}</span>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: progress + "%" }}
                    >
                      {progress}%
                    </div>
                  </div>
                </div>
              ))}

            <small className="label-pratica">
              Sostituisci il documento del modulo{" "}
            </small>
            <div className="input-group mb-3">
              <input
                type="file"
                name="files"
                multiple
                onChange={selectionFiles}
                className="form-control"
                id="inputGroupFile02"
              />
              <button
                className="btn btn-outline-secondary"
                disabled={!selectedFiles}
                onClick={onClickHandler}
                type="button"
                id="inputGroupFileAddon04"
              >
                Carica i files!
              </button>
            </div>
          </div>

          <div className="col">
            <ul>
              {fileInfos.map((el, i) => {
                return (
                  <li key={i}>
                    <a href={`${ENDPOINT}/static/documenti/${el.path} `}>
                      <i
                        className="bi bi-file-pdf"
                        style={{ color: "red" }}
                      ></i>
                      {el.filename}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="col"
            style={{
              "display": "flex",
              "justify-content": "space-between"
            }}
          >
            <button
              className="btn btn-outline-primary"
              disabled={!selectedFiles}
              onClick={handleSubmit}
              type="button"
              id="inputGroupFileAddon088"
            >
              Modifica Modulo!
            </button>

            <Button
              className="btn  btn-danger"
              onClick={deleteModulo}
            >
              <small>
                <i className="bi bi-archive"></i> Elimina!
              </small>
            </Button>
          </div>
        </div>
      );
    };

    const deleteModulo = () => {
      Swal.fire({
        title: "Vuoi davvero eliminare?",
        text: `Vuoi davvero eliminare "${tipo}", l'azione non sarà reversibile.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SI, elimina!",
      }).then((result) => {
        if (result.isConfirmed) {
          client(
            { url: `/admin/moduli/${selectedId}`, config: { method: "DELETE" } },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((data) => {
              if (data.data.success) {
                doFetch();
                Swal.fire("Eliminata!", data.data.success, "Grazie");
              }

            })
            .catch((error) => {
              Swal.fire("Error!", "C'è stato un errore durante l'eminazione del modulo", "Ok");
            });
        }
      });
    }

    const handleSubmit = (event) => {
      event.preventDefault();

      let messaggioNew = "";
      let contenutoNew = "";
      let documentoNew = [];
      let tipoNew = "";

      let test = messaggioEdit;

      if (messaggioEdit) {
        console.log(`MessaggioEdit: ${messaggioEdit}`);
        messaggioNew = messaggioEdit;
      } else {
        messaggioNew = messaggio;
      }

      if (tipoEdit) {
        console.log(`TipoEdit: ${tipoEdit}`);
        tipoNew = tipoEdit;
      } else {
        tipoNew = tipo;
      }

      if (documentoFile.length > 0) {
        console.log(`File: ${documentoFile}`);
        documentoNew = documentoFile;
      } else {
        documentoNew = documento;
      }

      console.log(`Messaggio: ${messaggio}`);
      console.log(`Tipo: ${tipo}`);
      console.log(`Html: ${convertedContent}`);
      console.log(documento[0]);
      console.log(documentoNew);
      console.log(selectedId);


      client({
        url: `/admin/moduli/edit/${selectedId}`,
        config: {
          method: "POST",
          data: {
            tipo: tipoNew,
            messaggio: messaggioNew,
            documento: documentoNew,
          },
        },
      })
        .then(function () {
          doFetch();
        })
        .catch(function (error) {
          console.log(error);
        });

      //fine
    };

    return (
      <>
        <Container className="mt-2 mb-5">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Tipo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="tipo"
                    name="tipo"
                    defaultValue={tipo}
                    onChange={handleOnTipo}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Messaggio / Descrizione del modello -{" "}
                    <small>
                      info: se vuoi lascire il campo vuoto, inserisci uno spazio
                      di tastiera
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="descrizione del modello"
                    name="messaggio"
                    defaultValue={messaggio}
                    onChange={handleOnMessaggio}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mb-2 mt-2"
                  >
                    Documento attuale
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="documento"
                    name="documento"
                    defaultValue={documento}
                    disabled
                  />
                </div>
              </div>
            </div>

            {RenderClosedCase()}
          </form>
        </Container>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <span className="btn1" onClick={() => handleShow(row)}>
                {" "}
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "_id",
        accessor: "_id",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
      },
      {
        Header: "Messaggio",
        accessor: "messaggio",
      },
      {
        Header: "Documento",
        accessor: (a) => `${a.documento[0]}`,
      },
    ],
    []
  );

  return (
    <div className="row">
      <div
        className="test"
        style={{
          paddingBottom: "100px",
          backgroundColor: "rgb(233, 236, 239)",
        }}
      >
        <div
          style={{ textAlign: "center", paddingTop: "1rem", fontWeight: "600" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16z"
            fill="currentColor"
            className="bi bi-house-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
            />
            <path
              fillRule="evenodd"
              d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
            />
          </svg>{" "}
          / Admin / Moduli / Vista Moduli
        </div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          <div class="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
        <div className="row">
          <div
            style={{
              textAlign: "center",
              paddingTop: "1rem",
              paddingBottom: "3rem",
              fontWeight: "600",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AdminModuloPratica;
