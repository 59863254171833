import React, { useState, useEffect, useMemo } from "react";
import socketIOClient from "socket.io-client";
import TableContainer from "./TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Table } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../components/Footer/Footer";
import Endpoints from "../components/services/Endpoints";
import "./loaderino.css";
import moment from "moment";

import client from "../utils/client";

const ENDPOINT = Endpoints.getUrls();

const MySwal = withReactContent(Swal);

const PraticheSospese = () => {
  const [data, setData] = useState([]);

  //   const [showA, setShowA] = useState(true);
  //   const toggleShowA = () => setShowA(!showA);
  //   const [roasterText, setRoasterText ] = useState("");
  const [statoPratica, setStatoPratica] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  //    const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);

  // PER CAMBIOSTATO DELLA PRATICA
  const [ricevuta, setRicevuta] = useState([]);
  //    const [aggiornato, setAggiornato] = useState([]);
  const [note, setNote] = useState("");
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    /*
      socket.on('visitor enters', data => {setOnline(data)
      console.log('online', data);
      console.log(typeof(online), online)
     // UserConnected();
    });
*/

    /*
      socket.on('visitor exits', data => { setOnline(data)
      console.log('online', data);
     // UserConnected();
    });
*/
    socket.on("assegnazione", function (payload) {
      console.log("praticaPresa da: ", payload);
      renewTable(JSON.parse(payload));
    });

    socket.on("creazione", function (loaded) {
      console.log("nuova pratica inserita da: ", JSON.stringify(loaded));
      //  setRoasterText(JSON.stringify(loaded));
    });

    /*
      socket.on("FromAPI", data => {
        console.log(data);
        if(data.evento != 'none'){
          setRoasterText(data.evento);
        }
      //  setResponse(data);
      });
*/

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  /*
    useEffect(() => {
      if (roasterText) {
        let text = JSON.parse(roasterText);
    //    updateItem(text.id);
      //  toggleShowA();

      }
    }, [roasterText]);

    */

  useEffect(() => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/pratiche/sospesegenerali/`,
        config: { method: "GET" },
      });
      const pratiche = response.data;
      setData(pratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  /*
    const renewTable = (val) => {
      setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
    }
*/
  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));

    client({ url: `/pratiche/sospesegenerali/`, config: { method: "GET" } })
      .then((res) => res.data)
      .then((res) => {
        const updatedTable = [...data, res];

        setData(res);
      });
  };

  const updateItem = (id) => {
    fetch(`${ENDPOINT}/pratiche/id?_id=${id}`)
      .then((res) => res.json())
      // .then((data) => setData({...data}))
      .then((res) => {
        const updatedCarsArray = [...data, res];

        setData(updatedCarsArray);
      });
  };

  const handleShow = (event) => {
    console.log(event.cell.row.values._id);
    var idPratica = event.cell.row.values._id;
    const descrConfirm = event.cell.row.values.descrizione;
    const aConfirmNome = event.cell.row.original.assistito.nome;
    const aConfirmCognome = event.cell.row.original.assistito.cognome;

    const cConfirmNome = event.cell.row.original.consulente.nome;
    const cConfirmCognome = event.cell.row.original.consulente.cognome;

    const oConfirmNome = event.cell.row.original.operatore[0].nome;
    const oConfirmCognome = event.cell.row.original.operatore[0].cognome;

    Swal.fire({
      title: "La vuoi prendere tu?",
      text: `${descrConfirm}`,
      icon: "warning",
      html: `<b>Descrizione: </b>${descrConfirm}<br><b>Consulente: </b>${cConfirmCognome} ${cConfirmNome} <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, prendo io!",
      footer: `<b>Operatore: <b/>${oConfirmCognome} ${oConfirmNome}`,
    }).then((result) => {
      if (result.isConfirmed) {
        //inizio conferma seleziona
        const operatoreClient = JSON.parse(localStorage.getItem("currentUser"));

        //const {_id, cognome, nome, email, role} = operatoreClient;
        const { _id } = operatoreClient;
        fetch(`${ENDPOINT}/users/id?_id=${_id}`)
          .then((res) => res.json())
          // .then((data) => setData({...data}))
          .then((res) => {
            //  setOperatoreAttivo(res);

            const operatoreAct = res;

            // qui modifico la pratica
            fetch(
              `${ENDPOINT}/pratiche/assegna/operatore:id?_id=${idPratica}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(operatoreAct),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                const operatoreAttivo = JSON.parse(
                  localStorage.getItem("currentUser")
                );
                const { _id, cognome, nome, email, role } = operatoreAttivo;

                let assegnaObj = {
                  _id: _id,
                  cognome: cognome,
                  nome: nome,
                  email: email,
                  role: role,
                  praticaId: idPratica,
                };

                const socket = socketIOClient(process.env.REACT_APP_BASE_URL);

                socket.emit("assegnazione", JSON.stringify(assegnaObj));

                // enter you logic when the fetch is successful
                console.log("data? ", data);
              })
              .catch((error) => {
                // enter your logic for when there is an error (ex. error toast)
                console.log(error);
              });
          });

        Swal.fire(
          "Presa!",
          "La pratica è tua! La troverai in Lavorazione",
          "Grazie"
        );

        //fine conferma seleziona
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let aggiornaObj = {
      _id: selectedId,
      //    statoPratica: statoPratica,
      statoPratica: "Lavorazione",
      ricevuta: ricevuta,
      note: note,
      storico_pratica: {
        stato: statoPratica,
        data: new Date().toLocaleString("it-IT"),
      },
    };
    console.log(aggiornaObj);
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${ENDPOINT}/pratiche/cambiostato/`, aggiornaObj, {
        headers: headers,
      })
      .then((response) => {
        // then print response status
        console.log(response);

        let updTbl = {
          praticaId: selectedId,
        };

        renewTable(updTbl);
      });

    //fine
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      tipo_pratica,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;
    let consulente = rest.consulente;
    //  let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    let statiPossibili = ["Lavorazione", "Sospesa", "Annullata"];

    console.log(rest.consulente);

    const RenderAllegati = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <Table bordered className="tabella">
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };
    // let RenderNote;

    return (
      <>
        <div
          className="container pratica"
          style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Consulente</div>
            <div className="col-4">
              <small style={{ color: "#434444" }}>Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope" style={{ color: "#434444" }}></i>
              <small style={{ color: "#434444" }}> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone" style={{ color: "#434444" }}></i>
              <small style={{ color: "#434444" }}> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone" style={{ color: "#434444" }}></i>
              <small style={{ color: "#434444" }}> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="tipo-pratica">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Tipo Pratica</div>
            <div className="col">
              <small style={{ color: "#434444" }}>descrizione: </small>
              {descrizione}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>tipo pratica: </small>
              {tipo_pratica}
            </div>
          </div>
        </div>

        <div className="container pratica" id="assistito">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Assistito</div>
            <div className="col">
              <small style={{ color: "#434444" }}>Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#434444" }}>Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#434444" }}>Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#434444" }}>Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="collaboratore">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Collaboratore volontario</div>
            <div className="col">
              <small style={{ color: "#434444" }}>Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "#434444" }}>C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="stato-pratica">
          <div className="row">
            <div>
              <span style={{ fontWeight: "bold" }}> Stato Pratica</span>
            </div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>

            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        <div className="container pratica" id="allegati">
          <div className="row">
            <div>
              {" "}
              Documenti Allegati <i className="bi bi-paperclip"></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Pratica <i className="bi bi-file-earmark-text"></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Ricevute{" "}
              <i
                className="bi bi-file-earmark-arrow-down"
                style={{ color: "#434444" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div className="container pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div className="container pratica">
          <div className="row">
            <div>
              {" "}
              <h2 style={{ color: "darkorange" }}>
                {" "}
                Stato Pratica: {`${statoPratica} `}{" "}
                <i className="bi bi-exclamation-triangle"></i>
              </h2>{" "}
            </div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <i className="bi bi-caret-right-fill"></i>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "CF. Assistito",
        accessor: (d) =>
          d.assistito.codice_fiscale_assistito
            ? d.assistito.codice_fiscale_assistito
            : undefined,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        accessor: (c) =>
          `${c.operatore[c.operatore.length - 1].nome} ${
            c.operatore[c.operatore.length - 1].cognome
          }`,
      },
      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "darkorange", fontWeight: "bolder" }}>
            {props.value} <i className="bi bi-pause-circle"></i>
          </span>
        ),
      },
      {
        Header: "Sospesa il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(
            d.storico_pratica[d.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,

        //accessor: "data_scadenza",
      },
      /*
          {
            Header: "Action",
            id: "id",
            Cell: props => <Button className="btn1" onClick={() => handleShow(props)}> <i className="bi bi-archive"></i> Prendi tu!</Button>
          },
          */
    ],
    []
  );

  return (
    <>
      <div
        className="test"
        style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? <div className="classic-3"></div> : null}
        </div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          {/*
        <RenderRoaster />
        <Button variant="danger">
        utenti online <Badge bg="secondary">{ online }</Badge>
          <span className="visually-hidden">unread messages</span>
        </Button>


           */}
          <div className="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PraticheSospese;
