import React, { useState, useEffect, useMemo } from "react";
import TableContainer from "./TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Table,
  Button,
  Accordion,
  Form,
  Col,
  Row,
} from "react-bootstrap";

import socketIOClient from "socket.io-client";
import client from "../utils/client";
import "./loaderino.css";
import Endpoints from "../components/services/Endpoints";
import Swal from "sweetalert2";
import moment from "moment";

import "./AdminPraticheChiuse.css";

import * as ExcelJS from "exceljs";
import Multiselect from 'multiselect-react-dropdown';

const ENDPOINT = Endpoints.getUrls();

function generateXLS(data) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Riepilogo");
  worksheet.columns = [
    { header: "ID", key: "_id", width: 30 },
    { header: "Descrizione", key: "descrizione", width: 20 },
    { header: "Tipo pratica", key: "tipo_pratica", width: 10 },
    { header: "Consulente", key: "consulente", width: 35 },
    { header: "Operatore", key: "operatore", width: 35 },
    { header: "Assistito", key: "assistito", width: 20 },
    { header: "Assistito CF", key: "assistitoCf", width: 20 },
    { header: "Assistito Recapiti", key: "assistitoRecapiti", width: 20 },
    { header: "Assistito Indirizzo", key: "assistitoIndirizzo", width: 30 },
    { header: "Assistito Categoria", key: "assistitoCategoria", width: 25 },
    {
      header: "Assistito Stato civile",
      key: "assistitoStatoCivile",
      width: 20,
    },
    { header: "Collaboratore", key: "colloboratore", width: 30 },
    { header: "Data scadenza", key: "data_scadenza", width: 20 },
    { header: "Storico pratica", key: "storico_pratica", width: 40 },
    { header: "Storico note", key: "storico_note", width: 40 },
    { header: "Storico operatore", key: "storico_operatore", width: 40 },
  ];

  worksheet.addRows([{ _id: `Totale: ${data.length} pratiche` }, {}, ...data]);

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

const AdminPraticheChiuse = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const [findDescrizione, setFindDescrizione] = useState("");
  const [findId, setFindId] = useState("");
  const [findConsulenteCognome, setFindConsulenteCognome] = useState("");
  const [findAssistitoCognome, setFindAssistitoCognome] = useState("");
  const [findAssistitoCF, setFindAssistitoCF] = useState("");
  const [findOperatoreCognome, setFindOperatoreCognome] = useState("");
  const [findFrom, setFindFrom] = useState("");
  const [findTo, setFindTo] = useState("");
  const [findRegione, setFindRegione] = useState("");
  const [findTipoPratica, setFindTipoPratica] = useState("");
  const [findRole, setFindRole] = useState("");

  const [count, setCount] = useState(0);

  const [tipopraticaList, setTipoPraticaList] = useState([]);

  const [descrizioni, setDescrizioni] = useState([])
  const [options, setOptions] = useState([])
  const multiselectRef = React.createRef();


  useEffect(() => {
    findValueOnServer();
    fetchTipoPraticaList();
  }, []);

  const fetchTipoPraticaList = async () => {
    const response = await client({
      url: `/tipopratica/tipo_pratica`,
      config: { method: "GET" },
    });
    const data = await response.data;
    setTipoPraticaList(data);
  };

  const fetchPraticheAll = () => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/admin/chiuse`,
        config: { method: "GET" },
      });
      const pratiche = await response.data;
      setData(pratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  };

  const fetchPraticheCount = async () => {
    const op = JSON.parse(localStorage.getItem("currentUser"));
    const id = op._id;
    const result = await client({
      url: `/pratiche/count/inviate/id?_id=${id}`,
      config: { method: "GET" },
    });
    setCount(result.data.chiuse);
  };

  // aggiorno la ta tabella dopo l'assegnazione
  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
  };

  const handleShow = (event) => {
    console.log(event.cell.row.values._id);
    var idPratica = event.row.original._id;
    const descrConfirm = event.cell.row.values.descrizione;
    const aConfirmNome = event.cell.row.original.assistito.nome;
    const aConfirmCognome = event.cell.row.original.assistito.cognome;

    const cConfirmNome = event.cell.row.original.consulente.nome;
    const cConfirmCognome = event.cell.row.original.consulente.cognome;

    const oConfirmNome = event.cell.row.original.operatore[0].nome;
    const oConfirmCognome = event.cell.row.original.operatore[0].cognome;

    const adminClient = JSON.parse(localStorage.getItem("currentUser"));

    if (adminClient.role[0] === "ROLE_ADMIN") {
      Swal.fire({
        title: "Vuoi davvero eliminare?",
        text: `${descrConfirm}`,
        icon: "warning",
        html: `<b>Descrizione: </b>${descrConfirm}<br><b>Consulente: </b>${cConfirmCognome} ${cConfirmNome} <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SI, elimina!",
        footer: `<b>Operatore: <b/>${oConfirmCognome} ${oConfirmNome}`,
      }).then((result) => {
        if (result.isConfirmed) {
          //inizio conferma seleziona
          // qui elimino la pratica
          client(
            { url: `/pratiche/${idPratica}`, config: { method: "DELETE" } },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                let updTbl = {
                  praticaId: idPratica,
                };

                renewTable(updTbl);

                Swal.fire("Eliminata!", data.success, "Grazie");
              }
              if (data.error) {
                Swal.fire("Error!", data.error, "Grazie");
              }
            })
            .catch((error) => {
              // enter your logic for when there is an error (ex. error toast)
              console.log(error);
            });

          //fine conferma seleziona
        }
      });
    } else {
      Swal.fire("Non hai i privilegi per eseguire questa azione", "Grazie");
    }
  };

  const handleAssegna = (props) => {
    console.log(props);

    let arrayOpe = [];
    const operatoriFetch = async () => {
      const response2 = await client({
        url: `/users/seleziona/operatori/`,
        config: { method: "GET" },
      });
      const operator = response2.data;
      arrayOpe = operator;

      var options = {};

      let test = arrayOpe.map(
        (x) => (options[x._id] = x.cognome + " " + x.nome)
      );
      console.log(test);

      let pretext = props.cell.row.original;

      Swal.fire({
        title: `Riassegnazione`,
        icon: "info",
        html: `<b>_id pratica: ${pretext._id}</b><br>
       Descrizione: <b>${pretext.descrizione}</b><br>
       Consulente: <b> ${pretext.consulente.cognome} ${
          pretext.consulente.nome
        }</b><br>
       Assistito: <b> ${pretext.assistito.cognome} ${
          pretext.assistito.nome
        }</b><br>  
       Ultimo Operatore: <b> ${
         pretext.storico_operatore[pretext.storico_operatore.length - 1].cognome
       } ${
          pretext.storico_operatore[pretext.storico_operatore.length - 1].nome
        }</b><br>
       <b>stato pratica: ${pretext.statoPratica}</b><br>`,
        input: "select",
        inputOptions: options,
        inputPlaceholder: "Seleziona Operatore di destinazione",
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              const found = arrayOpe.find((element) => element._id == value);
              console.log(found);

              Swal.fire({
                icon: "warning",
                title: "Sei sicuro?",
                html: `<b>Operatore</b><br>
                _id: <b>${found._id}</b><br>
               Nome: <b> ${found.nome}</b><br>
               Cognome: <b>${found.cognome}</b><br>
               Nome: <b> ${found.nome}</b><br>`,
                showCancelButton: true,
                confirmButtonText: "Si è tutto ok!",
                cancelButtonText: `Annulla`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  handleAssegnaPratica(found, pretext);
                  //     Swal.fire('Pratica assegnata!')
                } else {
                  Swal.fire("Operazione annullata");
                }
              });
              resolve();
            } else {
              resolve("Devi selezionare un operatore :)");
            }
          });
        },
      });
    };
    operatoriFetch();
  };

  const handleAssegnaPratica = (operatoreSelezionato, pretext) => {
    console.log(pretext._id);

    var idPratica = pretext._id;
    let decrizionePratica = pretext.descrizione;
    let consulente = pretext.consulente;
    let consulenteMail = pretext.consulente.email;
    let assistito = pretext.assistito;
    const operatoreClient = operatoreSelezionato;
    // const {_id, cognome, nome, email, role} = operatoreClient;
    const { _id } = operatoreClient;

    // qui modifico la pratica
    client({
      url: `/admin/riassegna/operatore:id?id=${idPratica}`,
      config: { method: "POST", data: operatoreClient },
    })
      .then((res) => res.data)
      .then((data) => {
        const { _id, cognome, nome, email, role } = operatoreClient;

        if (data.success) {
          let notifyObj = {
            emailto: consulenteMail,
            idpratica: idPratica,
            descrizione: decrizionePratica,
            stato: "Assegnata",
            assistito: assistito,
            operatore: `${operatoreClient.cognome} ${operatoreClient.nome}`,
            contatti: `Cell: ${operatoreClient.cellulare} - Tel: ${operatoreClient.telefono} Email: ${operatoreClient.email}`,
          };

          //     notificaAlConsulente(notifyObj);
        }

        let assegnaObj = {
          _id: _id,
          cognome: cognome,
          nome: nome,
          email: email,
          role: role,
          date: new Date().toLocaleString("it-IT"),
          consulente: consulente,
          assistito: assistito,
          praticaId: idPratica,
          descrizione: decrizionePratica,
        };

        const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
        socket.emit("adminAction", JSON.stringify(assegnaObj));

        const doFetch2 = async () => {
          setSpinnerLoading(true);

          const response = await client({
            url: `/admin/chiuse`,
            config: { method: "GET" },
          });
          const pratiche = response.data;
          setData(pratiche);
          setSpinnerLoading(false);
        };
        doFetch2();
      })
      .catch((error) => {
        // enter your logic for when there is an error (ex. error toast)
        console.log(error);
      });
  };

  const BasicExample = () => {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <i class="bi bi-binoculars"></i> Cerca sul server{" "}
          </Accordion.Header>
          <Accordion.Body>
            <p style={{ fontWeigth: "medium", color: "darkred" }}>
              Il campo ID va utilizzato solo e non in combo con altri campi
            </p>
            {BasicForm()}
            {data.length && (
              <p style={{ fontWeigth: "medium", marginTop: "20px" }}>
                Cerca tra {count} risultati
              </p>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const getAllDescriptions = async (tipoPratica) => {
    try {
      const response = await client({
        url: `/admin/${tipoPratica}/all_descriptions`,
        config: { method: "GET", },
      });
      return response.data
    } catch (error) {
      console.log(error);
    }
  };

  const FindAllDescriptions = async (event) => {
    event.preventDefault();
    multiselectRef.current.resetSelectedValues();
    setDescrizioni([])
    const tipoPratica = event.target.value
    const response = await getAllDescriptions(tipoPratica)
    setOptions(response.map((option, index) => { return { description: option._id, id: index } }))
  }

  const FindOnServer = (event) => {
    event.preventDefault();

    if (event.target.name === "find_id") {
      setFindId(event.currentTarget.value);
    }

    if (event.target.name === "find_descrizione") {
      setFindDescrizione(event.currentTarget.value);
    }

    if (event.target.name === "find_regione") {
      setFindRegione(event.currentTarget.value);
    }

    if (event.target.name === "find_consulente_cognome") {
      setFindConsulenteCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cognome") {
      setFindAssistitoCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cf") {
      setFindAssistitoCF(event.currentTarget.value);
    }
    if (event.target.name === "find_operatore_cognome") {
      setFindOperatoreCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_from") {
      setFindFrom(event.currentTarget.value);
    }
    if (event.target.name === "find_to") {
      setFindTo(event.currentTarget.value);
    }

    if (event.target.name === "find_tipo_pratica") {
      setFindTipoPratica(event.currentTarget.value);
    }

    if (event.target.name === "find_role") {
      setFindRole(event.currentTarget.value);
    }
  };

  const allFieldAreEmpty = () => {
    return (
      !findId &&
      !findDescrizione &&
      !findRegione &&
      !findConsulenteCognome &&
      !findAssistitoCognome &&
      !findAssistitoCF &&
      !findOperatoreCognome &&
      !findFrom &&
      !findTo &&
      !findTipoPratica &&
      !findRole
    );
  };

  const ClearAll = () => {
    setFindId("");
    setFindRegione("");
    setFindDescrizione("");
    setFindConsulenteCognome("");
    setFindAssistitoCognome("");
    setFindAssistitoCF("");
    setFindOperatoreCognome("");
    setFindFrom("");
    setFindTo("");
    setFindTipoPratica("");
    setFindRole("");
    fetchPraticheAll();
    fetchPraticheCount();
  };

  const findValueOnServer = () => {
    setSpinnerLoading(true);

    if (allFieldAreEmpty()) {
      fetchPraticheAll();
      fetchPraticheCount();
      return;
    }

    const asyncPostCall = async () => {
      try {
        const response = await client({
          url: `/pratiche/find/chiuse`,
          config: {
            method: "POST",
            data: {
              numberId: findId ? Number(findId) : "",
              descrizione: findDescrizione,
              descrizioni: descrizioni.map(descrizione => descrizione.description),
              consulenteCognome: findConsulenteCognome,
              assistitoCognome: findAssistitoCognome,
              operatoreCognome: findOperatoreCognome,
              assistitoCF: findAssistitoCF,
              regione: findRegione,
              findFrom: findFrom,
              findTo: findTo
                ? new Date(findTo).setUTCHours(23, 59, 59, 999)
                : "",
              tipoPratica: findTipoPratica,
              role: findRole,
            },
          },
        });
        const data = response.data;
        setData(data);
        setCount(data.length);
        setSpinnerLoading(false);
      } catch (error) {
        // enter your logic for when there is an error (ex. error toast)
        setSpinnerLoading(false);
        console.log(error);
      }
    };

    asyncPostCall();
  };

  const BasicForm = () => {
    return (
      <>
        <Form className="search-form">
          <Row>
            <Col>
              <Form.Control
                placeholder="_id"
                name="find_id"
                value={findId}
                type="number"
                onChange={FindOnServer}
                style={{ border: "solid 1px red" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Descrizione"
                name="find_descrizione"
                value={findDescrizione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Select
                aria-label="Seleziona tipo pratica"
                name="find_tipo_pratica"
                style={{ border: "solid 1px darkturquoise" }}
                onChange={(e) => { FindOnServer(e); FindAllDescriptions(e) }}
                value={findTipoPratica}
              >
                <option value="">Tipo pratica</option>
                {tipopraticaList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              {/* <Form.Select
                aria-label="Seleziona descrizioni pratica"
                name="find_descrizioni_pratica"
                style={{ border: "solid 1px darkturquoise" }}
                onChange={FindOnServer}
                value={findTipoPratica}
              >
                <option value="">Tipo pratica</option>
                {tipopraticaList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </Form.Select> */}
              <Multiselect
                ref={multiselectRef}
                options={options} // Options to display in the dropdown
                onSelect={(_, item) => setDescrizioni(prev => [...prev, item])} // Function will trigger on select event
                onRemove={(_, item) => setDescrizioni(prev => prev.filter(toRemove => item.id !== toRemove.id))}
                showCheckbox
                placeholder={descrizioni.length ? `Selezionate ${descrizioni.length} descrizioni` : "Seleziona descrizioni"}
                avoidHighlightFirstOption
                showArrow
                displayValue="description"
                hideSelectedList
                className={descrizioni.length ? "filled" : ""}
                emptyRecordMsg="Seleziona un tipo pratica"
              />

            </Col>
            <Col>
              <Form.Select
                aria-label="Seleziona ruolo"
                name="find_role"
                style={{ border: "solid 1px darkturquoise" }}
                onChange={FindOnServer}
                value={findRole}
              >
                <option value="">Ruolo</option>
                <option value="ROLE_CONSULENTE">Consulente</option>
                <option value="ROLE_TELECONSUL">Teleconsul</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Control
                placeholder="Regione"
                name="find_regione"
                value={findRegione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Consulente cognome"
                name="find_consulente_cognome"
                value={findConsulenteCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>

          </Row>
          <Row>
            <Col>
              <Form.Control
                placeholder="Assistito cognome"
                name="find_assistito_cognome"
                value={findAssistitoCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Assistito C.F."
                name="find_assistito_cf"
                value={findAssistitoCF}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Operatore cognome"
                name="find_operatore_cognome"
                value={findOperatoreCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Da"
                name="find_from"
                type="date"
                value={findFrom}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Da"
                name="find_to"
                type="date"
                value={findTo}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Button variant="primary" onClick={findValueOnServer}>
                Cerca
              </Button>
            </Col>
            <Col>
              <Button variant="primary" onClick={ClearAll}>
                Pulisci tutto
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                disabled={spinnerLoading}
                onClick={() =>
                  generateXLS(
                    data.map((p) => ({
                      _id: p._id,
                      descrizione: p.descrizione,
                      tipo_pratica: p.tipo_pratica,
                      consulente: `${p.consulente.nome} ${
                        p.consulente.cognome
                      } (${p.consulente.email}) - ${
                        p.consulente.role.includes("ROLE_TELECONSUL") &&
                        "TELECONSUL"
                      }`,
                      operatore:
                        p.operatore.length &&
                        `${p.operatore[0].nome} ${p.operatore[0].cognome} (${p.operatore[0].email})`,
                      assistito: `${p.assistito.nome} ${p.assistito.cognome} (${p.assistito.email})`,
                      assistitoCf: p.assistito.codice_fiscale_assistito,
                      assistitoRecapiti: `${p.assistito.telefono} (tel.) - ${p.assistito.cellulare} (cel.)`,
                      assistitoIndirizzo: `${p.assistito.indirizzo} ${
                        p.assistito.indirizzoAltro || ""
                      }, ${p.assistito.cap} - ${p.assistito.citta} (${
                        p.assistito.provincia
                      })`,
                      assistitoCategoria: p.assistito.categoria_assistito,
                      assistitoStatoCivile: `${p.assistito.stato_civile} ${p.assistito.data_stato_civile}`,
                      colloboratore: p.colloboratore
                        ? `${p.colloboratore.nome} ${p.colloboratore.cognome} (${p.colloboratore.codice_fiscale})`
                        : "",
                      data_scadenza: p.data_scadenza,
                      storico_pratica: p.storico_pratica
                        .map((s) => `${s.stato} il ${s.data}`)
                        .join(", "),
                      storico_note: p.storico_note
                        .map((s) => `${s.nota} il ${s.data}`)
                        .join(", "),
                      storico_operatore: p.storico_operatore
                        .map((s) => `${s.nome} ${s.cognome} il ${s.data}`)
                        .join(", "),
                    }))
                  )
                }
              >
                Esporta {count} risultati in Excel
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;
    let ricevuteArr = ricevute;

    const RenderAllegati = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <Table bordered striped style={{ borderColor: "#dee2e6" }}>
          <thead style={{ color: "#6c757d" }}>
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(108, 117, 125)" }}>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    let statiPossibili = [
      "Lavorazione",
      "Chiusa",
      "Lavorazione_generale",
      "Sospesa",
      "Annullata",
    ];

    return (
      <>
        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small style={{ color: "#6c757d" }}>Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope" style={{ color: "#6c757d" }}></i>
              <small style={{ color: "#6c757d" }}> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone" style={{ color: "#6c757d" }}></i>
              <small style={{ color: "#6c757d" }}> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone" style={{ color: "#6c757d" }}></i>
              <small style={{ color: "#6c757d" }}> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div> Assistito</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#6c757d" }}>Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#6c757d" }}>Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "#6c757d" }}>Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        {/* INIZIO CAMBIO STATO */}

        {/*
              <div className="container" style={{paddingBottom: '1rem', paddingTop:'1rem'}}>
                <div className="row">
                <div> Stato Pratica</div>
                  <div className="col">
                  <small style={{color: '#6c757d'}}>descrizione: </small>
                  {`${statoPratica} `}
                  </div>
                  <div className="col">
                  <small style={{color: '#6c757d'}}>cambia lo stato pratica qui: </small>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <select defaultValue={statoPratica} className="form-select" aria-label="Default select example" onChange={handleSelect} >
                  {statiPossibili.map((item, i) =>{
                    return <option key={i} value={item}>{item}</option>
                  })}
                  </select>


                  <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                aria-valuenow={ progress }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div></div>
                  { isVisible ?
                      <div className='edit' >
                      <div className='col'>

                      {fileInfos &&
                        fileInfos.map((fileInfo, index) => (
                          <div className="mb-2" key={index}>
                            <span>{fileInfo.path}</span>
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-info"
                                role="progressbar"
                                aria-valuenow={ progress }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div>
                            </div>
                          </div>
                        ))}

                      <small style={{color: '#6c757d'}}>carica la ricevuta </small>
                      <div className="input-group mb-3">
                      <input type="file" name="files" multiple onChange={selectionFiles} className="form-control" id="inputGroupFile02" />
                      <button className="btn btn-outline-secondary"
                      disabled={!selectedFiles}
                      onClick={onClickHandler}
                      type="button" id="inputGroupFileAddon04">Carica i files!</button>
                    </div>
                      </div>

                <div className='col'>
                   <ul>

                  {fileInfos.map((el, i)=>{
                    return(
                      <li key={i}>
                      <a  href={`http://localhost:5001/static/allegati/${el.path} `}><i className="bi bi-file-pdf" style={{color: 'red'}}></i>{el.filename}</a>
                      </li>
                      )

                  } )}
                   </ul>
                 </div>

                      <div className='col'>
                      <small style={{color: '#6c757d'}}>aggiungi note </small>
                      <div className="input-group">
                      <span className="input-group-text">With textarea</span>
                      <textarea
                      className="form-control"
                      aria-label="With textarea"
                      name='note'
                      onChange={e => setNote(e.target.value)}

                      defaultValue={note}></textarea>
                    </div>
                      </div>
                      <div className='col'>
                      <button className="btn btn-outline-secondary"
                      disabled={!selectedFiles}
                      onClick={handleSubmit}
                      type="button" id="inputGroupFileAddon088">Cambia lo stato pratica!</button>


                      </div>


                      </div>
                      : null }
                  </form>

                  </div>
                </div>
              </div>
*/}

        {/* FINE CAMBIO STATO */}

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div> Note</div>
            <div className="col">
              <small style={{ color: "#6c757d" }}>Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div>
              {" "}
              DOCUMENTI ALLEGATI{" "}
              <i
                className="bi bi-arrow-up-square"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico RICEVUTA{" "}
              <i
                className="bi bi-file-earmark-arrow-down"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Operatore{" "}
              <i
                className="bi bi-person-video3"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico pratica{" "}
              <i
                className="bi bi-folder2-open"
                style={{ color: "#6c757d" }}
              ></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Note{" "}
              <i className="bi bi-card-text" style={{ color: "#6c757d" }}></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <i className="bi bi-caret-right-fill"></i>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        accessor: (c) =>
          `${c.operatore[c.operatore.length - 1].nome} ${
            c.operatore[c.operatore.length - 1].cognome
          }`,
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "#046f3e", fontWeight: "700" }}>
            {props.value} <i className="bi bi-check2-circle"></i>
          </span>
        ),
      },
      {
        Header: "Chiusa il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(
            d.storico_pratica[d.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
      },
      {
        Header: "Assegna",
        ids: "id",
        Cell: (props) => (
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => handleAssegna(props)}
          >
            {" "}
            <i class="bi bi-person-plus-fill"></i> Assegna!
          </Button>
        ),
      },
      {
        Header: "Elimina",
        id: "id",
        Cell: (props) => (
          <Button
            className="btn btn-sm btn-danger"
            onClick={() => handleShow(props)}
          >
            {" "}
            <small>
              <i className="bi bi-archive"></i> Elimina!
            </small>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <div className="row">
      <div
        style={{
          textAlign: "center",
          textAlign: "center",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          fontWeight: "600",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-house-fill"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
          />
          <path
            fillRule="evenodd"
            d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
          />
        </svg>{" "}
        / Admin / Pratiche / Chiuse
      </div>

      <div
        className="test"
        style={{
          paddingBottom: "100px",
          backgroundColor: "rgb(233, 236, 239)",
        }}
      >
        {spinnerLoading ? <div className="classic-3"></div> : null}
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          {BasicExample()}
          <div class="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              type="chiuse"
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AdminPraticheChiuse;
