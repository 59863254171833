import React, { useState, useEffect, useMemo } from "react";
import TableConsContainer from "./TableConsContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Table, Badge, Button } from "react-bootstrap";
import FileUpload from "./File_Upload_Component/file-upload.component";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Endpoints from "../components/services/Endpoints";
import client from "../utils/client";

import moment from "moment";

const ENDPOINT = Endpoints.getUrls();
const ENDPOINTALLEGATI = Endpoints.getAllegatiUrls();

const PraticheInBozza = () => {
  const [data, setData] = useState([]);

  const [statoPratica, setStatoPratica] = useState("");
  const [newStatoPratica, setNewStatoPratica] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [progress, setProgress] = useState(0);
  const [allegati, setAllegati] = useState([]);
  const [note, setNote] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  });

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });

  useEffect(() => {
    doFetch();
  }, []);

  const doFetch = async () => {
    const consulente = JSON.parse(localStorage.getItem("currentUser"));
    const id = consulente._id;
    const response = await fetch(
      `${ENDPOINT}/pratiche/consulente/bozza/id?_id=${id}`
    );
    const pratiche = await response.json();
    console.log(pratiche);
    setData(pratiche);
  };

  // aggiorno la ta tabella dopo l'assegnazione
  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
  };

  const updateItem = (id) => {
    fetch(`${ENDPOINT}/pratiche/id?_id=${id}`)
      .then((res) => res.json())
      // .then((data) => setData({...data}))
      .then((res) => {
        const updatedCarsArray = [...data, res];
        setData(updatedCarsArray);
      });
  };

  const handleShow = (event) => {
    setSelectedId(event.original._id);
  };

  const handleDelete = (event) => {
    var idPratica = event.cell.row.original._id;
    const descrConfirm = event.cell.row.values.descrizione;
    const aConfirmNome = event.cell.row.original.assistito.nome;
    const aConfirmCognome = event.cell.row.original.assistito.cognome;

    const adminClient = JSON.parse(localStorage.getItem("currentUser"));

    if (adminClient.role[0] === "ROLE_CONSULENTE") {
      Swal.fire({
        title: "Vuoi davvero eliminare?",
        text: `${descrConfirm}`,
        icon: "danger",
        html: `<b>Descrizione: </b>${descrConfirm}<br> <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SI, elimina!",
        footer: `<b>Consulente<b/>`,
      }).then((result) => {
        if (result.isConfirmed) {
          //inizio conferma seleziona
          // qui elimino la pratica
          client(
            { url: `/pratiche/${idPratica}`, config: { method: "DELETE" } },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                let updTbl = {
                  praticaId: idPratica,
                };

                renewTable(updTbl);

                Swal.fire("Eliminata!", data.success, "Grazie");
              }
              if (data.error) {
                Swal.fire("Error!", data.error, "Grazie");
              }
            });

          //fine conferma seleziona
        }
      });
    } else {
      Swal.fire("Non hai i privilegi per eseguire questa azione", "Grazie");
    }
  };

  const handleFiles = () => {
    setIsVisible(true);
    let selectedFile = newUserInfo.profileImages;

    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("allCollection", selectedFile[x]);
    }
    const headers = { "Content-Type": "multipart/form-data" };

    client({
      url: `${ENDPOINT}/allegati`,
      config: {
        method: "POST",
        data,
        headers,
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      },
    }).then((response) => {
      // then print response status
      console.log(response);
      setFileInfos(response.data);

      let allegatiArray = [];

      for (let i = 0; i < response.data.length; i++) {
        allegatiArray.push({
          path: response.data[i].filename,
          creato: new Date().toISOString(),
        });
      }

      setAllegati(allegatiArray);
    });
    //********************* */
  };

  const handleSelectMenu = (val) => {
    alert(val);
    if (val != "0") {
      setNewStatoPratica(val);
    } else {
      alert("Prego selezionare uno stato pratica");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    /* const operatoreClient =JSON.parse(localStorage.getItem('currentUser'));
    const {_id, cognome, nome, email, role} = operatoreClient;*/

    let aggiornaObj = {
      id: selectedId,
      statoPratica: newStatoPratica,
      allegato: allegati,
      note: note,
      storico_pratica: {
        stato: statoPratica,
        data: new Date().toLocaleString("it-IT"),
      },
      last_status: {
        created: new Date(),
        status: newStatoPratica,
      },
    };

    console.log(aggiornaObj);

    const headers = {
      "Content-Type": "application/json",
    };

    client({
      url: `${ENDPOINT}/pratiche/consulente/cambiostato/`,
      config: { method: "POST", data: aggiornaObj, headers },
    }).then((response) => {
      setIsVisible(false);
      setFileInfos([]);
      setNote("");

      doFetch();
    });
  };

  const onClickHandler = () => {
    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("rcvCollection", selectedFile[x]);
      //   data.append('name', 'ricevitabis');
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${ENDPOINT}/ricevute`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })

      .then((response) => {
        // then print response status
        console.log(response);
        setFileInfos(response.data);

        let allegatiArray = [];

        for (let i = 0; i < response.data.length; i++) {
          allegatiArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString(),
          });
        }

        setAllegati(allegatiArray);
      });
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      note,
      statoPratica,
      storico_pratica,
      ricevute,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    console.log(rest.consulente);

    let statiPossibili = ["Bozza", "inviata"];

    const RenderAllegati = () => {
      return (
        <>
          <div>
            {allegato.length <= 0 ? (
              <p className="font-monospace" style={{ color: "red" }}>
                ATTEZIONE! Sembra che non ci siano allegati in questa Bozza:
                senza allegati puoi impostare lo stato solo in bozza. Se vuoi
                inviare la pratica inserisci gli allegati
              </p>
            ) : null}
          </div>
          <Table bordered style={{ borderColor: "#5e5c59" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>tipo</th>
                <th>url</th>
                <th>Creato</th>
              </tr>
            </thead>
            <tbody>
              {allegato?.map((rowData, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {" "}
                    <i
                      className="bi bi-file-pdf"
                      style={{ color: "red", fontSize: "25px" }}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <a
                      href={`${ENDPOINT}/static/allegati/${rowData.path} `}
                      target="_blank"
                      rel="noopener noreferrer"
                      downlod
                    >
                      {rowData.path}
                    </a>
                  </td>
                  <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      );
    };

    return (
      <>
        <div className="container blocco-row">
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Assistito</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row" >
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
            {data_scadenza && <div className="col">
              <small className="label-pratica">data di scadenza: </small>
              {`${data_scadenza} `}
            </div>}
          </div>
        </div>

        <div className="container">
          <div className="row blocco-row">
            <FileUpload
              accept=".pdf"
              label="Area caricamento ricevute"
              multiple
              updateFilesCb={updateUploadedFiles}
            />

            {isVisible ? (
              <div className="edit">
                <div className="col">
                  {fileInfos &&
                    fileInfos.map((fileInfo, index) => (
                      <div className="mb-2" key={index}>
                        <span>{fileInfo.path}</span>
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-info"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                          >
                            {progress}%
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="col">
                  <ul>
                    {fileInfos.map((el, i) => {
                      return (
                        <li key={i}>
                          <a
                            href={`${ENDPOINTALLEGATI}${el.filename}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            downlod
                          >
                            {" "}
                            {el.name}{" "}
                            <i
                              className="bi bi-file-pdf"
                              style={{ color: "red" }}
                            ></i>
                            {el.filename}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>

          <div className="row blocco-row">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFiles}
              >
                Upload!
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row blocco-row">
            <div className="col">
              <small className="label-pratica">aggiungi note </small>
              <div className="input-group">
                <span className="input-group-text">Area di testo</span>
                <textarea
                  className="form-control"
                  aria-label="With textarea"
                  name="note"
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row blocco-row">
            <form
              className="needs-validation"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="row mt-4">
                <div className="col">
                  <div className="label-pratica">
                    cambia lo stato pratica qui:{" "}
                  </div>

                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      handleSelectMenu(e.target.value);
                    }}
                    required
                  >
                    <option selected disabled value="0">
                      Seleziona stato...
                    </option>
                    {statiPossibili.map((item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <div className="valid-feedback">Looks good!</div>
                </div>

                <div className="col">
                  <div className="label-pratica">Salva e invia </div>
                  <button
                    className="btn btn-success"
                    disabled={!selectedFiles}
                    onClick={handleSubmit}
                    type="button"
                    id="inputGroupFileAddon088"
                  >
                    Cambia lo stato pratica!
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Note</div>
            <div className="col">
              <small className="label-pratica">Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> DOCUMENTI ALLEGATI</div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <span className="btn1" onClick={() => handleShow(row)}>
                {" "}
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "CF Assistito",
        //   accessor: c => `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}`
        accessor: (c) =>
          c.assistito?.codice_fiscale_assistito
            ? `${c.assistito.codice_fiscale_assistito} `
            : "NULL",
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <Badge bg="warning" text="dark">
            {props.value}
          </Badge>
        ),
      },
      {
        Header: "Creata il",
        accessor: (c) =>
          c.created_at
            ? `${moment(c.created_at).format("DD/MM/YYYY, HH:mm")}`
            : `${moment(c.lastElem.created).format("DD/MM/YYYY, HH:mm")}`,
        Cell: (props) => (
          <i className="bi bi-stopwatch" style={{ color: "#0c4a86" }}>
            {props.value}
          </i>
        ),
      },
      {
        Header: "Action",
        id: "id",
        Cell: (props) => (
          <Button className="btn1" onClick={() => handleDelete(props)}>
            {" "}
            <i className="bi bi-archive"></i> Elimina!
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <div
      className="test"
      style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
    >
      <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
        <div className="table-responsive">
          <TableConsContainer
            columns={columns}
            data={data}
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </Container>
    </div>
  );
};

export default PraticheInBozza;
