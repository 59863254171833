import React, { useState, useEffect, useMemo } from "react";
import socketIOClient from "socket.io-client";
import TableContainer from "./TableContainer";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Table, Button } from "react-bootstrap";
//import { Console } from 'jest-util';
import * as Loader from "react-loader-spinner";
import Endpoints from "../components/services/Endpoints";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../components/Footer/Footer";
//import UploadFiles from "./uploadAllegato";
import "./pratica.css";
import client from "../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();
const MySwal = withReactContent(Swal);

const PraticheOp = () => {
  const [data, setData] = useState([]);
  // const [response, setResponse] = useState("");
  // const [showA, setShowA] = useState(false);
  // const toggleShowA = () => setShowA(!showA);
  const [roasterText, setRoasterText] = useState("");
  // const [operatoreAttivo, setOperatoreAttivo ] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    /*
      socket.on('visitor enters', data => {setOnline(data)
      console.log('online', data);
      console.log(typeof(online), online)
     // UserConnected();
    });
*/

    /*
      socket.on('visitor exits', data => { setOnline(data)
      console.log('online', data);
     // UserConnected();
    });
*/

    socket.on("assegnazione", function (payload) {
      console.log("praticaPresa da: ", payload);
      renewTable(JSON.parse(payload));
    });

    socket.on("creazione", function (loaded) {
      console.log("nuova pratica inserita da: ", JSON.stringify(loaded));
      setRoasterText(JSON.stringify(loaded));
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    if (roasterText) {
      let text = JSON.parse(roasterText);
      updateItem(text.id);
      //  toggleShowA();
    }
  }, [roasterText]);

  useEffect(() => {
    setSpinnerLoading(true);
    const doFetch = async () => {
      const pratiche = await client({
        url: `/pratiche/inviate/`,
        config: {
          method: "get",
        },
      });
      setData(pratiche.data);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));

    client({
      url: `/pratiche/inviate/`,
      config: {
        method: "get",
      },
    }).then((res) => {
      setData(res.data);
    });
  };

  const updateItem = (id) => {
    fetch(`${ENDPOINT}/pratiche/inserita/id?_id=${id}`)
      .then((res) => res.json())
      // .then((data) => setData({...data}))
      .then((res) => {
        const updatedCarsArray = [...data, res];

        setData(updatedCarsArray);
      });
  };

  //qui mi assegno la pratica
  const handleShow = (event) => {
    var idPratica = event.row.original._id;
    let decrizionePratica = event.cell.row.values.descrizione;
    let consulente = event.cell.row.values.Consulente;
    let consulenteMail = event.cell.row.original.consulente.email;
    let assistito = event.cell.row.values.Assistito;
    const operatoreClient = JSON.parse(localStorage.getItem("currentUser"));
    // const {_id, cognome, nome, email, role} = operatoreClient;

    const { _id } = operatoreClient;

    // return
    //https://staging.cdlservizipatronato.it:5001/users/findone/id?id=629b0671cc405886c3028a29
    fetch(`${ENDPOINT}/users/findone/id?id=${_id}`)
      .then((res) => res.json())
      // .then((data) => setData({...data}))
      .then((res) => {
        //  setOperatoreAttivo(res);

        const operatoreAct = res;

        // qui modifico la pratica
        fetch(`${ENDPOINT}/pratiche/assegna/operatore?id=${idPratica}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(operatoreAct),
        })
          .then((res) => res.json())
          .then((data) => {
            const operatoreAttivo = JSON.parse(
              localStorage.getItem("currentUser")
            );
            const { _id, cognome, nome, email, role } = operatoreAttivo;

            if (data) {
              let notifyObj = {
                emailto: consulenteMail,
                idpratica: idPratica,
                descrizione: decrizionePratica,
                stato: "Assegnata",
                assistito: assistito,
                operatore: `${operatoreClient.cognome} ${operatoreClient.nome}`,
                contatti: `Cell: ${operatoreClient.cellulare} - Tel: ${operatoreClient.telefono} Email: ${operatoreClient.email}`,
              };

              notificaAlConsulente(notifyObj);
            }

            let assegnaObj = {
              _id: _id,
              cognome: cognome,
              nome: nome,
              email: email,
              role: role,
              date: new Date().toLocaleString("it-IT"),
              consulente: consulente,
              assistito: assistito,
              praticaId: idPratica,
              descrizione: decrizionePratica,
            };

            const socket = socketIOClient(process.env.REACT_APP_BASE_URL);

            socket.emit("assegnazione", JSON.stringify(assegnaObj));
            //  console.log("data? ",data)
          })
          .catch((error) => {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error);
          });
      });
  };

  function notificaAlConsulente(obj) {
    //notifico con mail al consulente
    axios
      .post(`${ENDPOINT}/consulente/notifica/action`, obj)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;
    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    console.log(row.original);
    console.log(rest.consulente);

    const RenderAllegati = () => {
      return (
        <div className="table-responsive">
          <Table bordered style={{ borderColor: "#5e5c59" }}>
            <thead style={{ color: "rgb(131 135 140)" }}>
              <tr>
                <th>#</th>
                <th>tipo</th>
                <th>url</th>
                <th>Creato</th>
              </tr>
            </thead>
            <tbody style={{ color: "rgb(131 135 140)" }}>
              {allegato?.map((rowData, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {" "}
                    <i
                      className="bi bi-file-pdf"
                      style={{ color: "red", fontSize: "25px" }}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <a
                      href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rowData.path}
                    </a>
                  </td>
                  <td>{rowData.creato}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <div className="table-responsive">
          <Table bordered style={{ borderColor: "#5e5c59" }}>
            <thead style={{ color: "rgb(131 135 140)" }}>
              <tr>
                <th>#</th>
                <th>tipo</th>
                <th>Url</th>
                <th>Creato</th>
              </tr>
            </thead>
            <tbody style={{ color: "rgb(131 135 140)" }}>
              {ricevute?.map((rowData, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                  </td>
                  <td>
                    <a
                      href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rowData.path}
                    </a>
                  </td>
                  <td>{rowData.creato}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <div className="table-responsive">
          <Table bordered style={{ borderColor: "#5e5c59" }}>
            <thead style={{ color: "rgb(131 135 140)" }}>
              <tr>
                <th>#</th>
                <th>Stato</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody style={{ color: "rgb(131 135 140)" }}>
              {storico_pratica?.map((rowData, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{rowData.stato}</td>
                  <td>{rowData.data}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <div className="table-responsive">
          <Table bordered style={{ borderColor: "#5e5c59" }}>
            <thead style={{ color: "rgb(131 135 140)" }}>
              <tr>
                <th>#</th>
                <th>Nota</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody style={{ color: "rgb(131 135 140)" }}>
              {storico_note?.map((rowData, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{rowData.nota}</td>
                  <td>{rowData.data}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <div className="table-responsive">
          <Table bordered style={{ borderColor: "#5e5c59" }}>
            <thead style={{ color: "rgb(131 135 140)" }}>
              <tr>
                <th>#</th>
                <th>_id</th>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Email</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody style={{ color: "rgb(131 135 140)" }}>
              {storico_operatore?.map((rowData, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{rowData.id}</td>
                  <td>{rowData.nome}</td>
                  <td>{rowData.cognome}</td>
                  <td>{rowData.email}</td>
                  <td>{rowData.data}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    };
    // let RenderNote;
    // Stefano copiare qui sotto
    return (
      <>
        <div
          className="container-md"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Consulente</div>
            <div className="col-4">
              <small style={{ color: "rgb(131 135 140)" }}>Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i
                className="bi bi-envelope"
                style={{ color: "rgb(131 135 140)" }}
              ></i>
              <small style={{ color: "rgb(131 135 140)" }}> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i
                className="bi bi-phone"
                style={{ color: "rgb(131 135 140)" }}
              ></i>
              <small style={{ color: "rgb(131 135 140)" }}> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i
                className="bi bi-telephone"
                style={{ color: "rgb(131 135 140)" }}
              ></i>
              <small style={{ color: "rgb(131 135 140)" }}> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="tipo-pratica"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Tipo Pratica</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>
                tipo pratica:{" "}
              </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="assistito"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Assistito</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>
                Tipo assistito:{" "}
              </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>data sc: </small>
              {`${assistitoObj.data_stato_civile} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="collaboratore"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Collaboratore volontario</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="stato-pratica"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Stato Pratica</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>descrizione: </small>
              {`${statoPratica} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="allegati"
        >
          <div className="row">
            <div>
              {" "}
              Documenti allegati <i className="bi bi-paperclip"></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico pratica <i className="bi bi-file-earmark-text"></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Ricevute <i className="bi bi-file-earmark-arrow-down"></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: () => null,
      //   id: "expander", // 'id' is required

      //   /*
      //           Cell: ({ row }) => (
      //             <span {...row.getToggleRowExpandedProps()}>
      //               {row.isExpanded ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
      //             </span>
      //           ),
      //           */

      //   Cell: ({ row, rows, toggleRowExpanded }) => (
      //     <span
      //       {...row.getToggleRowExpandedProps({
      //         ...row.getToggleRowExpandedProps({
      //           style: {
      //             // We can even use the row.depth property
      //             // and paddingLeft to indicate the depth
      //             // of the row
      //             paddingLeft: `${row.depth * 2}rem`,
      //           },
      //           onClick: () => {
      //             const expandedRow = rows.find((row) => row.isExpanded);

      //             if (expandedRow) {
      //               const isSubItemOfRow = Boolean(
      //                 expandedRow && row.id.split(".")[0] === expandedRow.id
      //               );

      //               if (isSubItemOfRow) {
      //                 const expandedSubItem = expandedRow.subRows.find(
      //                   (subRow) => subRow.isExpanded
      //                 );

      //                 if (expandedSubItem) {
      //                   const isClickedOnExpandedSubItem =
      //                     expandedSubItem.id === row.id;
      //                   if (!isClickedOnExpandedSubItem) {
      //                     toggleRowExpanded(expandedSubItem.id, false);
      //                   }
      //                 }
      //               } else {
      //                 toggleRowExpanded(expandedRow.id, false);
      //               }
      //             }
      //             row.toggleRowExpanded();
      //           },
      //         }),
      //       })}
      //     >
      //       {row.isExpanded ? (
      //         <i className="bi bi-caret-down-fill" style={{ color: "red" }}></i>
      //       ) : (
      //         <i className="bi bi-caret-right-fill"></i>
      //       )}
      //     </span>
      //   ),

      //   //
      // },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) =>
          `${a.assistito && a.assistito.nome} ${
            a.assistito && a.assistito.cognome
          }`,
        //  accessor: a => (a.assistito ? a.assistito.nome : undefined)
      },
      {
        Header: "CF. Assistito",
        accessor: (c) =>
          c.assistito ? c.assistito.codice_fiscale_assistito : undefined,
      },
      {
        Header: "Consulente",
        accessor: (b) =>
          `${b.consulente && b.consulente.nome} ${
            b.consulente && b.consulente.cognome
          }`,
        //  accessor: b => (b.consulente ? b.consulente.nome : undefined)
      },
      {
        Header: "Stato",
        accessor: "statoPratica",
      },
      {
        Header: "Scade il",
        accessor: "data_scadenza",
        // accessor: "data_scadenza",
      },
      {
        Header: "Inviata il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(
            d.storico_pratica[d.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
        // accessor: "data_scadenza",
      },
      {
        Header: "Action",
        id: "id",
        Cell: (props) => (
          <Button className="btn1 btn-sm" onClick={() => handleShow(props)}>
            {" "}
            <small>
              <i className="bi bi-archive"></i> Prendi tu!
            </small>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div
        className="test"
        style={{ paddingBottom: "100px", backgroundColor: "#e9ecef" }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? (
            <Loader.ThreeDots color="white" height={100} width={100} />
          ) : null}
        </div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          <div className="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PraticheOp;
