import React, { useState, useEffect, useMemo } from "react";
import TableConsContainer from "./TableConsContainer";

import { Container, Table } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import Endpoints from "../components/services/Endpoints";

import client from "../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();

const PraticheAnnullate = () => {
  const [data, setData] = useState([]);

  const [selectedFile, setSelectedFile] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);

  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    const doFetch = async () => {
      const response = await client({
        url: `/pratiche/consulente/annullate`,
        config: { method: "GET" },
      });
      const pratiche = response.data;
      setData(pratiche);
    };
    doFetch();
  }, []);

  const handleShow = (event) => {
    console.log(event.original._id);
    console.log(event.values._id);
    setSelectedId(event.values._id);
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      note,
      statoPratica,
      storico_pratica,
      ricevute,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    //     let statiPossibili = ['Bozza', 'inviata'];

    return (
      <>
        <div className="container blocco-row">
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelopelabel-pratica"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phonelabel-pratica"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephonelabel-pratica"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Assistito</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="colaboratore">
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>

            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">data di scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Note</div>
            <div className="col">
              <small className="label-pratica">Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> DOCUMENTI ALLEGATI</div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody>
                  {allegato?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {" "}
                        <i
                          className="bi bi-file-pdf"
                          style={{ color: "red", fontSize: "25px" }}
                        ></i>
                      </td>
                      <td>
                        {" "}
                        <a
                          href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {rowData.path}
                        </a>
                      </td>
                      <td>
                        {moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div> Storico pratica</div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>Stato</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {storico_pratica?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.stato}</td>
                      <td>
                        {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                          "DD/MM/YYYY, HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-ricevuta">
          <div className="row">
            <div>
              {" "}
              Storico Ricevuta{" "}
              <i className="bi bi-card-checklist label-pratica"></i>
            </div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>tipo</th>
                    <th>Url</th>
                    <th>Creato</th>
                  </tr>
                </thead>
                <tbody>
                  {ricevute?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <i
                          className="bi bi-file-pdf"
                          style={{ color: "red" }}
                        ></i>
                      </td>
                      <td>
                        <a
                          href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path}`}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {rowData.path}
                        </a>
                      </td>
                      <td>
                        {moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-note">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>Nota</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {storico_note?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.nota}</td>
                      <td>
                        {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                          "DD/MM/YYYY, HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-operatore">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">
              <Table bordered style={{ borderColor: "#5e5c59" }}>
                <thead className="label-pratica">
                  <tr>
                    <th>#</th>
                    <th>_id</th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Email</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {storico_operatore?.map((rowData, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{rowData.id}</td>
                      <td>{rowData.nome}</td>
                      <td>{rowData.cognome}</td>
                      <td>{rowData.email}</td>
                      <td>
                        {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                          "DD/MM/YYYY, HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <span className="btn1" onClick={() => handleShow(row)}>
                {" "}
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        //   accessor: c => `${c.operatore[c.operatore.length - 1].nome} ${c.operatore[c.operatore.length - 1].cognome}`
        accessor: (c) =>
          c.operatore
            ? `${c.operatore[c.operatore.length - 1].nome} ${
                c.operatore[c.operatore.length - 1].cognome
              }`
            : "NULL",
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "red", fontWeight: "bolder" }}>
            {props.value}
          </span>
        ),
      },
      {
        Header: "Annullata il",
        sortType: () => 1,
        accessor: (c) =>
          `${moment(
            c.storico_pratica[c.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
      },
    ],
    []
  );

  return (
    <div
      className="test"
      style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
    >
      <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
        <div className="table-responsive">
          <TableConsContainer
            columns={columns}
            data={data}
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </Container>
    </div>
  );
};

export default PraticheAnnullate;
