import React, {useRef,useEffect} from "react";
import { useAuth } from "react-oidc-context";


function Logins() {
    const auth = useAuth();

    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
          ref.current.click();
        }, 2000); //miliseconds
      }, []);

    // eslint-disable-next-line default-case
    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        
        return <div class="container-fluid">
        <div class="row"><div class="container">
            <div class="row"><div class="col" style={{background: 'rgb(12, 74, 134)'}}>
                <div class="card mt-20" style={{backgroundColor: 'transparent', marginTop: '20px', textAlign: 'center', border: 'none'}}>
                    <div class="card-body ">
                        <h1 class="no_toc text-white" style={{marginBottom: '4rem'}}>
                        Loading...</h1> 
                        <br />

                    <p class="card-text"></p>

                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
 
    }

    if (auth.error) {
        return <div class="container-fluid">
        <div class="row"><div class="container">
            <div class="row"><div class="col" style={{background: 'rgb(12, 74, 134)'}}>
                <div class="card mt-20" style={{backgroundColor: 'transparent', marginTop: '20px', textAlign: 'center', border: 'none'}}>
                    <div class="card-body ">
                        <h1 class="no_toc text-white" style={{marginBottom: '4rem'}}>
                        Oops... {auth.error.message}</h1> 
                        <br />

                    <p class="card-text"></p>

                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
 ;
    }

    if (auth.isAuthenticated) {
        auth.removeUser();
        return (
        <div>
            Hello {auth.user?.profile.sub}{" "}
            
            <button 
            onClick={auth.removeUser}>Log out
            
            </button>
        </div>
        );
    }

    const autologin = () => {
       // onClick={auth.signinRedirect}
       auth.signinRedirect();
    }

    return <div class="container-fluid">
        <div class="row"><div class="container">
            <div class="row"><div class="col" style={{background: 'rgb(12, 74, 134)'}}>
                <div class="card mt-20" style={{backgroundColor: 'transparent', marginTop: '20px', textAlign: 'center', border: 'none'}}>
                    <div class="card-body ">
                        <h1 class="no_toc text-white" style={{marginBottom: '4rem'}}>
                        Benvenuto Teleconsul User</h1> 
                        <br />
                    {/* */}   <div onLoad={auth.signinRedirect()}></div>
          
                        <button className="btn btn-primary btn-lg" ref={ref} onClick={autologin} >Log in</button>
                    <p class="card-text"></p>

                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    ;
}

export default Logins;