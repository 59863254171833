import moment from "moment";
import RenewButton from "./RenewButton";

/**
 * Shows a banner to remind the user to subscribe
 *
 * @returns {JSX.Element} the subscription banner
 */
const SubscriptionBanner = () => {
  const REMINDER_DAYS = 10;

  const user =
    localStorage.getItem("currentUser") &&
    JSON.parse(localStorage.getItem("currentUser"));

  if (!user) return null;

  /**
   * Check if the user has a subscription and it's expired
   * @returns {boolean} true if the user has a subscription and it's expired
   */
  const isSubscriptionExpired = () =>
    user.subscription && user.subscription.status === "inactive";

  /**
* Check if the user has a free plan
* @returns {boolean} true if the user has a free plan
*/
  const isFreeAccountUser = () =>
    user.freeAccount;

  /**
   * Check if the user has a subscription and it's about to expire
   * @returns {boolean} true if the user has a subscription and it's about to expire
   */
  const isSubscriptionAboutToExpire = () =>
    user.subscription &&
    user.subscription.status === "active" &&
    new Date(user.subscription.expires) <
    new Date(Date.now() + REMINDER_DAYS * 24 * 60 * 60 * 1000); // 10 days

  /**
   * Get the number of days remaining in the subscription
   * @returns {number} the number of days remaining in the subscription
   */
  const subscriptionRemainingDays = () =>
    Math.round(
      (new Date(user.subscription.expires) - new Date()) / (1000 * 60 * 60 * 24)
    );

  /**
   * Check if the banner should be visible
   * @returns {boolean} true if the banner should be visible
   */
  const isBannerVisible = () =>
    (isSubscriptionExpired() || isSubscriptionAboutToExpire() || isDemoUser()) && !isFreeAccountUser();

  /**
   * Checks if it's demo user
   *
   * @returns {boolean} true if it's demo user
   */
  const isDemoUser = () => user.email === "demo@cdlservizipatronato.it";

  /**
   * Get the message to display in the banner
   * @returns {JSX.Element} the message to display in the banner
   */
  const bannerMessage = () => {
    if (isDemoUser())
      return (
        <>
          Sei in modalità ospite, puoi visualizzare il portale ma non puoi
          creare nuove richieste.
        </>
      );
    else if (isSubscriptionExpired()) {
      return (
        <>
          Clicca qui per attivare le tue credenziali. <RenewButton />
        </>
      );
    } else if (isSubscriptionAboutToExpire()) {
      return (
        <>
          Le tue credenziali scadono tra {subscriptionRemainingDays()} giorni.
          <RenewButton />
        </>
      );
    }
  };

  return (
    isBannerVisible() && (
      <div
        className="banner"
        style={{
          display: "flex",
          backgroundColor: "orange",
          height: "50px",
          color: "white",
          fontSize: "1em",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {bannerMessage()}
      </div>
    )
  );
};

export default SubscriptionBanner;
