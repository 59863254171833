import React from 'react';
import { Container } from 'react-bootstrap';
import './Policy.css'
function Policy (props) {
    return (
   <div>
<Container>
        <div style={{padding: '50px', color:'white'}} >
        <p style={{marginLeft: "25px"}}><p style={{marginLeft: "25px"}}><p style={{marginLeft: "25px"}}></p><p><strong>INFORMATIVA AI SENSI DELL’ART. 13 DEL D.LGS. 196/2003</strong></p>

        <p>- Tipologia dei dati: i dati personali trattati sono essenzialmente 
        dati identificativi e fiscali, quali, ad esempio, dati anagrafici, 
        telefono, fax, e-mail, codice fiscale;</p><p><br />
        - Finalità del laborazione diretto o mediato dal Professionista;<br />
        · adempimento di specifici obblighi contrattuali o di legge ovvero 
        esecuzione di compiti previsti dalla normativa nazionale e/o 
        comunitaria;<br />
        · applicazione di regolamenti e istruzioni procedurali;<br />
        · somministrazione di corsi di formazione;<br />
        · gestione amministrativa e contabile del rapporto.</p><p><br />
        - Modalità del trattamento: i dati personali verranno trattati sia 
        manualmente che elettronicamente e saranno conservati sia in un archivio
         cartaceo sia nella banca dati elettronica a tal uopo predisposta per 
        adempiere agli obblighi e alle finalità sopra indicate. I dati così 
        archiviati sono trattati utilizzando le misure di sicurezza prescritte<br />
        dal D. Lgs. 196/03, in modo da ridurne al minimo i rischi di distruzione
         o perdita, di accesso non autorizzato o di trattamento non conforme 
        alle finalità della raccolta;</p><p><br />
        - Collaborazioni nel trattamento: cdlservizipatronato ai fini della 
        gestione del rapporto e degli obblighi che ne discendono, potrà 
        avvalersi di soggetti esterni alla propria struttura;</p><p><br />
        - Ambito &nbsp;di &nbsp;comunicazione dei &nbsp;dati: &nbsp;in relazione alle finalità sopra indicate i dati personali potranno essere<br />
        comunicati alle seguenti categorie di soggetti:<br />
        · società di consulenza;<br />
        · società che mette a disposizione la piattaforma e fornisce i programmi
         software, ed alle società intestatarie del dominio internet;<br />
        · Amministrazioni, Istituti, Enti che per legge chiedano i dati 
        identificativi dei soggetti preposti all’attività di assistenza fiscale 
        in nome e per conto di cdlservizipatronato;</p><p><br />
        - Obbligo di conferire i dati: il conferimento dei dati è essenziale per
         la gestione del rapporto. L'eventuale rifiuto a conferire i dati 
        personali comporterà l’impossibilità di dar vita alla collaborazione;</p><p><br />
        Diritti dell’interessato: in relazione al trattamento dei dati 
        personali, il sottoscritto può esercitare, anche a mezzo delega o 
        procura a persona fisica o associazione, i diritti riconosciutigli 
        dall’art. 7 del D.Lgs. 196/2003, riportato in<br />
        calce alla presente informativa e di cui dichiara di aver preso visione. Le richieste operate nell'esercizio dei diritti<br />
        possono essere trasmesse all'attenzione del titolare dei dati mediante 
        fax al numero: 0694446812, o raccomandata al seguente indirizzo: Via 
        Cristoforo Colombo 465, 00145 Roma.</p><p><br />
        - Titolare e Responsabile del trattamento titolare del trattamento dei 
        dati è SGC s.r.l., Via Cristoforo Colombo 465, 00145 Roma.</p>
        
        Art. 7. D. Lgs. 196/2003 “Diritto di accesso ai dati personali ed altri diritti<br />
        1. L'interessato ha diritto di ottenere la conferma dell'esistenza o 
        meno di dati personali che lo riguardano, anche se non ancora 
        registrati, e la loro comunicazione in forma intelligibile.<br />
        2. L'interessato ha diritto di ottenere l'indicazione:<br />
            a - dell'origine dei dati personali;<br />
            b - delle finalità e modalità del trattamento;<br />
            c - della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;<br />
            d - degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell'articolo 5, comma 2;<br />
            e - dei soggetti o delle categorie di soggetti ai quali i dati personali 
        possono essere comunicati o che possono venirne a conoscenza in qualità 
        di rappresentante designato nel territorio dello Stato, di responsabili o
         incaricati.<br />

        </p>
        <p style={{marginLeft: "25px"}}>3. L'interessato ha diritto di ottenere:<br />
            a - l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;<br />
            b - la cancellazione, la trasformazione in forma anonima o il blocco dei 
            dati trattati in violazione di legge, compresi quelli di cui non è 
            necessaria la conservazione in relazione agli scopi per i quali i dati 
            sono stati raccolti o successivamente trattati;<br />
            c - l'attestazione che le operazioni di cui alle lettere a) e b) sono 
            state portate a conoscenza, anche per quanto riguarda il loro<br />
            contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, 
            eccettuato il caso in cui tale adempimento si rivela impossibile o 
            comporta un impiego di mezzi manifestamente sproporzionato rispetto al 
            diritto tutelato.<br />
        </p>
        
        <p style={{marginLeft: "25px"}}>4. L'interessato ha diritto di opporsi, in tutto o in parte:<br />
            a - per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;<br />
            b - al trattamento di dati personali che lo riguardano a fini di invio di
            materiale pubblicitario o di vendita diretta o per il compimento di 
            ricerche di mercato o di comunicazione commerciale.<p></p></p></p>
    </div>
    </Container>
    </div>
    );
}

export default Policy;