import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "./TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Table, Button, Modal } from "react-bootstrap";
import socketIOClient from "socket.io-client";
import axios from "axios";

import "./loaderino.css";
import Swal from "sweetalert2";
import Endpoints from "../components/services/Endpoints";

import client from "../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();

const AdminPraticheSospese = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const checkRole = () => {
    navigate("/");
  };

  useEffect(() => {
    //  console.log("TEST-RoleAdmim module 104: ", localStorage.getItem('currentUser'));
    const admin = JSON.parse(localStorage.getItem("currentUser"));
    const role = admin.role[0];
    if (role !== "ROLE_ADMIN") {
      checkRole();
    }

    const doFetch = async () => {
      setSpinnerLoading(true);

      const response = await client({
        url: `/admin/sospese`,
        config: { method: "GET" },
      });
      const pratiche = await response.data;
      setData(pratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));

    client({ url: `/admin/sospese`, config: { method: "GET" } })
      .then((res) => res.data)
      .then((res) => {
        setData(res);
      });
  };

  const handleShow = (event) => {
    console.log(event.cell.row.values._id);
    var idPratica = event.row.original._id;
    const descrConfirm = event.cell.row.values.descrizione;
    const aConfirmNome = event.cell.row.original.assistito.nome;
    const aConfirmCognome = event.cell.row.original.assistito.cognome;

    const cConfirmNome = event.cell.row.original.consulente.nome;
    const cConfirmCognome = event.cell.row.original.consulente.cognome;

    const oConfirmNome = event.cell.row.original.operatore[0].nome;
    const oConfirmCognome = event.cell.row.original.operatore[0].cognome;

    const adminClient = JSON.parse(localStorage.getItem("currentUser"));

    if (adminClient.role[0] === "ROLE_ADMIN") {
      Swal.fire({
        title: "Vuoi davvero eliminare?",
        text: `${descrConfirm}`,
        icon: "danger",
        html: `<b>Descrizione: </b>${descrConfirm}<br><b>Consulente: </b>${cConfirmCognome} ${cConfirmNome} <br> <b> Assistito: </b> ${aConfirmCognome} ${aConfirmNome}`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SI, elimina!",
        footer: `<b>Operatore: <b/>${oConfirmCognome} ${oConfirmNome}`,
      }).then((result) => {
        if (result.isConfirmed) {
          //inizio conferma seleziona
          // qui elimino la pratica
          client(
            { url: `/pratiche/${idPratica}`, config: { method: "DELETE" } },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.data)
            .then((data) => {
              if (data.success) {
                let updTbl = {
                  praticaId: idPratica,
                };

                renewTable(updTbl);
                Swal.fire("Eliminata!", data.success, "Grazie");
              }
              if (data.error) {
                Swal.fire("Error!", data.error, "Grazie");
              }
            })
            .catch((error) => {
              // enter your logic for when there is an error (ex. error toast)
              console.log(error);
            });

          //fine conferma seleziona
        }
      });
    } else {
      Swal.fire("Non hai i privilegi per eseguire questa azione", "Grazie");
    }
  };

  const handleAssegna = (props) => {
    console.log(props);

    let arrayOpe = [];
    const operatoriFetch = async () => {
      const response2 = await client({
        url: `/users/seleziona/operatori/`,
        config: { method: "GET" },
      });
      const operator = response2.data;
      //     console.log(pratiche);
      arrayOpe = operator;

      var options = {};

      let test = arrayOpe.map(
        (x) => (options[x._id] = x.cognome + " " + x.nome)
      );
      console.log(test);

      let pretext = props.cell.row.original;

      Swal.fire({
        title: `Riassegnazione`,
        icon: "info",
        html: `<b>_id pratica: ${pretext._id}</b><br>
       Descrizione: <b>${pretext.descrizione}</b><br>
       Consulente: <b> ${pretext.consulente.cognome} ${
          pretext.consulente.nome
        }</b><br>
       Assistito: <b> ${pretext.assistito.cognome} ${
          pretext.assistito.nome
        }</b><br>  
       Ultimo Operatore: <b> ${
         pretext.storico_operatore[pretext.storico_operatore.length - 1].cognome
       } ${
          pretext.storico_operatore[pretext.storico_operatore.length - 1].nome
        }</b><br>
       <b>stato pratica: ${pretext.statoPratica}</b><br>`,
        input: "select",
        inputOptions: options,
        inputPlaceholder: "Seleziona Operatore di destinazione",
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              const found = arrayOpe.find((element) => element._id == value);
              console.log(found);

              Swal.fire({
                icon: "warning",
                title: "Sei sicuro?",
                html: `<b>Operatore</b><br>
                _id: <b>${found._id}</b><br>
               Nome: <b> ${found.nome}</b><br>
               Cognome: <b>${found.cognome}</b><br>
               Nome: <b> ${found.nome}</b><br>`,
                showCancelButton: true,
                confirmButtonText: "Si è tutto ok!",
                cancelButtonText: `Annulla`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  handleAssegnaPratica(found, pretext);
                  //     Swal.fire('Pratica assegnata!')
                } else {
                  Swal.fire("Operazione annullata");
                }
              });
              resolve();
            } else {
              resolve("Devi selezionare un operatore :)");
            }
          });
        },
      });
    };
    operatoriFetch();
  };

  // Qui assegno la pratica e notifico al consulente

  const handleAssegnaPratica = (operatoreSelezionato, pretext) => {
    console.log(pretext._id);

    var idPratica = pretext._id;
    let decrizionePratica = pretext.descrizione;
    let consulente = pretext.consulente;
    let consulenteMail = pretext.consulente.email;
    let assistito = pretext.assistito;
    const operatoreClient = operatoreSelezionato;

    // qui modifico la pratica
    client({
      url: `${ENDPOINT}/admin/riassegna/operatore:id?id=${idPratica}`,
      config: { method: "POST", data: operatoreClient },
    })
      .then((res) => res.data)
      .then((data) => {
        const { _id, cognome, nome, email, role } = operatoreClient;

        if (data.success) {
          let notifyObj = {
            emailto: consulenteMail,
            idpratica: idPratica,
            descrizione: decrizionePratica,
            stato: "Assegnata",
            assistito: assistito,
            operatore: `${operatoreClient.cognome} ${operatoreClient.nome}`,
            contatti: `Cell: ${operatoreClient.cellulare} - Tel: ${operatoreClient.telefono} Email: ${operatoreClient.email}`,
          };

          //     notificaAlConsulente(notifyObj);
        }

        let assegnaObj = {
          _id: _id,
          cognome: cognome,
          nome: nome,
          email: email,
          role: role,
          date: new Date().toLocaleString("it-IT"),
          consulente: consulente,
          assistito: assistito,
          praticaId: idPratica,
          descrizione: decrizionePratica,
        };

        const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
        socket.emit("adminAction", JSON.stringify(assegnaObj));

        const doFetch2 = async () => {
          setSpinnerLoading(true);

          const response = await client({
            url: `/admin/sospese`,
            config: { method: "GET" },
          });
          const pratiche = response.data;
          setData(pratiche);
          setSpinnerLoading(false);
        };
        doFetch2();
      })
      .catch((error) => {
        // enter your logic for when there is an error (ex. error toast)
        console.log(error);
      });
  };

  function notificaAlConsulente(obj) {
    //notifico con mail al consulente
    axios
      .post(`${ENDPOINT}/consulente/notifica/action`, obj)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      tipo_pratica,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;
    let consulente = rest.consulente;
    //  let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    // let statiPossibili = ['Lavorazione', 'Sospesa', 'Annullata'];

    //  console.log(rest.consulente);

    const RenderAllegati = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody className="label-pratica">
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod="true"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody className="label-pratica">
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod="true"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody className="label-pratica">
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody className="label-pratica">
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody className="label-pratica">
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };
    // let RenderNote;

    return (
      <>
        <div className="container blocco-row">
          <div className="row">
            <div> Consulente</div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i className="bi bi-envelope"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Tipo Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Assistito</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile}`}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> collaboratore volontario</div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        <div className="container blocco-row" id="allegati">
          <div className="row">
            <div> DOCUMENTI ALLEGATI</div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div> Storico pratica</div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico RICEVUTA <i className="bi bi-file-earmark-arrow-down"></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div className="container blocco-row" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div className="container blocco-row">
          <div className="row">
            <div>
              {" "}
              <h2 style={{ color: "rgb(203 107 16)" }}>
                {" "}
                Stato Pratica: {`${statoPratica} `}{" "}
                <i class="bi bi-exclamation-triangle"></i>
              </h2>{" "}
            </div>
          </div>
        </div>

        {show ? (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              I will not close if you click outside me. Don't even try to press
              escape key.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary">Understood</Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <i className="bi bi-caret-right-fill"></i>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        accessor: (c) =>
          `${c.operatore[c.operatore.length - 1].nome} ${
            c.operatore[c.operatore.length - 1].cognome
          }`,
      },
      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "rgb(169 95 30)", fontWeight: "bolder" }}>
            {props.value} <i className="bi bi-pause-circle"></i>
          </span>
        ),
      },
      {
        Header: "Sospesa il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(
            d.storico_pratica[d.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
        //  accessor: "data_scadenza",
      },
      {
        Header: "Assegna",
        ids: "id",
        Cell: (props) => (
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => handleAssegna(props)}
          >
            {" "}
            <i class="bi bi-person-plus-fill"></i> Assegna!
          </Button>
        ),
      },
      {
        Header: "Elimina",
        id: "id",
        Cell: (props) => (
          <Button
            className="btn btn-danger btn-sm"
            onClick={() => handleShow(props)}
          >
            {" "}
            <i className="bi bi-archive"></i> Elimina!
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <div className="row">
      <div
        style={{
          textAlign: "center",
          textAlign: "center",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          fontWeight: "600",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-house-fill"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
          />
          <path
            fillRule="evenodd"
            d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
          />
        </svg>{" "}
        / Admin / Pratiche / Pratiche sospese
      </div>

      <div
        className="test"
        style={{
          paddingBottom: "100px",
          backgroundColor: "rgb(233, 236, 239)",
        }}
      >
        {spinnerLoading ? <div className="classic-3"></div> : null}
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          <div class="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AdminPraticheSospese;
