import React, { useState, useEffect } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
//import { useNotificationCenter } from "react-toastify/addons/useNotificationCenter"
//import { ToastContainer,toast, TypeOptions  } from 'react-toastify';
import { ToastContainer, toast, TypeOptions } from "react-toastify";
import Endpoints from "../components/services/Endpoints";
//import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navbar,
  Nav,
  Container,
  Badge,
  Button,
  NavDropdown,
} from "react-bootstrap";
import "./navlinkOp.css";
import "./Toastcustom.css";

import client from "../utils/client";

const ENDPOINT = Endpoints.getUrls();

export default function MenuPraticheOp() {
  const [inviate, setInviate] = useState(0);
  const [chiuse, setChiuse] = useState(0);
  const [sospese, setSospese] = useState(0);
  const [annullate, setAnnullate] = useState(0);
  const [lavorazione, setLavorazione] = useState(0);
  //  const [assegnata, setAssegnata] = useState(0);
  const [generali, setGenerali] = useState(0);
  const [online, setOnline] = useState(0);
  const [notifica, setNotifica] = useState({});

  const [assegnazione, setAssegnazione] = useState({});
  const [active, setActive] = useState("default");
  const notify = (loaded) => toast(JSON.stringify("hello", { data: loaded }));
  const [numMess, setNumMess] = useState(0);

  const notify2 = (loaded) =>
    toast.info(JSON.stringify(loaded), {
      className: "error-toast",
      draggable: true,
      background: "#0dcaf0",
      position: toast.POSITION.TOP_CENTER,
    });

  const options = {
    onOpen: (props) => console.log(props.foo),
    onClose: (props) => console.log(props.foo),
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    newestOnTop: true,
    // progress: 0.2,
    background: "#0dcaf0",
    autoClose: 15000,
    // and so on ...

    //  overflowY: 'scroll',
    height: "553px",
    theme: "colored",
  };

  /*
{
  "_id":"621bf2599812dce315063ed6",
  "cognome":"Di Giacomo",
  "nome":"Martina",
  "email":"guidonia414@gmail.com",
  "role":["ROLE_OPERATORE"],
  "date":"23/5/2022, 14:20:31",
  "praticaId":"6246fcf946c51657d3db5df2"}

*/
  const toastId = React.useRef(null);
  const notify4 = (val) => toast(val, options);

  const notifyPresaInCarico = (val) => {
    toast.info(val, options);
  };

  const notify3 = (loaded) => toast(<ToastContainer foo={"bar"} />, options);

  useEffect(() => {
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  const fetchData = async () => {
    const op = JSON.parse(localStorage.getItem("currentUser"));
    const id = op._id;
    // You can await here
    const result = await client({
      url: `/pratiche/count/inviate/id?_id=${id}`,
      config: { method: "GET" },
    });
    let { inviate, chiuse, sospese, annullate, lavorazione, generali } =
      result.data;
    setInviate(inviate);
    setChiuse(chiuse);
    setSospese(sospese);
    setAnnullate(annullate);
    setLavorazione(lavorazione);
    //   setAssegnata(assegnata);
    setGenerali(generali);
    // ...
  };

  useEffect(async () => {
    const op = JSON.parse(localStorage.getItem("currentUser"));
    const id = op._id;
    const response = await axios.post(`${ENDPOINT}/direct/getmessages`, {
      from: id,
    });
    setNumMess(response.data.length);
  }, []);

  useEffect(() => {
    const socket2 = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log("socket2");
    console.log(socket2);

    socket2.on("assegnazione", function (payload) {
      console.log("praticaPresa da: ", payload);
      //notifyPresaInCarico(payload);
      //    notify(payload);
      let campi = JSON.parse(payload);
      notifyNuovo(campi);
      fetchData();
      // proviamo(payload);
      //   notificaAssegnazione(payload);
    });

    socket2.on("adminAction", function (payload) {
      console.log("pratica Assegnata ad: ", payload);
      //   console.log(JSON.stringify(payload));

      const textAdmin = JSON.parse(payload);

      const Msg2 = ({ closeToast }) => (
        <div>
          <strong>Admin assegnazione per:</strong>
          <small>
            {textAdmin.cognome} {textAdmin.cognome}
          </small>
          <br />
          <small>{textAdmin.descrizione}</small>
          <br />
          <small>
            Cons.: {textAdmin.consulente.cognome} {textAdmin.consulente.nome}
          </small>
          <br />
          <small>
            Ass.: {textAdmin.assistito.cognome} {textAdmin.assistito.nome}
          </small>
          <br />
          <small>{textAdmin.tipo} </small>
        </div>
      );

      toast.warn(Msg2, {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //  renewTable(JSON.parse(payload));
    });

    socket2.on("cambiostato", function (loaded) {
      console.log(loaded);
      //    toast(loaded);
      fetchData();
      //  setRoasterText(JSON.stringify(loaded));
    });

    /*
      socket2.on('inviata',function(loaded){
      setNotifica(loaded)
  //    toast(loaded);
      console.log('Inviata: ' , JSON.stringify(loaded));
  //  setRoasterText(JSON.stringify(loaded));
      });
*/

    /*
      socket2.on('creazione',function(loaded){
        console.log('Inviata da menu: ' , JSON.stringify(loaded));
        proviamo(loaded);
        });
*/

    // CLEAN UP THE EFFECT
    return () => socket2.disconnect();
  }, []);

  // default success
  function successX(message) {
    // prompt success if condition is met
    toast(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      className: "toast-success",
      progressClassName: "success-progress-bar",
      // autoClose: 4000,
      toastId: 1,
    });
  }

  const proviamo = (loaded) => {
    console.log("chiamo ilbadeg", loaded);
    let consul = loaded.nome;
    let tipo = loaded.descrizione;
    const Msg = ({ closeToast }) => (
      <div>
        <ul style={{ listStyleType: "none", fontWeight: "smaller" }}>
          <li>
            <small>Assegnazione</small>
          </li>
          <li>
            <small>
              OP{": "}
              {loaded.nome} {loaded.cognome} {loaded.email}
            </small>
          </li>
          <li>
            <small>
              ASS{": "}
              {loaded.assistito}
            </small>
          </li>
          <li>
            <small>
              CONS{": "}
              {loaded.consulente}
            </small>
          </li>
          <li>
            <small>{loaded.descrizione}</small>
          </li>
          <li>
            <small>{loaded.praticaId}</small>
          </li>
        </ul>
      </div>
    );
    notifyPresaInCarico(Msg);
  };

  const notificaAssegnazione = (loaded) => {
    console.log("chiamo ilbadeg", loaded);
    const Msg = ({ closeToast }) => (
      <div>
        <small>so iooooo</small>
        <small>{loaded.descrizione}</small>
        <br />
        <small>
          {loaded.cognome} {loaded.nome}
        </small>
        <br />
        <small>{loaded.tipo} </small>
      </div>
    );

    /*
  {
    "_id":"621bf2599812dce315063ed6",
    "cognome":"Di Giacomo",
    "nome":"Martina",
    "email":"guidonia414@gmail.com",
    "role":["ROLE_OPERATORE"],
    "date":"23/5/2022, 14:20:31",
    "praticaId":"6246fcf946c51657d3db5df2"}
  
  */

    //  notify(loaded);
    // notify3(loaded);
    notifyPresaInCarico(Msg);
    /*
  toast(loaded ,{
    className: "error-toast",
    draggable: true,
    position: toast.POSITION.TOP_CENTER
  })
*/
  };

  const notifyNuovo = (ye) => {
    toast.dismiss();

    toast(
      <ul style={{ listStyleType: "none", fontWeight: "smaller" }}>
        <li>
          <small>Assegnazione! </small>
          <small>
            {" "}
            Operatore{": "}
            {ye.nome} {ye.cognome}
          </small>
        </li>
        <li>
          <small>
            ASS{": "}
            {ye.assistito}
          </small>
        </li>
        <li>
          <small>
            CONS{": "}
            {ye.consulente}
          </small>
        </li>
        <li>
          <small>descr.:{ye.descrizione}</small>
        </li>
        <li>
          <small>_id: {ye.praticaId}</small>
        </li>
      </ul>,
      options
    );
  };

  return (
    <main>
      {/* 
        <div>
        <ToastContainer 
        newestOnTop
        />
      </div>
*/}

      <div
        className="boh"
        style={{ backgroundColor: "trasparent", height: "35px" }}
      >
        <div style={{ padding: "8px", textAlign: "center", color: "white" }}>
          Gestione pratiche
        </div>
      </div>

      <Nav
        justify
        variant="tabs"
        defaultActiveKey="link-0"
        activeKey={active}
        onSelect={(selectedKey) => setActive(selectedKey)}
        style={{ paddingTop: 5 }}
      >
        <Nav.Item>
          <NavLink
            eventkey="link-1"
            to="/operatore/pratiche_inviate"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Inviate
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {inviate}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-3"
            to="/operatore/pratiche_lavorazione"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Lavorazione
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {lavorazione}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-0"
            to="/operatore/pratiche_generali"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Generale
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {generali}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-4"
            to="/operatore/pratiche_sospese"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Sospese
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {sospese}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-5"
            to="/operatore/pratiche_chiuse"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Chiuse
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {chiuse}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-6"
            to="/operatore/pratiche_annullate"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Annullate
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {annullate}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-9"
            to="/operatore/archivio_pratiche_operatore"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Archivio
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            <i className="bi bi-archive"></i>
          </Badge>
        </Nav.Item>
      </Nav>

      {/*
<Navbar className='navbar navbar-expand-lg navbar-dark bg-dark'>
<Container>
  <Navbar.Brand href="#home">Pratiche</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="me-auto">
    <NavLink to="/" className={'unselected'}>Public </NavLink>
    <NavLink to="/operatore/pratiche_inviate" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}> Inviate  <Badge pill bg="danger">  { inviate }  </Badge>{' '}</NavLink>
    <NavLink to="/operatore/pratiche_lavorazione" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}>In Lavorazione <Badge pill bg="danger">
    { lavorazione }
  </Badge>{' '}</NavLink>

  <NavLink to="/operatore/pratiche_generali" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}>In Lavorazione Generale <Badge pill bg="danger">
  { generali }
</Badge>{' '}
</NavLink>

<NavLink to="/operatore/pratiche_sospese" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}> Sospese <Badge pill bg="danger">
{ sospese }
</Badge>{' '}</NavLink>

<NavLink to="/operatore/pratiche_chiuse" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}> Chiuse <Badge pill bg="danger">
{ chiuse }
</Badge>{' '}</NavLink>

<NavLink to="/operatore/pratiche_annullate" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}> Annullate <Badge pill bg="danger">
{ annullate }
</Badge>{' '}</NavLink>

    </Nav>
  </Navbar.Collapse>
  <Navbar.Collapse className="justify-content-end">

</Navbar.Collapse>
</Container>
</Navbar>  */}
    </main>
  );
}
