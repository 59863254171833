import { Button, Container, Form } from "react-bootstrap";
import client from "../utils/client";
import Swal from "sweetalert2";
import React, { useEffect, useState } from 'react';
import logoNegativo from '../CDLlogoNegativo.svg';

const BannerHandler = () => {

    const [bannerMessage, setBannerMessage] = useState("")

    useEffect(() => {
        const getBannerMessage = async () => {
            await client({
                url: `/banner`,
                config: { method: "GET" },
            })
                .then(function (response) {
                    if (response.data.length)
                        setBannerMessage(response.data[0].message)
                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        getBannerMessage()
    }, [])



    const saveBanner = () => {
        client({
            url: `/banner`,
            config: { method: "POST", data: { message: bannerMessage } },
        })
            .then(function (response) {
                Swal.fire(`${response.data}`);
            })
            .catch(function (error) {
                Swal.fire(`C'è stato un errore durante la creazione del banner`);
            });
    }

    const deleteBanner = () => {
        client({
            url: `/banner`,
            config: { method: "DELETE" },
        })
            .then(function (response) {
                Swal.fire(`Banner eliminato con successo`);
                setBannerMessage("")
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    return (
        <>
            <div
                className="logo"
                style={{ backgroundColor: "#0066cc", height: "160px" }}
            >
                <div style={{ padding: "8px", marginLeft: "16%" }}>
                    <img src={logoNegativo} alt="Logo" style={{ width: "274px" }} />
                </div>
            </div>
            <Container>
                <div style={{ paddingTop: "20px" }}>
                    <Button onClick={saveBanner}>Salva Banner</Button>
                    <Button onClick={deleteBanner} variant="danger" style={{ marginLeft: "20px" }}>Elimina Banner</Button>
                    <Form.Control
                        style={{ marginTop: "20px", height: '100px' }}
                        as="textarea"
                        value={bannerMessage}
                        onChange={(e) => setBannerMessage(e.target.value)}
                    />
                </div>
            </Container>
        </>

    )
}

export default BannerHandler;