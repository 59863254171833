// TableContainer.js
import React, { Fragment } from "react"
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, useExpanded, usePagination } from "react-table"
import { Table, Row, Col, Button, Form } from 'react-bootstrap';
//import { Table, Row, Col, Button, Input } from "reactstrap"
//import "bootstrap/dist/css/bootstrap.min.css"
import * as matchSorter from 'match-sorter';
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      style={{
        maxWidth: '180px',
        /*  background: '#212529', 
          color: 'wheat',
          borderColor: 'wheat',*/
        border: '1px solid',
        marginBottom: '8px',
        fontSize: 'small',
      }}
      onClick={e => e.preventDefault()}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Cerca qui ${count} records...`}
    />
  )
}


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val



const UsersTableContainer = ({ columns, data, renderRowSubComponent }) => {



  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    page,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { /*expanded,*/ pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 50 }
    },
    useFilters,
    //useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    usePagination
  )
  const generateSortingIndicator = column => {
    //   console.log(getTableProps());
    return column.isSorted ? (column.isSortedDesc ? " ⇩ " : " ⇧") : ""
  }

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (

    <>
      {/*
    <pre>
      <code>
        {JSON.stringify(
          {
            pageIndex,
            pageSize,
            pageCount,
            canNextPage,
            canPreviousPage,
          },
          null,
          2
        )}
      </code>
    </pre> */}

      <Table bordered striped variant="light" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                  {column.render("Header")}
                  {generateSortingIndicator(column)}

                </th>
              ))}
            </tr>
          ))}
          {/*    <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />

            </th>
          </tr> */}
        </thead>

        <tbody {...getTableBodyProps()} >

          {page.map((row, i) => {
            prepareRow(row)
            return (

              <Fragment key={row.getRowProps().key}>

                <tr >
                  {row.cells.map(cell => {
                    return <td style={{ fontWeight: '200', fontSize: 'smaller' }} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</td>
                  </tr>
                )}


              </Fragment>


            )
          })}
        </tbody>
      </Table>
      {/*
      <pre>
      <code>{JSON.stringify({ expanded: expanded }, null, 2)}</code>
    </pre>  */}

      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button size="sm"
            style={{ paddingLeft: '5px' }}
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button size="sm"
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7, color: '#767778', fontSize: 'Smaller' }}>
          Pagina{" "}
          <small>
            {pageIndex + 1} di {pageOptions.length}
          </small>
        </Col>
        <Col md={2}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput} />

          </Form.Group>


        </Col>

        <Col md={3}>
          <Button size="sm" color="primary" onClick={nextPage} disabled={!canNextPage}>
            <strong>  {">"} </strong>
          </Button>
          <Button size="sm"
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <strong> {">>"} </strong>
          </Button>
        </Col>
      </Row>

    </>
  )
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

export default UsersTableContainer
