import axios from "axios";
import Endpoints from "../components/services/Endpoints";

const ENDPOINT = Endpoints.getUrls();

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("currentUser"));

  const isDemoUser = user && user.email === "demo@cdlservizipatronato.it";

  /**
   * Perform login and store user data in local storage
   *
   * @param {string} email
   * @param {string} password
   * @returns
   */
  const login = async (email, password) => {
    const response = await axios.post(
      `${ENDPOINT}/login/`,
      {
        email,
        password,
        grant_type: "password",
      },
      {
        header: { "Content-Type": "application/json" },
      }
    );

    console.log("response");
    console.log(response.data.authuser);

    if (response) {
      const authUser = response.data.authuser;

      const tokenObj = {
        _id: authUser.id,
        cognome: authUser.cognome,
        nome: authUser.nome,
        email: authUser.email,
        cellulare: authUser.cellulare,
        telefono: authUser.telefono,
        password: authUser.password,
        role: authUser.roles,
        token: authUser.accessToken,
        subscription: authUser.subscription,
        created_at: authUser.creato,
        freeAccount: authUser.freeAccount
      };

      localStorage.setItem("currentUser", JSON.stringify(tokenObj));

      return tokenObj;
    }

    return null;
  };

  return { login, user, isDemoUser };
};

export default useAuth;
