import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormControl,
  ProgressBar,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "bootstrap/dist/css/bootstrap.min.css";
var CodiceFiscale = require("codice-fiscale-js");

const ENDPOINT = process.env.REACT_APP_BACKEND_URL;
const MySwal = withReactContent(Swal);

const UpdateMiddleAccount = (props) => {
  const navigate = useNavigate();
  let params = useLocation();

  console.log("params");
  console.log(params);

  const [data, setData] = useState({});
  const [verificato, setVerificato] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + "/users/teleconsul/fetch/", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ subid: params.state.subid }),
    })
      .then((response) => response.json())
      .then((data) => setData(data.authuser));
  }, []); // Or [] if effect doesn't need props or state

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let newUserObj = {
        _id: params.state._id,
        nome: event.target.form.nome.value,
        cognome: event.target.form.cognome.value,
        codice_fiscale: event.target.form.codice_fiscale.value,
        partita_iva: event.target.form.partita_iva.value,
        ragione_sociale: event.target.form.ragione_sociale.value,
        cellulare: event.target.form.cellulare.value,
        telefono: event.target.form.telefono.value,
        consenso: "si",
        email: event.target.form.email.value,
        pec: event.target.form.pec.value,
        fax: event.target.form.fax.value,
        ritratto: "",
        indirizzo: {
          via: event.target.form.via.value,
          cap: event.target.form.cap.value,
          localita_esercizio: event.target.form.localita_esercizio.value,
          comune: event.target.form.comune.value,
          provincia: event.target.form.provincia.value,
          regione: event.target.form.regione.value,
          citta: event.target.form.localita_esercizio.value,
        },
        collaboratore: {
          nome: event.target.form.cv_nome.value,
          cognome: event.target.form.cv_cognome.value,
          codice_fiscale: event.target.form.cv_codice_fiscale.value,
        },
        localita_esercizio: event.target.form.localita_esercizio.value,
      };

      console.log(newUserObj);

      if (newUserObj.nome) {
        axios
          .post(`${ENDPOINT}/users/teleconsul/updatemiddle/`, newUserObj)
          .then(function (response) {
            if (response.data.update == "1") {
              async function fetchData() {
                // You can await here
                let response = await fetch(
                  process.env.REACT_APP_BACKEND_URL +
                  "/users/teleconsul/fetch/",
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json, text/plain, */*",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ subid: params.state.subid }),
                  }
                );
                response = await response.json();
                if (response) {
                  console.log(response);
                  //  setData(response.authuser);
                  let info = response.authuser;

                  let tokenObj = {
                    _id: params.state._id,
                    nome: info.nome,
                    cognome: info.cognome,
                    email: info.email,
                    cellulare: info.cellulare,
                    telefono: info.telefono,
                    //   password: risposta.password,
                    role: info.roles,
                    token: info.accessToken,
                    subscription: info.subscription,
                    created_at: info.creato,
                    freeAccount: info.freeAccount
                  };
                  localStorage.setItem("currentUser", JSON.stringify(tokenObj));

                  Swal.fire({
                    title: "Updates",
                    text: `Account modificato con successo!`,
                    icon: "info",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok!",
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      //  event.target.form.reset();
                      navigate("/consulente/tipo_pratica");
                    }
                  });
                }

                // ...
              }
              fetchData();
            } else {
              Swal.fire({
                title: "Updates",
                text: `Impossibile procedere: errore!`,
                icon: "error",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok!",
                footer: `<b>Teleconsul<b/>`,
              }).then((result) => {
                if (result.isConfirmed) {
                  //  event.target.form.reset();
                  navigate("/");
                }
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      event.preventDefault();

      //    setValidated(true);
    }
  };

  const verificaCF = (event) => {
    verificaCFx(event.target.value);
  };

  function verificaCFx(cf) {
    if (CodiceFiscale.check(cf)) {
      console.log("corretto");
      setVerificato(true);
    } else {
      console.log("errato");
      setVerificato(false);
    }
  }

  return (
    <div className="login-page">
      <small></small>

      <div className="container">
        {data ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h3 className="mb-3 text-white"> </h3>
              <div
                className="bg-white shadow rounded"
                style={{
                  position: "relative",
                  top: "50%",
                  WebkitTransform: "translateY(-50%)",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <div className="row">
                  <div className="col-md-9 pe-0">
                    <div className="form-left h-100 py-5 px-5">
                      {/* FORM */}
                      <Form
                        className="row g-3 needs-validation"
                        novalidate
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        style={{ color: "darkgrey" }}
                      >
                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="Nome">
                              Nome <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="Nome"
                              name="nome"
                              type="text"
                              defaultValue={data.nome}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="Cognome">
                              Cognome <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="Cognome"
                              name="cognome"
                              type="text"
                              defaultValue={data.cognome}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="Ragione Sociale">
                              Ragione Sociale
                            </Form.Label>
                            <Form.Control
                              placeholder="Ragione Sociale"
                              name="ragione_sociale"
                              required
                              type="text"
                              defaultValue={data.ragione_sociale}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="email">
                              Email <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="email"
                              name="email"
                              style={{ textTransform: "lowercase" }}
                              defaultValue={data.email}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="cellulare">
                              Cellulare
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cellulare"
                              name="cellulare"
                              defaultValue={data.cellulare}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="telefono">Telefono</Form.Label>
                            <Form.Control
                              required
                              placeholder="telefono"
                              name="telefono"
                              defaultValue={data.telefono}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="fax">Fax</Form.Label>
                            <Form.Control
                              placeholder="fax"
                              name="fax"
                              type="text"
                              defaultValue={data.fax}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Pec
                            </Form.Label>
                            <Form.Control
                              placeholder="PEC"
                              name="pec"
                              type="text"
                              style={{ textTransform: "lowercase" }}
                              defaultValue={data.pec}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Codice Fiscale{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="Codice Fiscale"
                              name="codice_fiscale"
                              defaultValue={data.codice_fiscale}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              P. I.
                            </Form.Label>
                            <Form.Control
                              placeholder="Partita IVA"
                              name="partita_iva"
                              defaultValue={data.partita_iva}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mb-2 mt-2">
                            <Form.Label htmlFor="disabledTextInput">
                              Indirizzo
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="via"
                              name="via"
                              defaultValue={data.indirizzo?.via}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              CAP
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cap"
                              name="cap"
                              defaultValue={data.indirizzo?.cap}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Comune
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="comune"
                              name="comune"
                              defaultValue={data.indirizzo?.comune}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Prov. (Sigla)
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="esempio: MI"
                              name="provincia"
                              type="text"
                              maxLength="2"
                              style={{ textTransform: "uppercase" }}
                              defaultValue={data.indirizzo?.provincia}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Regione
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="regione"
                              name="regione"
                              defaultValue={data.indirizzo?.regione}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Città
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="citta"
                              name="citta"
                              defaultValue={data.indirizzo?.localita_esercizio}
                              type="text"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Località esercizio
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="localita_esercizio"
                              name="localita_esercizio"
                              defaultValue={data.localita_esercizio}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2 mt-2">
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Cognome
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cv_cognome"
                              name="cv_cognome"
                              defaultValue={data.collaboratore?.cognome}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="disabledTextInput">
                              Nome
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="cv_nome"
                              name="cv_nome"
                              defaultValue={data.collaboratore?.nome}
                              type="text"
                            />
                          </Col>
                        </Row>

                        <Row
                          className="mb-2 px-4 py-4 row"
                          style={{ background: "#009345", borderRadius: "6px" }}
                        >
                          <Col>
                            <Form.Label
                              htmlFor="disabledTextInput"
                              style={{ color: "white" }}
                            >
                              Codice Fiscale
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="codice fiscale del collaboratore"
                              name="cv_codice_fiscale"
                              type="text"
                              defaultValue={data.collaboratore?.codice_fiscale}
                              onChange={verificaCF}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3 mt-3">
                          <Col>
                            <div>
                              <Button
                                type="submit"
                                variant="primary"
                                style={{ float: "left" }}
                                onClick={handleSubmit}
                              >
                                Aggiorna dati utente
                              </Button>{" "}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-3 ps-0 d-none d-md-block">
                    <div className="form-right h-100 bg-primary text-white text-center pt-5">
                      <p></p>
                      <div className="row">
                        <h3 className="mb-3 text-white">Aggiornamento dati:</h3>

                        <h5 className="mb-3 text-white">
                          {" "}
                          I TUOI DATI SONO INCOMPLETI: COMPILA TUTTI I CAMPI PER
                          REGOLARIZZARE
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-end text-secondary mt-3">
                Bootstrap 5 Login Page Design
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UpdateMiddleAccount;
