import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";

import "./Usersignin.css";
import LogoNegativo from "../../CDLlogoNegativo.svg";

import client from "../../utils/client";
import Swal from "sweetalert2";

import useAuth from "../../hooks/useAuth";

const showSubscriptionBanner = async () => {
  const user =
    localStorage.getItem("currentUser") &&
    JSON.parse(localStorage.getItem("currentUser"));

  if (!user) return null;

  /**
   * Check if the user has a subscription and it's expired
   * @returns {boolean} true if the user has a subscription and it's expired
   */
  const isSubscriptionExpired = () =>
    user.subscription && user.subscription.status === "inactive";

  /**
   * Check if the user has a free plan
   * @returns {boolean} true if the user has a free plan
   */
  const isFreeAccountUser = () => user.freeAccount;

  if (isSubscriptionExpired() && !isFreeAccountUser())
    Swal.fire(
      "Info",
      "Gentile Associato, ti ricordiamo che a partire dal 1 Febbraio 2023 sarà introdotta, per aderire ai Servizi di Patronato del Caf CdL, una quota associativa annua pari ad € 240,00. La modalità di versamento della quota associativa verrà gestita in modo completamente automatizzato, attraverso l’utilizzo di un modulo e-commerce. Il modulo facile e intuitivo ti guiderà dal pagamento, che potrà essere effettuato tramite i metodi tradizionali: PayPal, Carta di Credito o Bonifico Bancario*, fino al rilascio della ricevuta. Una volta effettuato il pagamento le tue credenziali saranno attive per 12 mesi. Buon lavoro. * Con il pagamento tramite Bonifico Bancario le credenziali saranno attivate solo dopo il riscontro dell’accredito, per le altre forme di pagamento non sono previste attese, l’attivazione sarà immediata."
    );
};

const showBannerMessage = async () => {
  client({
    url: `/banner`,
    config: { method: "GET" },
  })
    .then(function (response) {
      if (response.data.length)
        Swal.fire("Info", response.data[0].message).then(() =>
          showSubscriptionBanner()
        );
      else showSubscriptionBanner();
    })
    .catch(function (error) {
      Swal.fire("Error", "an error occurred while retriveing banner");
    });
};

const Usersignin = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [userNotFound, setUserNotFound] = useState(false);
  const [passwordErrata, setPasswordErrata] = useState(false);
  const [subscriptionExpiredError, setSubscriptionExpiredError] =
    useState(false);

  const { login } = useAuth();

  const sendPostRequest = async () => {
    try {
      const user = await login(email, password);

      if (user) {
        if (user.role[0] === "ROLE_OPERATORE") {
          navigate("/operatore", { replace: true });
        }
        if (user.role[0] === "ROLE_ADMIN") {
          navigate("/admin", { replace: true });
        }
        if (user.role[0] === "ROLE_CONSULENTE") {
          navigate("/consulente/tipo_pratica", { replace: true });
        }
        if (!user) {
          navigate("/", { replace: true });
        }

        if (
          user.role.includes("ROLE_CONSULENTE") ||
          user.role.includes("ROLE_OPERATORE")
        ) {
          await showBannerMessage();
        }
      }
    } catch (err) {
      if (err.response.data.msg === "User not exist") {
        setUserNotFound(true);
      }

      if (err.response.data.msg === "Invalid credencial") {
        setPasswordErrata(true);
      }

      if (err.response.data.msg === "Subscription expired") {
        setSubscriptionExpiredError(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    setUserNotFound(false);
    setPasswordErrata(false);
    setSubscriptionExpiredError(false);

    sendPostRequest();

    e.preventDefault();
  };

  return (
    <div className="login-page bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h3 className="mb-3 text-white"> </h3>
            <div
              className="bg-white shadow rounded"
              style={{
                position: "relative",
                top: "50%",
                WebkitTransform: "translateY(-50%)",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)",
              }}
            >
              <div className="row">
                <div className="col-md-7 pe-0">
                  <div className="form-left h-100 py-5 px-5">
                    <form onSubmit={handleSubmit} className="row g-4">
                      <div className="col-12">
                        <label>
                          Email<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-envelope-fill"></i>
                          </div>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Inserisci Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {userNotFound ? (
                            <label
                              htmlFor="usurnotfound"
                              style={{ color: "red", padding: "5px" }}
                            >
                              Utente non trovato
                            </label>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12">
                        <label>
                          Password<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-lock-fill"></i>
                          </div>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Inserisci Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {passwordErrata ? (
                            <label
                              htmlFor="password errata"
                              style={{ color: "red", padding: "5px" }}
                            >
                              password errata
                            </label>
                          ) : null}
                          {subscriptionExpiredError && (
                            <label style={{ color: "red", padding: "5px" }}>
                              Per attivare le tue credenziali{" "}
                              <a href="https://storecdlservizipatronato.it">
                                Clicca qui
                              </a>
                            </label>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineFormCheck"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineFormCheck"
                          >
                            Ricordami
                          </label>
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary px-4 float-end mt-4"
                        >
                          login
                        </button>
                      </div>
                    </form>
                    <div className="row" style={{ paddingTop: "30px" }}>
                      <div className="col-sm-6">
                        <Link
                          to="/userforgot"
                          alt="forgot"
                          style={{ float: "left" }}
                        >
                          Reset password
                        </Link>
                      </div>

                      <div className="col-sm-6">
                        <Link
                          to="/usersignup"
                          alt="forgot"
                          style={{ float: "right" }}
                        >
                          Nuovo utente? iscriviti
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ps-0 d-none d-md-block">
                  <div className="form-right h-100 bg-primary text-white text-center pt-5">
                    <img src={LogoNegativo} width={300} alt="Logo"></img>
                    <p></p>
                    <h3 className="fs-1">Bentornato</h3>

                    <h3 className="mb-3 text-white">Esegui l'accesso</h3>
                    <div className="col-12">
                      {/*   <button type="button" className="btn btn-primary px-4 float-end mt-4" onClick={tcDeskLoginPage}>TCDesk</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-end text-secondary mt-3">
              Bootstrap 5 Login Page Design
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usersignin;
