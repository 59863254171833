import React, { useState, useEffect, useMemo } from "react";
import TableContainer from "./TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Table,
  Button,
  Accordion,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import * as Loader from "react-loader-spinner";
import Endpoints from "../components/services/Endpoints";
import axios from "axios";
import Footer from "../components/Footer/Footer";

import client from "../utils/client";
import moment from "moment";

const ENDPOINT = Endpoints.getUrls();

const PraticheAnnullate = () => {
  const [data, setData] = useState([]);
  const [statoPratica, setStatoPratica] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [progress, setProgress] = useState(0);
  // PER CAMBIOSTATO DELLA PRATICA
  const [ricevuta, setRicevuta] = useState([]);
  const [note, setNote] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [findDescrizione, setFindDescrizione] = useState("");
  const [findRegione, setFindRegione] = useState("");
  const [findId, setFindId] = useState("");
  const [findConsulenteCognome, setFindConsulenteCognome] = useState("");
  const [findAssistitoCognome, setFindAssistitoCognome] = useState("");
  const [findAssistitoCF, setFindAssistitoCF] = useState("");
  const [findOperatoreCognome, setFindOperatoreCognome] = useState("");

  const [findFrom, setFindFrom] = useState("");
  const [findTo, setFindTo] = useState("");

  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchPraticheAll();
    fetchPraticheCount();
  }, []);

  const fetchPraticheAll = () => {
    const doFetch = async () => {
      setSpinnerLoading(true);

      const response = await client({
        url: `/pratiche/annullate`,
        config: { method: "GET" },
      });
      const pratiche = response.data;

      setData(pratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  };

  const fetchPraticheCount = async () => {
    const op = JSON.parse(localStorage.getItem("currentUser"));
    const id = op._id;
    const result = await client({
      url: `/pratiche/count/inviate/id?_id=${id}`,
      config: { method: "GET" },
    });
    setCount(result.data.annullate);
  };

  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
  };

  const handleSelect = (event) => {
    setStatoPratica(event.target.value);
    setIsVisible(true);

    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let aggiornaObj = {
      _id: selectedId,
      statoPratica: statoPratica,
      ricevuta: ricevuta,
      note: note,
    };
    console.log(aggiornaObj);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${ENDPOINT}/pratiche/cambiostato/`, aggiornaObj, {
        headers: headers,
      })
      .then((response) => {
        // then print response status
        console.log(response);

        let updTbl = {
          praticaId: selectedId,
        };

        renewTable(updTbl);
      });

    setNote("");
  };

  const selectionFiles = (event) => {
    setSelectedFile(event.target.files);
    setProgressInfos([]);
  };

  const onClickHandler = () => {
    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("rcvCollection", selectedFile[x]);
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${ENDPOINT}/ricevute`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        // then print response status
        console.log(response);
        setFileInfos(response.data);

        let ricevutaArray = [];

        for (let i = 0; i < response.data.length; i++) {
          ricevutaArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString(),
          });
        }
        setRicevuta(ricevutaArray);
      });
  };

  const BasicExample = () => {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <i className="bi bi-binoculars"></i> Cerca sul server{" "}
          </Accordion.Header>
          <Accordion.Body style={{ background: "aliceblue" }}>
            <p style={{ fontWeigth: "medium", color: "darkred" }}>
              Il campo ID va utilizzato solo e non in combo con altri campi
            </p>
            {BasicForm()}
            {data.length && (
              <p style={{ fontWeigth: "medium", marginTop: "20px" }}>
                Cerca tra {count} risultati
              </p>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const FindOnServer = (event) => {
    event.preventDefault();

    if (event.target.name === "find_id") {
      setFindId(event.currentTarget.value);
    }

    if (event.target.name === "find_descrizione") {
      setFindDescrizione(event.currentTarget.value);
    }

    if (event.target.name === "find_regione") {
      setFindRegione(event.currentTarget.value);
    }

    if (event.target.name === "find_consulente_cognome") {
      setFindConsulenteCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cognome") {
      setFindAssistitoCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_assistito_cf") {
      setFindAssistitoCF(event.currentTarget.value);
    }

    if (event.target.name === "find_operatore_cognome") {
      setFindOperatoreCognome(event.currentTarget.value);
    }

    if (event.target.name === "find_from") {
      setFindFrom(event.currentTarget.value);
    }
    if (event.target.name === "find_to") {
      setFindTo(event.currentTarget.value);
    }
  };

  const allFieldAreEmpty = () => {
    return (
      !findId &&
      !findDescrizione &&
      !findRegione &&
      !findConsulenteCognome &&
      !findAssistitoCognome &&
      !findAssistitoCF &&
      !findOperatoreCognome &&
      !findFrom &&
      !findTo
    );
  };

  const ClearAll = () => {
    setFindId("");
    setFindDescrizione("");
    setFindRegione("");
    setFindConsulenteCognome("");
    setFindAssistitoCognome("");
    setFindAssistitoCF("");
    setFindOperatoreCognome("");
    setFindFrom("");
    setFindTo("");
    fetchPraticheAll();
    fetchPraticheCount();
  };

  const findValueOnServer = () => {
    setSpinnerLoading(true);

    if (allFieldAreEmpty()) {
      fetchPraticheAll();
      fetchPraticheCount();
      return;
    }

    const asyncPostCall = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/pratiche/find/annullate/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // your expected POST request payload goes here
            numberId: findId ? Number(findId) : "",
            descrizione: findDescrizione,
            regione: findRegione,
            consulenteCognome: findConsulenteCognome,
            assistitoCognome: findAssistitoCognome,
            operatoreCognome: findOperatoreCognome,
            assistitoCF: findAssistitoCF,
            findFrom: findFrom,
            findTo: new Date(findTo).setUTCHours(23, 59, 59, 999),
          }),
        });
        const data = await response.json();
        // enter you logic when the fetch is successful
        console.log(data);
        setData(data);
        setCount(data.length);
        setSpinnerLoading(false);
      } catch (error) {
        // enter your logic for when there is an error (ex. error toast)
        setSpinnerLoading(false);
        console.log(error);
      }
    };

    asyncPostCall();
  };

  const BasicForm = () => {
    return (
      <>
        <Form>
          <Row>
            <Col>
              <Form.Control
                placeholder="_id"
                name="find_id"
                type="number"
                value={findId}
                onChange={FindOnServer}
                style={{ border: "solid 1px red" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Descrizione"
                name="find_descrizione"
                value={findDescrizione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Regione"
                name="find_regione"
                value={findRegione}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Assistito cognome"
                name="find_assistito_cognome"
                value={findAssistitoCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Consulente cognome"
                name="find_consulente_cognome"
                value={findConsulenteCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Assistito C.F."
                name="find_assistito_cf"
                value={findAssistitoCF}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Operatore cognome"
                name="find_operatore_cognome"
                value={findOperatoreCognome}
                onChange={FindOnServer}
                style={{ border: "solid 1px darkturquoise" }}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Da"
                name="find_from"
                type="date"
                value={findFrom}
                onChange={FindOnServer}
                style={{ border: "solid 1px teal" }}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Da"
                name="find_to"
                type="date"
                value={findTo}
                onChange={FindOnServer}
                style={{ border: "solid 1px teal" }}
              />
            </Col>
            <Col>
              <Button variant="primary" onClick={findValueOnServer}>
                Cerca
              </Button>
            </Col>
            <Col>
              <Button variant="primary" onClick={ClearAll}>
                Pulisci tutto
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;

    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    const RenderAllegati = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead style={{ color: "rgb(131 135 140)" }}>
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(131 135 140)" }}>
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead style={{ color: "rgb(131 135 140)" }}>
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(131 135 140)" }}>
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path}`}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead style={{ color: "rgb(131 135 140)" }}>
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(131 135 140)" }}>
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead style={{ color: "rgb(131 135 140)" }}>
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(131 135 140)" }}>
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };
    const RenderStoricoOperatore = () => {
      return (
        <Table bordered style={{ borderColor: "#5e5c59" }}>
          <thead style={{ color: "rgb(131 135 140)" }}>
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(131 135 140)" }}>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    let statiPossibili = [
      "Lavorazione",
      "Chiusa",
      "In_lavorazione_generale",
      "Sospesa",
      "Annullata",
    ];

    return (
      <>
        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Consulente</div>
            <div className="col-4">
              <small style={{ color: "rgb(131 135 140)" }}>Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i
                className="bi bi-envelope"
                style={{ color: "rgb(131 135 140)" }}
              ></i>
              <small style={{ color: "rgb(131 135 140)" }}> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i
                className="bi bi-phone"
                style={{ color: "rgb(131 135 140)" }}
              ></i>
              <small style={{ color: "rgb(131 135 140)" }}> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i
                className="bi bi-telephone"
                style={{ color: "rgb(131 135 140)" }}
              ></i>
              <small style={{ color: "rgb(131 135 140)" }}> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="tipo-pratica"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Tipo Pratica</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>
                tipo pratica:{" "}
              </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="assistito"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Assistito</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>
                Tipo assistito:{" "}
              </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="collaboratore"
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> collaboratore volontario</div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small style={{ color: "rgb(131 135 140)" }}>C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container pratica">
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Stato Pratica</div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>
            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
            <div className="col">
              <small className="label-pratica">
                cambia lo stato pratica qui:{" "}
              </small>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <select
                  defaultValue={statoPratica}
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleSelect}
                >
                  {statiPossibili.map((item, i) => {
                    return (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>

                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + "%" }}
                  >
                    {progress}%
                  </div>
                </div>
                {isVisible ? (
                  <div className="edit">
                    <div className="col">
                      {fileInfos &&
                        fileInfos.map((fileInfo, index) => (
                          <div className="mb-2" key={index}>
                            <span>{fileInfo.path}</span>
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-info"
                                role="progressbar"
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                              >
                                {progress}%
                              </div>
                            </div>
                          </div>
                        ))}

                      <small className="label-pratica">
                        carica la ricevuta{" "}
                      </small>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          name="files"
                          multiple
                          onChange={selectionFiles}
                          className="form-control"
                          id="inputGroupFile02"
                        />
                        <button
                          className="btn btn-outline-secondary"
                          disabled={!selectedFiles}
                          onClick={onClickHandler}
                          type="button"
                          id="inputGroupFileAddon04"
                        >
                          Carica i files!
                        </button>
                      </div>
                    </div>

                    <div className="col">
                      <ul>
                        {fileInfos.map((el, i) => {
                          return (
                            <li key={i}>
                              <a
                                href={`${ENDPOINT}/static/allegati/${el.path}`}
                              >
                                <i
                                  className="bi bi-file-pdf"
                                  style={{ color: "red" }}
                                ></i>
                                {el.filename}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="col">
                      <small className="label-pratica">aggiungi note </small>
                      <div className="input-group">
                        <span className="input-group-text">Area di testo</span>
                        <textarea
                          className="form-control"
                          aria-label="Area di testo"
                          name="note"
                          onChange={(e) => setNote(e.target.value)}
                          defaultValue={""}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-outline-secondary"
                        disabled={!selectedFiles}
                        onClick={handleSubmit}
                        type="button"
                        id="inputGroupFileAddon088"
                      >
                        Cambia lo stato pratica!
                      </button>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "#f0f2f4",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
        >
          <div className="row">
            <div style={{ fontWeight: "bold" }}> Note</div>
            <div className="col">
              <small className="label-pratica">Note: </small>
              {`${note} `}
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
        >
          <div className="row">
            <div>
              {" "}
              Documenti allegati <i className="bi bi-paperclip"></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Ricevute <i className="bi bi-file-earmark-arrow-down"></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div
          className="container pratica"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico pratica <i className="bi bi-file-earmark-text"></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div
          className="container pratica"
          style={{
            paddingBottom: "1rem",
            paddingTop: "1rem",
            background: "rgb(222 226 230 / 33%)",
            marginBottom: "7px",
            borderBottom: "1px solid #5e5c59",
          }}
          id="storico-pratica"
        >
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-file-earmark-break"></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <i className="bi bi-caret-right-fill"></i>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "CF. Assistito",
        accessor: (d) =>
          d.assistito.codice_fiscale_assistito
            ? d.assistito.codice_fiscale_assistito
            : undefined,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Operatore",
        accessor: (c) =>
          `${c.operatore[c.operatore.length - 1].nome} ${
            c.operatore[c.operatore.length - 1].cognome
          }`,
      },

      {
        Header: "Stato",
        accessor: "statoPratica",
        Cell: (props) => (
          <span style={{ color: "red", fontWeight: "bolder" }}>
            {props.value} <i className="bi bi-x-circle"></i>
          </span>
        ),
      },
      {
        Header: "Annullata il",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(
            d.storico_pratica[d.storico_pratica.length - 1].data,
            "DD/MM/YYYY, HH:mm:ss"
          ).format("DD/MM/YYYY, HH:mm")}`,
        // accessor: "data_scadenza",
      },
      /*  {
            Header: "Action",
            id: "id",
           Cell: props => <Button className="btn btn-primary btn-sm" onClick={() => handleShow(props)}> <i className="bi bi-archive"></i> Prendi tu!</Button>
          }, */
    ],
    []
  );

  return (
    <>
      <div
        className="test"
        style={{ paddingBottom: "100px", backgroundColor: "#dee2e6" }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? (
            <Loader.ThreeDots color="white" height={100} width={100} />
          ) : null}
        </div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          {/*   <RenderRoaster /> */}
          {BasicExample()}
          <div className="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              type="annullate"
            />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PraticheAnnullate;
