import React, { useState, useEffect, useMemo } from "react";
import socketIOClient from "socket.io-client";
import TableContainer from "./TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Table, Button } from "react-bootstrap";
//import { Console } from 'jest-util';
import axios from "axios";
import * as Loader from "react-loader-spinner";
import Endpoints from "../components/services/Endpoints";
//import UploadRicevutaService from '../components/services/uploadRicevutaService';
import FileUpload from "./File_Upload_Component/file-upload.component";
import { useSpeechSynthesis } from "react-speech-kit";
import Footer from "../components/Footer/Footer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import UploadFiles from "./uploadAllegato";
import "./pratica.css";
import "../SlideDrawer/SlideDrawer.css";
import "../SlideDrawer/Backdrop.css";
import moment from "moment";

const MySwal = withReactContent(Swal);

const ENDPOINT = Endpoints.getUrls();
const ENDPOINTRICEVUTE = Endpoints.getRicevuteUrls();

const PraticheLavorazione = () => {
  const [data, setData] = useState([]);
  const [response, setResponse] = useState("");
  const [statoPratica, setStatoPratica] = useState("");
  const [isClosedShow, setIsClosedShow] = useState(false);
  const [isNotClosedShow, setIsNotClosedShow] = useState(false);
  const [showUploader, setShowUploader] = useState(false);

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [currentLoggedUser, setCurrentLoggedUser] = useState({});
  const [selectedConsulente, setSelectedConsulente] = useState({});
  //FileInfos anche se da warning che non è usato non è vero
  const [fileInfos, setFileInfos] = useState([]);
  const [progress, setProgress] = useState(0);
  const [ricevuta, setRicevuta] = useState([]);
  const [note, setNote] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedDescrizione, setSelectedDescrizione] = useState("");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [newRicevutaInfo, setNewRicevutaInfo] = useState({
    ricevutaFile: [],
  });

  const [messages, setMessages] = useState([]);

  // funzione per gestire l'array delle ricuvute
  const updateUploadedFiles = (files) => {
    setNewRicevutaInfo({ ...newRicevutaInfo, ricevutaFile: files });
  };

  const { speak } = useSpeechSynthesis();

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    socket.on("assegnazione", function (payload) {
      console.log("praticaPresa da: ", payload);
      //  renewTable(JSON.parse(payload));
    });

    socket.on("adminAction", function (payload) {
      console.log("pratica Assegnata ad: ", payload);
      //  renewTable(JSON.parse(payload));
    });

    socket.on("creazione", function (loaded) {
      console.log("nuova pratica inserita da: ", JSON.stringify(loaded));
      //  setRoasterText(JSON.stringify(loaded));
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      //    console.log("TEST-RoleOp: ", localStorage.getItem('currentUser'));
      const op = JSON.parse(localStorage.getItem("currentUser"));
      const id = op._id;
      setCurrentLoggedUser(op);
      const response = await fetch(
        `${ENDPOINT}/pratiche/lavorazione/id?_id=${id}`
      );
      const pratiche = await response.json();
      console.log(pratiche);
      setData(pratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.praticaId));
  };

  /*
    useEffect(async () => {

      const op = JSON.parse(localStorage.getItem('currentUser'));
       const id = op._id;

      const response = await axios.post(`${ENDPOINT}/direct/getmessages`, {
        from: id
      });
      console.log(response.data)
      setMessages(response.data);
    }, []);
*/

  /* NUOVO UPLOADER */
  const handleFiles = () => {
    setIsVisible(true);
    let selectedFile = newRicevutaInfo.ricevutaFile;

    console.log("Test Files");

    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("rcvCollection", selectedFile[x]);
    }
    const headers = { "Content-Type": "multipart/form-data" };

    // ATTENZIONE A DOVE CARICA
    axios
      .post(`${ENDPOINT}/ricevute`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        // then print response status
        console.log(response);
        setFileInfos(response.data);

        let ricevuteArray = [];

        for (let i = 0; i < response.data.length; i++) {
          ricevuteArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString(),
          });
        }

        setRicevuta(ricevuteArray);
      });
    //********************* */
  };

  //qui mi assegno la pratica
  const handleShow = (event) => {
    console.log(event.original._id);
    console.log("pratica_Id", event.values._id);
    setShowUploader(false);
    setFileInfos([]);
    setProgress(0);
    setRicevuta([]);
    console.log("empty arrays");
    setSelectedId(event.original._id);
    setSelectedConsulente(event.original.consulente);
    setSelectedDescrizione(event.original.descrizione);
  };

  const handleSelect = (event) => {
    setShowUploader(true);

    //alert('Your favorite flavor is: ' + event.target.value);
    if (event.target.value.toString() === "Chiusa") {
      alert("Stato pratica selezionato: " + event.target.value);
      setIsClosedShow(true);
      setStatoPratica(event.target.value);
    } else {
      setIsClosedShow(false);
    }

    if (event.target.value.toString() !== "Chiusa") {
      alert("Stato pratica selezionato: " + event.target.value);
      setIsNotClosedShow(true);
      setStatoPratica(event.target.value);
    } else {
      setIsNotClosedShow(false);
    }

    event.preventDefault();
  };
  /*
    const handleNote = (event) => {
      console.log(event.target.value)
      setNote(event.target.value);
     }
*/
  const handleSubmit = (event) => {
    event.preventDefault();
    let aggiornaObj = {
      _id: selectedId,
      statoPratica: statoPratica,
      ricevuta: ricevuta,
      note: note,
      storico_pratica: {
        stato: statoPratica,
        data: new Date().toLocaleString("it-IT"),
      },
      lastElem: {
        created: new Date(),
        status: statoPratica,
      },
    };
    console.log(aggiornaObj);

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${ENDPOINT}/pratiche/cambiostato/`, aggiornaObj, {
        headers: headers,
      })
      .then((response) => {
        // then print response status
        console.log(response);

        fetch(`${ENDPOINT}/pratiche/inserita/id?id=${aggiornaObj._id}`, {
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then((response) => response.json())
          .then((data) => notificaAlConsulente(data));

        // Importante qui resettare la ricevuta allegata alla pratica precedente
        setShowUploader(false);
        setFileInfos([]);
        setProgress(0);
        setRicevuta([]);
        setNote("");

        Swal.fire({
          title: "Cambio stato",
          text: `Hai cambiato questa pratica in: ${statoPratica}`,
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK!",
          footer: `<b>Operatore<b/>`,
        }).then((result) => {
          if (result.isConfirmed) {
            const socket = socketIOClient(process.env.REACT_APP_BASE_URL);

            socket.emit(
              "cambiostato",
              JSON.stringify({ partica: "aggiornata" })
            );

            let updTbl = {
              praticaId: selectedId,
            };

            renewTable(updTbl);
          } else {
            const socket = socketIOClient(process.env.REACT_APP_BASE_URL);

            socket.emit(
              "cambiostato",
              JSON.stringify({ partica: "aggiornata" })
            );

            let updTbl = {
              praticaId: selectedId,
            };

            renewTable(updTbl);
          }
        });
      });

    //fine
  };

  function notificaAlConsulente(data) {
    const notifyObj = {
      emailto: data.consulente.email,
      idpratica: data._id,
      descrizione: data.descrizione,
      stato: data.statoPratica,
      assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
      operatore: `${data.operatore[data.operatore.length - 1].nome} ${
        data.operatore[data.operatore.length - 1].nome
      }`,
      contatti: `${data.operatore[data.operatore.length - 1].email}`,
    };

    // notifico con mail al consulente
    axios
      .post(`${ENDPOINT}/consulente/notifica/action`, notifyObj)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const selectionFiles = (event) => {
    console.log("Selected files:", event.target.files);
    setSelectedFile(event.target.files);
    console.log("New selectedFile in ASYNC callback:", selectedFile);
    console.log("New fileInfos in ASYNC callback:", fileInfos);
  };

  const onClickHandler = () => {
    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("rcvCollection", selectedFile[x]);
      //   data.append('name', 'ricevitabis');
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${ENDPOINT}/ricevute`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        // then print response status
        console.log(response);
        setFileInfos(response.data);

        let ricevutaArray = [];

        for (let i = 0; i < response.data.length; i++) {
          ricevutaArray.push({
            path: response.data[i].filename,
            creato: new Date().toISOString(),
          });
        }
        setRicevuta(ricevutaArray);
      });
  };

  const handleMessaggi = (event) => {
    event.preventDefault();
    console.log(selectedConsulente);

    let currentUser = currentLoggedUser;

    let praticaId = "";
    let sender = "";
    let receiver = "";
    let to = selectedConsulente._id;
    let from = currentUser._id;
    let messaggio = "";

    const formData = new FormData(event.currentTarget);

    for (let [key, value] of formData.entries()) {
      if (key === "idpratica") {
        praticaId = value;
      }
      if (key === "sender") {
        sender = value;
      }
      if (key === "receiver") {
        receiver = value;
      }
      if (key === "messaggio") {
        messaggio = value;
      }
      console.log(key, value);
    }

    let msgObj = {
      idPratica: praticaId,
      descrizione: selectedDescrizione,
      users: [from, to],
      sender: from,
      senderName: sender,
      receiverName: receiver,
      message: messaggio,
      createdAt: new Date(),
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${ENDPOINT}/direct/message`, msgObj, {
        headers: headers,
      })
      .then((response) => {
        // then print response status
        console.log(response);
        setDrawerOpen(!drawerOpen);
      });
    /*
 idpratica 62406488d5e50f7df66067bd PraticheLavorazione.js:280
sender Di Giacomo Martina PraticheLavorazione.js:280
receiver Sandro Test PraticheLavorazione.js:280
messaggio dsdssffdsdf
        console.log(`${praticaId} ${sender} ${receiver} ${messaggio}`);

*/

    /*
    let messageArray=[].push({
      paraticaId: praticaId,
      sender: sender,
      receirver: receiver,
      messaggio: messaggio
    })

    console.log(messageArray);


        event.preventDefault();
*/
  };

  const drawerClickHandler = () => {
    console.log();
    //  setDrawerOpen(!drawerOpen);
  };

  const eliminaRiceuta = (path, index) => {
    alert(path, index);
    console.log(ricevuta);
    console.log(fileInfos);

    setRicevuta([...ricevuta.slice(0, index), ...ricevuta.slice(index + 1)]);

    setFileInfos([...fileInfos.slice(0, index), ...fileInfos.slice(index + 1)]);

    axios({
      method: "get",
      url: `${ENDPOINT}/pratiche/delete/ricevuta/path?path=${path}`,
    }).then(function (response) {
      console.log(response);
    });
  };

  const backdClickHandler = (props) => {
    //  setDrawerOpen(false);
    console.log(props);
  };

  const renderRowSubComponent = (row) => {
    let {
      allegato,
      operatore,
      data_scadenza,
      descrizione,
      note,
      ricevute,
      statoPratica,
      storico_pratica,
      storico_operatore,
      storico_note,
      ...rest
    } = row.original;
    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;

    let statiPossibili = ["Lavorazione", "Chiusa", "Sospesa", "Annullata"];

    console.log(rest.consulente);

    const RenderAllegati = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {allegato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/allegati/${rowData.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod="true"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoRicevute = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>Url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {ricevute?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                </td>
                <td>
                  <a
                    href={`${ENDPOINT}/static/pratiche/ricevute/${rowData.path} `}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod="true"
                  >
                    {rowData.path}
                  </a>
                </td>
                <td>{moment(rowData.creato).format("DD/MM/YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoPratica = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Stato</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_pratica?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.stato}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoNote = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>Nota</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_note?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.nota}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderStoricoOperatore = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>_id</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {storico_operatore?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{rowData.id}</td>
                <td>{rowData.nome}</td>
                <td>{rowData.cognome}</td>
                <td>{rowData.email}</td>
                <td>
                  {moment(rowData.data, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderNonClosedCase2 = () => {
      return (
        <div className="row">
          <FileUpload
            accept=".pdf,.jpg,.jpeg,.png,.zip"
            label="Area caricamento ricevute"
            multiple
            updateFilesCb={updateUploadedFiles}
          />

          {isVisible ? (
            <div className="edit">
              <div className="col">
                {fileInfos &&
                  fileInfos.map((fileInfo, index) => (
                    <div className="mb-2" key={index}>
                      {/*   <span>{fileInfo.path}</span> */}
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-info"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progress + "%" }}
                        >
                          {progress}%
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="col">
                <div className="container">
                  {fileInfos.map((el, i) => {
                    return (
                      <div className="row" key={i}>
                        <div className="col-md" style={{ padding: "9px" }}>
                          <a
                            href={`${ENDPOINT}/${el.filename}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            downlod="true"
                          >
                            {" "}
                            {el.name}{" "}
                            <i
                              className="bi bi-file-pdf"
                              style={{ color: "red" }}
                            ></i>
                            {el.filename}
                          </a>
                        </div>
                        <div className="col-sm">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            style={{ float: "right" }}
                            onClick={() => eliminaRiceuta(el.filename, i)}
                          >
                            Elimina sul server
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* 
          <ul>
  
            {fileInfos.map((el, i) => {
              return (
                <li key={i}>
                <a href={`${ENDPOINT}/${el.filename}`} 
                target="_blank" rel="noopener noreferrer" 
                downlod="true"> {el.name} <i className="bi bi-file-pdf" style={{ color: 'red' }}></i>{el.filename}</a> 
                <span><button type="button" className="btn btn-danger btn-sm" style={{float: 'right'}} onClick={()=>eliminaRiceuta(el.filename, i)}>Elimina sul server</button></span>
                </li>
              )
  
            })}
          </ul>
*/}
              </div>
              <div className="row blocco-row">
                <div className="col-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleFiles}
                  >
                    Carica i documenti sul server remoto!
                  </button>
                </div>
              </div>

              <div className="col">
                <small className="label-pratica">aggiungi note </small>
                <div className="input-group">
                  <span className="input-group-text">Area di testo</span>
                  <textarea
                    className="form-control"
                    aria-label="Area di testo"
                    name="note"
                    onChange={(e) => setNote(e.target.value)}
                    defaultValue={""}
                  ></textarea>
                </div>
              </div>

              <div className="col">
                <p />
                <button
                  className="btn btn-primary"
                  style={{ float: "right" }}
                  disabled={!selectedFiles}
                  onClick={handleSubmit}
                  type="button"
                  id="inputGroupFileAddon088"
                >
                  Cambia lo stato pratica!
                </button>
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    const RenderNonClosedCase = () => {
      return (
        <div className="edit">
          <div className="col">
            {fileInfos &&
              fileInfos.map((fileInfo, index) => (
                <div className="mb-2" key={index}>
                  <span>{fileInfo.path}</span>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: progress + "%" }}
                    >
                      {progress}%
                    </div>
                  </div>
                </div>
              ))}

            <small className="label-pratica">carica l'allegato </small>
            <div className="input-group mb-3">
              <input
                type="file"
                name="files"
                multiple
                onChange={selectionFiles}
                className="form-control"
                id="inputGroupFile02"
              />
              <button
                className="btn btn-outline-secondary"
                disabled={!selectedFiles}
                onClick={onClickHandler}
                type="button"
                id="inputGroupFileAddon04"
              >
                Carica i files!
              </button>
            </div>
          </div>

          <div className="col">
            <ul>
              {fileInfos.map((el, i) => {
                return (
                  <li key={i}>
                    <a href={`${ENDPOINT}/static/ricevuta/${el.path} `}>
                      <i
                        className="bi bi-file-pdf"
                        style={{ color: "red" }}
                      ></i>
                      {el.filename}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="col">
            <small className="label-pratica">aggiungi note </small>
            <div className="input-group">
              <span className="input-group-text">Area di testo</span>
              <textarea
                className="form-control"
                aria-label="Area di testo"
                name="note"
                onChange={(e) => setNote(e.target.value)}
                defaultValue={""}
              ></textarea>
            </div>
          </div>

          <div className="col">
            <button
              className="btn btn-outline-secondary"
              disabled={!selectedFiles}
              onClick={handleSubmit}
              type="button"
              id="inputGroupFileAddon088"
            >
              Cambia lo stato pratica!
            </button>
          </div>
        </div>
      );
    };

    const SlideDrawer = () => {
      let drawerClasses = "side-drawer";

      if (drawerOpen) {
        drawerClasses = "side-drawer open";
      }
      return (
        <div className={drawerClasses}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            className="bi bi-send"
            viewBox="0 0 16 16"
          >
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
          </svg>
          <button
            type="button"
            className="btn btn-light btn-sm"
            style={{ float: "right" }}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            {" "}
            X{" "}
          </button>

          <form onSubmit={handleMessaggi} encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="idpratica" className="form-label">
                id pratica
              </label>
              <input
                type="text"
                className="form-control"
                name="idpratica"
                id="idpratica"
                defaultValue={selectedId}
                readOnly="1"
              />
            </div>

            <div className="row">
              <div className="col mb-3">
                <label htmlFor="exampleInputSender" className="form-label">
                  Mittente
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="sender"
                  id="sender"
                  defaultValue={`${currentLoggedUser.cognome} ${currentLoggedUser.nome}`}
                  readOnly="1"
                />
              </div>
              <div className="col mb-3">
                <label v="exampleInputSender" className="form-label">
                  Destinatario
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="receiver"
                  id="receiver"
                  defaultValue={`${selectedConsulente.cognome} ${selectedConsulente.nome}`}
                  readOnly="1"
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="messaggio" className="form-label">
                Messaggio
              </label>
              <input
                type="text"
                className="form-control"
                name="messaggio"
                id="messaggio"
                rows="2"
              />
            </div>
            <div className="mb-3 form-check"></div>
            <button type="submit" className="btn btn-primary">
              Invia
            </button>
          </form>
        </div>
      );
    };

    const Backdrop = () => {
      return <div className="backdrop" />;
    };

    return (
      <>
        <div className="container pratica">
          <div className="row">
            {SlideDrawer()}
            <div className="col-4">
              <span style={{ fontWeight: "bold" }}>Consulente</span>
            </div>
            <div className="col-4">
              <small className="label-pratica">Cognome: </small>
              {`${consulente.cognome} `}
            </div>
            <div className="col-4">
              <small className="label-pratica">Nome: </small>
              {`${consulente.nome} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <i className="bi bi-envelope label-pratica"></i>
              <small className="label-pratica"> Email: </small>
              {`${consulente.email} `}
            </div>
            <div className="col">
              <i className="bi bi-phone label-pratica"></i>
              <small className="label-pratica"> Cellulare: </small>
              {`${consulente.cellulare} `}
            </div>
            <div className="col">
              <i className="bi bi-telephone label-pratica"></i>
              <small className="label-pratica"> Telefono: </small>
              {`${consulente.telefono} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="tipo-pratica">
          <div className="row">
            <div className="col">
              <span style={{ fontWeight: "bold" }}>Tipo Pratica</span>
            </div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${descrizione} `}
            </div>
            <div className="col">
              <small className="label-pratica">tipo pratica: </small>
              {`${tipo_pratica} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="assistito">
          <div className="row">
            <div>
              <span style={{ fontWeight: "bold" }}>Assistito</span>
            </div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${assistitoObj.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${assistitoObj.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F.: </small>
              {`${assistitoObj.codice_fiscale_assistito} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Prov.: </small>
              {`${assistitoObj.provincia} `}
            </div>
            <div className="col">
              <small className="label-pratica">Comune: </small>
              {`${assistitoObj.comune} `}
            </div>
            <div className="col">
              <small className="label-pratica">Via: </small>
              {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Tipo assistito: </small>
              {`${assistitoObj.categoria_assistito} `}
            </div>
            <div className="col">
              <small className="label-pratica">sc: </small>
              {`${assistitoObj.stato_civile} `}
            </div>
            <div className="col">
              <small className="label-pratica">data sc: </small>
              {assistitoObj.data_stato_civile &&
                `${moment(assistitoObj.data_stato_civile, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} `}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <small className="label-pratica">Email: </small>
              {`${assistitoObj.email} `}
            </div>
            <div className="col">
              <small className="label-pratica">Cellulare: </small>
              {`${assistitoObj.cellulare} `}
            </div>
            <div className="col">
              <small className="label-pratica">Tel.: </small>
              {`${assistitoObj.telefono} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="collaboratore">
          <div className="row">
            <div>
              <span style={{ fontWeight: "bold" }}>
                Collaboratore volontario
              </span>{" "}
            </div>
            <div className="col">
              <small className="label-pratica">Cognome: </small>
              {`${collaboratore.cognome} `}
            </div>
            <div className="col">
              <small className="label-pratica">Nome: </small>
              {`${collaboratore.nome} `}
            </div>
            <div className="col">
              <small className="label-pratica">C.F: </small>
              {`${collaboratore.codice_fiscale} `}
            </div>
          </div>
        </div>

        <div className="container pratica" id="stato-pratica">
          <div className="row">
            <div>
              <span style={{ fontWeight: "bold" }}> Stato Pratica</span>
            </div>
            <div className="col">
              <small className="label-pratica">descrizione: </small>
              {`${statoPratica} `}
            </div>

            {data_scadenza && (
              <div className="col">
                <small className="label-pratica">Data di Scadenza: </small>
                {`${data_scadenza} `}
              </div>
            )}
          </div>
        </div>

        <div className="container pratica" id="allegati">
          <div className="row">
            <div>
              {" "}
              Documenti allegati <i className="bi bi-paperclip"></i>
            </div>
            <div className="col">{RenderAllegati()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico pratica <i className="bi bi-file-earmark-text"></i>
            </div>
            <div className="col">{RenderStoricoPratica()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Note <i className="bi bi-card-checklist"></i>
            </div>
            <div className="col">{RenderStoricoNote()}</div>
          </div>
        </div>

        <div className="container pratiche" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Ricevute <i className="bi bi-file-earmark-arrow-down"></i>
            </div>
            <div className="col">{RenderStoricoRicevute()}</div>
          </div>
        </div>

        <div className="container pratica" id="storico-pratica">
          <div className="row">
            <div>
              {" "}
              Storico Operatore <i className="bi bi-person-video3"></i>
            </div>
            <div className="col">{RenderStoricoOperatore()}</div>
          </div>
        </div>

        <div
          className="container pratica"
          style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
        >
          Stato Pratica attuale: {`${statoPratica} `}
        </div>

        <div
          className="container pratica"
          style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
        >
          <div> </div>
          <div className="row">
            <h4 className="label-pratica" style={{ color: "#0d6efd" }}>
              Cambia lo stato pratica qui:{" "}
            </h4>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <select
                defaultValue={statoPratica}
                className="form-select form-select-lg mb-3"
                aria-label="Default select example"
                onChange={handleSelect}
                style={{ borderColor: "#0d6efd" }}
              >
                {statiPossibili.map((item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              <p />

              {/* 
              <div className="progress">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={ progress }
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                          >
                            {progress}%
                          </div></div>
*/}

              {showUploader ? RenderNonClosedCase2() : null}
            </form>
          </div>
          <p />
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required

        /*
          Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <i className="bi bi-caret-down-fill"></i> : <span className="btn1" onClick={() => handleShow(row)}> <i className="bi bi-caret-right-fill"></i></span>  }
            </span>
            ),
            */

        Cell: ({ row, rows, toggleRowExpanded }) => (
          <span
            {...row.getToggleRowExpandedProps({
              ...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
                onClick: () => {
                  const expandedRow = rows.find((row) => row.isExpanded);

                  if (expandedRow) {
                    const isSubItemOfRow = Boolean(
                      expandedRow && row.id.split(".")[0] === expandedRow.id
                    );

                    if (isSubItemOfRow) {
                      const expandedSubItem = expandedRow.subRows.find(
                        (subRow) => subRow.isExpanded
                      );

                      if (expandedSubItem) {
                        const isClickedOnExpandedSubItem =
                          expandedSubItem.id === row.id;
                        if (!isClickedOnExpandedSubItem) {
                          toggleRowExpanded(expandedSubItem.id, false);
                        }
                      }
                    } else {
                      toggleRowExpanded(expandedRow.id, false);
                    }
                  }
                  row.toggleRowExpanded();
                },
              }),
            })}
          >
            {row.isExpanded ? (
              <i className="bi bi-caret-down-fill" style={{ color: "red" }}></i>
            ) : (
              <span className="btn1" onClick={() => handleShow(row)}>
                {" "}
                <i className="bi bi-caret-right-fill"></i>
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "ID",
        accessor: "numberId",
      },
      {
        Header: "Descrizione",
        accessor: "descrizione",
      },
      {
        Header: "Regione",
        accessor: "assistito.regione",
      },
      {
        Header: "Assistito",
        accessor: (a) => `${a.assistito.nome} ${a.assistito.cognome}`,
      },
      {
        Header: "Consulente",
        accessor: (b) => `${b.consulente.nome} ${b.consulente.cognome}`,
      },
      {
        Header: "Stato",
        accessor: "statoPratica",
      },
      {
        Header: "Scadenza",
        sortType: () => 1,
        accessor: (d) =>
          `${moment(d.data_scadenza, "DD/MM/YYYY, HH:mm:ss").format(
            "DD/MM/YYYY, HH:mm"
          )}`,
      },
    ],
    []
  );

  return (
    <>
      <div
        className="test"
        style={{
          paddingBottom: "100px",
          backgroundColor: "rgb(233, 236, 239)",
        }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? (
            <Loader.ThreeDots color="white" height={100} width={100} />
          ) : null}
        </div>
        <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
          {/*
        <RenderRoaster />
        <Button variant="danger">
        utenti online <Badge bg="secondary">{ online }</Badge>
          <span className="visually-hidden">unread messages</span>
        </Button>
     */}
          <div className="table-responsive">
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PraticheLavorazione;
