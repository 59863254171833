import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Endpoints from "../components/services/Endpoints";
import { Container } from "react-bootstrap";
import axios from "axios";
import client from "../utils/client";

import UploadService from "../components/services/upload-files.service";

const ENDPOINT = Endpoints.getUrls();

const AdminModuloAdd = () => {
  const navigate = useNavigate();
  const [progressInfos, setProgressInfos] = useState([]);

  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [progress, setProgress] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [documentoFile, setDocumentoFile] = useState([]);

  const [tipoEdit, setTipoEdit] = useState("");
  const [messaggioEdit, setMessaggioEdit] = useState("");

  const [convertedContent, setConvertedContent] = useState(null);

  const upload = (idx, file) => {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        console.log(response);

        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Uploaded the file successfully: " + response.data.path,
          ];

          return {
            message: nextMessage,
          };
        });
        //   return `http://localhost:5001/static/allegati/${response.data.path}`;
        return UploadService.getFiles(response.data);
      })
      .then((files) => {
        this.setState((prevState) => ({
          fileInfos: [...prevState.fileInfos, files],
        }));
        const event = new Date();
        this.setState(
          (prevState) => ({
            allegati: [
              ...prevState.allegati,
              { creato: event.toISOString(), path: files.path },
            ],
          }),
          () => {
            console.log("New allegati in ASYNC callback:", this.state.allegati);
          }
        );
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Could not upload the file: " + file.name,
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  };

  const selectionFiles = (event) => {
    setSelectedFile(event.target.files);
    setProgressInfos([]);
    console.log("New state in ASYNC callback:", selectedFile);
    console.log("New fileInfos in ASYNC callback:", fileInfos);
  };

  const onClickHandler = () => {
    const data = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      data.append("docCollection", selectedFile[x]);
      //   data.append('name', 'ricevitabis');
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${ENDPOINT}/documenti`, data, {
        headers: headers,
        // receive two    parameter endpoint url ,form data
        onUploadProgress: (ProgressEvent) => {
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })

      .then((response) => {
        // then print response status
        console.log(response);
        setFileInfos(response.data);

        let documentoArray = [];

        for (let i = 0; i < response.data.length; i++) {
          documentoArray.push(response.data[i].filename);
        }

        setDocumentoFile(documentoArray);
      });
  };

  const handleOnTipo = (event) => {
    setTipoEdit(event.target.value);
  };
  const handleOnMessaggio = (event) => {
    setMessaggioEdit(event.target.value);
  };

  const RenderClosedCase = () => {
    return (
      <div className="edit">
        <div className="col">
          {fileInfos &&
            fileInfos.map((fileInfo, index) => (
              <div className="mb-2" key={index}>
                <span>{fileInfo.path}</span>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progress + "%" }}
                  >
                    {progress}%
                  </div>
                </div>
              </div>
            ))}

          <small className="label-pratica text-white">
            Sostituisci il documento del modulo{" "}
          </small>
          <div className="input-group mb-3">
            <input
              type="file"
              name="files"
              multiple
              onChange={selectionFiles}
              className="form-control"
              id="inputGroupFile02"
            />
            <button
              className="btn btn-primary"
              disabled={!selectedFiles}
              onClick={onClickHandler}
              type="button"
              id="inputGroupFileAddon04"
            >
              Carica i files!
            </button>
          </div>
        </div>

        <div className="col">
          <ul>
            {fileInfos.map((el, i) => {
              return (
                <li key={i}>
                  <a
                    href={`${ENDPOINT}/static/documenti/${el.filename} `}
                    target="_blank"
                    download
                  >
                    <i className="bi bi-file-pdf" style={{ color: "red" }}></i>
                    {el.filename}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        {/* 
       destination: "./public/data/uploads/documenti/"
  encoding: "7bit"
  fieldname: "docCollection"
  filename: "1652037539464_PGS_Lab_Logo_–_Osservatorio_Giovanile_sullo_Sport_copia.pdf"
  mimetype: "application/pdf"
  originalname: "PGS Lab Logo – Osservatorio Giovanile sullo Sport copia.pdf"
  path: "public/data/uploads/documenti/1652037539464_PGS_Lab_Logo_–_Osservatorio_Giovanile_sullo_Sport_copia.pdf"
  size: 169660 */}

        <div className="col">
          <button
            className="btn btn-primary"
            disabled={!selectedFiles}
            onClick={handleSubmit}
            type="button"
            id="inputGroupFileAddon088"
          >
            Crea nuovo Modulo!
          </button>
        </div>
      </div>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(messaggioEdit);
    console.log(tipoEdit);
    console.log(documentoFile);
    console.log(convertedContent);

    client({
      url: `/admin/moduli/create/`,
      config: {
        method: "POST",
        data: {
          tipo: tipoEdit,
          messaggio: messaggioEdit,
          documento: documentoFile,
          contenuto: convertedContent,
        },
      },
    })
      .then(function (response) {
        console.log(response);
        alert(JSON.stringify(response.data));

        window.location.reload(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Container fluid>
        <Container className="mb-4 mt-3">
          <div
            style={{
              textAlign: "center",
              textAlign: "center",
              paddingTop: "1rem",
              paddingBottom: "3rem",
              fontWeight: "600",
              color: "white",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-house-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>{" "}
            / Admin / Moduli / Aggiungi Modulo
          </div>

          <div className="card" style={{ width: "82rem", padding: "2rem" }}>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Tipo
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="tipo"
                        name="tipo"
                        onChange={handleOnTipo}
                        required
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Messaggio
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="inserisci qui la descrizione del modello"
                        name="messaggio"
                        onChange={handleOnMessaggio}
                      />
                    </div>
                  </div>
                </div>

                {RenderClosedCase()}
              </form>
            </div>
          </div>
        </Container>
        <div className="row">
          <div
            style={{
              textAlign: "center",
              textAlign: "center",
              paddingTop: "1rem",
              paddingBottom: "3rem",
              fontWeight: "600",
            }}
          ></div>
        </div>
      </Container>
    </>
  );
};

export default AdminModuloAdd;
