import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth as useAuthOIDC } from "react-oidc-context";
import useAuth from "../hooks/useAuth";

import Swal from "sweetalert2";
import axios from "axios";
import socketIOClient from "socket.io-client";
import ModalContact from "./ModalContact";
import "./customize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navbar,
  NavDropdown,
  Nav,
  Container,
  NavItem,
  Button,
} from "react-bootstrap";
import Endpoints from "../components/services/Endpoints";
import SubscriptionBanner from "./components/SubscriptionBanner";
const ENDPOINT = Endpoints.getUrls();

export default function NavConsulente() {
  const [id, setId] = useState([]);
  const [cognome, setCognome] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");

  const [modalShow, setModalShow] = React.useState(false);

  const auth = useAuthOIDC();

  const { isDemoUser } = useAuth();

  let navigate = useNavigate();

  let profiloUtente = auth.user?.profile;

  const getUserLogged = async () => {
    const consulenteClient = await JSON.parse(
      localStorage.getItem("currentUser")
    );

    const { _id, cognome, nome, email, role } = consulenteClient;

    setCognome(cognome);
    setNome(nome);
    setEmail(email);
    setRole(role);
    setUserId(_id);
  };

  useEffect(() => {
    getUserLogged();
  }, []);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    socket.on("quit_request", (data) => {
      console.log(data);

      const userActive = JSON.parse(localStorage.getItem("currentUser"));

      userActive.role.forEach((element, index, array) => {
        if (element === "ROLE_TELECONSUL") {
          console.log(element);
          //   alert(JSON.stringify(data), profiloUtente);
          checkIflogoutTrue(data);
        }
      });

      //  setResponse(data);
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  const checkIflogoutTrue = (data) => {
    const sidqui = profiloUtente;

    if (sidqui.sid === data.sid && sidqui.sub === data.sub) {
      console.log("SI ESCEEEEE");

      auth.removeUser();
      logout();
    }

    console.log(
      sidqui.sid + " - " + sidqui.sub + " -> " + data.sid + " -> " + data.sub
    );

    /*          if (auth.isAuthenticated) {
            alert(JSON.stringify(data), sid);
            checkIflogoutTrue(data)
          } */
  };

  const logout = () => {
    if (auth.isAuthenticated) {
      const token = auth.user?.id_token;
      auth.removeUser();
      auth.revokeTokens();
      localStorage.clear();
      sessionStorage.clear();
      //  logout();
      const redirect = "https://www.tcdesk.it/Catalogo";

      //  console.log(`https://tcis-dev.teleconsul.it/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${redirect}`)

      window.location.replace(
        `https://accounts.teleconsul.it/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${redirect}`
      );
    }
    let items = ["currentUser", "tipoPaticaId"];
    items.forEach((items) => {
      localStorage.removeItem(items);
    });
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const handleNamirial = (event) => {
    event.preventDefault();

    const doFetch = async () => {
      const response = await fetch(
        `${ENDPOINT}/users/getroles/id?_id=${userId}`
      );
      const userobj = await response.json();
      console.log(userobj);

      event.preventDefault();
      //     const tempPlayer = new FormData(event.target);

      const formDatax = new FormData();

      formDatax.append("famreg_sportello", userobj.userid);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/middleware/namirial_check.php`,
          formDatax
        )
        .then(function (response) {
          console.log(response);

          let responseDoc = new DOMParser().parseFromString(
            response.data,
            "application/xml"
          );
          let status =
            responseDoc.getElementsByTagName("status")[0].textContent;

          let successmess;
          let link;
          let errormess;

          if (status == "00") {
            successmess =
              responseDoc.getElementsByTagName("mess")[0].textContent;
            link = responseDoc.getElementsByTagName("url")[0].textContent;
            if (link) {
              window.location.replace(link, "_blank");
              //  navigate(`${element.value}`)
            }

            console.log(`successmess: ${successmess} - linkto: ${link}`);
          } else {
            errormess = responseDoc.getElementsByTagName("mess")[0].textContent;
            console.log(`errormess: ${errormess}`);
            navigate("/consulente/namirial-new");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    doFetch();
  };

  const logoutTeleconsul = () => {
    if (auth.isAuthenticated) {
      const token = auth.user?.id_token;
      auth.removeUser();
      auth.revokeTokens();

      logout();

      const redirect = "https://tcis-desk.teleconsul.it/Catalogo";

      console.log(
        `https://tcis-dev.teleconsul.it/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${redirect}`
      );

      window.location.replace(
        `https://tcis-dev.teleconsul.it/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${redirect}`
      );
    }
  };

  return (
    <main>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            CAF Nazionale Consulenti del Lavoro
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Profilo" id="basic-nav-dropdown">
                <NavDropdown.Item>{nome}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>{cognome}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>{email}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>{role[0]}</NavDropdown.Item>
                <NavDropdown.Item>{role[1]}</NavDropdown.Item>
                <NavDropdown.Item>
                  {role.length === 3 ? role[2] : null}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
              <Navbar.Text
                onClick={() => setModalShow(true)}
                style={{ paddingLeft: "8px" }}
              >
                <a href="#">Contatti</a>
              </Navbar.Text>
            </Nav>

            <Nav>
              <button
                type="button"
                disabled={isDemoUser}
                className="btn btn-primary btn-md hideOnDesktop"
                style={{ marginRight: "22px" }}
                onClick={handleNamirial}
              >
                PUNTO PREVIDENZA
              </button>

              <button
                type="button"
                disabled={isDemoUser}
                className="btn  btn-md hideOnDesktop"
                style={{
                  marginRight: "22px",
                  backgroundColor: "rgb(220 54 68)",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
                onClick={() => {
                  // Swal.fire({
                  //   title: "Il servizio sarà disponibile a breve",
                  //   icon: "info",
                  //   confirmButtonColor: "#3085d6",
                  //   cancelButtonColor: "#d33",
                  //   confirmButtonText: "Ok!",
                  // });
                  window.location.href = "/sos-avvocato/index.php";
                }}
              >
                SOS AVVOCATO
              </button>

              <div>
                {/*auth.isAuthenticated ? 
              <div>
              {auth.user?.profile.sub}{" "}
              <button className="btn btn-primary btn-md"
              onClick={logoutTeleconsul}>Esci da TcDesk
              
              </button>
          </div>
          :
          null     
    */}
              </div>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Ciao: <a href="#login">{nome}</a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <SubscriptionBanner />

      <ModalContact show={modalShow} onHide={() => setModalShow(false)} />
    </main>
  );
}
