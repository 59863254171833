import React from "react";
import { Navigate } from "react-router-dom";

import Endpoints from "../components/services/Endpoints";
import {
  Container,
  Form,
  Alert,
  Button,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import socketIOClient from "socket.io-client";
import axios from "axios";
import FileUpload from "./File_Upload_Component/file-upload.classcomponent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import parse from "html-react-parser";

import "bootstrap-icons/font//bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./denied.css";
import "./nuovapratica.css";
import "./Suggestion.css";
import client from "../utils/client";
import UploadService from "../components/services/upload-files.service";

const MySwal = withReactContent(Swal);
const ENDPOINT = Endpoints.getUrls();
const ENDPOINTALLEGATI = Endpoints.getAllegatiUrls();

class Nuovapratica extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      progressInfos: [],
      message: [],
      allegati: [],
      searchParams: null,
      tipo_pratica: "",
      descrizione: "",
      dataScadenzaObbligatoria: "",
      text: "",
      users: [],
      matches: [],
      suggestion: [],
      assegnatario: {},
      assistitoText: "",
      assistitoLabel: "",
      suggestAssistito: [],
      assistito: [],
      cliente: {
        cognome: "",
        nome: "",
        email: "",
        telefono: "",
        cellulare: "",
        indirizzo: "",
        indirizzo_altro: "",
        citta: "",
        cap: "",
        stato: "",
        regione: "",
        comune: "",
        provincia: "",
        categoria_assistito: "",
        stato_civile: "",
        data_stato_civile: "",
      },
      clienteEsistente: {},
      selectedFile: [],
      loaded: 0,
      dataScadenza: new Date(),
      // dataStorico: new Date(),
      dataStorico: [{ inviata: new Date().toISOString() }],
      consulente: {},
      consulenteLabel: "",
      statoPratica: "",
      collaboratore_nome: "",
      collaboratore_cognome: "",
      collaboratore_codice_fiscale: "",
      note: "",
      validCf: false,
      dataStatoCivileShow: false,
      tipoPraticaIdSelected: {},
      collaboratoreVolontario: {},
      redirectTo: false,
      moduloToBind: [],
      moduloBinded: [],
      docNecessariToBind: "",

      emptyAllegato: true,
      salvaAssistito: false,
      isActiveAccount: "",
      collaboratoreEsiste: false,
      isVisible: true,
      progress: [],
      fileInfos: [],
      newUserInfo: {
        profileImages: [],
      },
    };
  }

  componentDidMount() {

    let oPobject = JSON.parse(localStorage.getItem("currentUser"));

    const requestOptions = {
      method: "POST",
      async: true,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ids: oPobject._id }),
    };

    fetch(`${ENDPOINT}/consulente/checkcollaboratore/`, requestOptions)
      .then((response) => response.json())
      .then((data) =>
        this.setState({ collaboratoreVolontario: data.collaboratore })
      );

    const request = {
      method: "GET",
      async: true,
      headers: { "Content-Type": "application/json" },
    };

    fetch(`${ENDPOINT}/users/checkstato/id?_id=${oPobject._id}`, request)
      .then((response) => response.json())
      .then((data) => this.setState({ isActiveAccount: data.stato }));

    this.ceckLoggedUser();
    this.ceckTipoPraticaSelected();
    //  this.createMenuTipoPratica();
    // this.showFerieMessage();
  }

  showFerieMessage = () => {
    if (localStorage.hasOwnProperty("hideMessage")) {
      let boolStatus = localStorage.getItem("hideMessage");

      if (boolStatus === "false") {
        Swal.fire({
          title:
            "Si informa che il servizio non sarà disponibile, per la pausa estiva, dal 13/08 al 28/08 - Ringraziandovi per la gentile collaborazione vi auguriamo Buone Vacanze.",
          input: "checkbox",
          inputPlaceholder: "Non mostrare più questo messaggio",
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              localStorage.setItem("hideMessage", true);
              Swal.fire({
                icon: "success",
                text: "non vedrai questo messaggio fino al prossimo login",
              });
            } else {
              Swal.fire({
                icon: "success",
                text: "ti mostreremo ancora questo messaggio durante la sessione",
              });
              localStorage.setItem("hideMessage", false);
            }
          } else {
            console.log(`modal was dismissed by ${result.dismiss}`);
          }
        });
      }

      console.log("Item exists in localstorage");
    } else {
      Swal.fire({
        title:
          "Si informa che il servizio non sarà disponibile, per la pausa estiva, dal 13/08 al 28/08 - Ringraziandovi per la gentile collaborazione vi auguriamo Buone Vacanze.",
        input: "checkbox",
        inputPlaceholder: "Non mostrare più questo messaggio",
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            localStorage.setItem("hideMessage", true);
            Swal.fire({
              icon: "success",
              text: "non vedrai questo messaggio fino al prossimo login",
            });
          } else {
            Swal.fire({ icon: "error", text: "You don't have a bike :(" });
            localStorage.setItem("hideMessage", false);
          }
        } else {
          console.log(`modal was dismissed by ${result.dismiss}`);
        }
      });
      console.log("Item does not exist in localstoarge");
    }
  };

  ceckTipoPraticaSelected = () => {
    const objects = JSON.parse(localStorage.getItem("tipoPaticaId"));

    if (objects) {
      this.setState({
        tipoPraticaIdSelected: objects,
      });

      this.createMenuTipoPratica();
    }
  };

  ceckLoggedUser = () => {
    if (localStorage.getItem("currentUser")) {
      let oPobject = JSON.parse(localStorage.getItem("currentUser"));
      this.setState({
        consulenteLabel: oPobject.cognome + " " + oPobject.role[0],
      });
      this.setState({ consulente: oPobject });
    }
  };

  getId = () => {
    return this.state.tipoPraticaIdSelected;
  };

  createMenuTipoPratica = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("_id");

    // let parameters = this.getId();

    const objects = JSON.parse(window.localStorage.getItem("tipoPaticaId"));

    //   var url = `http://localhost:5001/users/autosearch/cognome?cognome=${svalue}`;
    //  fetch(`http://localhost:5001/tipopratica/_id/${foo}`, {
    fetch(`${ENDPOINT}/tipopratica/_id/${objects._id}`, {
      crossDodiv: true,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ tipo_pratica: response[0].tipo_pratica });
        this.setState({ descrizione: response[0].descrizione });
        this.setState({
          dataScadenzaObbligatoria: response[0].scadenza_obbligatoria
            ? response[0].scadenza_obbligatoria
            : 0,
        });
        this.setState({ moduloToBind: response[0].modulo });
        this.setState({ docNecessariToBind: response[0].documenti_necessari });
        //  this.createFilteredArray(response)

        // let self = this;
        axios
          .post(`${ENDPOINT}/admin/moduli/bind/`, {
            ids: this.state.moduloToBind,
          })
          .then((response) => {
            if (response) {
              this.setState({ moduloBinded: response.data });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }); //fine primo fetch
  };

  handleChange(event) {
    this.setState(
      {
        text: event.target.value,
      },
      () => {
        console.log("New state in ASYNC callback:", this.state.text);
      }
    );

    console.log("New state DIRECTLY after setState:", this.state.text);
  }

  handleChangeAssistito = (event) => {
    if (event.target.name === "codice_fiscale_assistito") {
      let cf = this.validaCodiceFiscale(event.target.value);
      if (cf === true) {
        this.setState({ validCf: true });
      }
    }

    if (event.target.name === "stato_civile") {
      if (event.target.value === "nubile o celibe") {
        this.setState({ dataStatoCivileShow: false });
      } else {
        this.setState({ dataStatoCivileShow: true });
      }
    }

    this.setState(
      (prevState) => ({
        cliente: {
          // object that we want to update
          ...prevState.cliente, // keep all other key-value pairs
          [event.target.name]: event.target.value, // update the value of specific key
        },
      }),
      () => {
        console.log("New state in ASYNC callback:", this.state.cliente);
      }
    );
    //  console.log(this.state.cliente);
    //  console.log(event.target.value, event.target.name);
  };

  handleChangeAssistoAutocomplete = (valori) => {
    /*
cliente: {
  cognome: '', nome: '',  email: '', telefono:'', cellulare:'', indirizzo: '', 
  indirizzo_altro:'', citta:'', cap:'', stato:'', regione:'', comune:'', provincia:'', 
  categoria_assistito:'', stato_civile:'', data_stato_civile:'' }
*/
  };

  validaCodiceFiscale = (cf) => {
    var validi, i, s, set1, set2, setpari, setdisp;
    if (cf === "") return "";
    cf = cf.toUpperCase();
    if (cf.length !== 16) return false;
    validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) === -1) return false;
    }
    set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    s = 0;
    for (i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 !== cf.charCodeAt(15) - "A".charCodeAt(0)) return false;
    return true;
  };

  // sezione stato pratica
  onStatoPraticaChange = (event) => {
    const value = event.target.value;
    this.setState({ statoPratica: value });
  };

  //Sezione Assistito
  onAssistitochange = (event) => {
    this.setState({
      assistitoLabel: event.target.value,
    });

    if (this.state.assistitoLabel.length > 0) {
      var url = `${ENDPOINT}/assistiti/autosearch/cognome?cognome=${this.state.assistitoLabel}&consulente=${this.state.consulente._id}`;
      fetch(url)
        .then((result) => result.json())
        .then((resdata) => {
          this.setState({ suggestAssistito: resdata });
          //console.log(this.state.suggestion);
          //console.log(resdata);
          //return resdata;
        });
    }

    /*
  const svalue = event.target.value;
  if (svalue.length > 0){
      var url = `${ENDPOINT}/assistiti/autosearch/cognome?cognome=${svalue}&consulente=${this.state.consulente._id}`;
      fetch(url).then( result => result.json())
          .then(resdata => {
              this.setState({suggestAssistito: resdata});
              //console.log(this.state.suggestion);
              //console.log(resdata);
              //return resdata;
          });
  }
*/
  };

  assistitoSelected(data, value, value2, value3) {
    if (data) {
      this.setState(
        (prevState) => ({
          clienteEsistente: {
            // object that we want to update
            ...prevState.clienteEsistente, // keep all other key-value pairs
            data, // update the value of specific key
          },
        }),
        () => {
          // se ho selezionato un cliente esistente, devo mostrare o meno la data dello stato civile
          this.setState({ dataStatoCivileShow: data.stato_civile != "nubile o celibe" });
        }
      );

      //qui cerco di impostare il cliente selzionato sotituendolo a quello controllato dalla form

      this.setState({ cliente: data });

      let cf = this.validaCodiceFiscale(data.codice_fiscale_assistito);
      if (cf === true) {
        this.setState({ validCf: true });
      }
    }

    this.setState(() => ({
      assistitoLabel: `${value2} ${value3}`,
      suggestAssistito: [],
    }));
  }

  renderAssistitoSuggs = () => {
    const mapdata = this.state.suggestAssistito;
    if (mapdata.length === 0) {
      return null;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          zIndex: 900000,
        }}
      >
        {mapdata.map((item, i) => (
          <div
            key={i}
            onClick={() =>
              this.assistitoSelected(item, item._id, item.cognome, item.nome)
            }
            className="suggestion"
          >
            {item.cognome} {item.nome} {item.codice_fiscale_assistito}
          </div>
        ))}
      </div>
    );
  };

  handleRestSuggestion = () => {
    this.setState({
      suggestAssistito: [],
    });
    this.setState({
      assistitoLabel: "",
      text: "",
    });
  };

  renderModuli = () => {
    if (this.state.moduloBinded.length === 0) {
      return null;
    }
    return (
      <table className="table table-light">
        {this.state.moduloBinded.map(
          ({ _id, tipo, messaggio, contenuto, documento }) => (
            <tbody key={_id}>
              <tr>
                <td>
                  {tipo}
                  <a
                    className="btn btn-primary"
                    href={`${ENDPOINT}/static/documenti/${documento}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ float: "right" }}
                  >
                    Scarica
                  </a>
                </td>
              </tr>
              {messaggio != " " ? (
                <tr>
                  <td>
                    <small>Descrizione: {messaggio}</small>
                  </td>
                </tr>
              ) : null}
            </tbody>
          )
        )}
      </table>
    );
  };

  renderDocumentiNecessari = () => {
    return (
      <div className="mb-3 fade alert alert-info show">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.state.docNecessariToBind,
                  }}
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderCollaboratoreVolontario = () => {
    // collaboratoreVolontario

    const collab2 = this.state.collaboratoreVolontario;

    return (
      <Row className="mb-3">
        <h3>
          <p style={{ borderBottom: "solid 1px", padding: "10px" }}>
            Collaboratore volontario
          </p>
        </h3>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="nome"
            name="nome_collaboratore"
            value={collab2.nome}
            readOnly
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridCognome">
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            type="text"
            name="cognome_collaboratore"
            placeholder="cognome"
            value={collab2.cognome}
            readOnly
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridAddress1">
          <Form.Label>Codice Fiscale</Form.Label>
          <Form.Control
            type="text"
            name="codice_fiscale_collaboratore"
            placeholder="CCC DDD NN LNN HNNNL"
            value={collab2.codice_fiscale}
            readOnly
          />
        </Form.Group>
      </Row>
    );
  };

  renderBottoni = () => {
    if (this.state.isActiveAccount === "Off") {
      return (
        <div
          style={{
            color: "red",
            textAlign: "center",
            padding: "20px",
            marginTop: "25px",
          }}
        >
          <h4>
            Account sospeso: prego contattare CDL per l'abilitazione. Grazie
          </h4>
        </div>
      );
    } else {
      if (!this.state.collaboratoreVolontario.codice_fiscale) {
        return (
          <div>
            Non puoi procedere: regolarizzare la posizione Del collaboratore
            volontario{" "}
          </div>
        );
      } else if (
        this.state.collaboratoreVolontario.codice_fiscale &&
        !this.state.emptyAllegato
      ) {
        return (
          <div>
            <Button
              variant="success"
              type="submit"
              name="Bozza"
              style={{ float: "left" }}
            >
              SALVA BOZZA
            </Button>
            <Button
              variant="primary"
              type="submit"
              name="inviata"
              style={{ float: "right" }}
            >
              INVIA
            </Button>
          </div>
        );
      } else if (
        this.state.collaboratoreVolontario.codice_fiscale &&
        this.state.emptyAllegato
      ) {
        return (
          <Button
            variant="success"
            type="submit"
            name="Bozza"
            style={{ float: "left" }}
          >
            SALVA BOZZA
          </Button>
        );
      }
    }
  };

  handleScadenza = (event) => {
    const value = event.target.value;
    const datascadenza = new Date(value);
    this.setState({ dataScadenza: datascadenza });
  };

  handleCollaboratore = (event) => {
    const nome = event.target.name;
    if (nome === "nome_collaboratore") {
      this.setState({ collaboratore_nome: event.target.value });
    }
    if (nome === "cognome_collaboratore") {
      this.setState({ collaboratore_cognome: event.target.value });
    }
    if (nome === "codice_fiscale_collaboratore") {
      this.setState({ collaboratore_codice_fiscale: event.target.value });
    }
    console.log(event.target.name);
  };

  handleNote = (event) => {
    const note = event.target.value;
    this.setState({ note: note });
  };

  updateUploadedFiles = (files) => {
    /*
  files.forEach(function(element) {
  console.log(element)  
  if(element.type != 'application/pdf'){ alert('You can upload pdf files only.');
  return;
  } else { this.setState(prevState => ({
  newUserInfo: { ...prevState.newUserInfo, profileImages: files }
  }))}
  
  
  
  }, this);
  */

    this.setState((prevState) => ({
      newUserInfo: { ...prevState.newUserInfo, profileImages: files },
    }));
  };

  handleFiles = () => {
    let selectedFile = this.state.newUserInfo.profileImages;

    selectedFile.forEach(function (element) {
      if (element.type !== "application/pdf") {
        alert(
          "PUOI ALLEGARE SOLTANTO FILE PDF! Prego rimuovere tutti i file non pdf e ripetere l'operazione"
        );
        return;
      } else {
        const data = new FormData();
        for (var x = 0; x < selectedFile.length; x++) {
          data.append("allCollection", selectedFile[x]);
        }
        const headers = { "Content-Type": "multipart/form-data" };

        client({
          url: `${ENDPOINT}/allegati`,
          config: {
            method: "POST",
            data: data,
            headers: headers,
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                progress: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
              // this.setState((ProgressEvent.loaded / ProgressEvent.total*100))
            },
          },
        }).then((response) => {
          // then print response status
          console.log(response);
          this.setState({ fileInfos: response.data });
          //  setFileInfos(response.data);

          let allegatiArray = [];

          for (let i = 0; i < response.data.length; i++) {
            allegatiArray.push({
              path: response.data[i].filename,
              creato: new Date().toISOString(),
            });
          }

          this.setState({ allegati: allegatiArray });
          this.setState({ emptyAllegato: false });

          // setRicevuta(allegatiArray);
        });
      }
    }, this);

    //********************* */
  };

  // files
  selectionFiles = (event) => {
    this.setState(
      {
        progressInfos: [],
        selectedFiles: event.target.files,
      },
      () => {
        console.log("New state in ASYNC callback:", this.state.selectedFiles);
        console.log("New fileInfos in ASYNC callback:", this.state.fileInfos);
      }
    );
  };

  selectFile(event) {
    this.setState({
      progressInfos: [],
      selectedFiles: event.target.files,
    });
  }

  uploadFiles = () => {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i]);
        }
      }
    );
  };

  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        console.log(response);

        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Uploaded the file successfully: " + response.data.path,
          ];

          return {
            message: nextMessage,
          };
        });
        //   return `http://localhost:5001/static/allegati/${response.data.path}`;
        return UploadService.getFiles(response.data);
      })
      .then((files) => {
        this.setState((prevState) => ({
          fileInfos: [...prevState.fileInfos, files],
        }));
        const event = new Date();
        this.setState(
          (prevState) => ({
            allegati: [
              ...prevState.allegati,
              { creato: event.toISOString(), path: files.path },
            ],
          }),
          () => {
            console.log("New allegati in ASYNC callback:", this.state.allegati);
          }
        );

        this.setState({ emptyAllegato: false });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Could not upload the file: " + file.name,
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  }

  // handleSubmit

  handleSaveAssistito = (event) => {
    if (event.target.checked) {
      this.setState({ salvaAssistito: true });
    }

    if (!event.target.checked) {
      this.setState({ salvaAssistito: false });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const stato = event.nativeEvent.submitter.name;
    if (stato === "inviata") {
      if (this.state.allegati.length > 0) {
        this.setState({ emptyAllegato: true });
      }

      this.setState(
        {
          statoPratica: event.nativeEvent.submitter.name,
        },
        () => {
          this.handleSubmit2();
          // Do an API call with this.state.searchTerm
        }
      );
    }

    if (stato === "Bozza") {
      if (this.state.allegati.length > 0) {
        this.setState({ emptyAllegato: true });
      }
      this.setState(
        {
          statoPratica: event.nativeEvent.submitter.name,
        },
        () => {
          this.handleSubmit2();
          // Do an API call with this.state.searchTerm
        }
      );
    }
  };

  handleSubmit2 = () => {
    let praticaObject = {
      consulente: this.state.consulente,
      //  operatore: this.state.assegnatario[0],

      operatore: null,
      statoPratica: this.state.statoPratica,
      allegato: this.state.allegati,
      tipo_pratica: this.state.tipo_pratica,
      descrizione: this.state.descrizione,
      assistito: this.state.cliente,
      colloboratore: {
        nome: this.state.collaboratoreVolontario.nome,
        cognome: this.state.collaboratoreVolontario.cognome,
        codice_fiscale: this.state.collaboratoreVolontario.codice_fiscale,
      },
      data_scadenza: this.state.dataScadenza.toLocaleString("it-IT"),
      // allegato: this.state.filePath,
      note: this.state.note,
      storico_pratica: [
        {
          stato: "inviata",
          data: new Date().toLocaleString("it-IT"),
        },
      ],
      ricevute: [],
      storico_note: [
        {
          nota: this.state.note,
          data: new Date().toLocaleString("it-IT"),
        },
      ],
      lastElem: {
        created: new Date(),
        status: this.state.statoPratica,
      },
    };

    console.log(praticaObject);

    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }

    client({
      url: `${ENDPOINT}/pratiche/create`,
      config: { method: "POST", data: praticaObject },
    }).then((data) => {
      console.log(data);
      Swal.fire(
        "Pratica creata con successo!",
        `${data.data.pratica_id}`,
        "success"
      );

      this.setState({ redirectTo: true });

      this.salvaUtenteNuovo(praticaObject);

      let creazioneObj = {
        consulente: praticaObject.consulente,
        tipo_pratica: praticaObject.tipo_pratica,
        pratica_id: data.data.pratica_id,
      };

      const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
      socket.emit("creazione", JSON.stringify(creazioneObj));

      socket.on("creazione", function (payload) {
        console.log("pratica trasmessa: ", JSON.stringify(payload));
        //      window.location.replace(`${ENDPOINT}/consulente/tipo_pratica`);
      });

      /*<Navigate to="/dashboard" replace={true} />
      socket.on('creazione', JSON.stringify(payload));
      window.location.replace("http://localhost:3000/consulente/tipo_pratica");
  */
    });
  };

  salvaUtenteNuovo = (praticaObject) => {
    if (this.state.salvaAssistito) {
      let salvaClienteObj = {
        consulenteid: praticaObject.consulente._id,
        cognome: praticaObject.assistito.cognome,
        nome: praticaObject.assistito.nome,
        email: praticaObject.assistito.email,
        telefono: praticaObject.assistito.telefono,
        cellulare: praticaObject.assistito.cellulare,
        indirizzo: praticaObject.assistito.indirizzo,
        indirizzo_altro: praticaObject.assistito.indirizzo_altro,
        cap: praticaObject.assistito.cap,
        regione: praticaObject.assistito.regione,
        comune: praticaObject.assistito.comune,
        provincia: praticaObject.assistito.provincia,
        categoria_assistito: praticaObject.assistito.categoria_assistito,
        stato_civile: praticaObject.assistito.stato_civile,
        data_stato_civile: praticaObject.assistito.data_stato_civile,
        codice_fiscale_assistito:
          praticaObject.assistito.codice_fiscale_assistito,
      };

      axios
        .post(`${ENDPOINT}/assistiti/salva`, salvaClienteObj)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  eliminaAllegato = (path, index) => {
    alert(path, index);

    console.log(this.state.allegati);
    console.log(this.state.fileInfos);

    this.setState({
      allegati: [
        ...this.state.allegati.slice(0, index),
        ...this.state.allegati.slice(index + 1),
      ],
    });

    this.setState({
      fileInfos: [
        ...this.state.fileInfos.slice(0, index),
        ...this.state.fileInfos.slice(index + 1),
      ],
    });

    client({
      url: `${ENDPOINT}/pratica/delete/allegato/?path=${path}`,
      config: {
        method: "GET",
      },
    }).then(function (response) {
      console.log(response);
    });
  };

  render() {
    const {
      selectedFiles,
      progressInfos,
      progress,
      message,
      fileInfos,
      collaboratoreVolontario,
      note,
    } = this.state;
    return (
      <>
        <div style={{ height: "100vh" }}>
          <div style={{ padding: "1rem 0", backgroundColor: "white" }}>
            <h1>{this.state.emptyAllegato}</h1>

            <Container fluid="md" style={{ marginBottom: "50px" }}>
              {this.state.isActiveAccount === "Off" ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    padding: "20px",
                    marginTop: "25px",
                  }}
                >
                  <h4>
                    Account sospeso: prego contattare CDL per l'abilitazione.
                    Grazie
                  </h4>
                </div>
              ) : null}

              <Form onSubmit={this.handleSubmit}>
                <h4>
                  <p style={{ fontSize: "smaller" }} className="font-monospace">
                    Info Pratica
                  </p>
                  <p className="font-monospace" style={{ fontSize: "smaller" }}>
                    {" "}
                    Tipo pratica: {this.state.tipo_pratica}
                  </p>
                  <p className="font-monospace" style={{ fontSize: "smaller" }}>
                    {" "}
                    Descrizione: {this.state.descrizione}
                  </p>
                </h4>

                <div></div>

                <Alert variant="warning">
                  <div>
                    <strong> Attenzione! </strong>Per la disamina dei documenti
                    caricati possono essere impiegati fino ad un massimo di 7
                    giorni..
                    <h1>{this.state.collaboratoreEsiste}</h1>
                  </div>
                </Alert>

                <div className="mb-0 text-primary 800">
                  {this.renderDocumentiNecessari()}
                </div>

                {this.renderModuli()}

                {/* SEZIONE ASSISTITO */}

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Cerca in anagrafiche esistenti</Form.Label>
                    <InputGroup>
                      <Form.Control
                        autoComplete="off"
                        type="text"
                        placeholder="auto"
                        name="auto"
                        value={this.state.assistitoLabel}
                        onChange={this.onAssistitochange}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={this.handleRestSuggestion}
                      >
                        X
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Row>
                {this.renderAssistitoSuggs()}
                <h3>
                  <p style={{ borderBottom: "solid 1px", padding: "10px" }}>
                    Anagrafica Assistito
                  </p>
                </h3>
                <Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="cognome">
                      <Form.Label>Cognome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cognome"
                        name="cognome"
                        value={
                          this.state.cliente.cognome
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="nome">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Nome"
                        name="nome"
                        value={
                          this.state.cliente.nome
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridCfAssistito">
                      <Form.Label>C.F. Richiedente</Form.Label>
                      <Form.Control
                        type="text"
                        name="codice_fiscale_assistito"
                        placeholder="AAABBB00L00H000U"
                        minLength="16"
                        value={
                          this.state.cliente.codice_fiscale_assistito
                        }
                        onChange={this.handleChangeAssistito}
                        onInput={this.handleChangeAssistito}
                        required
                      />
                      {!this.state.validCf ? (
                        <span style={{ color: "red", fontWeigth: "700" }}>
                          Codice fiscale errato
                          <i className="bi bi-exclamation-triangle-fill"></i>
                        </span>
                      ) : (
                        <span style={{ color: "green", fontWeigth: "700" }}>
                          Codice fiscale Corretto
                          <i className="bi bi-check-square"></i>
                        </span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formRegione">
                      <Form.Label>Regione</Form.Label>
                      <Form.Control
                        name="regione"
                        placeholder="Regione"
                        value={
                          this.state.cliente.regione
                        }
                        onChange={this.handleChangeAssistito}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridProvincia">
                      <Form.Label>Provincia di residenza (es. RM)</Form.Label>
                      <Form.Control
                        name="provincia"
                        placeholder="Provincia sigla"
                        value={
                          this.state.cliente.provincia
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridComune">
                      <Form.Label>Comune di residenza</Form.Label>
                      <Form.Control
                        name="comune"
                        placeholder="Comune"
                        value={
                          this.state.cliente.comune
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="indiririzzo1">
                      <Form.Label>Indirizzo di residenza</Form.Label>
                      <Form.Control
                        name="indirizzo"
                        placeholder="indirizzo"
                        value={
                          this.state.cliente.indirizzo
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formCap">
                      <Form.Label>Cap</Form.Label>
                      <Form.Control
                        name="cap"
                        placeholder="CAP"
                        value={
                          this.state.cliente.cap
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="stato">
                      <Form.Label>Stato civile</Form.Label>
                      <Form.Select
                        name="stato_civile"
                        value={
                          this.state.cliente.stato_civile
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      >
                        <option value="">Selezionare...</option>
                        <option value="nubile o celibe">Nubile/Celibe</option>
                        <option value="coniugato">Coniugato</option>
                        <option value="divorziato">Divorziato</option>
                        <option value="separato">Separato</option>
                        <option value="vedovo">Vedovo</option>
                      </Form.Select>
                    </Form.Group>

                    {this.state.dataStatoCivileShow ? (
                      <Form.Group
                        as={Col}
                        controlId="statoCivile"
                        onChange={this.handleChangeAssistito}
                      >
                        <Form.Label>Data stato civile</Form.Label>
                        <Form.Control
                          name="data_stato_civile"
                          type="date"
                          required
                          value={
                            this.state.cliente.data_stato_civile
                          }
                        />
                      </Form.Group>
                    ) : null}

                    <Form.Group as={Col} controlId="categoriaAssistito">
                      <Form.Label>Categoria Assistito</Form.Label>
                      <Form.Select
                        id="categoria_assistito"
                        name="categoria_assistito"
                        value={
                          this.state.cliente.categoria_assistito
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      >
                        <option value="">Selezionare...</option>
                        <option value="artigiano">Artigiano</option>
                        <option value="commerciante">Commerciante</option>
                        <option value="autonomo">Autonomo</option>
                        <option value="disoccupato">Disoccupato</option>
                        <option value="gestione separata">
                          Gestione separata
                        </option>
                        <option value="lavoro dipendente privato">
                          Lavoro dipendente - privato
                        </option>
                        <option value="lavoro dipendente pubblico">
                          Lavoro dipendente - pubblico
                        </option>
                        <option value="pensionato">Pensionato</option>
                        <option value="studente minore">Studente/Minore</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={
                          this.state.cliente.email
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="cellulare">
                      <Form.Label>Cellulare</Form.Label>
                      <Form.Control
                        name="cellulare"
                        placeholder="0000000000"
                        value={
                          this.state.cliente.cellulare
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="telefono">
                      <Form.Label>Telefono</Form.Label>
                      <Form.Control
                        name="telefono"
                        placeholder="0000000000"
                        value={
                          this.state.cliente.telefono
                        }
                        onChange={this.handleChangeAssistito}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <hr></hr>
                  <Row className="mb-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Salva/modifica Assistito"
                      onChange={this.handleSaveAssistito}
                    />
                    <hr />
                  </Row>
                </Row>
                {this.renderCollaboratoreVolontario()}
                {/* COLLABORATORE */}

                <Row className="mb-3">
                  <h3>
                    <p style={{ borderBottom: "solid 1px", padding: "10px" }}>
                      Data di scadenza
                    </p>
                  </h3>
                  <Form.Group
                    as={Col}
                    controlId="formGridDataScadenza"
                    onChange={this.handleScadenza}
                  >
                    <Form.Label> Data scadenza</Form.Label>
                    <Form.Control
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      required={this.state.dataScadenzaObbligatoria === "1"}
                    />
                  </Form.Group>
                </Row>

                <h3>
                  <p style={{ borderBottom: "solid 1px", padding: "10px" }}>
                    Allegati
                  </p>
                </h3>
                <Row className="mb-3">
                  {/** NUOVO UPLOADER */}
                  <div className="container blocco-row">
                    <div
                      className="label-pratica"
                      style={{ color: "rgb(0, 102, 204)", fontWeigth: "700" }}
                    >{` 1) SELEZIONA I DOCUMENTI IN PDF DA ALLEGARE ALLA PRATICA CLICCANDO SU "SELEZIONA FILES" `}</div>
                  </div>
                  <div className="container">
                    <div className="row blocco-row">
                      <FileUpload
                        accept=".pdf"
                        label="Area documenti allegati"
                        multiple
                        updateFilesCb={this.updateUploadedFiles}
                      />
                      {this.state.isVisible ? (
                        <div className="edit">
                          <div className="col">
                            <span> Documenti caricati: </span>
                            {fileInfos &&
                              fileInfos.map((fileInfo, index) => (
                                <div className="mb-2" key={index}>
                                  {/*  <span>{fileInfo.path}</span> */}
                                  <div className="progress">
                                    <div
                                      className="progress-bar progress-bar-info"
                                      role="progressbar"
                                      aria-valuenow={progress}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{ width: progress + "%" }}
                                    >
                                      {progress}%
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>

                          <div className="col">
                            <ul
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {fileInfos.map((el, i) => {
                                return (
                                  <li key={i} style={{ padding: "0.5rem" }}>
                                    <a
                                      href={`${ENDPOINTALLEGATI}${el.filename}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      downlod="true"
                                      style={{ float: "left" }}
                                    >
                                      {" "}
                                      {el.name}{" "}
                                      <i
                                        className="bi bi-file-pdf"
                                        style={{ color: "red" }}
                                      ></i>
                                      {el.filename}
                                    </a>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                      style={{ float: "right" }}
                                      onClick={() =>
                                        this.eliminaAllegato(el.filename, i)
                                      }
                                    >
                                      Elimina sul server <span> 🪣</span>
                                    </button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="row blocco-row">
                      <div
                        className="label-pratica"
                        style={{ color: "rgb(0, 102, 204)", fontWeigth: "700" }}
                      >{` 2) VERIFICA I DOCUMENTI ALLEGATI NELLA ZONA ANTEPRIMA, SE NON CORRETTI CLICCA SUL CESTINO PER ELIMINARLI. DOPO, SE VUOI PUOI AGGIUNGERNE ALTRI`}</div>
                    </div>
                    <div className="row blocco-row">
                      <div
                        className="label-pratica"
                        style={{ color: "rgb(0, 102, 204)", fontWeigth: "700" }}
                      >{` 3) CLICCA SUL BOTTONE "ALLEGA DOCUMENTI ALLA PRATICA" PER TERMINARE L'OPERAZIONE DI CARICAMENTO DEGLI ALLEGATI`}</div>
                    </div>

                    <div className="row blocco-row">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleFiles}
                        >
                          Allega documenti alla pratica
                        </button>
                      </div>
                    </div>

                    <div className="row blocco-row">
                      <div>
                        {parse(
                          "ATTENZIONE!<p> Se DOPO aver  Allegato documenti alla pratica (si ha un ripensamento) e si aggiungono o si eliminano documenti nell'area Anteprima:<p><span><strong>É necessario cliccare nuovamente sul bottone \"ALLEGA DOCUMENTI ALLA PRATICA\"!</strong> <span><br>Quindi, se hai modificato i documenti, prima di inviare la pratica, accertarti che i documenti nell'anteprima corrispondano con quelli caricati sul server (evidenziati dalla riga blu)</span></p>"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row blocco-row">
                      <div
                        className="label-pratica"
                        style={{ color: "rgb(0, 102, 204)", fontWeigth: "700" }}
                      >{` 4) AGGIUNGI UNA NOTA NEL CAMPO NOTE`}</div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row blocco-row">
                      <h3>
                        <p>Note</p>
                      </h3>
                      <div className="input-group">
                        <span className="input-group-text">Area di testo</span>
                        <textarea
                          className="form-control"
                          aria-label="With textarea"
                          name="note"
                          onChange={this.handleNote}
                          value={note || ""}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row blocco-row">
                      <div
                        className="label-pratica"
                        style={{ color: "rgb(0, 102, 204)", fontWeigth: "700" }}
                      >{` 5) CLICCA SU "SALVA BOZZA, SE VUOI TERMINARLA IN SEGUITO" O CLICCA SU "INVIA" PER INVIARE LA PRATICA`}</div>
                    </div>
                  </div>
                </Row>

                {this.renderBottoni()}
              </Form>
            </Container>
          </div>
          <Container fluid>
            <div
              className="spacer"
              style={{
                minHeight: "100px",
                backgroundColor: "rgb(0, 102, 204)",
                marginTop: "100px",
              }}
            ></div>
          </Container>
          {this.state.redirectTo && <Navigate to="/consulente/tipo_pratica" />}
        </div>
      </>
    );
  }
}

export default Nuovapratica;
