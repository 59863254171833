import React, { useState, useEffect, useMemo } from "react";
import UsersTableContainer from "./UsersTableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Table,
  Button,
  Accordion,
  Form,
  Col,
  Row,
} from "react-bootstrap";
//import { Console } from 'jest-util';
import axios from "axios";
import * as Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import Modal from "react-modal";
import Endpoints from "../components/services/Endpoints";
import "./users.css";
import "./ToggleSwitch.css";
import moment from "moment";

import client from "../utils/client";

import * as ExcelJS from "exceljs";

const ENDPOINT = Endpoints.getUrls();

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    overflowY: "scroll",
  },
  content: {
    inset: "50% auto auto 50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    overflowY: "scroll",
  },
};

Modal.setAppElement("#root");

function generateXLS(data) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Riepilogo");
  worksheet.columns = [
    { header: "ID", key: "userid", width: 10 },

    { header: "Nome", key: "nome", width: 20 },
    { header: "Cognome", key: "cognome", width: 20 },
    { header: "Ragione Sociale", key: "ragione_sociale", width: 20 },
    { header: "Email", key: "email", width: 30 },
    { header: "Ruolo", key: "ruolo", width: 30 },
    { header: "Pratiche Chiuse", key: "praticheChiuse", width: 10 },
    { header: "Codice Fiscale", key: "codice_fiscale", width: 20 },
    { header: "Partita IVA", key: "partita_iva", width: 15 },
    { header: "Cellulare", key: "cellulare", width: 15 },
    { header: "Telefono", key: "telefono", width: 15 },
    { header: "PEC", key: "pec", width: 30 },
    { header: "Fax", key: "fax", width: 15 },
    { header: "Indirizzo", key: "indirizzo", width: 30 },
    { header: "Provincia", key: "provincia", width: 15 },
    { header: "Regione", key: "regione", width: 15 },
    { header: "Consenso", key: "consenso", width: 5 },
    { header: "Data Creazione", key: "creato", width: 20 },
    { header: "Ultimo Accesso", key: "ultimo_accesso", width: 30 },
  ];
  worksheet.addRows([{ _id: `Totale: ${data.length} pratiche` }, {}, ...data]);

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

const AdminUsersTable = () => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [userEdit, setUserEdit] = useState({});

  const [admin, setAdmin] = useState(true);

  const [selectedId, setSelectedId] = useState("");

  const [isToggled, setIsToggled] = useState(true);

  const [isNotToggled, setIsNotToggled] = useState(false);

  const [praticheChiuseFrom, setPraticheChiuseFrom] = useState("");
  const [praticheChiuseTo, setPraticheChiuseTo] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");

  const doFetch = async () => {
    setSpinnerLoading(true);

    const response = await client({
      url: `/users`,

      config: {
        method: "GET",
        params: {
          ...(praticheChiuseFrom && { praticheChiuseFrom: praticheChiuseFrom }),
          ...(praticheChiuseTo && { praticheChiuseTo: praticheChiuseTo }),
          ...(subscriptionStatus && { subscriptionStatus: subscriptionStatus }),
        },
      },
    });

    const users = response.data;
    setData(users);
    setSpinnerLoading(false);
  };

  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("currentUser"));
    const role = admin.role[0];

    if (role !== "ROLE_ADMIN") {
      alert("Non hai l'accesso a questo livello");
    } else {
      doFetch();
    }
  }, []);

  const renewTable = (val) => {
    setData((data) => data.filter(({ _id }) => _id !== val.userId));
  };

  const handleShow = (event) => {
    let userId = event.cell.row.values._id;

    Swal.fire({
      title: `Elimina ${event.cell.row.values.cognome} ${event.cell.row.values.nome}`,
      text: `Attenzione! Questa operazione può influenzare lepratiche create da questo utente `,
      icon: "danger",
      html: `Attenzione! Questa operazione può influenzare le pratiche già create da questo utente <br><b>Vuoi Procedere comunque?<br>`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, elimina!",
      footer: `<b>Admin operation<b/>`,
    }).then((result) => {
      if (result.isConfirmed) {
        client({
          url: `/admin/user/${userId}`,
          config: { method: "DELETE" },
        }).then((response) => {
          if (response.data.success) {
            let updTbl = {
              userId: userId,
            };
            renewTable(updTbl);
            Swal.fire("Eliminata!", data.success, "Grazie");
          }
          if (response.data.error) {
            Swal.fire("Errore! non eliminare");
          }
        });
      }
    });
  };

  const handleUpdate = (event) => {
    let idUser = event.cell.row.values._id;
    setSelectedId(idUser);
    if (!event.row.isExpanded) event.row.toggleRowExpanded();

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .get(`${ENDPOINT}/users/${idUser}`, {
        headers: headers,
      })
      .then((response) => {
        setUserEdit(response.data[0]);
      });

    openModal();
  };

  const handleSubmit = (event) => {
    let statox;
    if (event.target.stato.checked) {
      statox = "On";
    } else {
      statox = "Off";
    }

    let rolesArray = [];
    if (event.target.admin.checked) {
      rolesArray.push(event.target.admin.value);
    }
    if (event.target.consulente.checked) {
      rolesArray.push(event.target.consulente.value);
    }
    if (event.target.operatore.checked) {
      rolesArray.push(event.target.operatore.value);
    }
    if (event.target.teleconsul.checked) {
      rolesArray.push(event.target.teleconsul.value);
    }
    if (event.target.namirial.checked) {
      rolesArray.push(event.target.namirial.value);
    }
    if (event.target.regionale.checked) {
      rolesArray.push(event.target.regionale.value);
    }

    let statoValue = "";
    if (event.target.stato.checked) {
      statoValue = event.target.stato.value;
    } else statoValue = "Off";

    const userObj = {
      nome: event.target.nome.value,
      cognome: event.target.cognome.value,
      codice_fiscale: event.target.codice_fiscale.value,
      partita_iva: event.target.partita_iva.value,
      ragione_sociale: event.target.ragione_sociale.value,
      cellulare: event.target.cellulare.value,
      telefono: event.target.telefono.value,
      consenso: "si",
      email: event.target.email.value,

      pec: event.target.pec.value,
      fax: event.target.fax.value,
      roles: rolesArray,
      stato: statox,
      freeAccount: event.target.freeAccount.checked,
      //  password:"password",
      // ritratto:"",
      //  ultimo_accesso:"",
      indirizzo: {
        via: event.target.via.value,
        cap: event.target.cap.value,
        localita_esercizio: event.target.localita_esercizio.value,
        comune: event.target.comune.value,
        provincia: event.target.provincia.value,
        regione: event.target.regione.value,
        citta: event.target.citta.value,
      },
      collaboratore: {
        nome: event.target.cv_nome.value,
        cognome: event.target.cv_cognome.value,
        codice_fiscale: event.target.cv_codice_fiscale.value,
      },
    };

    console.log(userObj);

    // return
    // qui chiamo utenti per distinguerli dalla classe users
    axios
      .post(`${ENDPOINT}/admin/utenti/editone/id?_id=${selectedId}`, userObj)
      .then(function (response) {
        console.log(response);
        const doFetch = async () => {
          setSpinnerLoading(true);

          // ora le API per le chiamate generiche degli utenti sono sotto classe users
          const response = await client({
            url: `/users/`,
            config: { method: "GET" },
          });
          const users = response.data;
          setData(users);
          setSpinnerLoading(false);
        };
        doFetch();
      })
      .catch(function (error) {
        console.log(error);
      });

    closeModal();
    event.preventDefault();
  };

  function setRoles(e, item) {
    if (e.target.name == "admin") {
      setAdmin(admin);
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setUserEdit({});
    setIsOpen(false);
  }

  const onToggle = (initial) => {
    setIsToggled(!isToggled);
    setIsNotToggled(!isNotToggled);
  };

  const renderRowSubComponent = (row) => {
    let {
      nome,
      cognome,
      codice_fiscale,
      partita_iva,
      ragione_sociale,
      cellulare,
      telefono,
      consenso,
      email,
      creato,
      pec,
      fax,
      roles,
      stato,
      freeAccount,
      password,
      ritratto,
      ultimo_accesso,
      ...rest
    } = row.original;

    let indirizzo = rest.indirizzo;
    let collaboratore = rest.collaboratore;
    let mandato = rest.mandato;
    let documento = rest.documento;

    const StatusToggleSwitch = () => {
      return (
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="stato"
            id="stato"
            checked={stato === "Off" ? isNotToggled : isToggled}
            onChange={() => onToggle(stato)}
          />
          <span className="switch" />
        </label>
      );
    };

    const FreeUserToggleSwitch = () => {
      return (
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="freeAccount"
            id="freeAccount"
            defaultChecked={freeAccount}
          />
          <span className="switch" />
        </label>
      );
    };

    const RenderIndirizzo = () => {
      return (
        <Table className="table" style={{ borderColor: "rgb(223 221 218)" }}>
          <thead className="label-user">
            <tr>
              <th>via</th>
              <th>cap</th>
              <th>località esercizio</th>
              <th>comune</th>
              <th>provincia</th>
              <th>regione</th>
            </tr>
          </thead>
          <tbody className="label-user">
            <tr>
              {Object.keys(indirizzo).map((oneKey, i) => {
                return <td key={i}>{indirizzo[oneKey]}</td>;
              })}
            </tr>
          </tbody>
        </Table>
      );
    };

    const RenderRoles = () => {
      return (
        <div className="bd-example">
          {roles?.map((rowData, index) => (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={rowData}
                id="flexCheckDefault"
                defaultChecked={true}
              />
              <label
                style={{ fontSize: "smaller" }}
                className="form-check-label"
                htmlFor="flexCheckDefault"
              >
                {rowData}
              </label>
            </div>
          ))}
        </div>
      );
    };

    const RenderCollaboratore = () => {
      return (
        <Table className="table" style={{ borderColor: "rgb(223 221 218)" }}>
          <thead className="label-user">
            <tr>
              <th>nome</th>
              <th>cognome</th>
              <th>codice_fiscale</th>
            </tr>
          </thead>
          <tbody className="label-user">
            <tr>
              {Object.keys(collaboratore).map((oneKey, i) => {
                return <td key={i}>{collaboratore[oneKey]}</td>;
              })}
            </tr>
          </tbody>
        </Table>
      );
    };
    const RenderDocumentoConsulente = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {documento?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/identitausers/${rowData.filename}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod="download"
                  >
                    {rowData.filename}
                  </a>
                </td>
                <td>
                  {moment(rowData.creato, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderMandatoCollaboratore = () => {
      return (
        <Table bordered>
          <thead className="label-pratica">
            <tr>
              <th>#</th>
              <th>tipo</th>
              <th>url</th>
              <th>Creato</th>
            </tr>
          </thead>
          <tbody style={{ color: "#434444" }}>
            {mandato?.map((rowData, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {" "}
                  <i
                    className="bi bi-file-pdf"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </td>
                <td>
                  {" "}
                  <a
                    href={`${ENDPOINT}/static/mandato/${rowData.filename}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    downlod="download"
                  >
                    {rowData.filename}
                  </a>
                </td>
                <td>
                  {moment(rowData.creato, "DD/MM/YYYY, HH:mm:ss").format(
                    "DD/MM/YYYY, HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    const RenderCollaboratoreEdit = () => {
      return Object.entries(collaboratore).map(([key, value]) => {
        return (
          <div className="col input-group-sm">
            <label
              style={{ fontSize: "smaller" }}
              htmlFor="cv_collaboratore"
              className="form-label"
            >
              {key}
            </label>
            <input
              type="text"
              name={"cv_" + key}
              className="form-control"
              id={"cv_" + key}
              defaultValue={value.toString()}
            />
          </div>
        );
      });
    };

    const checkRolesList = [
      "admin",
      "consulente",
      "operatore",
      "teleconsul",
      "namirial",
      "regionale",
    ];

    const RenderRolesList = () => {
      return (
        <div className="my-4 mb-4">
          {checkRolesList.map((item, index) => (
            <div key={index} className="form-check form-check-inline">
              {findValue(item) ? (
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={item}
                  value={item}
                  id={item}
                  defaultChecked={item}
                />
              ) : (
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={item}
                  value={item}
                  id={item}
                  defaultChecked={!item}
                />
              )}

              <label
                style={{ fontSize: "smaller" }}
                className="form-check-label"
                htmlFor="operatore"
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      );
    };

    const findValue = (valore) => {
      //   const array1 = [5, 12, 8, 130, 44];
      const found = roles.find((element) => element === valore);
      if (found) {
        return true;
      }
    };

    const RenderModal = () => {
      return (
        <Modal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {/*    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <div className="row mb-2">
            <div className="col">
              <button
                type="button"
                className="btn btn-secondary btn-sm "
                onClick={closeModal}
                style={{ float: "right" }}
              >
                Chiudi
              </button>
            </div>
          </div>
          <form className="form-inline" onSubmit={handleSubmit}>
            <div className="row mb-2">
              <div className="col-6 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="staticEmail2"
                  className="sr-only"
                >
                  Nome
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nome"
                  name="nome"
                  defaultValue={userEdit.nome}
                />
              </div>
              <div className="col-6 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="inputPassword2"
                  className="sr-only"
                >
                  Cognome
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cognome"
                  name="cognome"
                  defaultValue={userEdit.cognome}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Ragione Sociale
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ragione_sociale"
                  name="ragione_sociale"
                  defaultValue={userEdit.ragione_sociale}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  defaultValue={userEdit.email}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-4 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Cellulare
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cellulare"
                  name="cellulare"
                  defaultValue={userEdit.cellulare}
                />
              </div>
              <div className="col-4 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Telefono
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="telefono"
                  name="telefono"
                  defaultValue={userEdit.telefono}
                />
              </div>
              <div className="col-4 input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Fax
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fax"
                  name="fax"
                  defaultValue={userEdit.fax}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Pec
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pec"
                  name="pec"
                  defaultValue={userEdit.pec}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  C.F
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="codice_fiscale"
                  name="codice_fiscale"
                  defaultValue={userEdit.codice_fiscale}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  P.I
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="partita_iva"
                  name="partita_iva"
                  defaultValue={userEdit.partita_iva}
                />
              </div>
            </div>

            <div className="row mb-2 mt-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  via
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="via"
                  name="via"
                  defaultValue={indirizzo?.via}
                />
              </div>
            </div>
            <div className="row mb-2 mt-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  CAP
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cap"
                  name="cap"
                  defaultValue={indirizzo?.cap}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Comune
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="comune"
                  name="comune"
                  defaultValue={indirizzo?.comune}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Provincia
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="provincia"
                  name="provincia"
                  defaultValue={indirizzo?.provincia}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Regione
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="regione"
                  name="regione"
                  defaultValue={indirizzo?.regione}
                />
              </div>
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Citta
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="citta"
                  name="citta"
                  defaultValue={indirizzo?.comune}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col input-group-sm">
                <label
                  style={{ fontSize: "smaller" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Località esercizio
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="localita_esercizio"
                  name="localita_esercizio"
                  defaultValue={indirizzo?.comune}
                />
              </div>
            </div>

            <div className="container tabella" id="storico-pratica">
              <div className="row row-user">
                <div>
                  Roles <i className="bi bi-person-plus-fill"></i>
                </div>
                <div className="col input-group-sm">{RenderRolesList()}</div>

                <div className="col-3">
                  <div>
                    Status <i className="bi bi-key-fill"></i>
                  </div>

                  {/* RenderActiveSwitch() */}
                  {StatusToggleSwitch()}
                </div>

                <div className="col-3">
                  <div>Account gratis</div>
                  {FreeUserToggleSwitch()}
                </div>
              </div>
            </div>

            <div className="container tabella" id="storico-pratica">
              <div className="row row-user">
                <div>
                  {" "}
                  Collaboratore volontario
                  <i className="bi bi-person-plus-fill"></i>
                </div>
              </div>
              <div className="row row-user">{RenderCollaboratoreEdit()}</div>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </Modal>
      );
    };

    return (
      <>
        {RenderModal()}

        <div className="container tabella" id="test">
          <div className="row row-user">
            <div> Utente</div>
            <div className="col input-group-sm">
              <small className="label-user">Cognome: </small>
              {`${cognome} `}
            </div>
            <div className="col input-group-sm">
              <small className="label-user">Nome: </small>
              {`${nome} `}
            </div>
            <div className="col input-group-sm">
              <i className="bi bi-envelope"></i>
              <small className="label-user"> Email: </small>
              {`${email} `}
            </div>

            <div className="col input-group-sm">
              <small className="label-user">ragione_sociale: </small>
              {`${ragione_sociale} `}
            </div>
          </div>
          <div className="row row-user">
            <div className="col input-group-sm">
              <i className="bi bi-phone"></i>
              <small className="label-user"> Cellulare: </small>
              {`${cellulare} `}
            </div>
            <div className="col input-group-sm">
              <i className="bi bi-telephone"></i>
              <small className="label-user"> Telefono: </small>
              {`${telefono} `}
            </div>
            <div className="col input-group-sm">
              <small className="label-user">pec: </small>
              {`${pec} `}
            </div>
            <div className="col input-group-sm">
              <small className="label-user">fax: </small>
              {`${fax} `}
            </div>
          </div>
        </div>

        <div className="container tabella">
          <div className="row row-user">
            <div className="col input-group-sm">
              <small className="label-user">codice_fiscale: </small>
              {`${codice_fiscale} `}
            </div>
            <div className="col input-group-sm">
              <small className="label-user">partita_iva: </small>
              {`${partita_iva} `}
            </div>
            <div className="col input-group-sm">
              <small className="label-user">consenso: </small>
              {`${consenso} `}
            </div>
            <div className="col input-group-sm">
              <small className="label-user">creato: </small>
              {`${moment(creato, "DD/MM/YYYY, HH:mm:ss").format(
                "DD/MM/YYYY, HH:mm"
              )} `}
            </div>
          </div>
        </div>

        <div className="container tabella" id="list-Indirizzo">
          <div className="row row-user">
            <div>
              {" "}
              Indirizzo <i className="bi bi-card-text"></i>
            </div>
            <div className="col input-group-sm">{RenderIndirizzo()}</div>
          </div>
        </div>

        <div className="container tabella" id="list-roles">
          <div className="row row-user">
            <div>
              Roles <i className="bi bi-person-plus-fill"></i>
            </div>
            <div className="col input-group-sm">{RenderRoles()}</div>
            <div className="col -2">
              <div className="form-check form-switch">
                <label
                  style={{ fontSize: "smaller" }}
                  className="form-check-label"
                  htmlFor="operatore"
                >
                  Stato: {stato}
                </label>
              </div>
            </div>

            <div className="col -2">
              <div className="form-check form-switch">
                <label
                  style={{ fontSize: "smaller" }}
                  className="form-check-label"
                  htmlFor="operatore"
                >
                  Account Gratis: {freeAccount ? "Si" : "No"}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="container tabella" id="list-roles"></div>

        <div className="container tabella" id="list-collaboratore">
          <div className="row row-user">
            <div>
              {" "}
              Documento Consulente <i className="bi bi-people-fill"></i>
            </div>
            <div className="col input-group-sm">
              {RenderDocumentoConsulente()}
            </div>
          </div>
        </div>

        <div className="container tabella" id="list-collaboratore">
          <div className="row row-user">
            <div>
              {" "}
              Collaboratore Volontario <i className="bi bi-people-fill"></i>
            </div>
            <div className="col input-group-sm">{RenderCollaboratore()}</div>
          </div>
        </div>

        <div className="container tabella" id="list-collaboratore">
          <div className="row row-user">
            <div>
              {" "}
              Mandato Collaboratore Volontario
              <i className="bi bi-people-fill"></i>
            </div>
            <div className="col input-group-sm">
              {RenderMandatoCollaboratore()}
            </div>
          </div>
        </div>

        <div className="container tabella" id="list-ultimo-accesso">
          <div className="col input-group-sm">
            <label
              style={{ fontSize: "smaller" }}
              htmlFor="exampleFormControlInput1"
              className="form-label"
            >
              Ultimo accesso:{" "}
            </label>
            <label
              style={{ fontSize: "smaller" }}
              htmlFor="exampleFormControlInput1"
              className="form-label"
            >
              {moment(ultimo_accesso).format("DD/MM/YYYY, HH:mm")}
            </label>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander", // 'id' is required
        Cell: ({ row }) => {
          return (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <i className="bi bi-caret-down-fill"></i>
              ) : (
                <i className="bi bi-caret-right-fill"></i>
              )}
            </span>
          );
        },
      },
      {
        Header: "_id",
        accessor: "_id",
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Cognome",
        accessor: "cognome",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Ruolo",
        accessor: (b) => `${b.roles}`,
      },
      {
        Header: "Pratiche chiuse",
        accessor: (b) => `${b.praticheChiuse || ""}`,
      },
      {
        Header: "Stato",
        accessor: (c) => `${c.stato}`,
      },
      {
        Header: "Creato",
        sortType: () => 1,
        accessor: (c) =>
          `${moment(c.creato, "DD/MM/YYYY, HH:mm:ss").format(
            "DD/MM/YYYY, HH:mm"
          )}`,
      },
      {
        Header: "Regione",
        accessor: (d) => `${d.indirizzo?.regione}`,
      },
      {
        Header: "Provincia",
        accessor: (e) => `${e.indirizzo?.provincia}`,
      },
      {
        Header: "Modifica",
        id: "id",
        Cell: (props) => {
          return (
            <span>
              <Button
                className="btn btn-sm btn-default"
                onClick={() => handleUpdate(props)}
              >
                {" "}
                <i className="bi bi-pen"></i> Modifica!
              </Button>
            </span>
          );
        },
      },
      {
        Header: "Elimina",
        id: "ids",
        Cell: (props) => (
          <span>
            <Button
              className="btn btn-sm btn-danger"
              onClick={() => handleShow(props)}
            >
              {" "}
              <i className="bi bi-trash3"></i> Elimina!
            </Button>{" "}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <Container fluid style={{ marginTop: 25, marginBottom: "40px" }}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <i class="bi bi-binoculars"></i> Cerca sul server{" "}
          </Accordion.Header>
          <Accordion.Body>
            <p style={{ fontWeigth: "medium", color: "darkred" }}>
              Il campo ID va utilizzato solo e non in combo con altri campi
            </p>
            <Form className="search-form">
              <Row>
                <span>Numero di pratiche chiuse: </span>
                <Form.Control
                  placeholder="DA"
                  name="find_pratiche_chiuse_from"
                  type="number"
                  value={praticheChiuseFrom}
                  onChange={(e) => setPraticheChiuseFrom(e.target.value)}
                  style={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                />

                <Form.Control
                  placeholder="A"
                  name="find_pratiche_chiuse_to"
                  value={praticheChiuseTo}
                  onChange={(e) => setPraticheChiuseTo(e.target.value)}
                  type="number"
                  style={{ width: "100px", marginRight: "50px" }}
                />

                <Form.Select
                  aria-label="Stato abbonamento"
                  value={subscriptionStatus}
                  onChange={(e) => setSubscriptionStatus(e.target.value)}
                  style={{ width: "200px", marginRight: "50px" }}
                >
                  <option>Abbonamento</option>
                  <option value="free">
                    Credenziali attivate gratuitamente
                  </option>
                  <option value="active">
                    Credenziali attivate con abbonamento
                  </option>
                </Form.Select>

                <Button
                  variant="primary"
                  onClick={() => doFetch()}
                  style={{ width: "100px", marginRight: "50px" }}
                >
                  Cerca
                </Button>

                <Button
                  variant="primary"
                  disabled={spinnerLoading}
                  style={{ width: "250px", backgroundColor: "#008000" }}
                  onClick={() =>
                    generateXLS(
                      data.map((u) => ({
                        userid: u.userid,
                        nome: u.nome,
                        cognome: u.cognome,
                        ragione_sociale: u.ragione_sociale,
                        email: u.email,
                        praticheChiuse: u.praticheChiuse || "",
                        codice_fiscale: u.codice_fiscale,
                        partita_iva: u.partita_iva,
                        cellulare: u.cellulare,
                        telefono: u.telefono,
                        indirizzo: `${u.indirizzo?.via}  ${u.indirizzo?.cap}  ${u.indirizzo?.comune} `,
                        provincia: u.indirizzo?.provincia,
                        regione: u.indirizzo?.regione,
                        consenso: u.consenso,
                        creato: u.creato,
                        ultimo_accesso: u.ultimo_accesso,
                        pec: u.pec,
                        fax: u.fax,
                        ruolo:
                          u.roles &&
                          Array.isArray(u.roles) &&
                          u.roles.join(", "),
                      }))
                    )
                  }
                >
                  Esporta {data.length} risultati in Excel
                </Button>
              </Row>
            </Form>
            {data.length && (
              <p style={{ fontWeigth: "medium", marginTop: "20px" }}>
                Cerca tra {data.length} risultati
              </p>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div
        style={{
          textAlign: "center",
          textAlign: "center",
          paddingBottom: "2rem",
          fontWeight: "600",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-house-fill"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
          />
          <path
            fill-rule="evenodd"
            d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
          />
        </svg>{" "}
        / Admin / Utenti / Vedi
      </div>
      <div
        className="test"
        style={{ paddingBottom: "100px", background: "rgb(222 223 225)" }}
      >
        <div style={{ textAlign: "center", marginLeft: "44%" }}>
          {spinnerLoading ? (
            <Loader.ThreeDots color="white" height={100} width={100} />
          ) : null}
        </div>

        <UsersTableContainer
          columns={columns}
          data={data}
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </Container>
  );
};

export default AdminUsersTable;
