import React, { useState, useEffect } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
//import { ToastContainer, toast } from 'react-toastify';
import Endpoints from "../components/services/Endpoints";
//import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navbar,
  Nav,
  Container,
  Badge,
  Button,
  NavDropdown,
  NavbarBrand,
} from "react-bootstrap";

import logoNegativo from "../CDLlogoNegativo.svg";
import "./NavLinkAdmin.css";
//import './customize.css';
//import './style.css';
const ENDPOINT = Endpoints.getUrls();

export default function MenuPraticheOp() {
  const [inviate, setInviate] = useState(0);
  const [chiuse, setChiuse] = useState(0);
  const [sospese, setSospese] = useState(0);
  const [annullate, setAnnullate] = useState(0);
  const [lavorazione, setLavorazione] = useState(0);
  //  const [assegnata, setAssegnata] = useState(0);
  const [generali, setGenerali] = useState(0);
  const [online, setOnline] = useState(0);
  const [active, setActive] = useState("default");

  /*
  const options = {
    onOpen: props => console.log(props.foo),
    onClose: props => console.log(props.foo),
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
   // progress: 0.2,
    background: '#0dcaf0',
    autoClose: 8000,
    // and so on ...
};
*/
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const result = await axios(`${ENDPOINT}/pratiche/count`);
      let { inviate, chiuse, sospese, annullate, lavorazione, generali } =
        result.data;
      setInviate(inviate);
      setChiuse(chiuse);
      setSospese(sospese);
      setAnnullate(annullate);
      setLavorazione(lavorazione);
      //   setAssegnata(assegnata);
      setGenerali(generali);
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  useEffect(() => {
    const socket2 = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log("socket2");
    console.log(socket2);

    socket2.on("visitor enters", (data) => {
      setOnline(data);
      console.log("online", data);
      // UserConnected();
    });

    socket2.on("visitor exits", (data) => {
      setOnline(data);
      console.log("online", data);
      // UserConnected();
    });

    // CLEAN UP THE EFFECT
    return () => socket2.disconnect();
  }, []);

  const handleSelect = (eventKey) => setActive(eventKey);

  return (
    <div>
      <div>
        {/* <button onClick={notify}>Notify!</button>
       
        <ToastContainer />  */}
      </div>

      <Button variant="danger" style={{ float: "right" }}>
        utenti online <Badge bg="secondary">{online}</Badge>
        <span className="visually-hidden">unread messages</span>
      </Button>

      <div
        className="logo"
        style={{ backgroundColor: "#0066cc", height: "160px" }}
      >
        <div style={{ padding: "8px", marginLeft: "16%" }}>
          <img src={logoNegativo} alt="Logo" style={{ width: "274px" }} />
        </div>
      </div>

      <div className="boh" style={{ backgroundColor: "rgb(0, 102, 204)" }}>
        <div style={{ padding: "8px" }}></div>
      </div>

      {/* 
<Navbar className='navbar navbar-expand-lg navbar-dark bg-dark'>
<Container>

  <NavbarBrand style={{paddingBottom: '1.3rem'}}>Pratiche</NavbarBrand>
  <button
  className="navbar-toggler"
  type="button"
  data-toggle="collapse"
  data-target="#basic-navbar-nav"
  aria-controls="navbarNavDropdown"
  aria-expanded="false"
  aria-label="Toggle navigation"
>
  <span className="navbar-toggler-icon"></span>
</button>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="me-auto">
    
    <NavLink to="/admin/pratiche_inviate" className={({ isActive }) => (isActive ? 'selectedLink' : 'unselectedLink')} >Inviate<Badge bg="danger ml-5" style={{marginLeft: '5px'}}>{' '}{inviate}</Badge></NavLink>
    

  <NavLink to="/admin/pratiche_generali" className={({ isActive }) => (isActive ? 'selectedLink' : 'unselectedLink')}>Lavorazione Generale<Badge bg="danger ml-5" style={{marginLeft: '5px'}}>{' '}{generali}</Badge>
</NavLink>


<NavLink to="/admin/pratiche_sospese" className={({ isActive }) => (isActive ? 'selectedLink' : 'unselectedLink')}>Spospese<Badge bg="danger ml-5" style={{marginLeft: '5px'}}>{ sospese }</Badge>{' '}</NavLink>


<NavLink to="/admin/pratiche_chiuse" className={({ isActive }) => (isActive ? 'selectedLink' : 'unselectedLink')}>Chiuse<Badge bg="danger ml-5" style={{marginLeft: '5px'}}>{ chiuse }</Badge>{' '}</NavLink>


<NavLink to="/admin/pratiche_annullate" className={({ isActive }) => (isActive ? 'selectedLink' : 'unselectedLink')}>Annullate<Badge bg="danger ml-5" style={{marginLeft: '5px'}}>{ annullate }</Badge>{' '}</NavLink>

    </Nav>
  </Navbar.Collapse>
  <Navbar.Collapse className="justify-content-end">

</Navbar.Collapse>
<Navbar.Brand href="#home">{' | '}</Navbar.Brand>
<NavDropdown title="Utenti" id="basic-nav-dropdown" style={{paddingBottom: '1.3rem'}}>
<NavDropdown.Item href="/admin/users" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}><i class="bi bi-eye-fill"></i> Vedi 
</NavDropdown.Item>

<NavDropdown.Divider />
<NavDropdown.Item href="/admin/useradd" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}><i className="bi bi-person-plus-fill"></i> Aggiungi 
</NavDropdown.Item>
<NavDropdown.Divider />
</NavDropdown>

<Navbar.Collapse className="justify-content-end">

</Navbar.Collapse>

<NavDropdown title="Moduli" id="moduli" style={{paddingBottom: '1.3rem'}}>
<NavDropdown.Item href="/admin/moduli" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
<path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
<path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
</svg> Vedi/Modifica
</NavDropdown.Item>

<NavDropdown.Divider />
<NavDropdown.Item href="/admin/moduli/add" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16z" fill="currentColor" className="bi bi-file-plus-fill" viewBox="0 0 16 16">
<path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z"/>
</svg> Aggiungi 
</NavDropdown.Item>
<NavDropdown.Divider />
<NavDropdown.Item href="/admin/moduli/associa" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-subtract" viewBox="0 0 16 16">
<path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/>
</svg> Associa 
</NavDropdown.Item>


<NavDropdown.Divider />
</NavDropdown>

<Navbar.Collapse className="justify-content-end">

</Navbar.Collapse>

<NavDropdown title="Gestione Pratiche" id="moduli" style={{paddingBottom: '1.3rem'}}>
<NavDropdown.Item href="/admin/tipopratica" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}><i className="bi bi-eye-fill"></i> Vedi/Modifica 
</NavDropdown.Item>

<NavDropdown.Divider />
<NavDropdown.Item href="/admin/tipopratica/add" className={({ isActive }) => (isActive ? 'selected' : 'unselected')}><i className="bi bi-person-plus-fill"></i> Aggiungi 
</NavDropdown.Item>
<NavDropdown.Divider />
</NavDropdown>

</Container>
</Navbar>
*/}
      <Nav
        justify
        variant="tabs"
        defaultActiveKey="link-0"
        activeKey={active}
        onSelect={(selectedKey) => setActive(selectedKey)}
      >
        <Nav.Item>
          <NavLink
            eventKey="link-1"
            to="/admin/pratiche_inviate"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Inviate
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {inviate}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventKey="link-3"
            to="/admin/pratiche_generali"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Generali
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {generali}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventKey="link-4"
            to="/admin/pratiche_sospese"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Sospese
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {sospese}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventKey="link-5"
            to="/admin/pratiche_chiuse"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Chiuse
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {chiuse}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventKey="link-6"
            to="/admin/pratiche_annullate"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Annullate
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {annullate}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavDropdown
            title="Utenti"
            id="nav-dropdown"
            className={({ isActive }) => (isActive ? "selected" : "unselected")}
          >
            <NavDropdown.Item href="/admin/users">
              <i class="bi bi-eye-fill"></i> Vedi
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/admin/useradd">
              <i className="bi bi-person-plus-fill"></i> Aggiungi
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </NavDropdown>

          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            <i class="bi bi-people-fill"></i>
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavDropdown
            title="Moduli"
            id="nav-dropdown"
            className={({ isActive }) => (isActive ? "selected" : "unselected")}
          >
            <NavDropdown.Item href="/admin/moduli">
              <i class="bi bi-eye-fill"></i> Vedi
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/admin/moduli/add">
              <i class="bi bi-folder-plus"></i> Aggiungi
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/admin/moduli/associa">
              <i class="bi bi-file-break"></i> Associa
            </NavDropdown.Item>
          </NavDropdown>

          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            <i class="bi bi-arrow-left-right"></i>
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavDropdown
            title="Gestione Pratiche"
            id="nav-dropdown"
            className={({ isActive }) => (isActive ? "selected" : "unselected")}
          >
            <NavDropdown.Item href="/admin/tipopratica">
              <i class="bi bi-eye-fill"></i> Vedi
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/admin/tipopratica/add">
              <i class="bi bi-folder-plus"></i> Aggiungi
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </NavDropdown>

          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            <i class="bi bi-archive-fill"></i>
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventKey="link-7"
            to="/admin/archivio"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Archivio
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            <i class="bi bi-archive-fill"></i>
          </Badge>
        </Nav.Item>
      </Nav>
    </div>
  );
}
