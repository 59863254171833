import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Usersignin.css'
import LogoNegativo from '../../CDLlogoNegativo.svg'


function UserForgotStepFinal(props) {

    let navigate = useNavigate();

    const vaiAllaHome = (id) => {
      navigate("/", { replace: true });
      }


    return (
      <div className="login-page bg-light">
      <div className="container">
          <div className="row">
              <div className="col-lg-10 offset-lg-1">
              <h3 className="mb-3 text-white"> </h3>
                  <div className="bg-white shadow rounded" style={{position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)',  msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'}}>
                      <div className="row">
                          <div className="col-md-7 pe-0">
                              <div className="form-left h-100 py-5 px-5">
                                  <form action="" className="row g-4">

                                  <div className="row g-4">
                                  <button type="submit" className="btn btn-primary px-4 float-end mt-4" onClick={()=>vaiAllaHome()}>Clicca qui per eseguire l'accesso</button>
                                  </div>

                                  </form>
                              </div>
                          </div>
                          <div className="col-md-5 ps-0 d-none d-md-block">
                              <div className="form-right h-100 bg-primary text-white text-center pt-5">
                                 <img src={LogoNegativo} width={300} alt="CDL logo"></img>
                                 <p></p>
                                  <h3 className="fs-1">Benfatto!!!</h3>

                                 
                              </div>
                          </div>
                      </div>
                  </div>
                  <p className="text-end text-secondary mt-3">Bootstrap 5 Login Page Design</p>
              </div>
          </div>
      </div>
  </div>
    );
}

export default UserForgotStepFinal;