import React, { useState, useEffect } from "react";
import Endpoints from "../components/services/Endpoints";
import { Button } from "react-bootstrap";
import axios from "axios";
import client from "../utils/client";

const ENDPOINT = Endpoints.getUrls();

const AdminAssociaPratica = (props) => {
  const [data, setData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [pensioni, setPensioni] = useState([]);
  const [disoccupazioniMobilita, setDisoccupazioniMobilita] = useState([]);
  const [permessiCongedi, setPermessiCongedi] = useState([]);
  const [maternita, setMaternita] = useState([]);
  const [ratei, setRatei] = useState([]);
  const [assegniNucleoFamiliare, setAssegniNucleoFamiliare] = useState([]);
  const [ricostituzioni, setRicostituzioni] = useState([]);
  const [sostegnoAlReddito, setSostegnoAlReddito] = useState([]);
  const [invaliditaConvocazioneVistita, setInvaliditaConvocazioneVistita] =
    useState([]);
  const [invaliditaFaseConcessoria, setInvaliditaFaseConcessoria] = useState(
    []
  );
  const [certificazioni, setCertificazioni] = useState([]);
  const [altrePratiche, setAltrePratiche] = useState([]);
  const [versamentiVolontari, setVersamentiVolontari] = useState([]);
  const [emergenzaCovid, setEmergenzaCovid] = useState([]);

  const [moduliList, setModuliList] = useState([]);

  useEffect(() => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/admin/moduli/`,
        config: { method: "GET" },
      });

      const moduli = response.data;
      setModuliList(moduli);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  useEffect(() => {
    const doFetch = async () => {
      setSpinnerLoading(true);
      const response = await client({
        url: `/tipopratica/`,
        config: { method: "GET" },
      });
      const paratiche = await response.data;

      let pensioniArr = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "pensioni") {
          pensioniArr.push(paratiche[i]);
        }
      }
      setPensioni(pensioniArr);

      let disoccupazioniMobArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "disoccupazioni e mobilità") {
          disoccupazioniMobArray.push(paratiche[i]);
        }
      }
      setDisoccupazioniMobilita(disoccupazioniMobArray);

      let permessiCongediArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "permessi e congedi") {
          permessiCongediArray.push(paratiche[i]);
        }
      }
      setPermessiCongedi(permessiCongediArray);

      let maternitaArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "maternità") {
          maternitaArray.push(paratiche[i]);
        }
      }
      setMaternita(maternitaArray);

      let rateiArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "ratei") {
          rateiArray.push(paratiche[i]);
        }
      }
      setRatei(rateiArray);

      let assegniNucleoFamArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "assegni nucleo familiare") {
          assegniNucleoFamArray.push(paratiche[i]);
        }
      }
      setAssegniNucleoFamiliare(assegniNucleoFamArray);

      let ricostituzioniArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "ricostituzioni") {
          ricostituzioniArray.push(paratiche[i]);
        }
      }
      setRicostituzioni(ricostituzioniArray);

      let sostegnoAlRedditoArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "sostegno al reddito") {
          sostegnoAlRedditoArray.push(paratiche[i]);
        }
      }
      setSostegnoAlReddito(sostegnoAlRedditoArray);

      let invaliditaConvocazioneVistitaArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (
          paratiche[i].tipo_pratica === "invalidità - per convocazione a visita"
        ) {
          invaliditaConvocazioneVistitaArray.push(paratiche[i]);
        }
      }
      setInvaliditaConvocazioneVistita(invaliditaConvocazioneVistitaArray);

      let invaliditaFaseConcessoriaArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (
          paratiche[i].tipo_pratica === "invalidità - fase concessoria AP70"
        ) {
          invaliditaFaseConcessoriaArray.push(paratiche[i]);
        }
      }
      setInvaliditaFaseConcessoria(invaliditaFaseConcessoriaArray);

      let certificazioniArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "certificazioni") {
          certificazioniArray.push(paratiche[i]);
        }
      }
      setCertificazioni(certificazioniArray);

      let altrePraticheArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "altre pratiche") {
          altrePraticheArray.push(paratiche[i]);
        }
      }
      setAltrePratiche(altrePraticheArray);

      let versamentiVolontariArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "versamenti volontari") {
          versamentiVolontariArray.push(paratiche[i]);
        }
      }
      setVersamentiVolontari(versamentiVolontariArray);

      let emergenzaCovidArray = [];
      for (let i = 0; i < paratiche.length; i++) {
        if (paratiche[i].tipo_pratica === "emergenza covid-19") {
          emergenzaCovidArray.push(paratiche[i]);
        }
      }
      setEmergenzaCovid(emergenzaCovidArray);

      setData(paratiche);
      setSpinnerLoading(false);
    };
    doFetch();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("hello");

    let moduloId = [];
    for (let option of event.target[0]) {
      if (option.selected) {
        console.log(option.value);
        moduloId.push(option.value);
      }
    }

    const selectedArr = [];
    for (let i = 1; i < 14; i++) {
      for (let option of event.target[i]) {
        if (option.selected) {
          selectedArr.push(option.value);
        }
      }
      console.log(JSON.stringify(selectedArr));
    }

    for (let x = 0; x < selectedArr.length; x++) {
      client({
        url: `/admin/moduli/associapratica/${selectedArr[x]}`,
        config: {
          method: "POST",
          data: {
            moduli: moduloId,
          },
        },
      })
        .then(function (response) {
          alert(response.data.success);
          // fetchData();
          window.location.reload();
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  return (
    <div className="container">
      <div
        style={{
          textAlign: "center",
          textAlign: "center",
          paddingTop: "1rem",
          paddingBottom: "3rem",
          fontWeight: "600",
          color: "white",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-house-fill"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
          />
          <path
            fill-rule="evenodd"
            d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
          />
        </svg>{" "}
        / Admin / Moduli / Associa Modulo
      </div>

      <form name="formData" onSubmit={handleSubmit} style={{ color: "white" }}>
        <div className="row mb-4 mt-4">
          <div className="col">
            <div className="card">
              <div
                className="card-body"
                style={{ background: "cornflowerblue" }}
              >
                <h5 className="card-title">Moduli</h5>
                <p className="card-text">
                  Seleziona il modulo da associare ad una o più pratiche.
                </p>
                <div className="mb-3">
                  <select
                    name="moduloSelezionato"
                    className="form-select"
                    aria-label="Default select example"
                  >
                    {moduliList?.map((val) => (
                      <option
                        key={val._id}
                        value={val._id}
                        style={{ padding: "0.4rem" }}
                      >
                        {val.tipo}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 mt-4">
          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Pensioni
              </label>
              <select
                multiple
                name="pensioni"
                style={{ minHeight: "14rem" }}
                className="form-select"
                aria-label="Default select example"
              >
                {pensioni?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-8">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Disoccupazioni e Mobilità
              </label>
              <select
                multiple
                name="disoccupazioneMobilita"
                className="form-select"
                aria-label="Default select example"
                style={{ minHeight: "14rem", fontSize: "smaller" }}
              >
                {disoccupazioniMobilita?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row mb-4 mt-4">
          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Permessi e Congedi
              </label>
              <select
                multiple
                className="form-select"
                aria-label="Default select example"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
              >
                {permessiCongedi?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Maternità
              </label>
              <select
                multiple
                name="maternita"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {maternita?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Ratei
              </label>
              <select
                multiple
                name="ratei"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {ratei?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Assegni nucleo famigliare
              </label>
              <select
                multiple
                name="assegniNucleoFamigliare"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {assegniNucleoFamiliare?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Ricostituzioni
              </label>
              <select
                multiple
                name="ricostituzioni"
                style={{
                  minHeight: "12rem",
                  fontSize: "smaller",
                  overflowX: "scroll",
                }}
                className="form-select"
                aria-label="Default select example"
              >
                {ricostituzioni?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Sostegno al Reddito
              </label>
              <select
                multiple
                name="sostegnoReddito"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {sostegnoAlReddito?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                invalidità Convocazione Vistita
              </label>
              <select
                multiple
                name="invaliditaVisita"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {invaliditaConvocazioneVistita?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                invalidità Fase Concessoria AP70
              </label>
              <select
                multiple
                name="invaliditaConcessoria"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {invaliditaFaseConcessoria?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Certificazioni
              </label>
              <select
                multiple
                name="certificazioni"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {certificazioni?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Altre Pratiche
              </label>
              <select
                multiple
                name="altrePratiche"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {altrePratiche?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Versamenti Volontari
              </label>
              <select
                multiple
                name="versamentiVolontari"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {versamentiVolontari?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Emergenza Covid-19
              </label>
              <select
                multiple
                name="emergenzaCovid"
                style={{ minHeight: "12rem", fontSize: "smaller" }}
                className="form-select"
                aria-label="Default select example"
              >
                {emergenzaCovid?.map((val) => (
                  <option
                    key={val._id}
                    value={val._id}
                    style={{ padding: "0.4rem" }}
                  >
                    {val.descrizione}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mb-3">
              <Button
                type="submit"
                variant="primary"
                style={{ float: "right" }}
              >
                Associa!
              </Button>{" "}
            </div>
          </div>
        </div>
      </form>

      <div className="row">
        <div
          style={{
            textAlign: "center",
            textAlign: "center",
            paddingTop: "1rem",
            paddingBottom: "3rem",
            fontWeight: "600",
          }}
        ></div>
      </div>
    </div>
  );
};

export default AdminAssociaPratica;
