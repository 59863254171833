import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  Card,
} from "react-bootstrap";
import Endpoints from "../components/services/Endpoints";
import Swal from "sweetalert2";
import "./users.css";

import client from "../utils/client";

var CodiceFiscale = require("codice-fiscale-js");
const ENDPOINT = Endpoints.getUrls();

function AdminUserAdd(props) {
  const [unchecked, setUnchecked] = useState(false);
  const [isConsulente, setIsConsulente] = useState(false)

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [gender, setGender] = useState("");
  const [birthplaceProvincia, setBirthplaceProvincia] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [codiceFiscaleCv, setCodiceFiscaleCv] = useState("");

  const [verificato, setVerificato] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let rolesArray = [];
      if (event.target.admin.checked) {
        rolesArray.push(event.target.admin.value);
      }
      if (event.target.consulente.checked) {
        rolesArray.push(event.target.consulente.value);
      }
      if (event.target.operatore.checked) {
        rolesArray.push(event.target.operatore.value);
      }
      if (event.target.teleconsul.checked) {
        rolesArray.push(event.target.teleconsul.value);
      }
      if (event.target.namirial.checked) {
        rolesArray.push(event.target.namirial.value);
      }
      if (event.target.regionale.checked) {
        rolesArray.push(event.target.regionale.value);
      }

      let newUserObj = {
        nome: event.target.nome.value,
        cognome: event.target.cognome.value,
        codice_fiscale: event.target.codice_fiscale.value,
        partita_iva: event.target.partita_iva.value,
        ragione_sociale: event.target.ragione_sociale.value,
        cellulare: event.target.cellulare.value,
        telefono: event.target.telefono.value,
        consenso: "si",
        email: event.target.email.value,
        creato: new Date().toLocaleString("it-IT"),
        pec: event.target.pec.value,
        fax: event.target.fax.value,
        roles: rolesArray,
        stato: "On",
        password:
          "$2a$10$1ptBKCI2SSkvkd4UGO6aou3smO59DuCAp8hfYqpi21XSwpafkqbRy",
        ritratto: "",
        ultimo_accesso: "",
        indirizzo: {
          via: event.target.via.value,
          cap: event.target.cap.value,
          localita_esercizio: event.target.localita_esercizio.value,
          comune: event.target.comune.value,
          provincia: event.target.provincia.value,
          regione: event.target.regione.value,
          citta: event.target.citta.value,
        },
        collaboratore: {
          nome: name,
          cognome: surname,
          codice_fiscale: codiceFiscaleCv,
        },
      };

      if (newUserObj.nome) {
        client({
          url: `admin/user/create`,
          config: { method: "POST", data: newUserObj },
        })
          .then(function (response) {
            Swal.fire(`Good job! ${response.data}`, "success");
            event.target.reset();
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      event.preventDefault();
    }
    setValidated(true);
  };

  const onChangeSurName = async (event) => {
    await setSurname(event.target.value);
  };

  const onChangeName = async (event) => {
    await setName(event.target.value);
  };

  const onChangeGender = async (event) => {
    await setGender(event.target.value);
  };

  const onChangeBirthplace = async (event) => {
    await setBirthplace(event.target.value);
  };
  const onChangeBirthplaceProvincia = async (event) => {
    await setBirthplaceProvincia(event.target.value);
  };

  const onChangeDay = async (event) => {
    await setDay(event.target.value);
  };
  const onChangeMonth = async (event) => {
    await setMonth(event.target.value);
  };
  const onChangeYear = async (event) => {
    await setYear(event.target.value);
  };

  const calcolaCF = () => {
    calcolaCFx();
  };

  const verificaCF = (event) => {
    verificaCFx(event.target.value);
  };

  function calcolaCFx() {
    var cf = new CodiceFiscale({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });

    setCodiceFiscaleCv(cf);

    console.log({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });
    var cf = new CodiceFiscale({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });

    console.log(cf.code);

    setCodiceFiscaleCv(cf.code);
    verificaCFx(cf.code);
  }

  function verificaCFx(cf) {
    if (CodiceFiscale.check(cf)) {
      console.log("corretto");
      setVerificato(true);
    } else {
      console.log("errato");
      setVerificato(false);
    }
  }

  const RendeForm = () => {
    return (
      <>
        <Container style={{ paddingBottom: "100px" }}>
          <Card style={{ width: "auto" }}>
            <Card.Body>
              <Card.Title>Crea un nuovo utente</Card.Title>
              <Card.Text> </Card.Text>

              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Nome</Form.Label>
                    <Form.Control
                      required
                      placeholder="Nome"
                      name="nome"
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Cognome</Form.Label>
                    <Form.Control
                      required
                      placeholder="Cognome"
                      name="cognome"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Ragione Sociale
                    </Form.Label>
                    <Form.Control
                      placeholder="ragione_sociale"
                      name="ragione_sociale"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Email</Form.Label>
                    <Form.Control
                      placeholder="email"
                      name="email"
                      required
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Cellulare
                    </Form.Label>
                    <Form.Control
                      placeholder="cellulare"
                      name="cellulare"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Telefono
                    </Form.Label>
                    <Form.Control
                      placeholder="telefono"
                      name="telefono"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Fax</Form.Label>
                    <Form.Control
                      placeholder="fax"
                      defaultValue={"0000000000"}
                      name="fax"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Pec</Form.Label>
                    <Form.Control placeholder="pec" name="pec" type="text" />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">C.F.</Form.Label>
                    <Form.Control
                      placeholder="codice_fiscale"
                      name="codice_fiscale"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">P. I.</Form.Label>
                    <Form.Control
                      placeholder="partita_iva"
                      name="partita_iva"
                      required={isConsulente}
                      type="text"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-2 mt-2">
                    <Form.Label htmlFor="disabledTextInput">Via</Form.Label>
                    <Form.Control
                      placeholder="via"
                      name="via"
                      required
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">CAP</Form.Label>
                    <Form.Control
                      placeholder="cap"
                      name="cap"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Comune</Form.Label>
                    <Form.Control
                      placeholder="comune"
                      name="comune"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Provincia
                    </Form.Label>
                    <Form.Control
                      placeholder="provincia"
                      name="provincia"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Regione</Form.Label>
                    <Form.Control
                      placeholder="regione"
                      name="regione"
                      required
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Città</Form.Label>
                    <Form.Control
                      placeholder="citta"
                      name="citta"
                      required
                      type="text"
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Località esercizio
                    </Form.Label>
                    <Form.Control
                      placeholder="localita_esercizio"
                      name="localita_esercizio"
                      required
                      type="text"
                    />
                  </Col>
                </Row>
                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      <div>
                        Roles 2 <i className="bi bi-person-plus-fill"></i>
                      </div>
                    </Form.Label>
                    {RenderRolesList()}
                  </Col>
                </Row>

                <Container>
                  <Row>
                    <Col>
                      <Form.Label htmlFor="disabledTextInput">
                        Collaboratore volontario{" "}
                        <i className="bi bi-person-plus-fill"></i>
                      </Form.Label>
                    </Col>
                  </Row>
                </Container>

                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Cognome</Form.Label>
                    <Form.Control
                      placeholder="cv_cognome"
                      name="surname"
                      required
                      type="text"
                      onChange={onChangeSurName}
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">Nome</Form.Label>
                    <Form.Control
                      placeholder="cv_nome"
                      name="name"
                      required
                      type="text"
                      onChange={onChangeName}
                    />
                  </Col>
                  <Col className="col-2">
                    <Form.Label htmlFor="disabledTextInput">Sesso</Form.Label>
                    <Form.Select
                      aria-label="Sesso"
                      name="gender"
                      required
                      onChange={onChangeGender}
                    >
                      <option>Apri per selezionare</option>
                      <option value="M">M</option>
                      <option value="F">F</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mb-3 mt-3">
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Luogo di nascita
                    </Form.Label>
                    <Form.Control
                      placeholder="luogo"
                      name="birthplace"
                      onChange={onChangeBirthplace}
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="disabledTextInput">
                      Provincia (Sigla)
                    </Form.Label>
                    <Form.Control
                      placeholder="provincia"
                      name="birthplaceProvincia"
                      maxLength={2}
                      onChange={onChangeBirthplaceProvincia}
                    />
                  </Col>
                </Row>

                <Row className="mb-2 mt-2">
                  <Col>
                    <Form.Label htmlFor="Giorno">Giorno</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="day"
                      onChange={onChangeDay}
                    >
                      <option>Apri per selezionare</option>
                      <option value="02">02</option>
                      <option value="01">01</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </Form.Select>
                  </Col>

                  <Col>
                    <Form.Label htmlFor="Mese">Mese</Form.Label>
                    <Form.Select
                      aria-label="Mese"
                      name="month"
                      onChange={onChangeMonth}
                    >
                      <option>Apri per selezionare</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </Form.Select>
                  </Col>

                  <Col>
                    <Form.Label htmlFor="Anno">Anno</Form.Label>
                    <Form.Select
                      aria-label="Anno"
                      name="year"
                      onChange={onChangeYear}
                    >
                      <option>Apri per selezionare</option>
                      <option value="1920">1920</option>
                      <option value="1921">1921</option>
                      <option value="1922">1922</option>
                      <option value="1923">1923</option>
                      <option value="1924">1924</option>
                      <option value="1925">1925</option>
                      <option value="1926">1926</option>
                      <option value="1927">1927</option>
                      <option value="1928">1928</option>
                      <option value="1929">1929</option>
                      <option value="1930">1930</option>
                      <option value="1931">1931</option>
                      <option value="1932">1932</option>
                      <option value="1933">1933</option>
                      <option value="1934">1934</option>
                      <option value="1935">1935</option>
                      <option value="1936">1936</option>
                      <option value="1937">1937</option>
                      <option value="1938">1938</option>
                      <option value="1939">1939</option>
                      <option value="1940">1940</option>
                      <option value="1941">1941</option>
                      <option value="1942">1942</option>
                      <option value="1943">1943</option>
                      <option value="1944">1944</option>
                      <option value="1945">1945</option>
                      <option value="1946">1946</option>
                      <option value="1947">1947</option>
                      <option value="1948">1948</option>
                      <option value="1949">1949</option>
                      <option value="1950">1950</option>
                      <option value="1951">1951</option>
                      <option value="1952">1952</option>
                      <option value="1953">1953</option>
                      <option value="1954">1954</option>
                      <option value="1955">1955</option>
                      <option value="1956">1956</option>
                      <option value="1957">1957</option>
                      <option value="1958">1958</option>
                      <option value="1959">1959</option>
                      <option value="1960">1960</option>
                      <option value="1961">1961</option>
                      <option value="1962">1962</option>
                      <option value="1963">1963</option>
                      <option value="1964">1964</option>
                      <option value="1965">1965</option>
                      <option value="1966">1966</option>
                      <option value="1967">1967</option>
                      <option value="1968">1968</option>
                      <option value="1969">1969</option>
                      <option value="1970">1970</option>
                      <option value="1971">1971</option>
                      <option value="1972">1972</option>
                      <option value="1973">1973</option>
                      <option value="1974">1974</option>
                      <option value="1975">1975</option>
                      <option value="1976">1976</option>
                      <option value="1977">1977</option>
                      <option value="1978">1978</option>
                      <option value="1979">1979</option>
                      <option value="1980" defaultValue={"1980"}>
                        19800
                      </option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                    </Form.Select>
                  </Col>
                </Row>

                <Row className="mb-3 mt-3">
                  <Col>
                    <Button
                      variant="success"
                      style={{ float: "right" }}
                      onClick={calcolaCF}
                    >
                      Calcola
                    </Button>{" "}
                  </Col>
                </Row>

                <Row
                  className="mb-2 px-4 py-4"
                  style={{ background: "#009345", borderRadius: "6px" }}
                >
                  <Col>
                    <Form.Label
                      htmlFor="disabledTextInput"
                      style={{ color: "white" }}
                    >
                      Codice Fiscale
                    </Form.Label>
                    <Form.Control
                      placeholder="cv-codice-fiscale"
                      name="cv-codice-fiscale"
                      required
                      type="text"
                      defaultValue={codiceFiscaleCv}
                      onChange={verificaCF}
                    />
                  </Col>
                  <Col>
                    {verificato ? (
                      <div style={{ color: "white" }}>
                        {" "}
                        Verificato!{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="64"
                          height="64"
                          fill="currentColor"
                          className="bi bi-patch-check-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                        </svg>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mb-3 mt-3">
                  <Col>
                    <div style={{ padding: "15px" }}>
                      <Button
                        type="submit"
                        variant="primary"
                        style={{ float: "left" }}
                      >
                        Crea nuovo utente
                      </Button>{" "}
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  };

  const checkRolesList = [
    "admin",
    "consulente",
    "operatore",
    "teleconsul",
    "namirial",
    "regionale",
  ];
  const RenderRolesList = () => {
    return (
      <div className="my-4 mb-4">
        {checkRolesList.map((item, index) => (
          <div key={index} className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              name={item}
              value={item}
              id={item}
              defaultChecked={unchecked}
              onChange={(e) => { setUnchecked(!unchecked); handleConsulente(e.target.value, e.target.checked) }}
            />
            <label className="form-check-label" htmlFor="operatore">
              {item}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const handleConsulente = (role, value) => setIsConsulente(role === "consulente" && value)

  return (
    <>
      <div
        className="container"
        style={{
          height: "100%",
          marginBottom: "40px",
          padding: "20px",
          color: "white",
        }}
      >
        <div
          style={{
            textAlign: "center",
            textAlign: "center",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontWeight: "600",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-house-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
            />
            <path
              fillRule="evenodd"
              d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
            />
          </svg>{" "}
          / Admin / Moduli / Aggiungi Utente
        </div>
      </div>
      <div
        className="container"
        style={{
          height: "100%",
          marginBottom: "100px",
          paddingLeft: "80px",
          paddingRight: "80px",
        }}
      >
        {RendeForm()}
      </div>
    </>
  );
}

export default AdminUserAdd;
