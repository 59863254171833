import React, { useState, useEffect } from "react";
import axios from "axios";
import SHA1 from "sha1-es";
import XMLParser from "react-xml-parser";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Endpoints from "../../components/services/Endpoints";
import Logonamirial from "../../namirial_logo.png";

const ENDPOINT = Endpoints.getUrls();
const MySwal = withReactContent(Swal);

const NamirialCreate = () => {
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const doFetch = async () => {
      console.log("Role: ", localStorage.getItem("currentUser"));
      const consulente = JSON.parse(localStorage.getItem("currentUser"));
      const id = consulente._id;
      //  https://staging.cdlservizipatronato.it:5001/users/create/namirial/id?id=62c8023cb8fea4dcd84c4d71
      const response = await fetch(
        `${ENDPOINT}/users/create/namirial/id?id=${id}`
      );
      const userresp = await response.json();
      // console.log(userresp.indirizzo.cap);

      setUser(userresp);
    };
    doFetch();
  }, []);

  const formatData = () => {
    let date = new Date();

    const moonLanding = new Date();

    const handleDate = (dataD) => {
      let data = new Date(dataD);
      let month = data.getMonth() + 1;
      let day = data.getDate();
      let year = data.getFullYear();
      if (day <= 9) day = "0" + day;
      if (month < 10) month = "0" + month;
      const postDate = year + month + day;
      return postDate;
    };

    console.log(handleDate(moonLanding));

    let dataToHash = handleDate(moonLanding);

    const hash = SHA1.hash(`Wt56r2e%3weDsWqa!4rqyEq7!r${dataToHash}`);
    console.log("hash", hash);
    /*
           $sha1_today = sha1("yt56r5e3wEdsWqa%()A4rq".date("Ymd")); 
          */
    return hash;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const tempPlayer = new FormData(event.target);

    const formDatax = new FormData();
    /* 
          formDatax.append("token", formatData());
          formDatax.append("sppa", "cdl_famreg");
          formDatax.append("prefix", "famreg");
          formDatax.append("action", "functs");
          formDatax.append("ope", "new");
    */

    for (let [key, defaultValue] of tempPlayer.entries()) {
      console.log(key, defaultValue);
      formDatax.append(key, defaultValue);
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/middleware/namirial_new.php`,
        formDatax
      )
      .then(function (response) {
        console.log(response);
        var xml = new XMLParser().parseFromString(response.data);
        //   console.log("XML ->",typeof(xml), xml.children);

        let successObject = {};
        if (xml.children[0].value === "00") {
          setRolesToDb();

          Swal.fire({
            title: "Account creato con successo",
            text: `Punto previdenza`,
            icon: "info",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok!",
            footer: `<b>Consulente<b/>`,
          }).then((result) => {
            navigate("/consulente/namirial-check");
          });

          successObject = {
            status: xml.children[0].name,
            message: xml.children[0].value,
          };
          alert(JSON.stringify(successObject));
        } else if (xml.children[0].value != "00") {
          let status = xml.children[0].name;
          let message = xml.children[1].value;

          xml.children[1].children.forEach((element) => {
            element.children.forEach((element) => {
              alert(element.name + " - " + element.value);
            });
          });

          alert(
            `Error status: ${xml.children[0].value} message: ${xml.children[1].value}`
          );
        }
        /*
        let status = xml.children[0].name;
        let message = xml.children[1].value;
        let payload =  xml.children[2].children;

        console.log("status ->", status);
        console.log("message ->", message);
        console.log("payload ->", payload);

*/
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setRolesToDb = () => {
    let arrRuolo = ["namirial"];

    axios
      .post(`${ENDPOINT}/users/aggiungiruolo/action`, {
        _id: user._id,
        role: arrRuolo,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container" style={{ padding: "20px" }}>
        <div className="card" style={{ alignItems: "center" }}>
          <div>
            <img
              src={Logonamirial}
              width={250}
              height={250}
              alt="logo Namirial"
            />{" "}
          </div>

          <div className="card-body" style={{ width: "50rem" }}>
            <h1>Crea un Account in Punto Previdenza</h1>
            {user.indirizzo ? (
              <form onSubmit={handleSubmit} class="needs-validation" novalidate>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_sportello"
                    defaultValue={user?.userid}
                    readOnly
                    placeholder="famreg_sportello"
                    aria-label="famreg_sportello"
                    aria-describedby="famreg_sportello"
                  />
                  <span className="input-group-text" id="famreg_sportello">
                    id utente
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_cfisc"
                    defaultValue={user?.codice_fiscale}
                    required
                    placeholder="Codice fiscale"
                    aria-label="famreg_cfisc"
                    aria-describedby="famreg_cfisc"
                  />
                  <span className="input-group-text" id="famreg_cfisc">
                    Codice fiscale
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_piva"
                    defaultValue={user?.partita_iva}
                    required
                    placeholder="00000000000"
                    aria-label="famreg_piva"
                    aria-describedby="famreg_piva"
                  />
                  <span className="input-group-text" id="famreg_piva">
                    Partita Iva
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_denominazione"
                    defaultValue={user?.ragione_sociale}
                    required
                    placeholder="Ragione sociale"
                    aria-label="famreg_denominazione"
                    aria-describedby="famreg_denominazione"
                  />
                  <span className="input-group-text" id="famreg_denominazione">
                    Ragione sociale
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_comcom_codice"
                    placeholder="Codice comune"
                    required
                    aria-label="famreg_comcom_codice"
                    aria-describedby="famreg_comcom_codice"
                  />
                  <span className="input-group-text" id="famreg_comcom_codice">
                    Codice comune (es: Roma = H501U)
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_comune"
                    defaultValue={user.indirizzo.localita_esercizio}
                    required
                    placeholder="Comune"
                    aria-label="famreg_comune"
                    aria-describedby="famreg_comune"
                  />
                  <span className="input-group-text" id="famreg_comune">
                    Comune
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_indirizzo"
                    defaultValue={user.indirizzo.via}
                    required
                    placeholder="Indirizzo"
                    aria-label="famreg_indirizzo"
                    aria-describedby="famreg_indirizzo"
                  />
                  <span className="input-group-text" id="famreg_indirizzo">
                    Indirizzo
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_cap"
                    defaultValue={user.indirizzo.cap}
                    required
                    placeholder="CAP"
                    aria-label="famreg_cap"
                    aria-describedby="famreg_cap"
                  />
                  <span className="input-group-text" id="famreg_cap">
                    Cap
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_prov"
                    defaultValue={user.indirizzo.provincia_sigla}
                    required
                    placeholder="es. RM"
                    aria-label="famreg_prov"
                    aria-describedby="famreg_prov"
                  />
                  <span className="input-group-text" id="famreg_prov">
                    Provincia
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_telefono"
                    defaultValue={user.telefono}
                    placeholder="Telefono"
                    aria-label="famreg_telefono"
                    aria-describedby="famreg_telefono"
                  />
                  <span className="input-group-text" id="famreg_telefono">
                    Telefono
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_cellulare"
                    defaultValue={user.cellulare}
                    placeholder="famreg_cellulare"
                    aria-label="famreg_cellulare"
                    aria-describedby="famreg_cellulare"
                  />
                  <span className="input-group-text" id="famreg_cellulare">
                    Cellulare
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_fax"
                    defaultValue={user.fax}
                    placeholder="Fax"
                    aria-label="famreg_fax"
                    aria-describedby="famreg_fax"
                  />
                  <span className="input-group-text" id="famreg_fax">
                    Fax
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_email"
                    defaultValue={user.email}
                    required
                    placeholder="Email"
                    aria-label="famreg_email"
                    aria-describedby="famreg_email"
                  />
                  <span className="input-group-text" id="famreg_emailg_fax">
                    Email
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_resp_cfisc"
                    defaultValue={user.collaboratore.codice_fiscale}
                    required
                    placeholder="C.F. Responsibile"
                    aria-label="famreg_resp_cfisc"
                    aria-describedby="famreg_resp_cfisc"
                  />
                  <span className="input-group-text" id="famreg_resp_cfisc">
                    C.F. Responsibile
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_resp_cognome"
                    defaultValue={user.collaboratore.cognome}
                    required
                    placeholder="Cognome Responsabile"
                    aria-label="famreg_resp_cognome"
                    aria-describedby="famreg_resp_cognome"
                  />
                  <span className="input-group-text" id="famreg_resp_cognome">
                    Cognome Responsabile
                  </span>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="famreg_resp_nome"
                    defaultValue={user.collaboratore.nome}
                    required
                    placeholder="Nome Responsabile"
                    aria-label="famreg_resp_nome"
                    aria-describedby="famreg_resp_nome"
                  />
                  <span className="input-group-text" id="famreg_resp_nome">
                    Nome Responsabile
                  </span>
                </div>
                <div className="input-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ float: "right" }}
                  >
                    Crea account{" "}
                  </button>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NamirialCreate;
