import { Button } from "react-bootstrap";
import client from "../../../utils/client";

const RenewButton = ({ buttonText }) => {
  const AUTH_CODE = "7IvR7u1uvOwuUl71uvjUi";
  const BASE_URL = "https://www.storecdlservizipatronato.it/";

  const handleClick = async () => {
    const response = await client({
      url: "/ecommerce/token",
      config: { method: "GET" },
    });
    if (!response || !response.data || !response.data.token) {
      // There was an error getting the token, redirect to the base URç
      window.location.href = BASE_URL;
    }

    window.location.href = `${BASE_URL}/?rest_route=/jwt-login/v1/autologin&product=cdl-subscription&JWT=${response.data.token}&AUTH_CODE=${AUTH_CODE}`;
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className="btn btn-primary"
      onClick={handleClick}
    >
      {buttonText || "ATTIVA"}
    </Button>
  );
};

export default RenewButton;
