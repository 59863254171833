import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import socketIOClient from "socket.io-client";
import Endpoints from "../components/services/Endpoints";
import {
  Navbar,
  NavDropdown,
  Nav,
  Container,
  Button,
  NavLink,
} from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const ENDPOINT = Endpoints.getUrls();

export default function NavAdmin() {
  const [id, setId] = useState([]);
  const [cognome, setCognome] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const [statoServer, setStatoServer] = useState("");

  let navigate = useNavigate();

  const getUserLogged = async () => {
    const consulenteClient = await JSON.parse(
      localStorage.getItem("currentUser")
    );

    const { _id, cognome, nome, email, role } = consulenteClient;

    setCognome(cognome);
    setNome(nome);
    setEmail(email);
    setRole(role);
  };

  useEffect(() => {
    getUserLogged();
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      url: `${ENDPOINT}/tools/getmaintenance`,
    }).then(function (response) {
      console.log(response);
      if (response.data.status === true) {
        setStatoServer("stop");
      }
      if (response.data.status === false) {
        setStatoServer("start");
      }
    });
  }, []);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    socket.on("maintenance", function (payload) {
      console.log("Under maintenance: ", payload);
      //  renewTable(JSON.parse(payload));
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  const putInMaintenance = (e) => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    console.log(e.currentTarget.name);

    if (e.currentTarget.name === "start") {
      const maintenaceObj = {
        underMaintenance: false,
        backToLive: "scrivere data",
      };
      socket.emit("underMaintenanceAction", JSON.stringify(maintenaceObj));
      setServerStatusOnDb("start");
      /*
  const maintenaceObj = {
    serverState: "Run",
    backToLive: "scrivere data"
  }

  
  socket.emit('maintenanceStart', JSON.stringify(maintenaceObj));
  setServerStatusOnDb("start");
  */
    }

    if (e.currentTarget.name === "stop") {
      const maintenaceObj = {
        underMaintenance: true,
        backToLive: "scrivere data",
      };
      socket.emit("underMaintenanceAction", JSON.stringify(maintenaceObj));
      setServerStatusOnDb("stop");

      /*
  socket.emit('maintenanceStop', JSON.stringify(maintenaceObj));
  setServerStatusOnDb("stop");
  */
    }

    /*
  socket.emit('maintenance',function(payload){
    console.log('praticaPresa da: ' , payload);
  //  renewTable(JSON.parse(payload));
    });

*/
  };

  const setServerStatusOnDb = (status) => {
    axios({
      method: "get",
      url: `${ENDPOINT}/tools/setundermaintenace/status/${status}`,
    }).then(function (response) {
      setStatoServer(response.data.status);

      axios({
        method: "get",
        url: `${ENDPOINT}/tools/getmaintenance`,
      }).then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          setStatoServer("stop");
        }
        if (response.data.status === false) {
          setStatoServer("start");
        }
      });
    });
  };

  const logout = () => {
    let items = ["currentUser", "tipoPaticaId"];

    items.forEach((items) => {
      localStorage.removeItem(items);
    });

    navigate("/");
  };

  return (
    <main>
      <Navbar bg="warning" expand="lg">
        <Container>
          <Navbar.Brand href="/admin">
            CAF Nazionale Consulenti del Lavoro
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink href="/">Home</NavLink>

              <NavDropdown title="Controllo sito" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <Button
                    className="btn-danger"
                    name="stop"
                    onClick={putInMaintenance}
                  >
                    {" "}
                    Stop
                  </Button>{" "}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Button
                    name="start"
                    className="btn-success"
                    onClick={putInMaintenance}
                  >
                    {" "}
                    Start
                  </Button>{" "}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  Stato Attuale: {statoServer}{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Account" id="basic-nav-dropdown">
                <NavDropdown.Item>{nome}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>{cognome}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>{email}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>{role}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>

              <NavLink href="/admin/banner">Banner</NavLink>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Ciao:{" "}
              <a href="#login">
                {nome} {role}
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </main>
  );
}
