import React from "react";

import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  Outlet,
} from "react-router-dom";

//import { Auth0Provider } from '@auth0/auth0-react';

//import useToken from'./useToken';
import Home from "./HOME";
//import TipoPratica from './Consulente/TipoPratica';
import TipoPratica2 from "./Consulente/TipoPratica_bkp";
import Nuovapratica from "./Consulente/Nuovapratica";
import ConsulenteHeader from "./Consulente/ConsulenteHeader";
import PraticheOp from "./Operatore/PraticheOp";
//import PraticheAssegnate from './Operatore/PraticheAssegnate';
import PraticheChiuse from "./Operatore/PraticheChiuse";
import PraticheSospese from "./Operatore/PraticheSospese";
import PraticheAnnullate from "./Operatore/PraticheAnnullate";
import PraticheLavorazione from "./Operatore/PraticheLavorazione";
import PraticheGenerali from "./Operatore/PraticheGenerali";
import PraticheInBozza from "./Consulente/PraticheInBozza";
import PraticheConsSospese from "./Consulente/PraticheSospese";
import PraticheConsChiuse from "./Consulente/PraticheChiuse";
import PraticheConsLavorazione from "./Consulente/PraticheLavorazione";
import ArchivioPraticheOperatore from "./Operatore/Archivio/ArchivioPraticheOperatore";
import NavOp from "./Operatore/navOp";
import MessaggiOp from "./Operatore/MessaggiOp";

import NavConsulente from "./Consulente/NavConsulente";
import MenuPraticheOp from "./Operatore/MenuPraticheOp";
import PraticheInviate from "./Consulente/PraticheInviate";
import PraticheConsAnnullate from "./Consulente/PraticheAnnullate";
import MessaggiCons from "./Consulente/MessaggiCons";
import MenuAdminPratiche from "./Cafadmin/MenuAdminPratiche";
import NavAdmin from "./Cafadmin/NavAdmin";
import AdminPraticheSospese from "./Cafadmin/AdminPraticheSospese";
import AdminPraticheInviate from "./Cafadmin/AdminPraticheInviate";
import AdminPraticheGenerali from "./Cafadmin/AdminPraticheGenerali";
import AdminPraticheChiuse from "./Cafadmin/AdminPraticheChiuse";
import AdminPraticheAnnullate from "./Cafadmin/AdminPraticheAnnullate";
import AdminUsersTable from "./Cafadmin/AdminUsersTable";
import AdminUserAdd from "./Cafadmin/AdminUserAdd";
import AdminModuloPratica from "./Cafadmin/AdminModuloPratica";
import AdminModuloAdd from "./Cafadmin/AdminModuloAdd";
import AdminAssociaPratica from "./Cafadmin/AdminAssociaPratica";
import AdminGetionePratiche from "./Cafadmin/AdminGetionePratiche";
import AdminTipoPraticaAdd from "./Cafadmin/AdminTipoPraticaAdd";

import ArchivioPraticheAdmin from "./Cafadmin/Archivio/ArchivioPraticheAdmin";

import ArchivioPratiche from "./Archivio/ArchivioPratiche";
import Usersignin from "./components/Users/Usersignin";
import Userforgot from "./components/Users/Userforgot";
import UserforgotStepOne from "./components/Users/UserforgotStepOne";
import UserForgotStepFinal from "./components/Users/UserForgotFinal";
import Usersignup from "./components/Users/Usersignup";
import Policy from "./Policy";
import Cookies from "./Cookies";
import ContactPage from "./Contact/ContactPage";

import Teleconsul from "./components/Users/Teleconsul/Teleconsul";
import Logins from "./components/Users/Teleconsul/Logins";
import UpdateAccount from "./components/Users/Teleconsul/UpdateAccount";
import Telelogout from "./components/Users/Teleconsul/Telelogout";
import CreateAccount from "./components/Users/Teleconsul/CreateAccount";
import UpdateMiddleAccount from "./components/Users/Teleconsul/UpdateMiddleAccount";
/*
import Testconn from './components/Users/Testconn'; */
import NamirialCreate from "./components/Namirial/NamirialCreate";
import BannerHandler from "./Cafadmin/BannerHandler";

export default function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      {/*   <MyMenu /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route>
          {/* 
      <Route exact path="/signin" element={<Signin />} />
      <Route exact path="/forgotpassword" element={<Forgot />} />
      <Route exact path="/forgotstep2" element={<ForgotStep2 />} />

      <Route exact path="/forgotnextstep" element={<ForgotNextStep />} />
      <Route exact path="/forgotlast" element={<ForgotLast />} /> */}

          <Route exact path="/policy" element={<Policy />} />
          <Route exact path="/cookies" element={<Cookies />} />
          <Route exact path="/contatti" element={<ContactPage />} />

          <Route exact path="/signin" element={<Usersignin />} />
          <Route exact path="/userforgot" element={<Userforgot />} />
          <Route
            exact
            path="/userforgotstepone"
            element={<UserforgotStepOne />}
          />
          <Route
            exact
            path="/userforgotfinal"
            element={<UserForgotStepFinal />}
          />
          <Route exact path="/usersignup" element={<Usersignup />} />
          {/*       <Route exact path="/teleconsultsignin" element={<OpenIdLogin />} /> 

  
      <Route exact path="/teleconsul" element={<Teleconsul />} />
      <Route exact path="/telelanded" element={<TeleconsulLanded />} /> */}

          {/*    <Route exact path="/testconn" element={<Testconn />} /> 
      <Route exact path="/namirial-new" element={<NamirialCreate />} />
      <Route exact path="/namirial-check" element={<NamirialCheck />} />  */}

          <Route path="user/teleconsul" element={<Logins />} />
          <Route path="authentication/callback" element={<Teleconsul />} />
          <Route path="teleconsul/updateaccount" element={<UpdateAccount />} />
          <Route path="teleconsul/createaccount" element={<CreateAccount />} />
          <Route path="teleconsul/logout" element={<Telelogout />} />
          <Route
            path="teleconsul/updatemiddleaccount"
            element={<UpdateMiddleAccount />}
          />

          <Route></Route>

          {/*    <ConsulenteHeader /> */}
          <Route
            path="/consulente"
            element={
              <PrivateConsulente>
                <Consulente />
              </PrivateConsulente>
            }
          >
            <Route
              path="tipo_pratica"
              element={
                <PrivateConsulente>
                  <TipoPratica2 />
                </PrivateConsulente>
              }
            />
            <Route
              path="gestione_pratiche"
              element={
                <PrivateConsulente>
                  <ConsulenteTasks />
                </PrivateConsulente>
              }
            />
            <Route
              path="nuova_pratica"
              element={
                <PrivateConsulente>
                  <Nuovapratica />
                </PrivateConsulente>
              }
            />

            <Route
              path="about"
              element={
                <PrivateConsulente>
                  <ConsulenteAboutPage />
                </PrivateConsulente>
              }
            />

            <Route
              path="messages"
              element={
                <PrivateConsulente>
                  <ConsulenteMessages />
                </PrivateConsulente>
              }
            />
            <Route
              path="pratiche_inviate"
              element={
                <PrivateConsulente>
                  <PraticheConsInviate />
                </PrivateConsulente>
              }
            />
            <Route
              path="pratiche_lavorazione"
              element={
                <PrivateConsulente>
                  <PraticheConsulenteLavorazione />
                </PrivateConsulente>
              }
            />
            <Route
              path="pratiche_inbozza"
              element={
                <PrivateConsulente>
                  <PraticheConsBozza />
                </PrivateConsulente>
              }
            />
            <Route
              path="pratiche_sospese"
              element={
                <PrivateConsulente>
                  <PraticheConsulenteSospese />
                </PrivateConsulente>
              }
            />
            <Route
              path="pratiche_annullate"
              element={
                <PrivateConsulente>
                  <PraticheConsulenteAnnullate />
                </PrivateConsulente>
              }
            />
            <Route
              path="pratiche_chiuse"
              element={
                <PrivateConsulente>
                  <PraticheConsulenteChiuse />
                </PrivateConsulente>
              }
            />

            <Route
              path="namirial-new"
              element={
                <PrivateConsulente>
                  <NamirialCreate />
                </PrivateConsulente>
              }
            />
            <Route
              path="archivio"
              element={
                <PrivateConsulente>
                  <ArchivioPratiche />
                </PrivateConsulente>
              }
            />
            {/* <Route
              path="messaggi"
              element={
                <PrivateConsulente>
                  <ConsulenteMessaggi />
                </PrivateConsulente>
              }
            /> */}
          </Route>
        </Route>

        {/* OPERATORE */}

        <Route
          path="/operatore"
          element={
            <PrivateOperatore>
              <Operatore />
            </PrivateOperatore>
          }
        >
          <Route
            path="pratiche_inviate"
            element={
              <PrivateOperatore>
                <PraticheOpInviate />
              </PrivateOperatore>
            }
          />
          <Route
            path="pratiche_lavorazione"
            element={
              <PrivateOperatore>
                <PraticheOpLavorazione />
              </PrivateOperatore>
            }
          />
          <Route
            path="pratiche_sospese"
            element={
              <PrivateOperatore>
                <PraticheOpSospese />
              </PrivateOperatore>
            }
          />
          <Route
            path="pratiche_annullate"
            element={
              <PrivateOperatore>
                <PraticheOpAnnullate />
              </PrivateOperatore>
            }
          />
          <Route
            path="pratiche_chiuse"
            element={
              <PrivateOperatore>
                <PraticheOpChiuse />
              </PrivateOperatore>
            }
          />
          <Route
            path="pratiche_generali"
            element={
              <PrivateOperatore>
                <PraticheOpGenerali />
              </PrivateOperatore>
            }
          />

          <Route
            path="archivio_pratiche_operatore"
            element={
              <PrivateOperatore>
                <PraticheArchivioOperatore />
              </PrivateOperatore>
            }
          />
          {/* <Route
            path="messaggi_operatore"
            element={
              <PrivateOperatore>
                <OperatoreMessaggi />
              </PrivateOperatore>
            }
          /> */}
        </Route>

        <Route
          path="/admin"
          element={
            <PrivateCafAdmin>
              <CafAdmin />
            </PrivateCafAdmin>
          }
        />
        <Route
          path="/admin/pratiche_inviate"
          element={
            <PrivateCafAdmin>
              <PraticheAdminInviate />
            </PrivateCafAdmin>
          }
        />
        <Route
          path="pratiche_lavorazione"
          element={<PraticheOpLavorazione />}
        />
        <Route
          path="/admin/pratiche_sospese"
          element={
            <PrivateCafAdmin>
              <PraticheAdminSospese />
            </PrivateCafAdmin>
          }
        />
        <Route
          path="/admin/pratiche_annullate"
          element={
            <PrivateCafAdmin>
              <PraticheAdminAnnullate />
            </PrivateCafAdmin>
          }
        />
        <Route
          path="/admin/pratiche_chiuse"
          element={
            <PrivateCafAdmin>
              <PraticheAdminChiuse />
            </PrivateCafAdmin>
          }
        />
        <Route
          path="/admin/pratiche_generali"
          element={
            <PrivateCafAdmin>
              <PraticheAdminGenerali />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/users"
          element={
            <PrivateCafAdmin>
              <UsersAdmin />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/useradd"
          element={
            <PrivateCafAdmin>
              <UserAddAdmin />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/moduli"
          element={
            <PrivateCafAdmin>
              <ModuliAdmin />
            </PrivateCafAdmin>
          }
        />
        <Route
          path="/admin/moduli/add"
          element={
            <PrivateCafAdmin>
              <ModuliAdminAdd />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/moduli/associa"
          element={
            <PrivateCafAdmin>
              <ModuloAssociaAdmin />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/tipopratica"
          element={
            <PrivateCafAdmin>
              <TipoPraticaAdmin />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/tipopratica/add"
          element={
            <PrivateCafAdmin>
              <TipoPraticaAddAdmin />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/archivio"
          element={
            <PrivateCafAdmin>
              <ArchivioAdmin />
            </PrivateCafAdmin>
          }
        />

        <Route
          path="/admin/banner"
          element={
            <PrivateCafAdmin>
              <BannerAdmin></BannerAdmin>
            </PrivateCafAdmin>
          }
        />

      </Routes>
    </BrowserRouter>
  );
}

function PrivateCafAdmin({ children }) {
  const auth = useAuthRoleCafAdmin();
  console.log(children);
  // return auth ? children : <Navigate to="/login" />;
  return auth ? children : <Navigate to="/" />;
}

function useAuthRoleCafAdmin() {
  if (localStorage.getItem("currentUser")) {
    console.log("TEST-RoleADMIN: ", localStorage.getItem("currentUser"));

    const tokens = JSON.parse(localStorage.getItem("currentUser"));

    console.log("Role: ", tokens.role[0]);

    if (tokens.token && tokens.role[0] === "ROLE_ADMIN") {
      return true;
    }
  } else return false;
}

function PrivateConsulente({ children }) {
  const auth = useAuthRoleConsulente();
  // return auth ? children : <Navigate to="/login" />;
  return auth ? children : <Navigate to="/" />;
}

function useAuthRoleConsulente() {
  if (localStorage.getItem("currentUser")) {
    // console.log("TEST-RoleCONS: ", localStorage.getItem('currentUser'));
    const tokens = JSON.parse(localStorage.getItem("currentUser"));
    if (tokens.token && tokens.role[0] === "ROLE_CONSULENTE") {
      return true;
    }
  }
  return false;
}

function PrivateOperatore({ children }) {
  const auth = useAuthRoleOperatore();
  // return auth ? children : <Navigate to="/login" />;
  return auth ? children : <Navigate to="/" />;
}
function useAuthRoleOperatore() {
  if (localStorage.getItem("currentUser")) {
    console.log("TEST-RoleOp: ", localStorage.getItem("currentUser"));
    const tokens = JSON.parse(localStorage.getItem("currentUser"));
    console.log("Role 247: ", tokens.role[0]);
    if (tokens.token && tokens.role[0] === "ROLE_OPERATORE") {
      return true;
    }
  }
  return false;
}

/*

function useAuth() {
  if(localStorage.getItem('currentUser')){
    console.log("TEST-Z2: ", localStorage.getItem('currentUser'));
    const tokens = JSON.parse(localStorage.getItem('currentUser'));
    if(tokens.token){
      return true;
    }

  }
 // localStorage.getItem('currentUser');
  return false;
}


function useAuthRole() {

  if(localStorage.getItem('currentUser')){
    console.log("TEST-Z3: ", localStorage.getItem('currentUser'));
    const tokens = JSON.parse(localStorage.getItem('currentUser'));
      if(tokens.token && (tokens.role === "ROLE_OPERATORE")){
        return true;
      }
  }
  return false;
}
*/

function Consulente() {
  return (
    <>
      <NavConsulente />
      <div>
        <ConsulenteHeader />
        <Outlet />
      </div>
    </>
  );
}

function CafAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <Outlet />
      </div>
    </>
  );
}

function PraticheAdminSospese() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <AdminPraticheSospese />
        <Outlet />
      </div>{" "}
    </>
  );
}

function PraticheAdminInviate() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <AdminPraticheInviate />
        <Outlet />
      </div>
    </>
  );
}

function PraticheAdminGenerali() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <AdminPraticheGenerali />
        <Outlet />
      </div>
    </>
  );
}

function PraticheAdminAnnullate() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <AdminPraticheAnnullate />
        <Outlet />
      </div>
    </>
  );
}

function PraticheAdminChiuse() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <AdminPraticheChiuse />
        <Outlet />
      </div>
    </>
  );
}

function UsersAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminUsersTable />
        <Outlet />
      </div>
    </>
  );
}

function UserAddAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminUserAdd />
        <Outlet />
      </div>
    </>
  );
}

function ModuliAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminModuloPratica />
        <Outlet />
      </div>
    </>
  );
}

function ModuliAdminAdd() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminModuloAdd />
        <Outlet />
      </div>
    </>
  );
}

function ModuloAssociaAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminAssociaPratica />
        <Outlet />
      </div>
    </>
  );
}

function TipoPraticaAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminGetionePratiche />
        <Outlet />
      </div>
    </>
  );
}

function TipoPraticaAddAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />

        <AdminTipoPraticaAdd />
        <Outlet />
      </div>
    </>
  );
}

function ArchivioAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <MenuAdminPratiche />
        <ArchivioPraticheAdmin />
        <Outlet />
      </div>
    </>
  );
}

function BannerAdmin() {
  return (
    <>
      {" "}
      <NavAdmin />
      <div>
        <BannerHandler />

      </div>
    </>
  );
}

/*
function ConsulenteBKP() {
  let activeStyle = {
    textDecoration: "underline"
  };
  let consulenteObj = JSON.parse(localStorage.getItem('currentUser'));
  return (
    <div>
      <ul style={{ display: 'inline' }}>
        <li style={{ display: 'inline',  }} > {consulenteObj.role[0]} | </li>
        <li style={{ display: 'inline' }}> {consulenteObj.nome} |</li>
        <li style={{ display: 'inline' }}> {consulenteObj.cognome} |</li>
      </ul>

       <ConsulenteHeader />
      <small>Pratiche</small>
      <Outlet />
    </div>
  );
}
*/

function ConsulenteMessages() {
  return (
    <div>
      <h1>ConsulenteMessages</h1>
      Pratiche
      <Outlet />
    </div>
  );
}

function PraticheConsInviate() {
  return (
    <div>
      <PraticheInviate />
      <Outlet />
    </div>
  );
}

function PraticheConsBozza() {
  return (
    <div>
      <PraticheInBozza />
      <Outlet />
    </div>
  );
}

function PraticheConsulenteLavorazione() {
  return (
    <div>
      <PraticheConsLavorazione />
      <Outlet />
    </div>
  );
}

function PraticheConsulenteSospese() {
  return (
    <div>
      <PraticheConsSospese />
      <Outlet />
    </div>
  );
}

function PraticheConsulenteChiuse() {
  return (
    <div>
      <PraticheConsChiuse />
      <Outlet />
    </div>
  );
}

function PraticheConsulenteAnnullate() {
  return (
    <div>
      <PraticheConsAnnullate />
      <Outlet />
    </div>
  );
}

function ConsulenteTasks() {
  return (
    <div>
      Pratiche
      <Outlet />
    </div>
  );
}

function ConsulenteAboutPage() {
  return (
    <div>
      Pratiche
      <Outlet />
    </div>
  );
}

// function ConsulenteMessaggi() {
//   return (
//     <div>
//       <MessaggiCons />
//       <Outlet />
//     </div>
//   );
// }

/*
function Pratiche() {
  return (
    <div>
      <h1>Pratiche</h1>
      Pratiche
    </div>
  );
}
*/
// Operatore menu

function Operatore() {
  // let operatoreObj = JSON.parse(localStorage.getItem('currentUser'));
  return (
    <>
      <NavOp />
      <div>
        <MenuPraticheOp />
        <Outlet />
      </div>
    </>
  );
}

// function OperatoreMessaggi() {
//   return (
//     <>
//       <div>
//         <MessaggiOp />
//         <Outlet />
//       </div>
//     </>
//   );
// }

function PraticheOpInviate() {
  return (
    <div>
      {/* 
    <div style={{backgroundColor: '#033b72', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
      <h6 style={{color: 'ghostwhite'}}>Pratiche generali inserite dai consulenti e da assegnare</h6>
      </div>
    */}

      <Outlet />
      <PraticheOp />
    </div>
  );
}

function PraticheOpLavorazione() {
  return (
    <div>
      {/*
    <div style={{backgroundColor: '#1ab96f', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
    <h6 style={{color: 'ghostwhite'}}>Le tue pratiche in Lavorazione</h6>
    </div>
 */}
      <PraticheLavorazione />
      <Outlet />
    </div>
  );
}

function PraticheOpChiuse() {
  return (
    <div>
      {/* 
    <div style={{backgroundColor: '#1ab96f', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
    <h6 style={{color: 'ghostwhite'}}>Le pratiche Chiuse</h6>
    </div>
*/}
      <PraticheChiuse />
      <Outlet />
    </div>
  );
}

function PraticheOpSospese() {
  return (
    <div>
      {/* 
    <div style={{backgroundColor: 'rgb(185 99 26)', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
    <h6 style={{color: 'ghostwhite'}}>Le pratiche Sospese</h6>
    </div>
*/}
      <PraticheSospese />
      <Outlet />
    </div>
  );
}

function PraticheOpAnnullate() {
  return (
    <div>
      {/* 
    <div style={{backgroundColor: 'rgb(185 99 26)', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
    <h6 style={{color: 'ghostwhite'}}>Le pratiche Annullate</h6>
    </div>
*/}
      <PraticheAnnullate />
      <Outlet />
    </div>
  );
}

function PraticheOpGenerali() {
  return (
    <div>
      {/* 
      <div style={{backgroundColor: '#ffc107', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
      <h6 style={{color: '#1554d9'}}>Le pratiche Generali</h6>
      </div>
*/}
      <PraticheGenerali />
      <Outlet />
    </div>
  );
}
function PraticheArchivioOperatore() {
  return (
    <div>
      <ArchivioPraticheOperatore />
      <Outlet />
    </div>
  );
}
