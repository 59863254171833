import React, { useState, useEffect } from "react";
import { Container, Accordion, Form, Table } from "react-bootstrap";

import socketIOClient from "socket.io-client";
import Endpoints from "../components/services/Endpoints";
import axios from "axios";
//import logo from '../../logo.svg';
import { useNavigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";

//import Nuovapratica from "./Nuovapratica";
import Footer from "../components/Footer/Footer";
import "bootstrap-icons/font//bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import useAuth from "../hooks/useAuth";

import client from "../utils/client";

const ENDPOINT = Endpoints.getUrls();
const TipoPratica_bkp = () => {
  const { isDemoUser } = useAuth();

  const [data, setData] = useState([]);

  const [tipoPratica, setTipoPratica] = useState("");
  const [resources, setResources] = useState([]);
  //    resources:[],
  const [altrePratiche, setAltrePratiche] = useState([]);
  const [assegniNucleoFamiliare, setAssegniNucleoFamiliare] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [certificazioni, setCertificazioni] = useState([]);
  const [disoccupazioniEmobilita, setDisoccupazioniEmobilita] = useState([]);
  const [emergenzaCovid19, setEmergenzaCovid19] = useState([]);
  const [pensioni, setPensioni] = useState([]);
  const [maternita, setMaternita] = useState([]);
  const [permessiEcongedi, setPermessiEcongedi] = useState([]);
  const [ratei, setRatei] = useState([]);
  const [ricostituzioni, setRicostituzioni] = useState([]);
  const [sostegnoAlReddito, setSostegnoAlReddito] = useState([]);
  const [versamentiVolontari, setVersamentiVolontari] = useState([]);
  const [invaliditaConvocazione, setInvaliditaConvocazione] = useState([]);
  const [invaliditaAccessoria, setInvaliditaAccessoria] = useState([]);

  const [history, setHistory] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const [maintenance, setMaintenance] = useState(Boolean);
  const [time, setTime] = useState("fetching");

  const createFilteredArray = (array) => {
    let superArray = [];
    let result = array.reduce(function (r, a) {
      r[a.tipo_pratica] = r[a.tipo_pratica] || [];
      r[a.tipo_pratica].push(a);
      return r;
    }, Object.create(null));

    superArray.push(result);
    console.log(superArray);
    setFiltered(result);
    setAltrePratiche(result["altre pratiche"]);
    setAssegniNucleoFamiliare(result["assegni nucleo familiare"]);
    setCertificazioni(result["certificazioni"]);
    setDisoccupazioniEmobilita(result["disoccupazioni e mobilità"]);
    setEmergenzaCovid19(result["emergenza covid-19"]);
    setPensioni(result["pensioni"]);
    setMaternita(result["maternità"]);
    setPermessiEcongedi(result["permessi e congedi"]);
    setRatei(result["ratei"]);
    setRicostituzioni(result["ricostituzioni"]);
    setSostegnoAlReddito(result["sostegno al reddito"]);
    setVersamentiVolontari(result["versamenti volontari"]);
    setInvaliditaConvocazione(result["invalidità - per convocazione a visita"]);
    setInvaliditaAccessoria(result["invalidità - fase concessoria AP70"]);
  };

  useEffect(() => {
    const doFetch = () => {
      client({ url: `/tipopratica/`, config: { method: "GET" } })
        .then((response) => response.data)
        .then((response) => {
          setResources(response);
          createFilteredArray(response);
          // console.log(response);
        });
    };
    doFetch();
  }, []);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_BASE_URL);
    console.log(socket);

    socket.on("time", (data) => setTime(data));
    socket.on("disconnect", () => setTime("server disconnected"));

    //  socket.on('status', (data)=>setMaintenance(data))
    //  socket.on('disconnect',()=>setTime('server disconnected'))

    socket.on("baby", (msg) => {
      console.log("baby", msg);
    });

    socket.on("maintenance", (payload) => {
      console.log("maintenance? : ", payload);
      setMaintenance(payload);
      checkMaintenance(JSON.parse(payload));
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, [maintenance]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${ENDPOINT}/tools/getmaintenance`,
    }).then(function (response) {
      console.log(response);
      if (response.data.status === true) {
        setMaintenance(true);
      }
      if (response.data.status === false) {
        setMaintenance(false);
      }
    });
  }, []);

  const checkMaintenance = (payload) => {
    console.log(payload);
  };

  const handleChange = (event) => {
    console.log(event.target.name, event.target.value); // { foo: 'bar' }

    localStorage.setItem(
      "tipoPaticaId",
      JSON.stringify({ _id: event.target.value })
    );
    /*
       this.setState({
         console.log(this.state) // { foo: 'bar' }
           [event.target.name]: event.target.value
       })
       */
    //  this.setState({ month: event.target.value })
    //  console.log(month);
  };
  const navigate = useNavigate();
  const HandleGo = (e) => {
    navigate("/consulente/nuova_pratica");
    //    return <Navigate to="/consulente/nuova_pratica?_id="+e />;

    //   navigate("../success", { replace: true });
    //  navigate(`/consulente/nuova_pratica?_id=${e}`, { replace: true });
  };

  const HandleRowClick = (e, value) => {
    localStorage.setItem("tipoPaticaId", JSON.stringify({ _id: value }));

    //    HandleGo(e.target.firstChild.data)
    console.log(value);
    navigate("/consulente/nuova_pratica", { state: { _id: `${value}` } });

    //   let navigate = useNavigate();
    //  this.redirectTo(e.target.firstChild.data);
    //   this.props.history.push(`/consulente/nuova_pratica?_id=${e.target.firstChild.data}`, { replace: true });

    // navigate(`/consulente/nuova_pratica?_id=${e.target.firstChild.data}`);

    // window.location.replace(`/consulente/nuova_pratica?_id=${e.target.firstChild.data}`);

    // this.props.history.push(`/consulente/nuova_pratica?_id=${e.target.firstChild.data}`);
    // this.redirectForm(e.target.firstChild.data)
    //  this.props.history.push(`/consulente/nuova_pratica?_id=${e.target.firstChild.data}`);

    // this.props.history.push("/nuova_pratica");
  };

  return (
    <Container style={{ paddingBottom: "50px" }}>
      {maintenance ? (
        <h3 className="text-white" style={{ marginTop: 50 }}>
          {" "}
          {new Date(time).toLocaleDateString()} L'inserimento di nuove pratiche
          è sospeso: puoi, comunque, vedere le tue pratiche{" "}
        </h3>
      ) : (
        <Form>
          <h1 className="text-white"></h1>
          <Accordion>
            {altrePratiche && altrePratiche.length && (
              <Accordion.Item eventKey="{a}">
                <Accordion.Header>
                  <i
                    className="bi bi-file-earmark-medical"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Altre Pratiche{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {altrePratiche.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {assegniNucleoFamiliare && assegniNucleoFamiliare.length && (
              <Accordion.Item eventKey="{b}">
                <Accordion.Header>
                  <i
                    className="bi bi-check-square"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Assegni nucleo familiare
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {assegniNucleoFamiliare.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {certificazioni && certificazioni.length && (
              <Accordion.Item eventKey="{c}">
                <Accordion.Header>
                  <i
                    className="bi bi-wallet2"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Certificazioni
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {certificazioni.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {disoccupazioniEmobilita && disoccupazioniEmobilita.length && (
              <Accordion.Item eventKey="{d}">
                <Accordion.Header>
                  <i
                    className="bi bi-person-workspace"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Disoccupazioni e mobilità
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {disoccupazioniEmobilita.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {emergenzaCovid19 && emergenzaCovid19.length && (
              <Accordion.Item eventKey="{e}">
                <Accordion.Header>
                  <i
                    className="bi bi-headset-vr"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Emergenza covid-19
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {emergenzaCovid19.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {invaliditaConvocazione && invaliditaConvocazione.length && (
              <Accordion.Item eventKey="{e2}">
                <Accordion.Header>
                  <i
                    className="bi bi-clipboard2-pulse"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Invalidità - per convocazione a visita
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {invaliditaConvocazione.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {invaliditaAccessoria && invaliditaAccessoria.length && (
              <Accordion.Item eventKey="{e3}">
                <Accordion.Header>
                  <i
                    className="bi bi-clipboard2-pulse"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Invalidità - fase concessoria ap70 (dopo ricevimento verbale
                  invalidità)
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {invaliditaAccessoria.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {maternita && maternita.length && (
              <Accordion.Item eventKey="{f}">
                <Accordion.Header>
                  <i
                    className="bi bi-basket"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Maternità
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {maternita.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {pensioni && pensioni.length && (
              <Accordion.Item eventKey="{g}">
                <Accordion.Header>
                  <i
                    className="bi bi-piggy-bank"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Pensioni
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {pensioni.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {permessiEcongedi && permessiEcongedi.length && (
              <Accordion.Item eventKey="{h}">
                <Accordion.Header>
                  <i
                    className="bi bi-door-open"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Permessi e congedi
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {permessiEcongedi.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {/*
            <Accordion.Item eventKey="{i}">
            <Accordion.Header><i className="bi bi-door-open" style={{ 'marginRight': '20px' }}></i> Ratei</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <tbody>
                  {ratei.map((item, x) => {

                    return (
                      <tr key={x} ><td>{item.descrizione}</td><td><button type="button" className="btn btn-primary" style={{float: 'right'}} onClick={(event)=> HandleRowClick(event, item._id)}> Crea</button></td></tr>
                    );
                  })}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
 */}
            {ricostituzioni && ricostituzioni.length && (
              <Accordion.Item eventKey="{l}">
                <Accordion.Header>
                  <i
                    className="bi bi-arrow-repeat"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Ricostituzioni
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {ricostituzioni.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {sostegnoAlReddito && sostegnoAlReddito.length && (
              <Accordion.Item eventKey="{m}">
                <Accordion.Header>
                  <i
                    className="bi bi-aspect-ratio"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Sostegno al reddito
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {sostegnoAlReddito.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}

            {versamentiVolontari && versamentiVolontari.length && (
              <Accordion.Item eventKey="{n}">
                <Accordion.Header>
                  <i
                    className="bi bi-handbag"
                    style={{ marginRight: "20px" }}
                  ></i>{" "}
                  Versamenti volontari
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <tbody>
                      {versamentiVolontari.map((item, x) => {
                        return (
                          <tr key={x}>
                            <td>{item.descrizione}</td>
                            <td>
                              <button
                                type="button"
                                disabled={isDemoUser}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={(event) =>
                                  HandleRowClick(event, item._id)
                                }
                              >
                                {" "}
                                Crea
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </Form>
      )}

      <Footer />
    </Container>
  );

  //
};
export default TipoPratica_bkp;
