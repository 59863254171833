import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RenewButton from "../Consulente/components/SubscriptionBanner/RenewButton";

const client = (req) => {
  const currentUser = localStorage.getItem("currentUser");
  const { token } = JSON.parse(currentUser);

  const { url, config } = req;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log(error);
      if (error.response.status === 401) {
        if (error.response.data.message === "JwtExpired") {
          localStorage.clear();
          window.location.href = "/signin";
        }
        return;
      }

      if (error.response.status === 403) {
        const MySwal = withReactContent(Swal);

        if (error.response.data.code === "InvalidSubscription") {
          MySwal.fire({
            title: "Errore",
            html: (
              <>
                <div style={{ marginBottom: "10px" }}>
                  Non sei autorizzato ad effettuare questa operazione. Continua
                  in modalità ospite o attiva le tue credenziali.
                </div>
                <div style={{ marginTop: "10px" }}></div>
              </>
            ),
            confirmButtonText: <RenewButton />,
            confirmButtonColor: "#0d6efd",
            showDenyButton: true,
            denyButtonText: "Continua in modalità ospite",
          });
        } else if (error.response.data.code === "DemoUserForbidden") {
          MySwal.fire(
            "Errore",
            "Non sei autorizzato ad effettuare questa operazione. Continua in modalità ospite o crea un account."
          );
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance(url, config || { method: "GET" });
};

export default client;
